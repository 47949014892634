/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
import { Modal, Button, Form } from "react-bootstrap";
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, Pagination } from "@mui/material";
import Key from '../clientVariables.json';


import { connect } from "react-redux";

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

function Parserresumeslist(props) {
    const location = useLocation();

    const [show, setShow] = useState(false);
    const [showfile, setShowfile] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [xlData, setXlData] = useState([]);

    const [page, setPage] = useState(0)
    const [uid, setUid] = useState(0)

    const [count, setCount] = useState(parseInt(100))
    const [resumeId, setResumeId] = useState("")
    const [selectedResumeIds, setSelectedResumeIds] = useState([]);
    const [xlFile, setXlFile] = useState();

    const handleShowfile = () => setShowfile(true);
    const handleClosefile = () => setShowfile(false);

    const handleClose = () => {
        // console.log("im cled") 
        setShow(false);
    };

    useEffect(() => {
        //alert(props.location.state && props.location.state.paginationNumber)
        getData(page)
    }, [])

    const getData = (value) => {
        console.log(value)
        props.showLoader();

        setPage(value)
        if (location.state.OPT === "YES") {
            var api = `${Key.domain}/dashboard/get_OPTresumes_per_day_week`
        } else {
            api = `${Key.domain}/dashboard/get_resumes_per_day_week`
        }
        axios
            .post(api, {
                "infinite_scroll_index": value,
                "resumeslist_per": location.state.id
            })
            .then((response) => {
                // console.log(response);
                setResumes(response.data.Resumes.Resumes)
                setCount(response.data.Resumes.Resumes_count.resumesCount)
                props.hideLoader();

            })
    }

    const deleteResumes = (resumeIds) => {
        props.showLoader();

        axios
            .post(`${Key.domain}/parser/parser_delete_resume`, {
                "resumeid": resumeIds
            })
            .then((response) => {
                console.log(response);
                props.hideLoader();

                //getData(page)
            })
    }



    const readExcel = (file) => {
        setXlFile(file)

    };




    const handleEdit = (resumeid) => {
        // if (selectedResumeIds.length > 1) {
        //   alert("Please select one resume to edit")
        // } else {
        props.history.push({
            pathname: "/Parserresumestabs",
            state: {
                resumeid: resumeid,
                paginationNumber: page
            }
        });
        // }

    }
    const handleXlUpload = () => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(xlFile);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                console.log(data);
                resolve(data);

            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setXlData(d);
        });



        fetch(`${Key.domain}/parser/readResumesExcelFile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({
                postdata: xlData,
            })
        }).

            then(response => response.json()) //converting data into json format
            .then((response) => {
                //console.log(response)
                if (response.success === 1) {
                    //console.log(xlData)
                    var resumeIds = xlData.map(data => data.ResumeId)
                    console.log(resumeIds)
                    if (resumeIds) {
                        for (let i = 0; i <= resumeIds.length; i++) {
                            axios
                                .post(`${Key.domain}/parser/get_parser_resume_details`, {
                                    resumeid: resumeIds[i],
                                })
                                .then(async response => {
                                    //console.log(response)
                                    if (response && response.status === 200 && response.data.data && response.data.data.resumedetails) {
                                        var resumedetails = response.data.data.resumedetails
                                        //console.log("resumedetails", resumedetails)
                                        //console.log(xlData[0])
                                        axios
                                            .post(`${Key.domain}/resume/js_add_resume_to_solr`, {
                                                created: resumedetails.created && resumedetails.created !== undefined && resumedetails.created !== null ? resumedetails.created : null,
                                                user_id: resumedetails.uid,
                                                // session_token: jssession_token,
                                                resume_id: resumeIds[i],
                                                application_title: xlData[i].ApplicationTitle,
                                                email_address: xlData[i].email_address || '',
                                                search_flag: 1,
                                                full_name: xlData[i].full_name || '',
                                                filename: resumedetails.filename || '',
                                                location_state: resumedetails.location_state || '',
                                                address_city: resumedetails.address_city || '',
                                                searchable: resumedetails.searchable === 1 ? true : false,
                                                section_skills: resumedetails.section_skills || '',
                                                summary: xlData[i].Summary || '',
                                                home_phone: xlData[i].Phone || '',
                                                jobtype: resumedetails.jobtype || '',
                                                total_experience: xlData[i].total_experience || '',
                                                visatype: resumedetails.visatype || '',
                                                OPT_issued_year: "2000",
                                                grad_year: resumedetails.graduation_year || '',
                                                res_stateName: xlData[i].stateName || '',
                                                res_cityName: xlData[i].cityName || '',
                                                res_jobtypeName: xlData[i].jobtypeName || '',
                                                skillName: xlData[i].skillName || '',
                                                profile_rating: resumedetails.profile_rating || '3.5',
                                                country: resumedetails.country_id || '',
                                                qualificationId: resumedetails.heighestfinisheducation || '',
                                                linkedin_url: resumedetails.linkedin_url || '',
                                                salary: resumedetails.desired_salary || '',
                                                salary_type: resumedetails.salary_type || '',
                                                res_countryName: resumedetails.countryname || "",
                                                res_shortRegion: xlData[i].shortRegion || '',
                                                res_visatypeName: xlData[i].visatypeName || '',
                                                res_salarrytypeName: resumedetails.jobsalaryrangetypeName || '',
                                                qualificationTitle: resumedetails.qualififcationtitle || '',
                                                willingToRelocate: resumedetails.willing_to_relocate !== undefined && resumedetails.willing_to_relocate === 1 ? true : false,
                                                viewed_by: resumedetails.viewed_by && resumedetails.viewed_by !== undefined ? resumedetails.viewed_by : ""
                                            })
                                            .then(async (response) => {
                                                console.log(response);

                                            });
                                    }

                                })

                        }
                    }
                }
            })

    }


    const columns = [
        {
            name: "id",
            label: "Resume Id",
            options: {
                filter: true,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },

            }

        },
        {
            name: "application_title",
            label: "Application Title",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },
            }
        },
        {
            name: "full_name",
            label: "Full Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "email_address",
            label: "Email",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "home_phone",
            label: "Phone no",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "total_experience",
            label: "Exp",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "last_modified",
            label: "Last Modified",
            options: {
                filter: true,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    // var usaTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
                    // usaTime = new Date(usaTime);
                    // var todaydate = moment(usaTime).format('L');
                    return moment(value).format('MMM DD, YYYY HH:mm A')
                },
            }
        },


        /* {
             name: "edit",
             label: "Edit",
             options: {
                 filter: true,
                 sort: false,
 
                 customBodyRender: (value, tableMeta, updateValue) => {
                    
                     ////console.log(value)
                     return <Tooltip title={"Edit Resume"}>
                         <IconButton onClick={() => handleEdit(tableMeta.rowData[0])}>
                             <EditIcon />
                         </IconButton>
                     </Tooltip>
                 },
             }
         },*/



    ];

    const options = {
        // filterType: 'checkbox',
        onCellClick: (cellIndex, rowIndex) => {
            //console.log(cellIndex,rowIndex)
            // console.log(cellIndex, rowIndex, rowIndex.dataIndex);
            console.log(rowIndex);
            console.log(rowIndex.dataIndex);

            if (cellIndex === 8) {

            }
            setShow(true);

        },
        filter: false,
        // rowsPerPage: 20,
        rowsPerPageOptions: false,
        count: count,
        serverSide: false,
        print: false,
        viewColumns: false,
        download: false,
        pagination: false,
        selectableRows: false,
        onChangePage: (currentPage) => {
            console.log(currentPage)
            setPage(parseInt(sessionStorage.getItem("parserResumesPageNumber")))
            sessionStorage.setItem("parserResumesPageNumber", currentPage)
            getData(parseInt(sessionStorage.getItem("parserResumesPageNumber")) + 1)
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        },



        onRowsDelete: (rowData, rowState) => {
            var resumeIds = rowData.data.map((row) => {
                return resumes[row.index].id;
            });
            if (window.confirm("Are you sure you want to delete resume?")) {
                console.log(resumeIds)
                deleteResumes(resumeIds);
            }
        },

        onRowClick: (rowData, rowState) => {
            var row = resumes[rowState.dataIndex];
            console.log(row)
            setResumeId(row.id)
            setUid(row.uid)
        },

        onRowsSelect: (currentRowsSelected, allRowsSelected) => {
            console.log(currentRowsSelected)
            //console.log(allRowsSelected)
            var shotlists = allRowsSelected.map((row) => {
                // setselectedRows(cands[row.index].candidate_id)
                return resumes[row.dataIndex];
            });
            console.log(shotlists.map(row => row.id))

            setSelectedResumeIds(shotlists.map(row => row.id))
            //    setSelectedJobs(shotlists)
        },


        onCellClick: (cellIndex, rowIndex) => {
            // console.log(cellIndex.value);
            // console.log(rowIndex.dataIndex);
            // console.log(rowIndex);

            var row = resumes[rowIndex.dataIndex];

            const url = `${Key.JNP_URL}/mail/redirect/resume_${row.id}`

            const pdfWindow = window.open();
            pdfWindow.location.href = url;


            if (rowIndex.colIndex === 4) {
                // console.log(cellIndex)
                // console.log(row)
                // handleResumeDownload(row, cellIndex)
            }
        },

        customToolbar: () => {
            //   return (
            //     <Toolbarparser active={false} isAddJob={true} handleShowfile={handleShowfile} />
            //   );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            {/* <Parsertoolbar
        selectedResumeIds={selectedResumeIds}

    />*/}
        ),

        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
        ) => {
            return <div>
                <br />
                <Pagination count={Math.ceil(count / 20)}
                    onChange={(e, value) => {
                        getData(value);
                        sessionStorage.setItem("parsedResumesPagination", value)
                    }}
                    defaultPage={parseInt(sessionStorage.getItem("parsedResumesPagination"))}
                    style={{ float: "right" }}
                />
                <br /><br />

            </div>
        }

        //   customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        //     return (
        //         <CustomFooter
        //             count={count}
        //             page={page}
        //             rowsPerPage={rowsPerPage}
        //             changeRowsPerPage={changeRowsPerPage}
        //             changePage={changePage}
        //             textLabels={textLabels}
        //         />
        //     );
        // },

    };



    return (
        <div className='container' style={{ paddingTop: "38px" }}>
            <Modal
                show={showfile}
                onHide={handleClosefile}
                backdrop="static"
                centered
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Excel Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: "50vw" }} method="post" enctype="multipart/form-data" action="/upload" >

                        {/* <input type="file" style={{ position: "relative" }} label="Upload Resume Manual:"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="input"
              onChange={readExcel}
  /> */}

                        <input
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                readExcel(file);
                            }}
                        />

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosefile}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleXlUpload}>Upload</Button>
                </Modal.Footer>
            </Modal>

            <MUIDataTable
                title={"Resumes"}
                data={resumes}
                columns={columns}
                options={options}
            />

            <br />
        </div>
    )
}


const mapDispatchToProps = dispatch => {

    return {
  
      showLoader: () => dispatch(showLoader()),
      hideLoader: () => dispatch(hideLoader()),
  
    }
  }
  
  function mapStateToProps(state, ownProps) {
    return {
      currentUser: state.UserReducer.user,
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Parserresumeslist);