import React, { useEffect, useState, useRef } from "react";
import './SalesMarketing.css';

import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { MdLocationOn } from "react-icons/md";
import ReactHtmlParser from "html-react-parser";
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import LeadsDetailView from './LeadsDetailView';
import Key from "../clientVariables.json";

import ToastMessage from "../components/ToastMessage";


export default function JobSearch() {
  const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Jobs and sending it across. Please find the attached Jobs and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

  const [data, setData] = useState({
    fromEmail: "hot-jobs@jobsnprofiles.com",
    fullname: '',
    toEMail: '',
    ccEMail: '',
    subj: '',
    body: mailbody,
    jobtitle: '',
    skillname: '',
    location: '',

  })
  const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location } = { ...data }
  const [profileResumes, setProfileResumes] = useState([]);
  const [selectedResIds, setSelectedResids] = useState([]);
  const [resumeChecked, setResumeChecked] = useState();
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [emails, setEmails] = useState([])



  const changeHandler = e => {
    e.preventDefault()
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const handleSearchJobs = (e) => {
    e.preventDefault();
    console.log(data)
    const jobtitle = JSON.stringify(data.jobtitle);
    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:${jobtitle}&fq=status:(1)&rows=10&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        // console.log(resdata.data.response.numFound)
        setProfileResumes(resdata.data.response.docs)

      });
    });
  }
  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
    let filteredobjs = []
    if (name === String(resumeId)) {
      console.log("comes here", resumeId)
      const newResumes = profileResumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setProfileResumes(newResumes);
      console.log(newResumes)
      const obj = newResumes.filter((x) => x.isChecked === true);
      filteredobjs = obj.filter((x) => x.application_title !== '');
      const ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      // setProfileResumes(filteredobjs);
    }

  };
  const handleSendMail = (e) => {
    e.preventDefault();
    console.log(data)
    // var jobtitle = '"' + data.jobtitle + '"'
    console.log(profileResumes)
    console.log(selectedResumes)
    if (toEMail && subj && fromEmail && fullname) {

    if (selectedResumes.length > 5) {


      const msg = "Maximum limit is to share resumes is 5";
      toast(<ToastMessage message={msg} />);

    } else if (selectedResumes.length > 0) {
      fetch(`${Key.domain}/common/send_contactSales_jobs_Mail`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ formdata: data, jobdata: selectedResumes.length > 0 ? selectedResumes : profileResumes }),
      })
        .then((response) => {
          response.json().then((resdata) => {
            console.log(resdata)
            if (resdata.data === "Mail sent To Employer") {
              const msg = "Mail Sent Successfully";
              toast(<ToastMessage message={msg} />);
              window.location.reload()
            }
          })
        })
    }
    else {
      const msg = "Select atleast 1 Job";
      toast(<ToastMessage message={msg} />);

    }

  } else {

    const msg = "Enter required fields";
    toast(<ToastMessage message={msg} />);

  }
  }
  return (
    <div>

      <div className='container'>
        <div className='row'>
          <div className='col-md-6 sales-styles'>

            <div className="sales-style">
              <Form>
                
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                  <Form.Label column sm={2}>
                    From * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Name * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2} >
                    To * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="eg : example@domain.com" name="toEMail" value={toEMail} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2} >
                    Cc:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="eg : something@domain.com, something2@domain.com" name="ccEMail" value={ccEMail} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Sub * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="Subject" name="subj" value={subj} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Body:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control as="textarea" rows={3} name="body" value={body} onChange={changeHandler} />
                  </Col>
                </Form.Group> */}

              </Form>

              <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

              <Form>
                <Row className="align-items-center">
                  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Job Title
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Job Title"
                      name="jobtitle" value={jobtitle} onChange={changeHandler}
                    />
                  </Col>
                {/*  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Skills
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Skills"
                      name="skillname" value={skillname} onChange={changeHandler}
                    />
              </Col> */}
                  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Location
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Location"
                      name="location" value={location} onChange={changeHandler}
                    />
                  </Col>
                </Row>
              </Form>

              <Form onSubmit={(e) => handleSearchJobs(e)}>

                <Button className="mt-5" variant="primary" type="submit" style={{ float: "right", }}>
                  Search
                </Button>
              </Form>

            </div>

          </div>


          <div className='col-md-6 sales-styles'>
            <div className="positions-scroll">
              {profileResumes.length > 0 ? <h4>These are the active JOBS for your requirement {data.jobtitle} </h4> : <h4>Search for JOBS</h4>}
              <Form onSubmit={(e) => handleSendMail(e)}>
              <Button variant="primary" type="submit" style={{ float: "right", marginTop:"-35px" }}>
                Share Mail
              </Button>
            </Form>

              <br />

              {profileResumes.length > 0 ? profileResumes.map((resume) => {
                console.log((resume.workmode === "true"))
                return (
                  <>
                    <div className="job-titile" style={{ padding: "5px" }}>
                      <Row className="job-checkbox">
                        <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                          id="check"
                          checked={resume?.isChecked}
                          onClick={(e) => handleChange(e, resume.id)} /></Col>
                        <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }}> {resume.title} </a> </h3> </Col>
                      </Row>


                      <ul>
                        <li> <FaSuitcase /> {resume.prefferdskillsName
                          ? resume.prefferdskillsName
                            .join(",")
                            .split(",")
                            .join(", ")
                            .slice(0, 100)
                          : "N/A"}</li>

                        <li style={{ float: "left" }}> <MdLocationOn /> {resume.workmode === "true" ? "Remote" : <>{resume.cityName}, {resume.stateName}</>} </li>

                        <li style={{ float: "right" }}> <FaCalendarAlt />

                          {resume.created ? moment(resume.created).format("LL") : "N/A"
                          }
                        </li>


                      </ul>
                    </div>
                    <hr style={{ width: "100%" }} />
                  </>)

              }) : <center style={{ marginTop: "50px" }}> <h4>No JOBS</h4> </center>
              }



            </div>
            <br />
           

          </div>
        </div>
        <hr />
      </div>

    </div>
  )
}
