/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Personaldetails from "./Customerpersonaldetails";
import Companydetails from "./Companydetails";
import Packagelimitations from "./Packagelimitations";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [compuserId, setCompUserId] = React.useState(0);
  const [companyId, setCompanyId] = React.useState(0);
  const [customerDetailsDisable, setCustomerDetailsDisable] = React.useState(false);
  const [companyDetailsDisable, setCompanyDetailsDisable] = React.useState(true);
  const [packageDetailsDisable, setPackageDetailsDisable] = React.useState(true);


  useEffect(() => {
    if (location.state.upgrade === true) {
      setValue(2)
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (newValue, useridForCompany) => {
    setValue(newValue);
    setCompUserId(useridForCompany)
    setCompanyDetailsDisable(false)
  }
  const handleCompanyTabChange = (tabvalue, companyId) => {
    setValue(tabvalue);
    sessionStorage.setItem("customer_companyid", companyId)
    setCompanyId(companyId)
    setPackageDetailsDisable(false)

  }


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Customer Details" {...a11yProps(0)} disabled={customerDetailsDisable} />
          <Tab label="Company details" {...a11yProps(1)} disabled={companyDetailsDisable}/>
          <Tab label="Package Limitations" {...a11yProps(2)} disabled={packageDetailsDisable}/>

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Personaldetails handleTabChange={handleTabChange} companyuserid={compuserId !== 0 ? compuserId : location.state.customerid} /> {/*companyuserid means customerid*/}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Companydetails companyId={companyId} handleTabChange={handleCompanyTabChange} companyuserid={compuserId !== 0 ? compuserId : location.state.customerid} />
      </TabPanel>
      <TabPanel value={value} index={2} >
        <Packagelimitations freepackage={location.state.freepackage} companyuserid={compuserId !== 0 ? compuserId : location.state.customerid} companyId={companyId} />
      </TabPanel>
    </Box>
  );
}
