/* eslint-disable*/
import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Companiestabs from './Companiestabs';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BiArrowBack } from 'react-icons/bi';
import Companylogo from '../components/images/company.png'
import Key from "../clientVariables.json";
import axios from "axios"
import Modal from 'react-bootstrap/Modal';

function CompaniesList() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [companies, setCompanies] = useState([])
    const [companyId, setCompanyId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [lcaDetails, setLcaDetails] = useState([])
    const [h1bDetails, seth1bDetails] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [searchCompanyName, setsearchCompanyName] = useState("")
    const [searchLocation, setSearchLocation] = useState("")
    const [lcaStatus, setLcaStatus] = useState([])
    const [greenCardDetails, setGreenCardDetails] = useState([])
    const [greenCardStatus, setGreenCardStatus] = useState([])
    const [h1bStatus, seth1bStatus] = useState([])
    const [attorneyDetails, setAttorneyDetails] = useState([])

    useEffect(() => {
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=*%3A*&start=0&wt=json`

        console.log(searchResumesUrl)
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                  
                    setCompanies(resdata.data.response.docs)
                    setCompanyId(resdata.data.response.docs[0].id)
                    setCompanyName(resdata.data.response.docs[0].name)
                    getLcaStatus(resdata.data.response.docs[0].id)
                    getLcaDetails(resdata.data.response.docs[0].id)
                    getGreenCardDetails(resdata.data.response.docs[0].id)
                    getGreenCardStatus(resdata.data.response.docs[0].id)
                    getH1BStatus(resdata.data.response.docs[0].id)
                    getAttorneys(resdata.data.response.docs[0].id)
                    getCompanyDetails(resdata.data.response.docs[0].id)
                })
            })
    }, [])

    const getLcaDetails = (compid) => {
        axios.post(`${Key.domain}/company/get_lca_details`, {
            "company_id": compid
        })
            .then(resdata => {
                console.log(resdata.data.data.lcaDetails)
                setLcaDetails(resdata.data.data.lcaDetails)
            })
    }


    const getGreenCardDetails = (compid) => {
        axios.post(`${Key.domain}/company/get_GreenCard_details`, {
            "company_id": compid
        })
            .then(resdata => {
                console.log(resdata.data.data.greenCardDetails)
                setGreenCardDetails(resdata.data.data.greenCardDetails)
            })

    }

    const getH1bDetails = (compid) => {
        axios.post(`${Key.domain}/company/get_h1b_details`, {
            "company_id": compid
        })
            .then(resdata => {
                console.log(resdata.data.data.h1bdetails)
                seth1bDetails(resdata.data.data.h1bdetails)
            })

    }

    const getLcaStatus = (compid) => {
        axios.post(`${Key.domain}/company/get_LCA_Status`, {
            "company_id": compid
        })
            .then(resdata => {
                setLcaStatus(resdata.data.data)
                //  seth1bDetails(resdata.data.data.h1bdetails)
            })

    }



    const getGreenCardStatus = (compid) => {
        axios.post(`${Key.domain}/company/get_GreenCard_Status`, {
            "company_id": compid
        })
            .then(resdata => {
                setGreenCardStatus(resdata.data.data)
                //  seth1bDetails(resdata.data.data.h1bdetails)
            })

    }


    const getH1BStatus = (compid) => {
        axios.post(`${Key.domain}/company/get_H1B_status`, {
            "company_id": compid
        })
            .then(resdata => {
                seth1bStatus(resdata.data.data.h1bStatus)
            })

    }


    const clickOnBack = () => {
        sessionStorage.removeItem("hideSideBar");
        window.location.href = "/dashboard/app"
    }

    const getCompanyDetails = (company_ID) => {
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?fq=id:${company_ID}&start=0&wt=json`
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    setCompanyId(resdata.data.response.docs[0].id)
                    setCompanyName(resdata.data.response.docs[0].name)
                    setCompanyDetails(resdata.data.response.docs[0])
                })
            })
    }



    const searchCompany = (e) => {
        e.preventDefault()
        var companyName = '"' + searchCompanyName + '"'
        var location = '"' + searchLocation + '"'
        if (searchCompanyName !== "") {
            var searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=name:${(companyName)}&start=0&wt=json`

        } else if (searchLocation !== "") {
            searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?fq=${(location)}&start=0&wt=json`

        } else if (searchCompanyName !== "" && searchLocation !== "") {
            searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=name:${(companyName)}&fq=${(location)}&start=0&wt=json`
        }
        else {
            searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=*%3A*&start=0&wt=json`

        }
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    console.log(resdata)
                    setCompanies(resdata.data.response.docs)
                    setCompanyId(resdata.data.response.docs[0].id)
                    setCompanyName(resdata.data.response.docs[0].name)

                })
            })
    }

    const lcaStatusChange = (status) => {
        axios.post(`${Key.domain}/company/get_lca_details`, {
            "company_id": companyId
        })
            .then(resdata => {
                console.log(resdata.data.data.lcaDetails)
                if (status !== "LCA Status") {
                    setLcaDetails(resdata.data.data.lcaDetails.filter(data => data.CASE_STATUS === status))
                } else {
                    setLcaDetails(resdata.data.data.lcaDetails)

                }
            })

    }

    const greenCardStatusChange = (status) => {

        axios.post(`${Key.domain}/company/get_GreenCard_details`, {
            "company_id": companyId
        })
            .then(resdata => {
                setGreenCardDetails(resdata.data.data.greenCardDetails.filter(data => data.CASE_STATUS === status))
            })

    }

    const getAttorneys = (compid) => {
        axios.post(`${Key.domain}/company/get_company_Attorneys`, {
            "company_id": compid
        })
            .then(resdata => {
                //console.log(resdata.data.data.companyAttorneyDetails)
                setAttorneyDetails(resdata.data.data.companyAttorneyDetails)
            })
    }

  

      
    return (
        <div style={{marginTop:"-40px"}}>
            <div className='container'>
                <span>



                </span>
                <div className="companiessearch">
                    <div className='row'>



                        <div>
                            <Form onSubmit={(e) => searchCompany(e)}>

                                {/*<Modal show={show} onHide={handleClose} size="lg" centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Filters</Modal.Title>
                                    </Modal.Header>
    <Modal.Body>*/}


                                {/*   </Modal.Body>

</Modal>*/}
                                <div className='row'>
                                    <div className='col-md-2'> </div>
                                    <div className='col-md-3'>
                                        <fieldset>
                                            <Form.Group className="mb-3">

                                                <Form.Control id="disabledTextInput" placeholder="Company Name" onChange={(e) => setsearchCompanyName(e.target.value)} />
                                            </Form.Group>
                                        </fieldset>
                                    </div>

                                    <div className='col-md-3'>
                                        <fieldset>
                                            <Form.Group className="mb-3">

                                                <Form.Control placeholder="Location" onChange={(e) => setSearchLocation(e.target.value)} />
                                            </Form.Group>
                                        </fieldset>
                                    </div>

                                    <div className='col-md-2'>
                                        <Button type="submit" >Submit</Button><br />
                                        {/* <p onClick={handleShow} className='mt-2 ml-2' style={{ textDecoration: "underline", marginLeft: "19px", cursor: "pointer" }}><span>Filters</span></p>*/}
                                    </div>


                                    <div className='col-md-10'>
                                        <br />
                                        <br />
                                        <form>
                                            <div className='row'>


                                                {/* <div className='col-sm-3'>
                                                    <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                                                        <option selected>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
</div>*/}

                                                <div className='col-sm-3'>

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => lcaStatusChange(e.target.value)}>
                                                        <option selected>LCA Status</option>
                                                        <option value="Certified">Certified</option>
                                                        <option value="Withdrawn">Withdrawn</option>
                                                        <option value="Certified - Withdrawn">Certified - Withdrawn</option>
                                                        <option value="Denied">Denied</option>
                                                    </select>
                                                </div>
                                                <div className='col-sm-3'>
                                                    <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => greenCardStatusChange(e.target.value)}>
                                                        <option selected>Green Card Status</option>
                                                        <option value="Certified">Certified</option>
                                                        <option value="Certified-Expired">Certified-Expired</option>
                                                        <option value="Withdrawn">Withdrawn</option>
                                                        <option value="Denied">Denied</option>

                                                    </select>
                                                </div>
                                                <div className='col-sm-3'>

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                                                        <option selected>Filter3</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                               </div>*/}



                                            </div>

                                        </form>
                                        <br />

                                    </div>

                                    <div className='col-md-2'>

                                        <button type="button" style={{ float: "right" }} className='btn btn-outline-primary' onClick={() => clickOnBack()}>
                                            <BiArrowBack size={18} />
                                            &nbsp;Back
                                        </button>
                                    </div>
                                </div>




                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>


                <div className='col-md-3' style={{ height: "800px", overflow: "scroll" }}>

                    {

                        companies.map(

                            (value) =>
                                <div>
                                    <Card className='mb-2' >
                                        <Card.Body style={{ backgroundColor: value.id === companyId ? "#acd8ff" : "aliceblue" }}>
                                            <div className='row' >
                                                <div className='col-md-3'>

                                                    <img src={value.logo_url !== "N/A" && value.logo_url !== undefined ? value.logo_url : Companylogo} />
                                                </div>

                                                <div className='col-md-9'>
                                                    <p style={{ color: "#406882", fontSize: "15px", fontWeight: "bold", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setCompanyId(value.id);
                                                            setCompanyName(value.name);
                                                            getLcaDetails(value.id);
                                                            getH1bDetails(value.id);
                                                            getCompanyDetails(value.id)
                                                            getLcaStatus(value.id);
                                                            getGreenCardDetails(value.id)
                                                            getGreenCardStatus(value.id)
                                                            getH1BStatus(value.id)
                                                            getAttorneys(value.id)
                                                        }}
                                                    >{value.name}</p>
                                                </div>
                                            </div>
                                        </Card.Body>

                                    </Card>
                                </div>

                        )
                    }

                </div>

                <div className='col-md-9'>
                    <Companiestabs companyId={companyId} companyName={companyName} lcaDetails={lcaDetails} h1bDetails={h1bDetails} companyDetails={companyDetails} lcaStatus={lcaStatus} greenCardDetails={greenCardDetails} greenCardStatus={greenCardStatus} h1bStatus={h1bStatus} attorneyDetails={attorneyDetails} />
                </div>
            </div>
        </div >
    )
}

export default CompaniesList