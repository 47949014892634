/* eslint-disable */
import React from 'react'
import MUIDataTable from "mui-datatables";
import { FcDocument } from "react-icons/fc";
import UserMoreMenu from '../sections/@dashboard/user/Customersusersactions';


const CustomerContactInfo = (props) => {
  const { recruiterDetails } = props;



  const columns = [
    {
      name: "recruiter_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "recruiter_email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "phone",
      label: "Contact No",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      }
    },

    //      {
    //        name: "actions",

    //   label: <span>Status</span>,

    //   options: {

    //     customBodyRender: (value, tableMeta, updateValue) => {

    //       return <UserMoreMenu />
    //       // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

    //     },

    //     filter: true,

    //     sort: true,



    //   },
    // }



  ];



  const options = {
    filterType: 'checkbox',
    download: 'false',
    print: "false",
    viewColumns: "false"
  };

  return (
    <div>
      <MUIDataTable
        title={"Users List"}
        data={recruiterDetails}
        columns={columns}
        options={options}
      />
      {/* <div className="container">
        <div className="row">
          <div className="col-6">
          <div className="card p-2 mb-4">
          <ul className="list-group list-group-flush">
    <li className="list-group-item">
    <div className="row mb-1">
                <div className="col-5">
                  {' '}
                  <b> Name</b>
                </div>
                <div className="col-1">:</div>
                <div className="col-6">{companyDetails.contactname ? companyDetails.contactname : "N/A"}</div>
              </div>
      </li>
      <li className="list-group-item">
      <div className="row mb-1">
                <div className="col-5">
                  {' '}
                  <b> Email</b>
                </div>
                <div className="col-1">:</div>
                <div className="col-6">{companyDetails.contactemail ? companyDetails.contactemail : "N/A"}</div>
              </div>
      </li>
      <li className="list-group-item">
      <div className="row mb-1">
                <div className="col-5">
                  {' '}
                  <b> Phone</b>
                </div>
                <div className="col-1">:</div>
                <div className="col-6">{companyDetails.contactphone ? companyDetails.contactphone : "N/A"}</div>
              </div>
      </li>
      </ul>
              
              
              

            </div>
          </div>
          </div>
          </div>
  */}
    </div>
  )
}

export default CustomerContactInfo