import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Jobseekerregistrationsold from './Jobseekerregistrationsold';
import Employerregistrationsold from './Employersregistrationsold';
import Jobseekerregistrations from './Jobseekerregistrations';
import Employerregistrations from './Employersregistrations';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', marginTop: "-60px" }} >
      <center><h4>Virtual Jobfair Registrations On October 18th 2023</h4></center>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Job Seeker" {...a11yProps(0)} />
          <Tab label="Employers" {...a11yProps(1)} />
          <Tab label="Previous Job Seeker" {...a11yProps(0)} />
          <Tab label="Previous Employers" {...a11yProps(1)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Jobseekerregistrations />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Employerregistrations />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Jobseekerregistrationsold />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Employerregistrationsold />
      </TabPanel>

    </Box>
  );
}