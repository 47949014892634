/* eslint-disable */

import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import "./users.css"

import ActiveSubscribersTab from "./ActiveSubscribersTab";
import InactiveSubscribersTab from "./InactiveSubscribersTab";
import NonVerifiedTab from "./NonVerifiedTab";
import { Box, Tab, Tabs } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataTable } from 'mui-datatables';
import "./users.css"

const theme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
  },
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%',marginTop:"-60px", padding: "0px" }} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "0px"  }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Expired" {...a11yProps(1)}  />
            <Tab label="Non Verified" {...a11yProps(2)} />
           
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} >
        <ActiveSubscribersTab  />
        </TabPanel>
        <TabPanel value={value} index={1} >
          <InactiveSubscribersTab />
        </TabPanel>
        <TabPanel value={value} index={2} >
          <NonVerifiedTab />
        </TabPanel>
    
      </Box>
      </ThemeProvider>
    );
  }