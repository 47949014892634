/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import { BsTelephone } from 'react-icons/bs';
import { LuUsers } from 'react-icons/lu';
import { CiLocationOn } from 'react-icons/ci';
import { TbWorld, TbAddressBook, TbMapPinCode, TbBrandLinkedin, TbBriefcase } from 'react-icons/tb';
import { BiBuilding } from 'react-icons/bi';
import { AiFillLinkedin, AiOutlineHeatMap, AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { SlLocationPin } from 'react-icons/sl';
import InfiniteScroll from 'react-infinite-scroll-component';
import Target from '../components/images/target.png';
import Key from '../clientVariables.json';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Companyinfo() {
  const [search, setSearch] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [lcaDetails, setLcaDetails] = useState([]);
  const [h1bDetails, seth1bDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [searchCompanyName, setsearchCompanyName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [lcaStatus, setLcaStatus] = useState([]);
  const [greenCardDetails, setGreenCardDetails] = useState([]);
  const [greenCardStatus, setGreenCardStatus] = useState([]);
  const [h1bStatus, seth1bStatus] = useState([]);
  const [attorneyDetails, setAttorneyDetails] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [visaStrength, setVisaStrength] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showMessage, setShowMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [visaByyear, setvisaByYear] = useState([]);
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newValue1) => {
    setValue1(newValue1);
  };
  const limit = 10;
  useEffect(() => {
    fetchCompaniesData();
  }, []);

  const fetchMoreData = () => {
    fetch(`${Key.domain}/company/get_Companies_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: offset, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        if (resdata.data.length === 0) {
          setHasMore(false);
        } else {
          setCompanies((prevData) => [...prevData, ...resdata.data]);
          setOffset(offset + resdata.data.length);
        }
      });
    });
  };

  const getCompanyDetails = (company_ID) => {
    fetch(`${Key.domain}/company/get_Company_Details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ company_id: company_ID }),
    }).then((response) => {
      response.json().then((resdata) => {
        setCompanyId(resdata.data[0].id);
        setCompanyName(resdata.data[0].name);
        setCompanyDetails(resdata.data[0]);
      });
    });
  };

  const fetchCompaniesData = () => {
    fetch(`${Key.domain}/company/get_Companies_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        setCompanies(resdata.data);

        // setCompanies(prevData => [...prevData, ...resdata.data]);
        // setPage(page + 1);

        setCompanyId(resdata.data[0].id);
        setCompanyName(resdata.data[0].name);
        /* getLcaStatus(resdata.data.response.docs[0].id);
        getLcaDetails(resdata.data.response.docs[0].id);
        getGreenCardDetails(resdata.data.response.docs[0].id);
        getGreenCardStatus(resdata.data.response.docs[0].id);
        getH1BStatus(resdata.data.response.docs[0].id); */
        getAttorneys(resdata.data[0].id);
        getCompanyDetails(resdata.data[0].id);
        getCOmpanyContacts(resdata.data[0].id);
        getVisaStrength(resdata.data[0].id);
      });
    });
  };

  const getAttorneys = (compid) => {
    axios
      .post(`${Key.domain}/company/get_company_Attorneys`, {
        company_id: compid,
      })
      .then((resdata) => {
        setAttorneyDetails(resdata.data.data.companyAttorneyDetails);
      });
  };

  const getCOmpanyContacts = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_Contacts`, {
        company_id: compId,
      })
      .then((resdata) => {
        setCompanyContacts(resdata.data.data.companyContacts);
      });
  };

  const getVisaStrength = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_Count`, {
        company_id: compId,
      })
      .then((resdata) => {
        setVisaStrength(resdata.data.data);
      });
  };

  const getVisaByYear = (e) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_byYear`, {
        company_id: companyId,
        year: e.target.value,
      })
      .then((resdata) => {
        console.log(resdata);
        setvisaByYear(resdata.data.data);
      });
  };

  const searchCompany = (value) => {
    console.log(value.length);
    if (value.length > 0) {
      axios
        .post(`${Key.domain}/company/search_company`, {
          companyName: value,
        })
        .then((resdata) => {
          console.log(resdata);
          setCompanies(resdata.data.data);
        });
    } else {
      fetchCompaniesData();
    }
  };

  return (
    <div>
      <div className="row" style={{ marginTop: '-70px' }}>
        <div
          className="col-3"
          style={{
            borderRightColor: '#000',

            padding: '0px',
            width: '260px',
            position: 'fixed',
          }}
        >
          <h5 style={{ color: '#a63755' }}>My Companies</h5>
          <div className="mb-0">
            <input
              type="text"
              //value={search}
              placeholder="Search Company"
              onChange={(e) => searchCompany(e.target.value)}
              style={{ width: '250px', borderRadius: '12px', border: '1px solid lightgrey', padding: '5px' }}
            />
          </div>{' '}
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <InfiniteScroll
              dataLength={offset * 10}
              next={fetchMoreData}
              hasMore={hasMore}
              //loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              height={550}
              style={{ overflowY: 'scroll', overflowX: 'hidden' }}
              scrollableTarget="scrollableDiv"
              // refreshFunction={refresh}
              // pullDownToRefresh
              pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              // }
            >
              {companies.map((value) => (
                <Card>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-2">
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleRoundedIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </div>
                      <div
                        className="col-10"
                        style={{
                          color: '#406882',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          paddingLeft: '22px',
                          paddingTop: '9px',
                        }}
                        onClick={() => {
                          setCompanyId(value.id);
                          setCompanyName(value.name);

                          getCompanyDetails(value.id);

                          getAttorneys(value.id);
                          getCOmpanyContacts(value.id);
                          getVisaStrength(value.id);
                        }}
                      >
                        {value.name.slice(0, 50)}
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </InfiniteScroll>
          </List>
        </div>
        <div className="col-9" style={{ marginTop: '5px', width: '1027px', marginLeft: '269px' }}>
          <div>
            <img src={companyDetails.logo_url} style={{ width: '100px' }} alt="test" className="img-circle" />

            {/*<h2 style={{ marginTop: '15px' }}>{companyDetails.name}</h2>*/}
          </div>

          <div className="company name"></div>

          <div className="row">
            <div className="col-12">
              <br />
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    variant="fullwidth"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#a63755',
                      },
                    }}
                  >
                    <Tab label="Company Info" {...a11yProps(0)} style={{ minWidth: '20%' }} />
                    <Tab label="Contacts" {...a11yProps(1)} style={{ minWidth: '20%' }} />
                    <Tab label="Attroneys" {...a11yProps(2)} style={{ minWidth: '20%' }} />
                    <Tab label="Visa Status" {...a11yProps(3)} style={{ minWidth: '20%' }} />
                    <Tab label="Recruiters" {...a11yProps(3)} style={{ minWidth: '20%' }} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="mt-3">
                    <h3>
                      {' '}
                      <BiBuilding style={{ color: 'rgb(166, 55, 85)' }} />
                      &nbsp;
                      {companyDetails.name}
                    </h3>

                    <p>
                      {' '}
                      We are a pure-play data and digital engineering solutions company. Our proven Digital Business
                      Methodology accelerates growth with greater consistency, speed..
                    </p>
                    <p>
                      <BsTelephone style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                      {companyDetails.contactphone ? companyDetails.contactphone : 'N/A'}{' '}
                    </p>

                    <p>
                      <AiOutlineMail style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;{companyDetails.contactemail}{' '}
                    </p>

                    <p>
                      <CiLocationOn style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;USA
                    </p>

                    <p>
                      <TbMapPinCode style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                      {companyDetails.zipcode}{' '}
                    </p>

                    <p>
                      <TbBrandLinkedin style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                      <a
                        href={companyDetails.linkedin}
                        target="_blank"
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        <span>LinkedIn Profile</span>{' '}
                      </a>
                    </p>

                    <p>
                      <TbWorld style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                      {companyDetails.url}{' '}
                    </p>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <br />
                  <div className="row">
                    {companyContacts.map((contact) => (
                      <div className="col-4">
                        <Card>
                          <Card body>
                            <div className="row" style={{ fontSize: '15px', fontWeight: '600' }}>
                              <div className="col-12" style={{ fontSize: '12px' }}>
                                <img
                                  src={
                                    contact.EMP_PHOTO_URL != null
                                      ? contact.EMP_PHOTO_URL
                                      : 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                                  }
                                  className="rounded"
                                  style={{ width: '70', height: '60px', marginBottom: '5px' }}
                                />
                                <p style={{ lineHeight: '13px' }}>
                                  <AiOutlineUser size={15} style={{ marginRight: '12px' }} /> {contact.EMP_CONTACT_NAME}{' '}
                                  {contact.lastname}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  <TbBriefcase size={15} style={{ marginRight: '12px' }} /> {contact.EMP_DECL_TITLE}
                                </p>
                                {contact.EMP_CONTACT_EMAIL &&
                                contact.EMP_CONTACT_EMAIL !== '' &&
                                contact.EMP_CONTACT_EMAIL !== null &&
                                contact.EMP_CONTACT_EMAIL !== undefined ? (
                                  <p style={{ lineHeight: '13px' }}>
                                    <AiOutlineMail size={15} style={{ marginRight: '12px' }} />

                                    <span>{contact.EMP_CONTACT_EMAIL}</span>
                                  </p>
                                ) : (
                                  <p style={{ lineHeight: '13px' }}>
                                    <AiOutlineMail size={15} style={{ marginRight: '12px' }} />

                                    <span>N/A</span>
                                  </p>
                                )}

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlinePhone size={15} style={{ marginRight: '12px' }} />{' '}
                                  {contact.EMP_CONTACT_PHONE}
                                </p>
                                {contact.EMP_LINKEDIN_URL &&
                                contact.EMP_LINKEDIN_URL !== '' &&
                                contact.EMP_LINKEDIN_URL !== null &&
                                contact.EMP_LINKEDIN_URL !== undefined ? (
                                  <p style={{ lineHeight: '13px' }}>
                                    <TbBrandLinkedin size={15} style={{ marginRight: '12px' }} />
                                    <a target="_blank" href={contact.EMP_LINKEDIN_URL}>
                                      <span>LinkedIn Url</span>
                                    </a>
                                  </p>
                                ) : (
                                  <p style={{ lineHeight: '13px' }}>
                                    <TbBrandLinkedin size={15} style={{ marginRight: '12px' }} />

                                    <span>N/A</span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Card>
                        <br />
                      </div>
                    ))}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <br />
                  <div className="row">
                    {attorneyDetails.map((attorney) => (
                      <div className="col-6">
                        <Card>
                          <Card body>
                            <>
                              <div className="col-12" style={{ fontSize: '12px' }}>
                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineUser size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_NAME}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineUser size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_FIRM_NAME}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineMail size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_EMAIL}
                                </p>
                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlinePhone size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_PHONE}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <BiBuilding size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_CITY} |{' '}
                                  {attorney.AGENT_ATTORNEY_COUNTRY === 'UNITED STATES OF AMERICA'
                                    ? 'USA'
                                    : attorney.AGENT_ATTORNEY_COUNTRY}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <SlLocationPin size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_ADDRESS_1}, {attorney.AGENT_ATTORNEY_ADDRESS_2},{' '}
                                  {attorney.AGENT_ATTORNEY_POSTAL_CODE}
                                </p>
                              </div>
                            </>
                          </Card>
                        </Card>
                        <br />
                      </div>
                    ))}{' '}
                    &nbsp;
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                  <div>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => getVisaByYear(e)}
                    >
                      <option selected>Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                    </select>
                    &nbsp;
                    {visaByyear.map((visa) => (
                      <Card>
                        <Card body>
                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <p style={{ lineHeight: '20px', fontSize: '15px' }}>Green Card</p>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Certified</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.certified}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Certified-Exipred</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.certified_expired}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Withdrawn</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.withdrawn}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Denied</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.denied}</div>
                          </div>
                        </Card>
                      </Card>
                    ))}
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companyinfo;
