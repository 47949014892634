/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Companiestabs from './Companiestabs';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BiArrowBack } from 'react-icons/bi';
import Key from '../clientVariables.json';
import axios from 'axios';
import Logo from 'src/components/Logo';
import InfiniteScroll from 'react-infinite-scroll-component';

import Companylogo from '../components/images/company.png';
import { AiOutlineHeatMap, AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { SlLocationPin } from 'react-icons/sl';
import { TbMapPinCode } from 'react-icons/tb';
import { BiBuilding } from 'react-icons/bi';
import { set } from 'lodash';

function Newmenu() {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [lcaDetails, setLcaDetails] = useState([]);
  const [h1bDetails, seth1bDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [searchCompanyName, setsearchCompanyName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [lcaStatus, setLcaStatus] = useState([]);
  const [greenCardDetails, setGreenCardDetails] = useState([]);
  const [greenCardStatus, setGreenCardStatus] = useState([]);
  const [h1bStatus, seth1bStatus] = useState([]);
  const [attorneyDetails, setAttorneyDetails] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [visaStrength, setVisaStrength] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showMessage, setShowMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [visaByyear, setvisaByYear] = useState([]);

  const limit = 10;
  useEffect(() => {
    fetchCompaniesData();
  }, []);

  const fetchMoreData = () => {
    fetch(`${Key.domain}/company/get_Companies_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: offset, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        if (resdata.data.length === 0) {
          setHasMore(false);
        } else {
          setCompanies((prevData) => [...prevData, ...resdata.data]);
          setOffset(offset + resdata.data.length);
        }
      });
    });
  };

  const fetchCompaniesData = () => {
    fetch(`${Key.domain}/company/get_Companies_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        setCompanies(resdata.data);

        // setCompanies(prevData => [...prevData, ...resdata.data]);
        // setPage(page + 1);

        //console.log.log("res", resdata)
        //setCompanies(resdata.data);
        setCompanyId(resdata.data[0].ID);
        setCompanyName(resdata.data[0].EMPLOYER_NAME);
        /* getLcaStatus(resdata.data.response.docs[0].id);
        getLcaDetails(resdata.data.response.docs[0].id);
        getGreenCardDetails(resdata.data.response.docs[0].id);
        getGreenCardStatus(resdata.data.response.docs[0].id);
        getH1BStatus(resdata.data.response.docs[0].id); */
        getAttorneys(resdata.data[0].ID);
        getCompanyDetails(resdata.data[0].ID);
        getCOmpanyContacts(resdata.data[0].ID);
        getVisaStrength(resdata.data[0].ID);
        //getVisaByYear(resdata.data.data[0].ID)
      });
    });
  };

  const getLcaDetails = (compid) => {
    axios
      .post(`${Key.domain}/company/get_lca_details`, {
        company_id: compid,
      })
      .then((resdata) => {
        //console.log.log(resdata.data.data.lcaDetails);
        setLcaDetails(resdata.data.data.lcaDetails);
      });
  };

  const getGreenCardDetails = (compid) => {
    axios
      .post(`${Key.domain}/company/get_GreenCard_details`, {
        company_id: compid,
      })
      .then((resdata) => {
        //console.log.log(resdata.data.data.greenCardDetails);
        setGreenCardDetails(resdata.data.data.greenCardDetails);
      });
  };

  const getH1bDetails = (compid) => {
    axios
      .post(`${Key.domain}/company/get_h1b_details`, {
        company_id: compid,
      })
      .then((resdata) => {
        //console.log.log(resdata.data.data.h1bdetails);
        seth1bDetails(resdata.data.data.h1bdetails);
      });
  };

  const getLcaStatus = (compid) => {
    axios
      .post(`${Key.domain}/company/get_LCA_Status`, {
        company_id: compid,
      })
      .then((resdata) => {
        setLcaStatus(resdata.data.data);
        //  seth1bDetails(resdata.data.data.h1bdetails)
      });
  };

  const getGreenCardStatus = (compid) => {
    axios
      .post(`${Key.domain}/company/get_GreenCard_Status`, {
        company_id: compid,
      })
      .then((resdata) => {
        setGreenCardStatus(resdata.data.data);
        //  seth1bDetails(resdata.data.data.h1bdetails)
      });
  };

  const getH1BStatus = (compid) => {
    axios
      .post(`${Key.domain}/company/get_H1B_status`, {
        company_id: compid,
      })
      .then((resdata) => {
        seth1bStatus(resdata.data.data.h1bStatus);
      });
  };

  const clickOnBack = () => {
    sessionStorage.removeItem('hideSideBar');
    window.location.href = '/dashboard/app';
  };

  const getCompanyDetails = (company_ID) => {
    //const searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?fq=id:${company_ID}&start=0&wt=json`;
    fetch(`${Key.domain}/company/get_Company_Details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ company_id: company_ID }),
    }).then((response) => {
      response.json().then((resdata) => {
        setCompanyId(resdata.data[0].ID);
        setCompanyName(resdata.data[0].EMPLOYER_NAME);
        setCompanyDetails(resdata.data[0]);
      });
    });
  };

  const searchCompany = (e) => {
    e.preventDefault();
    var companyName = '"' + searchCompanyName + '"';
    var location = '"' + searchLocation + '"';
    if (searchCompanyName !== '') {
      var searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=name:${companyName}&start=0&wt=json`;
    } else if (searchLocation !== '') {
      searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?fq=${location}&start=0&wt=json`;
    } else if (searchCompanyName !== '' && searchLocation !== '') {
      searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=name:${companyName}&fq=${location}&start=0&wt=json`;
    } else {
      searchResumesUrl = `${Key.Solr_url}/${Key.solrImmi_collection}/select?q=*%3A*&start=0&wt=json`;
    }
    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        //console.log.log(resdata);
        setCompanies(resdata.data.response.docs);
        setCompanyId(resdata.data.response.docs[0].id);
        setCompanyName(resdata.data.response.docs[0].name);
      });
    });
  };

  const lcaStatusChange = (status) => {
    axios
      .post(`${Key.domain}/company/get_lca_details`, {
        company_id: companyId,
      })
      .then((resdata) => {
        //console.log.log(resdata.data.data.lcaDetails);
        if (status !== 'LCA Status') {
          setLcaDetails(resdata.data.data.lcaDetails.filter((data) => data.CASE_STATUS === status));
        } else {
          setLcaDetails(resdata.data.data.lcaDetails);
        }
      });
  };

  const greenCardStatusChange = (status) => {
    axios
      .post(`${Key.domain}/company/get_GreenCard_details`, {
        company_id: companyId,
      })
      .then((resdata) => {
        setGreenCardDetails(resdata.data.data.greenCardDetails.filter((data) => data.CASE_STATUS === status));
      });
  };

  const getAttorneys = (compid) => {
    axios
      .post(`${Key.domain}/company/get_company_Attorneys`, {
        company_id: compid,
      })
      .then((resdata) => {
        setAttorneyDetails(resdata.data.data.companyAttorneyDetails);
      });
  };

  const getCOmpanyContacts = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_Contacts`, {
        company_id: compId,
      })
      .then((resdata) => {
        setCompanyContacts(resdata.data.data.companyContacts);
      });
  };

  const getVisaStrength = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_Count`, {
        company_id: compId,
      })
      .then((resdata) => {
        setVisaStrength(resdata.data.data);
      });
  };

  const getVisaByYear = (e) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_byYear`, {
        company_id: companyId,
        year: e.target.value,
      })
      .then((resdata) => {
        console.log(resdata);
        setvisaByYear(resdata.data.data);
      });
  };
  //getVisaByYear(compId,year)

  const refresh = () => {};
  return (
    <div>
      <div style={{ marginTop: '-31px' }}>
        <br />
        <div className="row">
          <div className="col-2" id="scrollableDiv" style={{ height: '80vh' }}>
            <div className="filter">
              <input
                type="text"
                style={{ borderRadius: '8px', border: '1px solid lightgray', fontSize: '15px', padding: '2px' }}
                value={search}
                placeholder="Search Company Name22222222222"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <InfiniteScroll
              dataLength={offset * 10}
              next={fetchMoreData}
              hasMore={hasMore}
              //loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              height={600}
              scrollableTarget="scrollableDiv"
              // refreshFunction={refresh}
              // pullDownToRefresh
              pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              // }
            >
              {companies
                .filter((companies) => companies.EMPLOYER_NAME.toLowerCase().includes(search.toLowerCase()))
                .map((value) => (
                  <Card className="mb-2">
                    <Card.Body
                      style={{ backgroundColor: value.ID === companyId ? '#acd8ff' : 'aliceblue', height: '66px' }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <p
                            style={{ color: '#406882', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => {
                              setCompanyId(value.ID);
                              setCompanyName(value.EMPLOYER_NAME);
                              //getLcaDetails(value.id);
                              //getH1bDetails(value.id);
                              getCompanyDetails(value.ID);
                              //getLcaStatus(value.id);
                              //getGreenCardDetails(value.id);
                              //getGreenCardStatus(value.id);
                              // getH1BStatus(value.id);
                              getAttorneys(value.ID);
                              getCOmpanyContacts(value.ID);
                              getVisaStrength(value.ID);
                            }}
                          >
                            {value.EMPLOYER_NAME}
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
            </InfiniteScroll>

            {/* {companies
              .filter((companies) => companies.EMPLOYER_NAME.toLowerCase().includes(search.toLowerCase()))
              .map((value) => (
                <div>
                  <Card className="mb-2">
                    <Card.Body
                      style={{ backgroundColor: value.ID === companyId ? '#acd8ff' : 'aliceblue', height: '66px' }}
                    >
                      <div className="row">


                        <div className="col-12">
                          <p
                            style={{ color: '#406882', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => {
                              setCompanyId(value.ID);
                              setCompanyName(value.EMPLOYER_NAME);
                              //getLcaDetails(value.id);
                              //getH1bDetails(value.id);
                              getCompanyDetails(value.ID);
                              //getLcaStatus(value.id);
                              //getGreenCardDetails(value.id);
                              //getGreenCardStatus(value.id);
                              // getH1BStatus(value.id);
                              getAttorneys(value.ID);
                              getCOmpanyContacts(value.ID);
                              getVisaStrength(value.ID);
                              //getVisaByYear(value.ID)
                            }}
                          >
                            {value.EMPLOYER_NAME}
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))} */}
          </div>
          <div
            className="col-10"
            style={{ boxShadow: '0px 0px 3px black', height: '80vh', backgroundColor: 'aliceblue' }}
          >
            <button
              style={{
                float: 'right',
                width: '30px',
                marginTop: '-38px',
                backgroundColor: 'rgb(64, 104, 130)',
                color: '#ffff',
                border: 'none',
                borderRadius: '4px',
              }}
              onClick={clickOnBack}
            >
              <BiArrowBack size={25} />
            </button>
            <div
              className="row"
              style={{
                height: '127px',
                padding: '10px',
              }}
            >
              <div
                className="col-3 "
                style={{
                  overflowY: 'auto',
                  overflowX: 'none',
                  height: '76vh',
                  borderRightColor: '#406882',
                  border: '1px solid lightgrey',
                }}
              >
                <h6
                  className="col-12"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    position: 'sticky',
                    top: '12px',
                    background: '#406882',
                    padding: '8px',
                    borderRadius: '8px',
                    fontWeight: '400',
                  }}
                >
                  Company Details
                </h6>
                <br />
                <div>
                  <div class="row">
                    <center>
                      {' '}
                      {/* <img
                        src={companyDetails.logo_url !== 'N/A' ? companyDetails.logo_url : Companylogo}
                        alt="img"
                        style={{ width: '50px', height: '50px', marginBottom: '5px' }}
                      /> */}
                    </center>
                    <center>
                      {' '}
                      <h6 style={{ fontSize: '14px' }}>{companyDetails.EMPLOYER_NAME}</h6>
                    </center>
                    <hr />
                    <ul class="list-group list-group-flush" style={{ fontSize: '12px', fontWeight: '600' }}>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> Phone</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.EMPLOYER_PHONE} </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> No.of Employees</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.EMPLOYER_NUM_EMPLOYEES} </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> City</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.EMPLOYER_CITY} </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4">State</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.EMPLOYER_STATE_PROVINCE}</div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> Country</div>
                          <div class="col-1">:</div>
                          <div class="col-6">
                            <span>
                              {companyDetails.EMPLOYER_COUNTRY === 'UNITED STATES OF AMERICA'
                                ? 'USA'
                                : companyDetails.EMPLOYER_COUNTRY}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> Address</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.EMPLOYER_ADDRESS_1} </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4"> Zip Code</div>
                          <div class="col-1">:</div>
                          <div class="col-6">{companyDetails.NAICS_CODE} </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-4">website</div>
                          <div class="col-1">:</div>
                          <div class="col-6">N/A</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-3 "
                style={{ overflowY: 'auto', overflowX: 'none', height: '76vh', border: '1px solid lightgrey' }}
              >
                <h6
                  className="col-12"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    position: 'sticky',
                    top: '12px',
                    background: '#406882',
                    padding: '8px',
                    borderRadius: '8px',
                    fontWeight: '400',
                  }}
                >
                  Contacts
                </h6>
                <br />
                {companyContacts.map((contact) => (
                  <div>
                    <Card>
                      <Card body>
                        <div className="row" style={{ fontSize: '15px', fontWeight: '600' }}>
                          <div className="col-12" style={{ fontSize: '12px' }}>
                            <p style={{ lineHeight: '13px' }}>
                              <AiOutlineUser size={15} style={{ marginRight: '12px' }} /> {contact.EMP_CONTACT_NAME}{' '}
                              {contact.lastname}
                            </p>
                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlineMail size={15} style={{ marginRight: '12px' }} /> {contact.EMP_CONTACT_EMAIL}
                            </p>
                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlinePhone size={15} style={{ marginRight: '12px' }} /> {contact.EMP_CONTACT_PHONE}
                            </p>
                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <SlLocationPin size={15} style={{ marginRight: '12px' }} />
                              {contact.EMP_CONTACT_ADDRESS_1}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Card>
                    <br />
                  </div>
                ))}
              </div>

              <div
                className="col-3 "
                style={{ overflowY: 'auto', overflowX: 'none', height: '76vh', border: '1px solid lightgrey' }}
              >
                <h6
                  className="col-12"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    position: 'sticky',
                    top: '12px',
                    background: '#406882',
                    padding: '8px',
                    borderRadius: '8px',
                    fontWeight: '400',
                  }}
                >
                  Attorneys
                </h6>
                <br />
                {attorneyDetails.map((attorney) => (
                  <div>
                    <Card>
                      <Card body>
                        <div className="row" style={{ fontSize: '12px', fontWeight: '600' }}>
                          <div className="col-12">
                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlineUser size={15} style={{ marginRight: '12px' }} /> {attorney.AGENT_ATTORNEY_NAME}
                            </p>

                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlineUser size={15} style={{ marginRight: '12px' }} />{' '}
                              {attorney.AGENT_ATTORNEY_FIRM_NAME}
                            </p>

                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlineMail size={15} style={{ marginRight: '12px' }} />{' '}
                              {attorney.AGENT_ATTORNEY_EMAIL}
                            </p>
                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <AiOutlinePhone size={15} style={{ marginRight: '12px' }} />
                              {attorney.AGENT_ATTORNEY_PHONE}
                            </p>

                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <BiBuilding size={15} style={{ marginRight: '12px' }} />
                              {attorney.AGENT_ATTORNEY_CITY} |{' '}
                              {attorney.AGENT_ATTORNEY_COUNTRY === 'UNITED STATES OF AMERICA'
                                ? 'USA'
                                : attorney.AGENT_ATTORNEY_COUNTRY}
                            </p>

                            <p style={{ lineHeight: '13px' }}>
                              {' '}
                              <SlLocationPin size={15} style={{ marginRight: '12px' }} />
                              {attorney.AGENT_ATTORNEY_ADDRESS_1}, {attorney.AGENT_ATTORNEY_ADDRESS_2},{' '}
                              {attorney.AGENT_ATTORNEY_POSTAL_CODE}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Card>
                    <br />
                  </div>
                ))}{' '}
                &nbsp;
              </div>

              <div
                className="col-3 "
                style={{ overflowY: 'auto', overflowX: 'none', height: '76vh', border: '1px solid lightgrey' }}
              >
                <h6
                  className="col-12"
                  style={{
                    color: '#FFF',
                    textAlign: 'center',
                    position: 'sticky',
                    top: '12px',
                    background: '#406882',
                    padding: '8px',
                    borderRadius: '8px',
                    fontWeight: '400',
                  }}
                >
                  Visa Status
                </h6>
                <br />
                <select class="form-select" aria-label="Default select example" onChange={(e) => getVisaByYear(e)}>
                  <option selected>Select Year</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </select>
                &nbsp;
                {visaByyear.map((visa) => (
                  <Card>
                    <Card body>
                      <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                        <p style={{ lineHeight: '20px', fontSize: '15px' }}>Green Card</p>
                        <div className="col-6">
                          <p style={{ lineHeight: '13px' }}>Certified</p>
                        </div>

                        <div className="col-1">:</div>

                        <div className="col-4">{visa.certified}</div>
                      </div>

                      <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                        <div className="col-6">
                          <p style={{ lineHeight: '13px' }}>Certified-Exipred</p>
                        </div>

                        <div className="col-1">:</div>

                        <div className="col-4">{visa.certified_expired}</div>
                      </div>

                      <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                        <div className="col-6">
                          <p style={{ lineHeight: '13px' }}>Withdrawn</p>
                        </div>

                        <div className="col-1">:</div>

                        <div className="col-4">{visa.withdrawn}</div>
                      </div>

                      <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                        <div className="col-6">
                          <p style={{ lineHeight: '13px' }}>Denied</p>
                        </div>

                        <div className="col-1">:</div>

                        <div className="col-4">{visa.denied}</div>
                      </div>
                    </Card>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newmenu;
