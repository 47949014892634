import React, { useState, useEffect } from 'react';
import './Notes.css';

import { MdOutlineNoteAdd } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-tooltip-lite';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment'
import Key from '../clientVariables.json';

function Activity(props) {
    const { currentUser, customerId } = props;
    const [show, setShow] = useState(false);
    const [activity, setActivity] = useState("")
    const [msgArray, setMsgArray] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getActivities = () => {
        
            axios
              .post(`${Key.domain}/admin/get_activities`,{
                  admin_id: parseInt(sessionStorage.getItem("id"), 10),
                  customer_id: parseInt(customerId, 10),
                  session_token: currentUser.session_token,
                }).then(res => {
                console.log(res)
                if(res.status === 200) {
                  setMsgArray(res.data.data)
                  setActivity("")
                  handleClose();
                }
                
              })
          
    }
    useEffect(() => {
        getActivities()
    },[])

    const handleSaveActivity = e => {
        e.preventDefault()
        console.log("send note", activity)
       
        if (activity !== "") {
          axios
            .post(`${Key.domain}/admin/insert_activities`,{
                admin_id: parseInt(sessionStorage.getItem("id"), 10),
                customer_id: parseInt(customerId, 10),
                message: activity,
                session_token: currentUser.session_token,
              }).then(res => {
              console.log(res)
              if(res.status === 200) {
                getActivities()
                setActivity("")
                handleClose();
              }
              
            })
        }
    
    
      }
      const columns = [

      
      {
        label: <span>Message</span>,
        name: "message",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
  
            return value !== undefined && value !== null && value !== "" ? value.slice(0, 100) : ""
  
          }
        },
      },
      {
        label: <span>Date</span>,
        name: "created",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return moment(value).format("LL")
  
          }
        },
      },
  
    ];

    const options = {
       
        onCellClick: (cellIndex, rowIndex) => {
          console.log(cellIndex.props.children);
        //   if (typeof (cellIndex.props.children) === 'number') {
        //     history("/dashboard/customerDetailsView", { state: { customerid: cellIndex.props.children, setIndex: 3 } })
        //   }
          console.log(rowIndex.dataIndex);
          console.log(rowIndex);
        },
        selectableRows: false
    
      };

  return (
    <div>
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add Activity</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-lg-12">
          <form onSubmit={handleSaveActivity}>
            <div className="chat-message-form">
              <div className="form-grouping">
                <textarea
                  className="form-controlling message-input"
                  name="message-boxing"
                  placeholder="Enter message text "
                  value={activity}
                  onChange={(e) => {
                  setActivity(e.target.value)

                  }}
                >
                  &nbsp;
                </textarea>
                <Button variant="primary"  type="submit" style={{ float: "right", marginTop: "10px" }} >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> */}

    </Modal.Footer>
  </Modal>
    <div className="container info">
      <ul className="list-group notes-styles">
        <li className="  d-flex justify-content-start align-items-start">
          <h4 className="heading" style={{ paddingLeft: '12px', fontSize: '22px' }}>
            <b>Activity</b>
          </h4>
        </li>
        <div
          className="modal1"
          style={{ display: 'flex', justifyContent: 'end', marginTop: '-35px' }}
         
        >
          <Tooltip content="Add Activity">
            <button onClick={handleShow} type="button" className="btn btn-outline-primary" style={{ border: '#406882' }}>
              <MdOutlineNoteAdd size={22} color="#406882" />
            </button>
          </Tooltip>
        </div>

        <div className="container">
          <div className="contentWrapper wrapper-content ">
            <div className="row">
              <div className="col-lg-12">
                
                  <div className="ibox-content">
                    
                      <div className="col-md-12 ">
                        <div className="chat-discussion" style={{background: "transparent"}}>
                        <MUIDataTable
        options={options}
        title={"Activity List"}
        data={msgArray}
        columns={columns}

      />
                       
                        </div>
                      </div>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
    
  </div>
  )
}
function mapStateToProps(state, ownProps) {
    return {
      currentUser: state.UserReducer.user,
    };
  }
  
  export default connect(mapStateToProps, null)(Activity);