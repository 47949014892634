import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Withoutlogo from './layouts/Withoutlogo';
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import Payment from './pages/payment';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Addcustomer from './pages/Addcustomer';
import Subscriptionslist from './pages/Subscriptions';
import Benchsales from './pages/BenchsalesTabs';
import Jobseekers from './pages/Jobseekers';

import ManageUsersList from './pages/ManageUsersList';
import Userdetailview from './pages/Userdetailview';
import Invoices from './pages/Invoices';
import Researchdashboard from './pages/Reserchdashboard';
import AccountingDashboard from './pages/AccountingDashboard';
import SalesDashboard from './pages/SalesDashboard';
import Sales from './pages/Sales';
import Leads from './pages/Leads';
import JobFairLeads from './pages/JobFairLeads';

import AddUser from './pages/AddUser';
import CustomerDetailView2 from './pages/CustomerDetailView2';
import BsDetailView from './pages/BsDetailView';
import SubscriberDetailsView from './pages/SubscriberDetailsView';
import Reserchdashboardactiveleads from './pages/Researchactiveleads';
import SalesExecutiveDasboard from './pages/SalesExecutiveDashboard';
import LeadsDetailView from './pages/LeadsDetailView';
import Requests from './pages/Requests';
import Createpassword from './pages/Createpassword';
import Forgotpassword from './pages/Forgotpassword';
import Purchaseorderview from './pages/Purchaseorderview';
import Paymentinformations from './pages/Paymentinformation';
import SalesMarketing from './pages/SalesMarketing';
import ResumesPerDay from './pages/resumesPerDay';
import ApplicationTitleResumes from './pages/ApplicationTitleResumes';
import Jobfair from './pages/Jobfair';
import Dbconstraints from './pages/Dbconstraints';
import ResumesCount from './pages/ResumesCount';
import LiveJobs from './pages/LiveJobs';
import CeipalJobs from './pages/CeipalJobs';

import Packages from './pages/packages';
import CompaniesList from './pages/CompaniesList';
import CompanyJobsSubscriptions from './pages/SubscriptionsFromCompanyjobs';
import UserOnlineStatus from './pages/UserOnlineStatus';
import Newmenu from './pages/VisaCompanies';
import Companyinfo from './pages/Companyinfo';
import Dialogmenu from './layouts/dashboard/AccountPopover';
import People from './pages/People';

import ScrappedJobsListView from './pages/ScrappedJobsListView';
import LivejobsScrapped from './pages/LivejobsScrapped';
import LinkedInProfiles from './pages/LinkedInProfiles'
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },

        { path: 'resumes', element: <ResumesPerDay /> },
        { path: 'applicationTitleResumes', element: <ApplicationTitleResumes /> },

        { path: 'Leads', element: <Leads /> },
        { path: 'researchdashboard', element: <Researchdashboard /> },
        { path: 'Accountingdashboard', element: <AccountingDashboard /> },
        { path: 'Salesdashboard', element: <SalesDashboard /> },
        { path: 'SalesExecutiveDashboard', element: <SalesExecutiveDasboard /> },
        { path: 'Purchaseorder', element: <Purchaseorderview /> },
        { path: 'Paymentinformation', element: <Paymentinformations /> },
        { path: 'SalesMarketing', element: <SalesMarketing /> },
        { path: 'Sales', element: <Sales /> },

        { path: 'Requests', element: <Requests /> },
        { path: 'Jobfair', element: <Jobfair /> },
        { path: 'Configurations', element: <Dbconstraints /> },
        { path: 'applicationsTitlesList', element: <ResumesCount /> },

        { path: 'Livejobs', element: <LiveJobs /> },
        { path: 'Package', element: <Packages /> },
        { path: 'CompaniesList', element: <CompaniesList /> },
        // { path: 'user', element: <Loader /> },
        { path: 'customerDetailsView', element: <CustomerDetailView2 /> },
        { path: 'bsDetailsView', element: <BsDetailView /> },
        { path: 'manageUsers', element: <ManageUsersList /> },
        { path: 'addUser', element: <AddUser /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'addcustomer', element: <Addcustomer /> },
        { path: 'Subscriptions', element: <Subscriptionslist /> },
        { path: 'Benchsales', element: <Benchsales /> },

        { path: 'SubscriberDetailsView', element: <SubscriberDetailsView /> },
        { path: 'userdetails', element: <Userdetailview /> },
        { path: 'Invoices', element: <Invoices /> },

        { path: 'Leads', element: <Leads /> },
        { path: 'JobFairLeads', element: <JobFairLeads /> },

        { path: 'LeadsDetailView', element: <LeadsDetailView /> },
        { path: 'Researchactiveleads', element: <Reserchdashboardactiveleads /> },
        { path: 'Jobseekers', element: <Jobseekers /> },
        { path: 'compSubscriptions/:jobtitle/:email/:name', element: <CompanyJobsSubscriptions /> },
        { path: 'onlineUsers', element: <UserOnlineStatus /> },
        { path: 'Newmenu', element: <Newmenu /> },
        { path: 'People', element: <People /> },
        { path: 'Companyinfo', element: <Companyinfo /> },
        { path: 'Dialogmenu', element: <Dialogmenu /> },
        // {path:'scrapingjobs',element : <Scrapingjobslist/>},
        { path: 'LivejobsScrapped', element: <LivejobsScrapped /> },
        { path: 'jobslistview', element: <ScrappedJobsListView /> },
        { path: 'CeipalJobs', element: <CeipalJobs /> },
        { path: 'LinkedInProfiles', element: <LinkedInProfiles /> },

      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },

        { path: 'Paymentinformation', element: <Paymentinformations /> },
        { path: 'register', element: <Register /> },
        { path: 'forgotpasswordemail', element: <Forgotpassword /> },
        { path: 'createpassword/:email', element: <Createpassword /> },

        { path: '404', element: <NotFound /> },
        { path: 'compSubscriptions', element: <CompanyJobsSubscriptions /> },

        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <Withoutlogo />,
      children: [
        { path: 'payment/:email/:id', element: <Payment /> },
        { path: 'payment/:email/', element: <Payment /> },
      ],
    },

    // {path:'/',
    //   element:<Withinlogo/>,
    //     children:[
    //       { path: 'payment', element: <Payment /> },
    //     ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
