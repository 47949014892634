/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Useravathar from '../components/images/User.png'
import Key from "../clientVariables.json";
import axios from "axios"
function H1DetailView(props) {

    const [value, setValue] = React.useState(0);
    const [h1bDetails, seth1bDetails] = React.useState([]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const filterYear = (year) => {
        const filterData = props.h1bDetails.filter(data => data.Fiscal_Year === parseInt(year))
        seth1bDetails(filterData)
    }

    useEffect(() => {
        seth1bDetails(props.h1bDetails)
    }, [props.h1bDetails])

    return (
        <div>
            <div style={{ float: "right" }}>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => filterYear(e.target.value)}>
                    <option selected>Year</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                </select>
            </div>


            <table class="table table-bordered">

                <tbody>
                    <p>Status</p>
                    <tr>
                        <td>
                            <Row>
                                <Col sm={4}>No.of Approvals</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.h1bStatus.InitialApprovals !== null && props.h1bStatus.ContinuingApprovals !== null  ? parseInt(props.h1bStatus.InitialApprovals) + parseInt(props.h1bStatus.ContinuingApprovals) : 0}</Col>
                            </Row>
                        </td>

                        <td>
                            <Row>
                                <Col sm={4}>No.of Denials</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.h1bStatus.InitialDenials !== null && props.h1bStatus.ContinuingDenials !== null ? parseInt(props.h1bStatus.InitialDenials) + parseInt(props.h1bStatus.ContinuingDenials) : 0}</Col>
                            </Row>
                        </td>
                    </tr>




                </tbody>
            </table>

            {/*  <div className='card' style={{ width: "50%" }}>
    <div style={{ textAlign: "center", paddingTop: "15px" }}>


        <p><b>Status</b></p>

    </div>
    <ul class="list-group list-group-flush">
      
        <li className="list-group-item">
           
        </li>
        <li className="list-group-item">
            
        </li>
        <li className="list-group-item">
           
        </li>

        <li className="list-group-item">
           
        </li>
        <li className="list-group-item">
            <Row>
                <Col sm={4}>No.of Continuing Denials</Col>
                <Col sm={2} >:</Col>
                <Col sm={4}>{props.h1bStatus[0].ContinuingDenials !== null ? props.h1bStatus[0].ContinuingDenials : 0}</Col>
            </Row>
        </li>
    </ul>

</div>
*/}
            <br />
            <Row>

                {h1bDetails.map(data =>
                    <Col sm={5}>
                        <div className='card p-3' >
                            <div style={{ textAlign: "center" }}>


                                <p><b>{data.Employer}</b></p>

                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Location : {data.City} , {data.State}</li>
                                <li className="list-group-item">Zipcode : {data.ZIP}</li>
                                <li className="list-group-item">Fiscal Year : {data.Fiscal_Year}</li>

                            </ul>

                        </div>
                        <br />

                    </Col>

                )}


            </Row>
        </div>





    )
}

export default H1DetailView