/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, Pagination } from "@mui/material";
import Key from '../clientVariables.json';

import { MdRefresh } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { connect } from "react-redux";

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

function Parserresumeslist(props) {
    const formikRef = useRef();

    const location = useLocation();
    const { userDetails } = props;

    const [show, setShow] = useState(false);
    const [showfile, setShowfile] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [xlData, setXlData] = useState([]);

    const [page, setPage] = useState(0)
    const [uid, setUid] = useState(0)

    const [count, setCount] = useState(parseInt(100))
    const [resumeId, setResumeId] = useState("")
    const [selectedResumeIds, setSelectedResumeIds] = useState([]);
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedStateId, setSelectedStateId] = useState(0);



    useEffect(() => {
        // alert(location.state.title && location.state.count)
        getData(page)

    }, [])

    const getData = (value) => {
        console.log(value)
        props.showLoader();

        setPage(value)

        var resCount = (location.state.count)

        axios
            .post(`${Key.domain}/customer/get_bs_resumes`, {
                "user_id": userDetails.id
            })
            .then((response) => {
                console.log(response);
                setResumes(response.data.data)
                setCount(resCount)
                props.hideLoader();

            })

    }




    const columns = [
        {
            name: '',
            label: 'S No',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, update) => {
                    let rowIndex = Number(tableMeta.rowIndex) + 1;
                    return (<span>{rowIndex}</span>)
                }
            },
        },
        {
            label: <span>Full Name</span>,
            name: "full_name",
            options: {
                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Application Title</span>,
            name: "application_title",
            options: {
                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Location</span>,
            name: "location",
            options: {
                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Experience</span>,
            name: "total_experience",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value > 1 ? value + " years" : value + " year" : "N/A"
                }
            },
        },
        {
            label: <span>Created</span>,
            name: "created",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value && value !== null
                        ? moment(value).format("MMM DD, YYYY")
                        : "N/A"
                }
            },
        },
    ];

    const options = {
        selectableRows: false,
        selectableRowsOnClick: false,

        fixedHeader: true,
        density: true,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],
        onCellClick: (cellIndex, rowIndex) => {

            // let selectedTitle = titles[rowIndex.dataIndex]


        },

        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            const handleClick = () => {
                const recordsToDownload = selectedRows?.data?.map(
                    ({ index }) => displayData[index]
                ).map(
                    ({ data }) => data
                );

                console.log('recordsToDownload', recordsToDownload);
                handleShare(recordsToDownload)

            };

            return (
                <div>

                    <div class="button-div" onClick={() => handleClick()}>
                        <Tooltip content="Share Hot List">
                            <a
                                href="#"
                                class="print-icon"
                                aria-label="Unstar this item"
                            >
                                <AiOutlineShareAlt
                                    color="#406882"
                                    size={20}
                                />
                            </a>
                        </Tooltip>
                    </div>

                </div>
            );
        },

    };





    return (
        <div className='container' style={{ paddingTop: "38px" }}>

            <MUIDataTable
                title={"Hot List"}
                data={resumes}
                columns={columns}
                options={options}
            />

            <br />
        </div>
    )
}


const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Parserresumeslist);