/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { Viewer } from '@react-pdf-viewer/core';
import { themePlugin } from '@react-pdf-viewer/theme';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core';
//import pdfFile from '../pdf/document.pdf'
// import './document.css'
import Key from '../clientVariables.json'
import axios from 'axios'

const DocumentNew = (props) => {
  //var defaultfilename = props.resumeDetails.filename.split('.').slice(0, -1).join('.') + ".pdf"
  const [defaultPdfFile, setDefaultFile] = useState(`${Key.domain1}/MyFiles/resume_attachments/${props.selectresumeFileDetails.uid}/${props.selectresumeFileDetails.id}/${props.selectresumeFileDetails.res_fileName !== undefined ? props.selectresumeFileDetails.res_fileName : props.selectresumeFileDetails.filename}`)
  const [isFileFound, setFileFound] = useState(true)
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const themePluginInstance = themePlugin();

  useEffect(() => {
    console.log(props)
    // if (props.resumeDetails && props.resumeDetails.filename !== undefined && props.resumeDetails.filename !== null && props.resumeDetails.filename !== '') {
    //   //var filename=props.resumeDetails.filename.split('.').slice(0, -1).join('.')+".pdf";
    //   var filename = props.resumeDetails.filename;
    //   setDefaultFile(`${Key.domain1}/MyFiles/resume_attachments/${sessionStorage.getItem('id')}/${props.resumeDetails.id}/${filename}`)
    // }
    //call resume file fetch api

    // axios.get(`${Key.domain1}/MyFiles/resume_attachments/${sessionStorage.getItem('id')}/${props.resumeDetails.id}/${filename}`, {
    //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //   },
    // })
    //   .then((resdata) => {
    //     console.log(resdata)
    //   }).catch((err) => {
    //     console.log(err)
    //     setFileFound(false)
    //   });

  }, [props])
  return (
    isFileFound === true ?
      <div className='pdf-container' style={{ height: "70vh", width: "100%" }}>
        {/* show pdf conditionally (if we have one)  */}
        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={defaultPdfFile}
            plugins={[defaultLayoutPluginInstance, themePluginInstance]} theme="dark" />
        </Worker></>}

      </div> : <div></div>
  )
}

export default DocumentNew