import React from 'react';
import { Card, Container } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(title, postedDate, postedOn, recruiterName, recruiterMail) {
    return { title, postedDate, postedOn, recruiterName, recruiterMail };
  }
function createRecruiterData(name,role,phone,email){
    return {name,role,phone,email}
}
const LeadsDetailView = () => {
  const columns = [
    {
      label: <span>Name</span>,
      name: 'name',
    },
    {
      label: <span>Role</span>,
      name: 'role',
    },
    {
      label: <span>Phone</span>,
      name: 'mobile',
    },
    {
      label: <span>Email</span>,
      name: 'email',
    },
  ];
 
  const rows = [
    createData('Java Developer', '22/07/2022', 'Dice','Smith', 'smith@gmail.com'),
    createData('python Developer', '2/08/2022', 'jobsnprofiles','james', 'james@gmail.com'),
   
  ];
  const recruiters=[
    createRecruiterData("Mike",'HR','354654656','mike@gmail.com')

  ]
  return (
    <div>
      <Container>
        <Card style={{ padding: '32px', marginBottom: '15px' }}>
          <div className="row">
            <div className="col-6">
              <h5 className='mb-4'
              >Company Details</h5>
              <div className="row">
                <div className="col-5">
                  <p>Company Name</p>
                  <p>Type of Business</p>
                  <p>Revenue Info</p>
                  <p>Size</p>
                  <p>Technology</p>
                </div>
                <div className="col-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  <p>Microsoft</p>
                  <p>IT Councelting/Recruiting Agency</p>
                  <p>$6M</p>
                  <p>200</p>
                  <p>Java,php,python</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5 className='mb-4'>Location</h5>
              <div className="row">
                <div className="col-5">
                  <p>Address</p>
                  <p>City</p>
                  <p>state</p>
                  <p>Country</p>
                  <p>Zipcode</p>
                </div>
                <div className="col-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  <p>12th avenue</p>
                  <p>California</p>
                  <p>California</p>
                  <p>USA</p>
                  <p>46532</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5 className='mb-4'>Point of Contact</h5>
              <div className="row">
                <div className="col-5">
                  <p>Name</p>
                  <p>Role</p>
                  <p>Phone</p>
                  <p>Mail</p>
                  <p>Deciding Person</p>
                </div>
                <div className="col-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  <p>Cavin</p>
                  <p>Hr Manager</p>
                  <p>(654)-546-5566</p>
                  <p>cavin@gmail.com</p>
                  <p>Cavin</p>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card style={{ padding: '32px', marginBottom: '15px' }}>
            <h5>Recruiters</h5>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell >Name</TableCell>
                  <TableCell >Role</TableCell>
                  <TableCell >Phone</TableCell>
                  <TableCell >Email</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {recruiters.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                    {row.name}
                    </TableCell>
                    <TableCell >{row.role}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell >{row.email}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Card>
        <Card style={{ padding: '32px', marginBottom: '15px', marginTop: '20px' }}>
          <h5>Jobs Posted By Recruiters</h5>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell >Job Title</TableCell>
                  <TableCell >Posted Date</TableCell>
                  <TableCell >Posted On</TableCell>
                  <TableCell >Recruiter Name</TableCell>
                  <TableCell >Recruiter Mail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                    {row.title}
                    </TableCell>
                    <TableCell >{row.postedDate}</TableCell>
                    <TableCell>{row.postedOn}</TableCell>
                    <TableCell >{row.recruiterName}</TableCell>
                    <TableCell >{row.recruiterMail}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </div>
  );
};

export default LeadsDetailView;
