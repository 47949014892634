import React from 'react'

import {

  Button,

} from '@mui/material';
import MUIDataTable from "mui-datatables";
import Customers from './User'

const columns = [
  {
    name: "companyname",
    label: "Company Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "typeofbusiness",
    label: "Type Of Business",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "location",
    label: "Location",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "revenue",
    label: "Revenue",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "companysize",
    label: "Company Size",

  },
  {
    name: "more",
    label: "Actions",

  },
];

const data = [
  { companyname: "Prime Soft", typeofbusiness: "It Software", location: "Newyork", revenue: "$400", companysize: "1200", more: "NY", },



];
const options = {
  filterType: 'checkbox',
};
function Reserchdashboard() {

  return (
    <div>
     <Customers />

    </div>
  )
}

export default Reserchdashboard