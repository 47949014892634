import React from 'react'

import { Bar } from "react-chartjs-2";

import { Chart, CategoryScale, LinearScale, BarElement, Legend, Title, Tooltip } from 'chart.js';

Chart.register(
  LinearScale, CategoryScale, BarElement, Legend, Title, Tooltip
)

function Salesrevenue(props) {

  
const labels = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const options = {
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: "Sales Revenue ",
      color: '#406882',
      align: "start",
      font: {
        size: 20
      }

    }
  }
}
const data = {
  labels,
  datasets: [

    {
      label: 'Monthly',
      data: [props.janCount, props.febCount, props.marchCount, props.aprCount, props.mayCount, props.junCount, props.julyCount, props.augCont, props.sepCount, props.octCount, props.novCount, props.decCount],
      backgroundColor: '#69bab3',
    },

  ],
};

  return (
    <div>

      <Bar options={options} data={data} />

    </div>
  )
}

export default Salesrevenue