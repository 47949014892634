import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Legend, Title, Tooltip } from 'chart.js';
import { connect } from "react-redux";
import Key from '../clientVariables.json'

Chart.register(
  LinearScale, CategoryScale, BarElement, Legend, Title, Tooltip
)



function Subscribers(props) {
  // const [subscribers, setSubScribers] = useState([])
  const [janCount, setJanCount] = useState(0)
  const [febCount, setFebCount] = useState(0)
  const [marchCount, setMarchCount] = useState(0)
  const [aprCount, setAprCount] = useState(0)
  const [mayCount, setMayCount] = useState(0)
  const [junCount, setJunCount] = useState(0)
  const [julyCount, setJulyCount] = useState(0)
  const [augCount, setAugCount] = useState(0)
  const [sepCount, setSepCount] = useState(0)
  const [octCount, setOctCount] = useState(0)
  const [novCount, setNovCount] = useState(0)
  const [decCount, setDecCount] = useState(0)

  useEffect(() => {
    axios
      .post(`${Key.domain}/dashboard/get_subscribers_count_monthly`, {
        "userid": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((resdata) => {
        console.log(resdata.data.data)
        const subscribers = resdata.data.data.filter(data => data.y === 2022)

        const janObj = subscribers.find(sub => sub.m === 1)
        setJanCount(janObj.count !== undefined ? janObj.count : 0)

        const febObj = subscribers.find(sub => sub.m === 2)
        setFebCount(febObj !== undefined ? febObj.count : 0)

        const marObj = subscribers.find(sub => sub.m === 3)
        setMarchCount(marObj !== undefined ? marObj.count : 0)

        const aprObj = subscribers.find(sub => sub.m === 4)
        setAprCount(aprObj !== undefined ? aprObj.count : 0)

        const mayObj = subscribers.find(sub => sub.m === 5)
        setMayCount(mayObj !== undefined ? mayObj.count : 0)

        const junObj = subscribers.find(sub => sub.m === 6)
        setJunCount(junObj !== undefined ? junObj.count : 0)

        const julyyCount = subscribers.find(sub => sub.m === 7)
        setJulyCount(julyyCount !== undefined ? julyyCount.count : 0)

        const augCont = subscribers.find(sub => sub.m === 8)
        setAugCount(augCont !== undefined ? augCont.count : 0)

        const sepObj = subscribers.find(sub => sub.m === 9)
        setSepCount(sepObj !== undefined ? sepObj.count : 0)

        const octObj = subscribers.find(sub => sub.m === 10)
        setOctCount(octObj !== undefined ? octObj.count : 0)

        const novObj = subscribers.find(sub => sub.m === 11)
        setNovCount(novObj !== undefined ? novObj.count : 0)

        const decObj = subscribers.find(sub => sub.m === 12)
        setDecCount(decObj !== undefined ? decObj.count : 0)


      }).catch((err) => {
        // console.log(err.response.data.data )
        // if (err.response.data.data === "Access denied.. Please Login") {
        //   // setSessionExpired(true)
        // }
      });;

  }, [])


console.log(janCount,febCount,marchCount,aprCount,mayCount,junCount,julyCount,augCount,sepCount,octCount)
  const labels = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const options = {
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: "Subscribers ",
        color: '#406882',
        align: "start",
        font: {
          size: 20
        }

      }
    }
  }
  const data = {
    labels,
    datasets: [
      // {
      //   label: 'Day',
      //   data:[15,20,30,10,10,10,30,25,20,5,10],
      //   backgroundColor: '#c5b5d2',
      // },

      // {
      //   label: 'Weekly',
      //   data:[10,20,10,15,10,10,30,20,10,25],
      //   backgroundColor: '#e6e5ea',
      // },

      {
        label: 'Monthly',
        data: [janCount, febCount, marchCount, aprCount, mayCount, junCount, julyCount, augCount, sepCount, octCount, novCount, decCount],
        backgroundColor: '#69bab3',
      },

    ],
  };

  return (
    <div>

      <Bar options={options} data={data} />

    </div>
  )
}


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(Subscribers));