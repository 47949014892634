import React from 'react'

import {

  Button,

} from '@mui/material';
import MUIDataTable from "mui-datatables";

const columns = [
 {
  name: "companyname",
  label: "Company Name",
  options: {
   filter: true,
   sort: true,
  }
 },
 {
  name: "typeofbusiness",
  label: "Type Of Business",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "location",
  label: "Location",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "revenue",
  label: "Revenue",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "companysize",
  label: "Company Size",
 
 },
 {
  name: "more",
  label: "Actions",
 
 },
];

const data = [
  { companyname: "Prime Soft", typeofbusiness: "It Software", location: "Newyork", revenue: "$400",companysize: "1200",more: "NY", },

 
 
 ];
const options = {
  filterType: 'checkbox',
};
function Reserchdashboard() {
 
  return (
    <div>
    <div className='container' style={{float:"right"}}><Button variant="contained"  style={{float:"right"}}>
    Upload
  </Button>
  <br/>
  </div>
    <MUIDataTable
  title={"Leads List"}
  data={data}
  columns={columns}
  options={options}
/>
    
    </div>
  )
}

export default Reserchdashboard