/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";
import Pagination from "@mui/material/Pagination";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from "react-redux";
import Key from '../clientVariables.json'


function Mail(props) {

  const validationSchema = Yup.object().shape({
    to: Yup.string()
      .required("TO is required."),

    subject: Yup.string()
      .required("Subject is required"),

    body: Yup.string()

      .required("Decription is required"),



  });
  const [show, setShow] = useState(false);
  const [mails, setMails] = useState([])
  const [detailMail, setDetailMail] = useState([])
  const [page, setPage] = React.useState(1);





  useEffect(() => {
    axios
      .post(`${Key.domain}/admin/get_all_email_conversations_list`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        console.log(response.data.data)
        setMails(response.data.data)
        props.hideLoader();

      }).catch((err) => {

      });;
  }, [])


  const itemsPerPage = 6;
  const noOfPages = Math.ceil(mails.length / itemsPerPage);
  const handleClose = () => setShow(false);
  const handleShow = (mail) => { setShow(true); setDetailMail(mail) }

  const handleChange = (event, value) => {
    setPage(value);
  };


  const handleAttachment = () => {


    fetch(
      `${Key.domain}/payment/attachments/${detailMail.customer_id}/${detailMail.attachment_name}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + window.localStorage['Access_Token'],
        },
      }
    ).then((resdata) => {
      console.log(resdata);
      console.log(resdata.url);
      const fileURL = resdata.url;

      console.log(fileURL);


      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    })
      .catch((e) => {
        console.log(e);
      });
  }







  return (
    <div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{detailMail.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ to: '', subject: '', body: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <Form>
                <div className='col-md-12'>

                  <div className='row'>
                    <div className='col-md-4'>
                      <p>To</p>
                      <p>Subject</p>
                      <p>Attachment</p>
                      <p>Description</p>

                    </div>

                    <div className='col-md-1'>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                    </div>

                    <div className='col-md-7'>
                      <p>{detailMail.to_emailId}</p>
                      <p>{detailMail.subject}</p>
                      <a onClick={()=>handleAttachment()} style={{color: 'blue',cursor:"pointer"}}  >
                      {detailMail.attachment_name}
                      </a>
                      <p>{
                        parse(detailMail.mail_body)
                      }</p>

                    </div>
                  </div>



                </div>





              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
      <table className="table table-hover">

        <tbody>

          {mails
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((mail,index) => 
            <tr>
              <th scope="row">{index + 1}</th>
              <td><button type='button' style={{ color: "#000" }} className='btn btn-link' onClick={() => handleShow(mail)}>{mail.to_emailId}</button></td>
              <td>{mail.subject}</td>
              <td>{moment(mail.created).format("lll")}</td>


            </tr>
          )}
        </tbody>
      </table>


<br/>

      <div
                className="pagination"
              // style={{ float: "right" }}
              >
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton

                //  classes={{ ul: classes.paginator }}
                />
              </div>

    </div>
  )
}


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(Mail);