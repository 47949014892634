import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ScaleLoader from "react-spinners/ScaleLoader";

import axios from 'axios'
import { connect } from "react-redux";
import moment from 'moment'
import { useLocation, useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';
import { showLoader, hideLoader } from "../constant/loader.action";
import Key from '../clientVariables.json'
import CustomerPersonalInfo from './CustomerPersonalInfo';
import CustomerCompanyInfo from './CustomerCompanyInfo';
import CustomerContactInfo from './CustomerContactInfo';
import CustomerPackageInfo from './CustomerPackageInfo';
import CustomerNotes from './CustomerNotes';
import Activity from "./Activity";
import Mailbox from './Mail';
import "../components/Loader/loading.css"



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomerDetailView2 = (props) => {
  const [value, setValue] = useState(0);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [recruiterDetails, setRecruiterDetails] = useState([]);
  const [paymentsList, setPaymentsList] = useState([]);
  const [showResetPassword, setShowResetPass] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [refreshh, setrefreshh] = useState(1);
  const location = useLocation();
  const [loading, setLoading] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (location.state && location.state.setIndex) {
      setValue(location.state.setIndex)
    }
  }, [])
  useEffect(() => {
    // props.showLoader();
    getCustomerDetails()

  }, [props.customerId])



  const handleActionClose = () => {
    setShowActionPopup(false)
    setShowDeactivate(false)
    setShowResetPass(false)

  }

  const handleResetPassClose = () => {
    setShowResetPass(false)
  }
  const handleDeactivateClose = () => {
    setShowDeactivate(false)
  }

  const resetPasswordSubmit = () => {
    props.showLoader();

    axios
      .post(`${Key.domain}/customer/reset_jnp_user_password`, {
        "email": userDetails.username
      })
      .then((response) => {
        console.log(response)

        const reloadusers = refreshh + 1
        setrefreshh(reloadusers)
        props.refreshData()

        props.hideLoader();
        const msg = `Account ${userDetails.username} password reset successfully !`;
        toast(<ToastMessage message={msg} />);

        handleActionClose()
      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          props.hideLoader();

          const msg = "Access denied.. Please Login";
          toast(<ToastMessage message={msg} />);

          handleActionClose()

        }
      });;
  }
  console.log(userDetails)
  const deactivateAccountSubmit = () => {
    props.showLoader();

    axios
      .post(`${Key.domain}/customer/deactivate_jnp_user`, {
        "email": userDetails.username
      })
      .then((response) => {
        console.log(response)
        const reloadusers = refreshh + 1
        setrefreshh(reloadusers)

        props.refreshData()
        // props.hideLoader();
        const msg = `Account ${userDetails.company_name} deactivated Successfully !`;
        toast(<ToastMessage message={msg} />);

        handleActionClose()
      }).catch((err) => {
        // console.log(err.response.data.data )
        props.hideLoader();

        const msg = "Access denied.. Please Login";
        toast(<ToastMessage message={msg} />);

        handleActionClose()


      });;
  }

  const getCustomerDetails = () => {
    console.log(props)

    if ((location.state && location.state.customerid) || props.customerId) {
      setLoading(true)
      axios
        .post(`${Key.domain}/customer/get_customerdetails`, {
          "customer_id": location.state && location.state.customerid ? location.state.customerid : props.customerId,
        })
        .then((response) => {
          // console.log(response)
          console.log(response.data.data)

          // console.log(response.data.data.companyDetails)

          setCustomerDetails(response.data.data)
          setCompanyDetails(response.data.data.companyDetails ? response.data.data.companyDetails : [])
          setPackageDetails(response.data.data.packageDetails ? response.data.data.packageDetails : [])
          setUserDetails(response.data.data.userDetails ? response.data.data.userDetails : [])
          setRecruiterDetails(response.data.data.recruiterDetails ? response.data.data.recruiterDetails : [])
          setLoading(false)

        }).catch((err) => {
          setLoading(false)

          // console.log(err.response.data.data )
          if (err.response.data.data === "Access denied.. Please Login") {
            //   props.hideLoader();


          }
        });;

      axios
        .post(`${Key.domain}/customer/get_cust_payment_details`, {
          "customer_id": location.state && location.state.customerid ? location.state.customerid : props.customerId,
        })
        .then((response) => {
          // console.log(response)
          console.log(response.data.data)

          // console.log(response.data.data.companyDetails)

          setPaymentsList(response.data.data)
          // setLoading(false)


        }).catch((err) => {
          setLoading(false)

          // console.log(err.response.data.data )
          if (err.response.data.data === "Access denied.. Please Login") {
            //   props.hideLoader();


          }
        });;
    }
  }


  const refreshhdata = () => {
    props.refreshData()

  }
  return (
    <>
      {loading ?

<div style = {{textAlign: "center"}}>
        <ScaleLoader
          height='45'
          width='5'
          color='#406882'
          loading={loading} size={100} />
</div>

        :


        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Company Info" {...a11yProps(1)} />
              <Tab label={`${location && location.state && (location.state.benchSales === "true") ? "Resumes" : "Users"}`} {...a11yProps(2)} />
              <Tab label="Package Info" {...a11yProps(2)} />

              <Tab label="Mail Conversation" style={{ display: `${location && location.state && (location.state.benchSales === "true") ? 'none' : "visible"}` }} {...a11yProps(2)} />
              <Tab label="Queries" style={{ display: `${location && location.state && (location.state.benchSales === "true") ? 'none' : "visible"}` }} {...a11yProps(2)} />
              <Tab label="Activity" style={{ display: `${location && location.state && (location.state.benchSales === "true") ? 'none' : "visible"}` }} {...a11yProps(2)} />
              <Tab label="Actions" style={{ display: `${location && location.state && (location.state.benchSales === "true") ? 'none' : "visible"}` }} {...a11yProps(2)} />


            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CustomerPersonalInfo packageDetails={packageDetails} userDetails={userDetails} refreshData={refreshhdata}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CustomerCompanyInfo companyDetails={companyDetails} userDetails={userDetails} getCustomerDetails={getCustomerDetails} />
          </TabPanel>
          <TabPanel value={value} index={2} >
            <CustomerContactInfo companyDetails={companyDetails} recruiterDetails={recruiterDetails} />
          </TabPanel>
          <TabPanel value={value} index={3} >
            <CustomerPackageInfo customerId={location.state && location.state.customerid ? location.state.customerid : props.customerId} companyDetails={companyDetails} freepackage={location.state ? location.state.freepackage : ""} packageDetails={packageDetails} paymentsList={paymentsList} />
          </TabPanel>
          <TabPanel value={value} index={4} >
            <Mailbox />
          </TabPanel>
          <TabPanel value={value} index={5} >
            <CustomerNotes customerId={location.state && location.state.customerid ? location.state.customerid : props.customerId} />
          </TabPanel>
          <TabPanel value={value} index={6} >
            <Activity customerId={location.state && location.state.customerid ? location.state.customerid : props.customerId} />
          </TabPanel>
          <TabPanel value={value} index={7} >
            <FormControl style={{ marginTop: "5vh", marginBottom: "3vh" }}>
              <div className=''>
                {/* <Button variant="secondary" className=" m-2" style={{ background: "grey", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(true); setShowResetPass(false) }
                }>
                  Deactivate
                </Button> */}

                <Button variant="Primary" className=" m-2" style={{ background: "blue", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(false); setShowResetPass(true) }
                }>
                  Reset Password
                </Button>
              </div>
              <> {showResetPassword ? <div className='mt-2'  >

                <h5>Are you sure to <span style={{ textDecoration: "underline", color: "blue" }}>reset</span> password ? </h5>
                <div>
                  <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                    No
                  </Button>
                  <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={resetPasswordSubmit}>
                    Yes
                  </Button>
                </div>
              </div>
                : ""}
                {showDeactivate ?
                  <div className='mt-2'>

                    <h5>Confirm to <span style={{ textDecoration: "underline", color: "grey" }}>deactivate</span> account ? </h5>

                    <div>
                      <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                        No
                      </Button>
                      <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                        Yes
                      </Button>
                    </div>
                  </div>
                  : ""}
              </>
            </FormControl>

          </TabPanel>
        </Box>
      }
    </>
  )
}
const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailView2)