/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'
import { connect } from "react-redux";
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";
import { MdRefresh } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";

function User(props) {
    const formikRef = useRef();
    const history = useNavigate();

    const [titles, setTitles] = useState([]);
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [data, setData] = useState([]);
    const [value, setValue] = React.useState('created');




    const createdColumns = [{
        label: "S. No",
        name: "sno",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Application Title</span>,
        name: "application_title",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Count</span>,
        name: "count",
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        name: "latest_added_date",
        label: <span>Latest added on</span>,
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? moment(value).format("LLL") : "N/A"
            }
        },
    }];

    const modifiedColumns = [{
        label: "S. No",
        name: "sno",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Application Title</span>,
        name: "application_title",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Count</span>,
        name: "count",
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        name: "latest_modified_date",
        label: <span>Latest modified on</span>,
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? moment(value).format("LLL") : "N/A"
            }
        },
    }]

    const handleChange = (event) => {
        setValue(event.target.value);

    };


    useEffect(() => {
        getTitles();
    }, [])

    const getTitles = () => {
        props.showLoader();
        axios
            .post(`${Key.domain}/dashboard/get_applicationtitles_list_count`, {
                "user_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token,
                "perDay": 0
            })
            .then((response) => {
                console.log(response)
                setData(response.data.data)

                setTitles(response.data.data[2].old_applications)
                props.hideLoader();

            }).catch((err) => {
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();
                    setSessionExpired(true)
                }
            });;
    }



    const columns = [
        {
            label: "S. No",
            name: "sno",
            options: {
                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Application Title</span>,
            name: "application_title",
            options: {
                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Count</span>,
            name: "count",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            name: "latest_added_date",
            label: <span>Latest added on</span>,
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).fromNow() : "N/A"
                }
            },
        }
    ];

    const options = {
        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],
        onCellClick: (cellIndex, rowIndex) => {

            let selectedTitle = titles[rowIndex.dataIndex]


            if (rowIndex.colIndex === 1) {
                console.log(selectedTitle)
                history("/dashboard/applicationTitleResumes", { state: { title: selectedTitle.application_title, count: selectedTitle.count, perDay: 2 } })
            }
        },

    };


    return (
        <div style={{ marginTop: "-0px", boxShadow: "0px 0px 10px black" }}>
            <MUIDataTable
                title={
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {/* <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="created" control={<Radio />} label="Created" />
                                    <FormControlLabel value="modified" control={<Radio />} label="Last Modified" />
                                </RadioGroup>
                            </FormControl> */}
                            <h5> Application Titles</h5>
                            <h6>Total Resumes : {data.length > 2 ? (value === 'created' ? data[3].old_resumes_count : data[5].old_modified_resumes_count) : 0}</h6>
                        </div>
                    </div>
                    // // <div>
                    // //     <div className='d-flex align-items-center'>
                    // //         <MdRefresh style={{ cursor: "pointer" }} onClick={() => { getTitles() }} />
                    // //         <div style={{ width: "100%", marginLeft: "10px" }}>
                    // //             <Formik
                    // //                 innerRef={formikRef}
                    // //                 initialValues={{ state: selectedState }}
                    // //                 onSubmit={(values) => {

                    // //                 }}
                    // //             >
                    // //                 {({ touched, errors, isSubmitting, values, handleChange }) =>

                    // //                     <div className="col-4">
                    // //                         <div className="form-group mb-3">

                    // //                             <Field
                    // //                                 name="state"
                    // //                                 as="select"
                    // //                                 className={'mt-2 form-select form-control'}
                    // //                                 onChange={(e) => getTitlesByState(e.target.value)}
                    // //                                 value={selectedState}
                    // //                             >
                    // //                                 <option>Select state</option>
                    // //                                 {states.map((item, index) => (
                    // //                                     <option value={item.id}>{item.name}</option>
                    // //                                 ))}
                    // //                             </Field>
                    // //                             <ErrorMessage component="div" name="state" className="invalid-feedback" />
                    // //                         </div>
                    // //                     </div>
                    //                 }
                    //             </Formik>
                    //         </div>

                    //     </div>
                    // </div>
                }
                data={data.length > 2 ? (value === 'created' ? data[2].old_applications : data[4].old_modified_applications) : []}
                columns={value === 'created' ? createdColumns : modifiedColumns}
                options={options}
            />
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    }
}
function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);