
/* eslint-disable */
import {SHOW_LOADER} from '../constant/actionTypes';
import {HIDE_LOADER} from '../constant/actionTypes';




const initialState = {
    data: {},
    loading: false
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
        
      case SHOW_LOADER:
          return { ...state, loading: true };
  
      case HIDE_LOADER:
        return { ...state, loading: false };
  
      default:
        return state;
    }
  };