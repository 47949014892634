/* eslint-disable */

import React from 'react';
import parse from "html-react-parser";
import Logo from '../components/images/Icon-awesome-building.png';
import Key from "../clientVariables.json";

const CustomerCompanyInfo = (props) => {
    const { companyDetails, userDetails } = props;
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <center >

                            <img
                                src={
                                    companyDetails.logofilename &&
                                        companyDetails.logofilename !== undefined &&
                                        companyDetails.logofilename !== null &&
                                        companyDetails.logofilename !== ""
                                        ? `${Key.domain}/customer/company_logo/${companyDetails.id}/${companyDetails.logofilename}`
                                        : companyDetails.logo_url &&
                                            companyDetails.logo_url !== "" &&
                                            companyDetails.logo_url !== "null" &&
                                            companyDetails.logo_url !== null &&
                                            companyDetails.logo_url !== undefined
                                            ? companyDetails.logo_url
                                            : Logo
                                }
                                //src={Logo}
                                alt="logo" style={{ width: "90px", height: "80px", boxShadow: "0 5px 5px black", borderRadius: "50px" }} />
                            <p><b>{userDetails.company_name ? userDetails.company_name : 'N/A'}</b></p>

                        </center>
                        <div className="card px-3 py-3 mb-4" style={{ boxSizing: "border-box" }}>
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-group list-group-flush">
                                        <div className="row">
                                            <div className="col-5" style={{ boxShadow: "0px 0px 5px black", marginRight: "30px", marginLeft: "70px", paddingTop: "5px" }}>

                                                {/* <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Company Name</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>{companyDetails.name ? companyDetails.name : 'N/A'}</span>
                            </div>
                          </div>
                        </p> */}
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Contact Name</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{userDetails.full_name ? userDetails.full_name : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Contact Mail</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.contactemail ? companyDetails.contactemail : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Contact </b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.contactphone ? companyDetails.contactphone : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Description</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.description ? parse(companyDetails.description.replace(/<[^>]+>/g, "")
                                                                .slice(0, 80)) : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Company URL</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">

                                                            <span>{companyDetails.url ? companyDetails.url : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>EIN/SSN</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>N/A</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>State Of Incorporation</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.State_of_Inc ? companyDetails.State_of_Inc : "N/A"
                                                            }</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Since</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.since ? companyDetails.since : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Size Of Company</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5">
                                                            <span>{companyDetails.companysize ? companyDetails.companysize : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </p>


                                            </div>

                                            <div className="col-5" style={{ boxShadow: "0px 0px 5px black", paddingTop: "5px" }}>

                                                <p>
                                                    <div className="row">
                                                        <div className="col-6" >
                                                            <span><b>Address</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5"><span>{companyDetails.address1 ? companyDetails.address1 : 'N/A'}</span></div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>City</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5"><span>{companyDetails.cityname ? companyDetails.cityname : 'N/A'}</span></div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>State</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5"><span>{companyDetails.statename ? companyDetails.statename : 'N/A'}</span></div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Country</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5"><span>USA</span></div>
                                                    </div>
                                                </p>
                                                <p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span><b>Zipcode</b></span>
                                                        </div>
                                                        <div className="col-1">:</div>
                                                        <div className="col-5"><span>{companyDetails.zipcode ? companyDetails.zipcode : 'N/A'}</span></div>
                                                    </div>
                                                </p>



                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerCompanyInfo;
