/* eslint-disable */



// component
import UserService from "../../src/services/user.service";
import * as types from "../constant/actionTypes";




class UserActions {

    constructor() {
        this.userService = UserService;

    }




    createUser(newUser) {
        return (dispatch) => {
            return this.userService.createUser(newUser)
                .then(user => {
                    const updatedUser = user.data.data[0]
                    if (updatedUser.id) {
                        if (updatedUser.usertype === "5") {

                            window.location.href = "/Jsdashboard"
                            dispatch(this.createUserSuccess(updatedUser));
                        }

                    } else {
                        alert("Signup failure !");



                        sessionStorage.clear();
                        localStorage.clear();

                    }
                })
                .catch(error => {
                    throw error;
                });
        };
    }

    createUserSuccess(user) {
        return { type: types.CREATE_USER_SUCCESS, payload: user };
    }






    login(username, password, ipaddress, emp) {
        return (dispatch) => {
            return this.userService.login(username, password, ipaddress, emp)
                .then(user => {
                    console.log(user)
                    const isloading = false
                    dispatch(this.LoginLoadingSuccess(isloading))
                    const updatedUser = user.data.data[0]
                    if (user.data.success === 0) {
                        dispatch(this.loginFailure(user.message))
                        sessionStorage.clear();
                        localStorage.clear();
                        isloading = false
                        return dispatch(this.LoginLoadingSuccess1(isloading))

                    }
                    else {
                        if (updatedUser.id) {
                            if (emp === 1) {

                                if (updatedUser.usertype === "5") {
                                    toast(
                                        <ToastMessage message="Employer credentials invalid" />
                                    );


                                    window.location.reload()
                                    sessionStorage.clear();
                                    localStorage.clear();

                                } if (updatedUser.id) {
                                    dispatch(this.loginSuccess(updatedUser));
                                }


                            } else {

                                if (parseInt(updatedUser.user_type) === 1) {

                                    window.location.href = "/dashboard/Leads"



                                    dispatch(this.loginSuccess(updatedUser));



                                } else if (parseInt(updatedUser.user_type) === 5) {

                                    window.location.href = "/dashboard/researchdashboard"
                                    // window.location.href = "/dashboard/AccountingDashboard"



                                    dispatch(this.loginSuccess(updatedUser));

                                }

                                else if (parseInt(updatedUser.user_type) === 3) {

                                    //window.location.href = "/dashboard/Salesdashboard"
                                    // window.location.href = "/dashboard/AccountingDashboard"

                                    window.location.href = "/dashboard/Leads"

                                    dispatch(this.loginSuccess(updatedUser));

                                }
                                else if (parseInt(updatedUser.user_type) === 7) {

                                    //window.location.href = "/dashboard/Salesdashboard"
                                    window.location.href = "/dashboard/AccountingDashboard"



                                    dispatch(this.loginSuccess(updatedUser));

                                }
                                else if (parseInt(updatedUser.user_type) === 2) {

                                    window.location.href = "/dashboard/Leads"



                                    dispatch(this.loginSuccess(updatedUser));

                                }

                                if (parseInt(updatedUser.user_type) === 4) {

                                    window.location.href = "/dashboard/SalesExecutiveDashboard"
                                    dispatch(this.loginSuccess(updatedUser));

                                }

                            }

                        } else {

                            alert("Please enter valid user name ");
                            sessionStorage.clear();
                            localStorage.clear();

                        }
                    }
                })

        };
    }

    loginSuccess(user) {
        return { type: types.LOGIN_SUCCESS, payload: user };
    }


    formEmpty(msg) {
        return (dispatch) => {
            dispatch(this.loginFailure(msg))
        }
    }

    loginFailure(msg) {
        return { type: types.LOGIN_FAILURE, payload: msg };
    }


    LoginLoadingSuccess(isloading) {
        return (dispatch) => {
            dispatch(this.LoginLoadingSuccess1(isloading));
            //return { type: types.IS_LOADING, payload: isloading };
        }

    }

    LoginLoadingSuccess1(isloading) {
        return { type: types.IS_LOGINLOADING, payload: isloading };
    }

    // Logout
    logout(uid, ipadress) {
        return (dispatch) => {
            return this.userService.logout(uid, ipadress).then(() => {
                dispatch(this.logoutSuccess());
            })
        };
    }

    logoutSuccess(user) {
        return { type: types.LOGOUT_SUCCESS };
    }

}

export default new UserActions();
