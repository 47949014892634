/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Paymentform from "./Paymentform";
import Logo from '../components/Logo';

import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import axios from 'axios'
import { connect } from "react-redux";
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Key from "../clientVariables.json";
import ToastMessage from '../components/ToastMessage';
import Paymentinformation from './Paymentinformation';
import { showLoader, hideLoader } from "../constant/loader.action";





const PaymentPermission = (props) => {
  const [packageDetails, setPackageDetails] = useState({})
  const [userDetails, setUserDetails] = useState({})
  const [salesPersonDetails, setSalesPersonDetails] = useState({})
  const [companyDetails, setCompanyDetails] = useState({})
  const [paymentResponse, setPaymentResponse] = useState({})
  const [paymentSuccess, setPaymentSuccess] = useState({})
  const [paymentSuccssRes, setPaymentSuccessResponse] = useState({})
  const [paymentMsg, setPaymentMsg] = useState("")
  const [Alreadypayformshow, setAlreadypayformshow] = useState(false)
  const mdUp = useResponsive('up', 'md');


  const handleToken = async (token, addresses) => {
    console.log(token)
    const description = "Jobs n Profiles Package subscription for" + ' ' + `${userDetails.username}`
    const amount = 1 * 100 // set the package amount here dynimacally from state-packageDetails.Amount

    const response = await axios.post(
      `${Key.domain}/payment/stripePaymentCheckout`,
      { token, description, amount }
    );
    console.log("Response:", response.data);
    setPaymentMsg(response.data.data.msg)

    setPaymentSuccess(response.data.data.transactionresponse.charge !== undefined && response.data.data.transactionresponse.charge !== null ? response.data.data.transactionresponse.charge : {})

    if (response.data.data.msg === "Payment Successful") {
      const transactionresponse = JSON.stringify(response.data.data.transactionresponse.charge);
      const transactionid = response.data.data.transactionresponse.charge;

      // props.showLoader();

      await fetch(`${Key.domain}/payment/save_transaction_details`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          "crm_userid": salesPersonDetails.id,
          // "session_token": props.currentUser.session_token,
          "customer_id": userDetails.id,
          "customer_email": userDetails.username,
          "transactionid": transactionid,
          "transatcionData": transactionresponse,
          "companyDetails": companyDetails,
          "salesPersonDetails": salesPersonDetails,
          "packageDetails": packageDetails,
          "userDetails": userDetails,
          "price": packageDetails.Amount ? packageDetails.Amount : packageDetails.price
        })
      })
        .then(response => response.json())
        .then(resdata => {
          console.log(resdata)
          setPaymentResponse(resdata)
          // props.hideLoader();

          /* if (resdata.data === "Transaction details saved successfully") {
 
             const msg = "Payment Successful and your plan is Activated"
 
             toast(
 
               <ToastMessage message={msg} />
 
             )
 
             window.location.href = "https://jobsnprofiles.com/EmpLogin"
 
           } else{
             const msg = "Transaction Failed..Please try Again"
 
             toast(
 
               <ToastMessage message={msg} />
 
             )
           } */
        })
    }
    else if (response.data.data.msg === "Payment failed") {
      const msg = "Payment failed. Please try again";


      const transactionresponse = JSON.stringify(response.data.data.transactionresponse.raw.message);
      // const transactionid = response.data.data.transactionresponse.charge;

      // props.showLoader();

      await fetch(`${Key.domain}/payment/save_transaction_details`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          "crm_userid": salesPersonDetails.id,
          // "session_token": props.currentUser.session_token,
          "customer_id": userDetails.id,
          "customer_email": userDetails.username,
          "transactionid": null,
          "transatcionData": transactionresponse,
          "companyDetails": companyDetails,
          "salesPersonDetails": salesPersonDetails,
          "packageDetails": packageDetails,
          "userDetails": userDetails,
          "price": packageDetails.Amount ? packageDetails.Amount : packageDetails.price
        })
      })
        .then(response => response.json())
        .then(resdata => {
          console.log(resdata)
          setPaymentResponse(resdata)

        })

      toast(

        <ToastMessage message={msg} />

      )
    }
  }


  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathArray = currentPath.split("/");
    const emailToken = pathArray[2]
    const subscription_id = pathArray[3]
    console.log(emailToken)
    console.log(subscription_id)
    if (subscription_id !== '') {
      axios.post(`${Key.domain}/payment/get_paymentdetails`, {
        "cust_email_token": emailToken,
        "cust_subscription_id": subscription_id
      })
        .then((response) => {
          console.log(response)
          if (response.data.data.packageDetails.payment_status == 1) {
            setAlreadypayformshow(true);
          } else {
            if (response.data.data) {
              if (response.data.data.packageDetails) {
                setPackageDetails(response.data.data.packageDetails)
              }
              if (response.data.data.userDetails) {
                console.log(response.data.data.userDetails)
                setUserDetails(response.data.data.userDetails)
              }
              if (response.data.data.salesPersonDetails) {
                setSalesPersonDetails(response.data.data.salesPersonDetails)
              }
              if (response.data.data.companyDetails) {
                setCompanyDetails(response.data.data.companyDetails)
              }
            }
          }

        })
    } else if (subscription_id == '') {
      axios.post(`${Key.domain}/payment/get_paymentdetails`, {
        "cust_email_token": emailToken,
        "cust_subscription_id": null
      })
        .then((response) => {
          console.log(response)
          if (response.data.data.packageDetails.payment_status == 1) {
            setAlreadypayformshow(true);
          } else {
            if (response.data.data) {
              if (response.data.data.packageDetails) {
                setPackageDetails(response.data.data.packageDetails)
              }
              if (response.data.data.userDetails) {
                console.log(response.data.data.userDetails)
                setUserDetails(response.data.data.userDetails)
              }
              if (response.data.data.salesPersonDetails) {
                setSalesPersonDetails(response.data.data.salesPersonDetails)
              }
              if (response.data.data.companyDetails) {
                setCompanyDetails(response.data.data.companyDetails)
              }
            }
          }

        })
    }
  }, [])


  const getPaymentResponse = (data, paymentResponse) => {
    setPaymentMsg(data)
    setPaymentSuccessResponse(paymentResponse.transactionresponse.charge)
  }
  return (
    <div>
      {Alreadypayformshow ? <p>

        <span style={{ paddingTop: "12px" }}>
          <Logo />
        </span>
        <center>
          <div style={{ marginTop: "180px", width: "50%" }}>


            <div class="alert alert-success" role="alert">

              Your payment has been processed successfully! payment link has been expired
            </div>

          </div>
        </center>
      </p> :
        <div className='container'>

          {paymentMsg.data === "Transaction details saved successfully" ? <Paymentinformation paymentMsg={paymentMsg} paymentSuccess={paymentSuccssRes} userDetails={userDetails} packageDetails={packageDetails} salesPersonDetails={salesPersonDetails} /> :

            <div className='row'>
              <div className='col-sm-7 col-md-12 col-sm-12 col-lg-7 card' style={{ padding: "3%" }}>
                <div className="">
                  <Logo /><br />
                  <h4>Billing Information</h4>
                  <li className="list-group-item">
                    <div className="row mb-1">
                      <div className="col-5">
                        <b>Full Name</b>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-6">{userDetails.full_name ? userDetails.full_name : 'N/A'}</div>
                    </div>
                  </li>



                  <li className="list-group-item">
                    <div className="row mb-1">
                      <div className="col-5">
                        <b> City</b>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-6">{companyDetails.cityname ? companyDetails.cityname : 'N/A'}</div>
                    </div>
                  </li>

                  <li className="list-group-item">
                    <div className="row mb-1">
                      <div className="col-5">
                        <b> State</b>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-6">{companyDetails.statename ? companyDetails.statename : 'N/A'}</div>
                    </div>
                  </li>


                  <li className="list-group-item">
                    <div className="row mb-1">
                      <div className="col-5">
                        <b> Country</b>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-6">{companyDetails.countryname ? companyDetails.countryname : 'N/A'}</div>
                    </div>
                  </li>

                  <li className="list-group-item">
                    <div className="row mb-1">
                      <div className="col-5">
                        <b> Zipcode</b>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-6">{companyDetails.zipcode ? companyDetails.zipcode : 'N/A'}</div>
                    </div>
                  </li>




                  <br />


                  <div className="pt-2">
                    <h4>Subscription Information</h4>



                    <li className="list-group-item">
                      <div className="row mb-1">
                        <div className="col-5">
                          <b> Plan Opted</b>
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-6">{packageDetails.title ? packageDetails.title : 'Custom Plan'}</div>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="row mb-1">
                        <div className="col-5">
                          <b> Job Posts</b>
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-6">
                        
                        {/* packageDetails.jobs_allowed ? packageDetails.jobs_allowed : 'N/A' */}
                        Unlimited
                        
                        </div>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="row mb-1">
                        <div className="col-5">
                          <b> Profile Views</b>
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-6">{packageDetails.resumeviews_allowed ? packageDetails.resumeviews_allowed : 'N/A'}</div>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="row mb-1">
                        <div className="col-5">
                          <b> Recruiters</b>
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-6">{packageDetails.users_allowed ? packageDetails.users_allowed : 'N/A'}</div>
                      </div>
                    </li>




                  </div>







                </div>
              </div>






              &nbsp;

              <div className='col-sm-4 col-md-12 col-lg-4 card' style={{ padding: "1%" }}>
                <div className="a">
                  <br /><br /><br /><br />

                  <div className="pt-2 mt-4">
                    <h4>Order Summary</h4>
                    <div className='mt-2'>
                      <li className="list-group-item ">
                        <div className="row mb-1">
                          <div className="col-5">
                            <b> Sub Total</b>
                          </div>
                          <div className="col-1">:</div>
                          <div className="col-6">{packageDetails.Amount ? <>{packageDetails.Amount}  USD</> : packageDetails.price}</div>
                        </div>
                      </li>

                      <li className="list-group-item">
                        <div className="row mb-1">
                          <div className="col-5">
                            <b> Tax</b>
                          </div>
                          <div className="col-1">:</div>
                          <div className="col-6">0 USD</div>
                        </div>
                      </li>


                      <li className="list-group-item">
                        <div className="row mb-1">
                          <div className="col-5">
                            <b> Grand</b>
                          </div>
                          <div className="col-1">:</div>
                          <div className="col-6">{packageDetails.Amount ? <>{packageDetails.Amount}  USD</> : packageDetails.price}</div>
                        </div>
                      </li>



                    </div>
                  </div>




                  <hr />


                    <Paymentform packageDetails={packageDetails} userDetails={userDetails} salesPersonDetails={salesPersonDetails} companyDetails={companyDetails} getPaymentResponse={getPaymentResponse} />
                    
                </div>
              </div>
            </div>
          }

        </div>
      }

    </div>


  )
}

export default PaymentPermission