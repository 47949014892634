/* eslint-disable */
import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FcDocument } from "react-icons/fc";
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import { UserMoreMenu } from '../sections/@dashboard/user';
import Key from '../clientVariables.json'
import { useLocation, useNavigate } from "react-router-dom";

const columns = ["Invoice No", "Customer Name", "Date", "Plan", {


  name: "actions",

  label: <span>Aggrement</span>,

  options: {

    customBodyRender: (value, tableMeta, updateValue) => {

      // return <UserMoreMenu />
      return <div>&nbsp;&nbsp;<FcDocument size={20} /></div>

    },

    filter: true,

    sort: true,

    // setCellProps: value => {

    //   return {

    //     style: {

    //       cursor: 'pointer'

    //     }

    //   };

    // }



  },
},];


const options = {
  filterType: 'checkbox',
};





function Requests(props) {
  const [requestData, setrequestData] = useState([])
  const [customerId, setCustomerId] = useState("")
  const [invoiceDoc, setInvoiceDoc] = useState("")
  const [poDoc, setPoDoc] = useState("")
  const [invoice, setInvoice] = useState([])
  const history = useNavigate();

  useEffect(() => {
    axios.get(`${Key.domain}/customer/get_requests`)
      .then(resdata => {
        console.log(resdata)
        setrequestData(resdata.data.data)
        console.log(requestData)
      })
  }, [])



  const columns = [
    {
      label: <span>Customer ID</span>,
      name: "user_id",
      options: {
        customBodyRender: (value) => {
          return value === null ? "N/A" : <a style={{ cursor: "pointer" }}>{value}</a>
        }
      },
    },
    {
      label: <span>Customer Name</span>,
      name: "firstname",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value
        }
      },
    },
    {
      label: <span>Email</span>,
      name: "email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value
        }
      },
    },
    {
      label: <span>Date</span>,
      name: "created",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("LL")

        }
      },
    },
    {
      label: <span>Message</span>,
      name: "message",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return value !== undefined && value !== null && value !== "" ? value.slice(0, 100) : ""

        }
      },
    },


  ];
  const options = {
    fixedHeader: true,
    density:true,
    selectableRows: true,
    search:true,
    filter:true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
    onCellClick: (cellIndex, rowIndex) => {
      console.log(cellIndex.props.children);
      if (typeof (cellIndex.props.children) === 'number') {
        history("/dashboard/customerDetailsView", { state: { customerid: cellIndex.props.children, setIndex: 3 } })
      }
      console.log(rowIndex.dataIndex);
      console.log(rowIndex);
    },
    selectableRows: false

  };
  return (
    <div style={{marginTop:"-60px",boxShadow:"0px 0px 5px black"}}>

      <MUIDataTable
        options={options}
        title={<h4>Requests List</h4>}
        data={requestData}
        columns={columns}

      />

    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(Requests));
