/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Grid, Card, Container } from '@mui/material'

import MUIDataTable from "mui-datatables";
import axios from 'axios'
import { connect } from "react-redux";
import moment from 'moment'

import Key from '../clientVariables.json'


import { showLoader, hideLoader } from "../constant/loader.action";

const columns = ["Customer Id", "Company Name", "Plan Opted", "Activation Date", "Expiry Date", "Status"];

const data = [
    ["88568", "Netflex", "Monthly", "01-07-2021", "21-08-2022"],
    ["42568", "Google", "Yearly", "01-07-2021", "21-08-2022"],


];

const options = {
    filterType: 'checkbox',
};

const Userdetailview = (props) => {
    const location = useLocation();
    const [customersDetails, setcustomersDetails] = useState([]);
    const [salesDetails, setsalesDetails] = useState([]);
    const [reportingDetails, setreportingDetails] = useState([]);
    const [userDetails, setuserDetails] = useState([]);



    // console.log(location.state.empid)


    useEffect(() => {
        props.showLoader();

        axios
            .post(`${Key.domain}/admin/get_crm_userdetails`, {
                "crm_user_id": location.state.empid,
            })
            .then((response) => {
                // console.log(response)
                // console.log(response.data.data)


                setcustomersDetails(response.data.data.customersDetails ? response.data.data.customersDetails : [])
                setsalesDetails(response.data.data.salesDetails ? response.data.data.salesDetails : [])
                setreportingDetails(response.data.data.reportingDetails ? response.data.data.reportingDetails : [])
                setuserDetails(response.data.data.userDetails ? response.data.data.userDetails : [])

                props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();


                }
            });;


    }, [])





    const columns = [
        {
            label: <span>Customer Id</span>,
            name: "userid",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Company Name</span>,
            name: "companyName",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Plan Opted</span>,
            name: "PlanOpted",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"

                }
            },
        },
        {
            label: <span>Activation Date</span>,
            name: "package_start_date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return value ? moment(value).format("LL") : "N/A"

                }
            },
        },
        {
            label: <span>Expiry Date</span>,
            name: "package_end_date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return value ? moment(value).format("LL") : "N/A"

                }
            },
        },
        {
            label: <span>Status</span>,
            name: "status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return parseInt(value) === 1 ? "Active" : "In Active"
                }
            },
        },
    ];



    return (
        <div>
            <Container>
                <Card style={{ padding: "32px", marginBottom: "15px" }}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h4 className='mb-4'><b>User Details</b></h4>

                            <div className='row'>
                                <div className='col-md-5'>
                                    <p>Employer Id</p>
                                    <p>User Name </p>
                                    <p>Role</p>
                                    <p>Mobile</p>
                                    <p>Email</p>
                                </div>

                                <div className='col-md-2'>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>

                                </div>

                                <div className='col-md-5'>
                                    <p>{userDetails.id ? userDetails.id : "N/A"}</p>
                                    <p>{userDetails.full_name ? userDetails.full_name : "N/A"}</p>
                                    <p>{userDetails.usertypeName ? userDetails.usertypeName : "N/A"}</p>
                                    <p>{userDetails.mobile ? userDetails.mobile : "N/A"}</p>
                                    <p>{userDetails.username ? userDetails.username : "N/A"}</p>
                                </div>
                            </div>



                        </div>

                        <div className='col-md-3'>
                            &nbsp;
                        </div>
                        <div className='col-md-4'>
                            <h4 className='mb-4'><b>Reporting Person Details</b></h4>

                            <div className='row'>
                                <div className='col-md-5'>
                                    <p>Employer Id</p>
                                    <p>User Name </p>
                                    <p>Role</p>
                                    <p>Mobile</p>
                                    <p>Email</p>
                                </div>

                                <div className='col-md-2'>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>
                                    <p>:</p>

                                </div>

                                <div className='col-md-5'>
                                    <p>{reportingDetails.reporting_to ? reportingDetails.reporting_to : "N/A"}</p>
                                    <p>{reportingDetails.ReportingPerson ? reportingDetails.ReportingPerson : "N/A"}</p>
                                    <p>{reportingDetails.ReportPersonRole ? reportingDetails.ReportPersonRole : "N/A"}</p>
                                    <p>{reportingDetails.mobile ? reportingDetails.mobile : "N/A"}</p>
                                    <p>{reportingDetails.email ? reportingDetails.email : "N/A"}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <MUIDataTable
                    title={"customer Sales"}
                    data={customersDetails}
                    columns={columns}
                    options={options}
                />
            </Container>

        </div>
    )
}


const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Userdetailview);