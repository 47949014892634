/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, Pagination } from "@mui/material";
import Key from '../clientVariables.json';

import { MdRefresh } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { connect } from "react-redux";

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";



const visatypes = [
    { id: 3, text: 'H1', value: '3' },
    { id: 1, text: 'GC', value: '1' },
    { id: 2, text: 'USC', value: '2' },
    { id: 7, text: 'EAD', value: '7' },
    { id: 4, text: 'OPT', value: '4' },
    { id: 6, text: 'TN', value: '6' },
    { id: 11, text: 'F1', value: '11' },
    { id: 6, text: 'L1', value: '9' },
    { id: 6, text: 'GC - EAD', value: '10' },
    { id: 6, text: 'H4 - EAD', value: '11' },
    { id: 6, text: 'H4', value: '12' },
    { id: 6, text: 'L2 - EAD', value: '14' },
    { id: 6, text: 'OPT - EAD', value: '15' },
    { id: 6, text: 'CPT', value: '16' },
    { id: 6, text: 'Others', value: '6' },

]

function Parserresumeslist(props) {
    const formikRef = useRef();

    const location = useLocation();

    const [show, setShow] = useState(false);
    const [showfile, setShowfile] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [xlData, setXlData] = useState([]);

    const [page, setPage] = useState(0)
    const [uid, setUid] = useState(0)

    const [count, setCount] = useState(parseInt(100))
    const [resumeId, setResumeId] = useState("")
    const [selectedResumeIds, setSelectedResumeIds] = useState([]);
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedVisa, setSelectedVisa] = useState("");
    const [selectedStateId, setSelectedStateId] = useState(0);



    useEffect(() => {
        // alert(location.state.title && location.state.count)
        getData(page)
        getStates();

    }, [])

    const getData = (value) => {
        console.log(value)
        props.showLoader();

        setPage(value)

        var resCount = (location.state.count)

        axios
            .post(`${Key.domain}/dashboard/get_applications_list_by_title`, {
                "offset": value,
                "title": location.state.title,
                "limit": 20,
                "stateid": selectedState,
                "visaid": selectedVisa,
                "created": parseInt(location.state.perDay) === 1 ? location.state.dataByCreated : "",
                "perDay": location.state.perDay,
                "start_date": location.state.startdate ? location.state.startdate : "",
                "end_date": location.state.enddate ? location.state.enddate : ""


            })
            .then((response) => {
                console.log(response);
                setResumes(response.data.data)
                setCount(resCount)
                props.hideLoader();

            })

    }




    const columns = [
        {
            name: "id",
            label: "Resume Id",
            options: {
                filter: true,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },

            }

        },
        {
            name: "application_title",
            label: "Application Title",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },
            }
        },
        {
            name: "full_name",
            label: "Full Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p style={{ cursor: 'pointer' }}>{value}</p>
                },
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "email_address",
            label: "Email",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "home_phone",
            label: "Phone no",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "total_experience",
            label: "Exp",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "created",
            label: "Created On",
            options: {
                filter: true,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    // var usaTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
                    // usaTime = new Date(usaTime);
                    // var todaydate = moment(usaTime).format('L');
                    return moment(value).format('MMM DD, YYYY HH:mm A')
                },
            }
        },


        {
            name: "last_modified",
            label: "Last Modified",
            options: {
                filter: true,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    // var usaTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
                    // usaTime = new Date(usaTime);
                    // var todaydate = moment(usaTime).format('L');
                    return moment(value).format('MMM DD, YYYY HH:mm A')
                },
            }
        },



    ];

    const options = {


        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],


        onRowClick: (rowData, rowState) => {
            var row = resumes[rowState.dataIndex];
            console.log(row)
            setResumeId(row.id)
            setUid(row.uid)
        },

        onCellClick: (cellIndex, rowIndex) => {

            var row = resumes[rowIndex.dataIndex];
            const url = `${Key.JNP_URL}/mail/redirect/resume_${row.id}`
            const pdfWindow = window.open();
            pdfWindow.location.href = url;
        },
    };



    const getTitlesByState = (stateid, visaid) => {
        setSelectedState(stateid)
        setSelectedVisa(visaid)
        setPage(0)
        console.log(stateid)
        formikRef.current.setFieldValue("state", stateid);


        console.log(visaid)
        formikRef.current.setFieldValue("visa", stateid);

        props.showLoader();
        setPage(0)

        axios
            .post(`${Key.domain}/dashboard/get_applications_list_by_title`, {
                "offset": 1,
                "title": location.state.title,
                "limit": 20,
                "stateid": stateid !== "Select state" ? stateid : "",
                "visaid": visaid !== "Select VisaType" ? visaid : "",
                "perDay": location.state.perDay,
                "start_date": location.state.startdate ? location.state.startdate : "",
                "end_date": location.state.enddate ? location.state.enddate : ""

            })
            .then((response) => {
                console.log(response);
                setResumes(response.data.data)

                axios.post(`${Key.domain}/dashboard/get_applicationtitles_list_by_state`, {
                    "title": location.state.title,
                    "state_id": stateid !== "Select state" ? stateid : "",
                    "visaid": visaid !== "Select VisaType" ? visaid : "",
                    "perDay": location.state.perDay,
                    "start_date": location.state.startdate ? location.state.startdate : "",
                    "end_date": location.state.enddate ? location.state.enddate : ""
                })
                    .then((response) => {
                        console.log(response)

                        var resCount = (response.data.data.length > 0 ? response.data.data[0].count : 0)

                        setCount(resCount)
                        props.hideLoader();

                    })
            })
    }



    const getStates = () => {
        axios.post(`${Key.domain}/common/get_states_by_countryid`, {
            country_id: 233,
        })
            .then((response) => {
                console.log(response);
                setStates(response.data.data);

            });
    };



    return (
        <div className='container' style={{ paddingTop: "38px" }}>

            <MUIDataTable
                title={<div>
                    <div className='d-flex align-items-center'>
                        <MdRefresh style={{ cursor: "pointer" }} onClick={() => { getTitles() }} />

                        <div style={{ width: "100%", marginLeft: "10px" }}>
                            <Formik
                                innerRef={formikRef}
                                initialValues={{ state: selectedState, visa: selectedVisa }}
                                onSubmit={(values) => {

                                }}
                            >
                                {({ touched, errors, isSubmitting, values, handleChange }) =>

                                    <div className="d-flex align-items-center col-8">
                                        <div className="form-group mb-3">

                                            <Field
                                                name="state"
                                                as="select"
                                                className={'mt-2 form-select form-control'}
                                                onChange={(e) => getTitlesByState(e.target.value, selectedVisa)}
                                                value={selectedState}
                                            >
                                                <option>Select state</option>
                                                {states.map((item, index) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage component="div" name="state" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group mb-3" style={{ marginLeft: "10px" }}>

                                            <Field
                                                name="visa"
                                                as="select"
                                                className={'mt-2 form-select form-control'}
                                                onChange={(e) => getTitlesByState(selectedState, e.target.value)}
                                                value={selectedVisa}
                                            >
                                                <option>Select VisaType</option>
                                                {visatypes.map((item, index) => (
                                                    <option value={item.value}>{item.text}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage component="div" name="visa" className="invalid-feedback" />
                                        </div>
                                    </div>

                                }
                            </Formik>
                        </div>

                    </div>
                </div>}
                data={resumes}
                columns={columns}
                options={options}
            />

            <br />
        </div>
    )
}


const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Parserresumeslist);