/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import User from '../components/images/User.png'
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';
import './users.css'
import Key from "../clientVariables.json";
import request from 'src/utils/request';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';




function UserOnlineStatus() {

    const [employerUserStatus, setEmployerUserStatus] = useState([])
    const [jobseekerUserStatus, setJobseekerUserStatus] = useState([])
    const [bsUserStatus,setbsUserStatus] = useState([])

    const getUserStatus = async () => {
        const result = await request(`${ENDPOINT['getUsersOnlineStatus']}`, postOptions({ user_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken") }))
        console.log(result)
        setEmployerUserStatus(result.data.filter(user => user.user_type_id === '2'))
        setJobseekerUserStatus(result.data.filter(user => user.user_type_id === '5'))
        setbsUserStatus(result.data.filter(user => user.user_type_id === '3'))


    }
    console.log(bsUserStatus)


    useEffect(() => {
        getUserStatus()
    }, [])
    console.log(employerUserStatus)

    return (
        <div style={{boxShadow:"0px 0px 5px black",marginTop:"-40px",height:"80vh"}}>
            <h5 style={{ color: "#406882" ,padding:"20px 20px 0px 20px "}}> <h4>Users</h4></h5>
            <br />
            <div
                className="row"
                style={{
                    height: "127px",
                    padding: "10px",
                    

                    // height: "100px",
                    // cursor: "pointer",
                    //borderBottom: "1px solid lightgray",
                    //backgroundColor: index === currentindex ? "#EEEEEE" : "",
                }}
            >
                <div className="col-md-4 " style={{overflowY:"auto",overflowX:"none" ,height: "60vh" }}>
                    <h6 className='col-12' style={{ color: "#406882", textAlign: "center",position:"sticky",top:"0px" , background: "#ffffff"}}>Employers</h6>
                    {employerUserStatus.map(user =>

                        <div className="row "  >
                            <div className="col-md-2  "  >
                                <img
                                    src={User}
                                    height="40"
                                    width="40"
                                    className="rounded"
                                    style={{marginTop:"17px",marginLeft:"10px"}}

                                />
                            </div>

                            <div className="col-md-10 "  >
                                <div
                                    className="box"
                                >
                                    <h5
                                        className="postedJobTitle"
                                        style={{ fontSize: "16px", color: "#406882" }}
                                    >
                                        {user.full_name}

                                    </h5>
                                    <p style={{ fontSize: "15px", marginBottom: "4px" }}>
                                        {user.username}, &nbsp;
                                        {user.company_name !== null ? user.company_name : user.userCompanyName}
                                    </p>
                                   {/*  <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                         {user.company_name !== null ? user.company_name : user.userCompanyName}
                                    </p>
 */}
                                    <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                        Last Logged in :
                                        {user.action__type === "loggedin" ?
                                            <span>
                                                <FiberManualRecordIcon style={{ fontSize: "100%", color: "green" }} /> Online
                                            </span>
                                            : user.action__type === "away" ?
                                                <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "yellow" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>

                                                : <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "grey" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>
                                        }
                                    </p>
                                    <br />


                                    {/* <p style={{ fontSize: "14px" }}>Job Type: {job.jobtypeName}</p> */}


                                </div>
                            </div>
                        </div>)}
                </div>



                <div className="col-md-4  " style={{  overflowX:"none",overflowY:"auto", height: "60vh" }}>
                    <h6 className='col-12' style={{ color: "#406882", textAlign: "center",position:"sticky",top:"0px" , background: "#ffffff"}}>Jobseekers</h6>

                    {jobseekerUserStatus.map(user =>

                        <div className="row mb-3" >
                            <div className="col-md-2">
                                <img
                                    src={User}
                                    height="40"
                                    width="40"
                                    className="rounded"
                                    style={{marginTop:"17px"}}

                                />
                            </div>

                            <div className="col-md-10"  >
                                <div
                                    className="box"
                                >
                                    <h5
                                        className="postedJobTitle"
                                        style={{ fontSize: "17px", color: "#406882" }}
                                    >
                                        {user.full_name}
                                    </h5>
                                    <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                         {user.username}
                                    </p>
                                    <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                        Last Logged in :
                                        {user.action__type === "loggedin" ?
                                            <span>
                                                <FiberManualRecordIcon style={{ fontSize: "100%", color: "green" }} /> Online
                                            </span>
                                            : user.action__type === "away" ?
                                                <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "yellow" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>

                                                : <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "grey" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>
                                        }
                                    </p>




                                </div>
                            </div>
                        </div>
                    )}

                </div>
                

                <div className="col-md-4 " style={{ overflowX:"none",overflowY:"auto", height: "60vh" }}>
                    <h6 className='col-12' style={{ color: "#406882", textAlign: "center",position:"sticky",top:"0px" , background: "#ffffff"}}>Bench sales</h6>

                    {bsUserStatus.map(user =>

                        <div className="row mb-3" >
                            <div className="col-md-2">
                                <img
                                    src={User}
                                    height="40"
                                    width="40"
                                    className="rounded"
                                    style={{marginTop:"17px"}}

                                />
                            </div>

                            <div className="col-md-10"  >
                                <div
                                    className="box"
                                >
                                    <h5
                                        className="postedJobTitle"
                                        style={{ fontSize: "17px", color: "#406882" }}
                                    >
                                        {user.full_name}
                                    </h5>
                                    <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                         {user.username}
                                    </p>
                                    <p style={{ fontSize: "16px", marginBottom: "4px" }}>
                                        Last Logged in :
                                        {user.action__type === "loggedin" ?
                                            <span>
                                                <FiberManualRecordIcon style={{ fontSize: "100%", color: "green" }} /> Online
                                            </span>
                                            : user.action__type === "away" ?
                                                <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "yellow" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>

                                                : <span>
                                                    <FiberManualRecordIcon style={{ fontSize: "100%", color: "grey" }} /> {moment(user.lastCreated).fromNow()}
                                                </span>
                                        }
                                    </p>




                                </div>
                            </div>
                        </div>
                    )}

                </div>
 

            





            </div>
            <br />
            <br /> <br /> <br /> <br /> <br /><br /> <br /><br />
        </div>
    )
}

export default UserOnlineStatus
