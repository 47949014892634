/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Useravathar from '../components/images/User.png'
import Companylogo from '../components/images/company.png'
import Key from "../clientVariables.json";
import axios from "axios"
function Companydetailsview(props) {

  const [value, setValue] = React.useState(0);
  const [companyContacts, setCompanyContacts] = React.useState([]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    axios.post(`${Key.domain}/company/get_Company_Contacts`, {
      "company_id": props.companyId
    })
      .then(resdata => {
        console.log(resdata.data.data.companyContacts)
        setCompanyContacts(resdata.data.data.companyContacts)
      })
  }, [props.companyId])



  const companyDetails = props.companyDetails
  let sum = 0
  return (
    <div>

      <Row>
        <Col sm={6}>
          <div className='card p-3' >
            <div style={{ textAlign: "center" }}>
              <img src={companyDetails.logo_url !== "N/A" && companyDetails.logo_url !== undefined  ? companyDetails.logo_url  : Companylogo} width="100px" height="100px" className='pb-3' style={{ display: "inline" }}
                alt="company placeholder" />

              <p><b>{props.companyName}</b></p>

            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Row>
                  <Col sm={5}>
                    Location
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>
                  <Col sm={6}>
                    {companyDetails.city ? companyDetails.city + "," + companyDetails.state + "," + companyDetails.country + "." : "N/A"}
                  </Col>
                </Row>
              </li>
              {/* <li className="list-group-item">Email    : {companyDetails.contactemail ? companyDetails.contactemail : "N/A"}</li>*/}
              <li className="list-group-item">
                <Row>
                  <Col sm={5}>
                    Company Phone
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                    {companyDetails.contactphone ? companyDetails.contactphone : "N/A"}
                  </Col>
                </Row>
              </li>

              <li className='list-group-item'>
                <Row>
                  <Col sm={5}>
                    No.of Employees
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>
                  <Col sm={6}>
                  {companyDetails.companysize && companyDetails.companysize !=="null" && companyDetails.companysize!==""  ? companyDetails.companysize : "0" }
                 {/* {props.greenCardDetails.reduce(function(tot, arr) { 
                    
                    return parseInt(tot) + parseInt(arr.EMPLOYER_NUM_EMPLOYEES);
                  
                    // set initial value as 0
                  },0)}*/}
                  </Col>
                </Row>
              </li>
              <li className='list-group-item'>

                <Row>
                  <Col sm={5}>
                    Current Year H1's
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                    {props.h1bStatus.InitialApprovals !== undefined && props.h1bStatus.InitialApprovals !== null ? parseInt(props.h1bStatus.InitialApprovals) + parseInt(props.h1bStatus.ContinuingApprovals) : 0}
                  </Col>
                </Row>

              </li>
              <li className='list-group-item'>
                <Row>
                  <Col sm={5}>
                    LCA Count
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                    {props.lcaStatus.noOfTotalFiles}
                  </Col>
                </Row>

              </li>
              <li className='list-group-item'>

                <Row>
                  <Col sm={5}>
                    Green Card Count
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                    {props.greenCardStatus.noOfTotalFiles}
                  </Col>
                </Row>
              </li>
              <li className="list-group-item">

                <Row>
                  <Col sm={5}>
                    website
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                  {companyDetails.url ? companyDetails.url : "N/A"}
                  </Col>
                </Row>
              </li>
              <li className="list-group-item">

                <Row>
                  <Col sm={5}>
                    About Company
                  </Col>
                  <Col sm={1}>
                    :
                  </Col>

                  <Col sm={6}>
                    {companyDetails.description !== "N/A" && companyDetails.description !== undefined ? companyDetails.description.slice(0,100)+"..." : "N/A"}
                  </Col>
                </Row>

              </li>

            </ul>




          </div>
        </Col>



        <Col sm={6}>
          <div className='card'>
            <div className='p-3'>

              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Contacts" {...a11yProps(0)} />
                    <Tab label="Address" {...a11yProps(1)} />

                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <ul className="list-group list-group-flush">

                    {companyContacts.map(contact =>
                      <li className="list-group-item">
                        <p>{contact.firstname + ' ' + contact.lastname}</p>
                        <p>{contact.email}</p>
                        <p>{ '+1 '+contact.phone.replace('1','')}</p>

                      </li>
                    )}



                  </ul>



                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ul className="list-group list-group-flush">

                    {companyContacts.map(contact =>
                      <li className="list-group-item">
                        <p>{contact.city + ', ' + contact.state}</p>
                        <p>{contact.address}</p>
                        <p>{contact.zipcode}</p>
                      </li>
                    )}
                  </ul>
                </TabPanel>

              </Box>

            </div>
          </div>
        </Col>
      </Row>
    </div>





  )
}

export default Companydetailsview