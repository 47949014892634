/* eslint-disable */

import React, { Component } from "react";
import Swal from "sweetalert2";
import UserActions from './controllers/user.controller'
import { withRouter, Redirect, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios'
class SessionAlert extends Component {

  constructor(props) {
    super(props);
    this.state={
      ipAdress:""
    }
    this.HandleClick = this.HandleClick.bind(this);
    this.Handlelogout = this.Handlelogout.bind(this);

  }

  componentDidMount() {
    this.HandleClick1()
    axios.get(`https://api.ipify.org/?format=text`)
      .then(async response => {
        const ipAdress = response.data
        this.setState({ ipAdress: ipAdress })
      })
  }

  HandleClick() {
    Swal.fire({
      title: 'Success',
      type: 'success',
      text: 'Your work has been saved.',
    });
  }

  Handlelogout = () => {
    this.props.logout(sessionStorage.getItem("id"), this.state.ipAdress)
  }




HandleClick1 = () => {
  Swal.fire({
    title: 'Session Was Expired ',
    text: 'Please Login Again',
    icon: 'warning',
    //  showCancelButton: true,
    confirmButtonColor: '#406882',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Please Login!'
  }).then((res) => {
    if (res.isConfirmed) {
      console.log(this)
      //sessionStorage.clear()
     // localStorage.clear()
      // this.props.history.({
      //   pathname: '/login',
      // })
     // window.location.href="/"
   //   window.location.reload()
      this.Handlelogout()
    }

  }
  )
}

HandleClicktop() {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Your work has been saved',
    showConfirmButton: false,
    timer: 1500
  });
}
HandleClickAutoclose() {
  let timerInterval
  Swal.fire({
    title: 'Auto close alert!',
    html: 'I will close in <b></b> milliseconds.',
    timer: 1000,
    timerProgressBar: true,
    onBeforeOpen: () => {
      Swal.showLoading()
      timerInterval = setInterval(() => {
        const content = Swal.getContent()
        if (content) {
          const b = content.querySelector('b')
          if (b) {
            b.textContent = Swal.getTimerLeft()
          }
        }
      }, 100)
    },
    onClose: () => {
      clearInterval(timerInterval)
    }
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer')
    }
  })
}


render() {
  return (
    <div>

    </div>
  );
}

}


const mapDispatchToProps = function (dispatch) {
  return {
    logout: (uid,ipadress) => {
      UserActions.logout(uid,ipadress)(dispatch);
    },
  }
};
export default connect(null, mapDispatchToProps)(SessionAlert);
