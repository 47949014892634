/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Useravathar from '../components/images/User.png'
import Key from "../clientVariables.json";
import axios from "axios"
function LcaDetailView(props) {

    const [value, setValue] = React.useState(0);
    const [lcaDetails, setLcaDetails] = React.useState([]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const filterYear = (year) => {
        const filterData = props.lcaDetails.filter(data => data.FINANCIAL_YEAR === year)
        setLcaDetails(filterData)
    }


    useEffect(() => {

        setLcaDetails(props.lcaDetails)
    }, [props.lcaDetails])

    return (
        <div style={{ height: "700px", overflow: "scroll" }}>
            <div style={{ float: "right" }}>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => filterYear(e.target.value)}>
                    <option selected>Year</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                </select>
            </div>
            <table class="table table-bordered">

                <tbody>
                    <p>Status</p>
                    <tr>
                        <td>
                            <Row>
                                <Col sm={4}> No.of Filed </Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.lcaStatus.noOfTotalFiles}</Col>
                            </Row>
                        </td>

                        <td>
                            <Row>
                                <Col sm={4}> No.of Certified </Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.lcaStatus.noOfFiled}</Col>
                            </Row>
                        </td>


                        <td>

                            <Row>
                                <Col sm={4}> No.of Withdrawn</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.lcaStatus.noOfWithDrawn}</Col>
                            </Row>
                        </td>

                    </tr>

                    <tr>



                        <td>

                            <Row>
                                <Col sm={4}> No.of Certified - Withdrawn</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.lcaStatus.noOfCertifiedWithDrawn}</Col>
                            </Row>
                        </td>



                        <td>
                            <Row>
                                <Col sm={4}> No.of Denied</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.lcaStatus.noOfDenied}</Col>
                            </Row>
                        </td>





                    </tr>

                    <tr>
                    </tr>






                </tbody>
            </table>

            <br />
            <Row>


                {lcaDetails.map(data =>
                    <Col sm={5}>
                        <div className='card p-3' >
                            <div style={{ textAlign: "center" }}>


                                <p><b>{data.JOB_TITLE}</b></p>

                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">VISA Class : {data.VISA_CLASS}</li>
                                <li className="list-group-item">NAICS Code : {data.NAICS_CODE}</li>
                                <li className="list-group-item">Status : {data.CASE_STATUS}</li>

                            </ul>

                        </div>
                        <br />

                    </Col>
                )}
            </Row>
        </div>





    )
}

export default LcaDetailView