/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'
import { connect } from "react-redux";
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";
import { MdRefresh } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";

function User(props) {
    const formikRef = useRef();
    const history = useNavigate();

    const [titles, setTitles] = useState([]);
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [startdate, setStartdate] = useState("");

    const [enddate, setEnddate] = useState("");

    const [startdate1, setStartdate1] = useState("");
    const [dataByCreated, setDataByCreated] = useState(1);

    const [enddate1, setEnddate1] = useState("");
    const [todaydate, setTodaydate] = useState("");
    const [data, setData] = useState([]);

    const [created, setCreated] = useState(true)
    const [lastModified, setLastModified] = useState(false)
    const [value, setValue] = React.useState('created');


    const createdColumns = [{
        label: "S. No",
        name: "sno",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Application Title</span>,
        name: "application_title",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Count</span>,
        name: "count",
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        name: "latest_added_date",
        label: <span>Latest added on</span>,
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? moment.utc(moment(value)).format("LLL") : "N/A"
            }
        },
    }];

    const modifiedColumns = [{
        label: "S. No",
        name: "sno",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Application Title</span>,
        name: "application_title",
        options: {
            customBodyRender: (value) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        label: <span>Count</span>,
        name: "count",
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },
    },
    {
        name: "latest_modified_date",
        label: <span>Latest modified on</span>,
        options: {
            setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? moment.utc(moment(value)).format("LLL") : "N/A"
            }
        },
    }]

    const handleChange = (event) => {
        setValue(event.target.value);
        getTitles(startdate, enddate, event.target.value, 0)

    };
 
    useEffect(() => {


        var s = "";
        var e = "";
        getTitles(s, e, value, 1);
        getStates();
    }, [])

    const getTitles = (sd, ed, v, flag) => {
        props.showLoader();

        console.log(sd)
        console.log(ed)
        const today = new Date();
        setTodaydate(moment(today).format('YYYY-MM-DD'));


        var start = sd !== "" && sd !== null ? moment(sd).format('YYYY-MM-DD HH:mm:ss') : moment(today).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss');
        var end = ed !== "" && ed !== null ? moment(ed).format('YYYY-MM-DD HH:mm:ss') : moment(today).format('YYYY-MM-DD HH:mm:ss');

        setStartdate(start)
        setEnddate(end)
        setDataByCreated(v === 'created' ? 1 : 0)

        setStartdate1(parseInt(flag) === 1 ? 1 : 0)
        setEnddate1(parseInt(flag) === 1 ? 1 : 0)


        axios
            .post(`${Key.domain}/dashboard/get_applicationtitles_list_count`, {
                "user_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token,
                "perDay": 1,
                "start_date": parseInt(flag) === 1 ? 0 : start,
                "end_date": parseInt(flag) === 1 ? 0 : end,
                "created": v === 'created' ? 1 : 0
            })
            .then((response) => {
                console.log(response)
                setData(response.data.data)

                setTitles(response.data.data[0].application_titles)
                props.hideLoader();

            }).catch((err) => {
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();
                    setSessionExpired(true)
                }
            });;
    }



    const options = {
        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],
        onCellClick: (cellIndex, rowIndex) => {

            let selectedTitle = titles[rowIndex.dataIndex]


            if (rowIndex.colIndex === 1) { 
                console.log(parseInt(startdate1))
                history("/dashboard/applicationTitleResumes", { state: { title: selectedTitle.application_title, count: selectedTitle.count, perDay: 1, startdate: startdate, enddate: enddate, dataByCreated: dataByCreated } })
            }
        },

    };

    const getTitlesByState = (stateid) => {
        setSelectedState(stateid)
        console.log(stateid)
        formikRef.current.setFieldValue("state", stateid);

        props.showLoader();
        axios
            .post(`${Key.domain}/dashboard/get_applicationtitles_list_by_state`, {
                "state_id": stateid
            })
            .then((response) => {
                console.log(response)
                setTitles(response.data.data)
                props.hideLoader();

            })
    }



    const getStates = () => {

        axios.post(`${Key.domain}/common/get_states_by_countryid`, {
            country_id: 233,
        })
            .then((response) => {
                console.log(response);
                setStates(response.data.data);

            });
    };





    const onChangeStartDate = (d) => {

        var startdate = moment(d).format('YYYY-MM-DD');

        setStartdate(startdate)
        getTitles(startdate, enddate, value, 0)

    }


    const onChangeEndDate = (d) => {

        var enddate = moment(d).format('YYYY-MM-DD');

        setEnddate(enddate)

        getTitles(startdate, enddate, value, 0)
    }




    return (
        <div style={{ marginTop: "-0px", boxShadow: "0px 0px 10px black" }}>
            <MUIDataTable
                title={
                    <h4>  <div style={{ display: "flex", justifyContent: "space-between" }}>



                        <div style={{ display: "flex", width: "75%", padding: "10p", margin: "1%" }}>
                            <h6 style={{ marginRight: "4px", marginTop: "1%" }}>Start Date</h6>
                            <input style={{ width: "30%", padding: "6px", fontSize: "14px", marginRight: "4%" }} type="date" id="start" name="trip-start"
                                value={moment(startdate).format('YYYY-MM-DD')}
                                onChange={(e) => onChangeStartDate(e.target.value)}
                                min="2001/01/01" max={todaydate} />

                            <h6 style={{ marginRight: "4px", marginTop: "1%" }}>End Date</h6>
                            <input style={{ width: "30%", padding: "6px", fontSize: "14px" }} type="date" id="start" name="trip-start"
                                value={moment(enddate).format('YYYY-MM-DD')}
                                onChange={(e) => onChangeEndDate(e.target.value)}
                                min="2001/01/01" max={todaydate} />

                        </div>
                        <h6 style={{ marginTop: "1%" }}>Total Resumes : {data.length > 0 ? data[1].total_resumes_count : 0}</h6>
                    </div>

                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="created" control={<Radio />} label="Created" />
                                <FormControlLabel value="modified" control={<Radio />} label="Last Modified" />
                            </RadioGroup>
                        </FormControl>
                    </h4>
                    // // <div>
                    // //     <div className='d-flex align-items-center'>
                    // //         <MdRefresh style={{ cursor: "pointer" }} onClick={() => { getTitles() }} />
                    // //         <div style={{ width: "100%", marginLeft: "10px" }}>
                    // //             <Formik
                    // //                 innerRef={formikRef}
                    // //                 initialValues={{ state: selectedState }}
                    // //                 onSubmit={(values) => {

                    // //                 }}
                    // //             >
                    // //                 {({ touched, errors, isSubmitting, values, handleChange }) =>

                    // //                     <div className="col-4">
                    // //                         <div className="form-group mb-3">

                    // //                             <Field
                    // //                                 name="state"
                    // //                                 as="select"
                    // //                                 className={'mt-2 form-select form-control'}
                    // //                                 onChange={(e) => getTitlesByState(e.target.value)}
                    // //                                 value={selectedState}
                    // //                             >
                    // //                                 <option>Select state</option>
                    // //                                 {states.map((item, index) => (
                    // //                                     <option value={item.id}>{item.name}</option>
                    // //                                 ))}
                    // //                             </Field>
                    // //                             <ErrorMessage component="div" name="state" className="invalid-feedback" />
                    // //                         </div>
                    // //                     </div>
                    //                 }
                    //             </Formik>
                    //         </div>

                    //     </div>
                    // </div>
                }
                data={titles}
                columns={value === 'created' ? createdColumns : modifiedColumns}
                options={options}
            />
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    }
}
function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);