import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomerPaymentInfo from './CustomerPaymentslist';

const CustomerPackageInfo = (props) => {
  const {freepackage, packageDetails, paymentsList, companyDetails } = props;
  const [disable, setDisable] = useState(true)
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
if(companyDetails.id) {

  setDisable(false)

}
  })
  const upgradePackage = () => {
    history('/dashboard/addcustomer', {
      state: {
        customerid: location.state ? location.state.customerid :props.customerId ,
        upgrade: true,
        freepackage:packageDetails.title,
      },
    });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-2 mb-4">
              <div className="row">
                <div className="col-12">
                  <ul className="list-group list-group-flush">
                    
                    <div className="row" style={{padding:"10px"}}>
                      
                      <div className="col-4" style={{boxShadow:"0px 0px 2px black",paddingTop:"10px" }} >
                      <p className='mb-4'> <b>Plan Opted :</b> <span>{packageDetails.title ? packageDetails.title : "Custom Plan"}</span></p>
                        <p>
                          <div className="row">
                            <div className="col-6"  >
                              <span><b>Jobs Allowed</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>
  {/* {packageDetails.jobs_allowed ? packageDetails.jobs_allowed : 'N/A'} */}
                              Unlimited
                              </span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Resume Views Allowed</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>
                                {packageDetails.resumeviews_allowed ? packageDetails.resumeviews_allowed : 'N/A'}
                              </span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Users Allowed</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>{packageDetails.users_allowed ? packageDetails.users_allowed : 'N/A'}</span>
                            </div>
                          </div>
                        </p>
                      </div> 
                      <div className="col-4" style={{boxShadow:"0px 0px 2px black",paddingTop:"10px"}}>
                      <p className='mb-4'><b>Benefits Active From : </b> <span>{packageDetails.package_start_date ? moment(packageDetails.package_start_date).format("LL") : "N/A"}</span></p>
                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Jobs Posted</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>{packageDetails.jobsposted ? packageDetails.jobsposted : 'N/A'}</span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row " style={{marginBottom:"38px"}}>
                            <div className="col-6">
                              <span><b>Resumes Viewed</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>{packageDetails.resumesviewed ? packageDetails.resumesviewed : 'N/A'}</span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Users Added</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>{packageDetails.usersCreated ? packageDetails.usersCreated : 'N/A'}</span>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="col-4" style={{boxShadow:"0px 0px 2px black",paddingTop:"10px"}}>
                      <p className='mb-4'><b>Benefits Expires On : </b><span>{packageDetails.package_end_date ? moment(packageDetails.package_end_date).format("LL") : "N/A"}</span></p>

                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Jobs Posts Left</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span> Unlimited
                                { /* packageDetails.jobsposted && packageDetails.jobs_allowed
                                  ? packageDetails.jobs_allowed - packageDetails.jobsposted
  : 'N/A' */}
                              </span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row " style={{marginBottom:"38px"}}>
                            <div className="col-6">
                              <span><b>Resume Views Left</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>
                                {packageDetails.resumeviews_allowed && packageDetails.resumesviewed
                                  ? packageDetails.resumeviews_allowed - packageDetails.resumesviewed
                                  : 'N/A'}
                              </span>
                            </div>
                          </div>
                        </p>
                        <p>
                          <div className="row">
                            <div className="col-6">
                              <span><b>Users Left to Add</b></span>
                            </div>
                            <div className="col-1">:</div>
                            <div className="col-5">
                              <span>
                                {packageDetails.usersCreated && packageDetails.users_allowed
                                  ? packageDetails.users_allowed - packageDetails.usersCreated
                                  : 'N/A'}
                              </span>
                            </div>
                          </div>
                        </p>
                        
                      </div>
                     
                    </div>
                  </ul>
                </div>
              </div>
             

              
            </div>
            <button type="submit" disabled={disable} className="btn btn-primary btn-block me-4 " onClick={() => upgradePackage()} style={{float:"right"}}>
                Upgrade
              </button>
          </div>
          
        </div>
        <div className="row">
         <CustomerPaymentInfo paymentsList = {props.paymentsList} />
          </div>
      </div>
    </div>
  );
};

export default CustomerPackageInfo;
