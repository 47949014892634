/* eslint-disable */
import React from 'react'
import MUIDataTable from "mui-datatables";
import { FcDocument } from "react-icons/fc";
import UserMoreMenu from '../sections/@dashboard/user/Customersusersactions';
import moment from 'moment';

const CustomerPaymentInfo = (props) => {
  const { paymentsList } = props;



  const columns = [
    {
      name: "title",
      label: "Plan",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'Custom Plan'

      }
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "transaction_id",
      label: "Transaction ID",
      options: {
        filter: true,
        sort: false,
      
      }
    },
    {
      name: "transaction_status",
      label: "Transaction Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

                if(value ==1){
                  return "Payment Success"
                } else{
                  return "Payment Failed"
                }

               },
      }
    },
    {
        name: "invoice_no",
        label: "Invoice  No",
        options: {
          filter: true,
          sort: false,
        }
      },
      {
        name: "created",
        label: "Date",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return moment(value).format("LL")

        }
        }
      },
    //      {
    //        name: "actions",

    //   label: <span>Status</span>,

    //   options: {

    //     customBodyRender: (value, tableMeta, updateValue) => {

    //       return <UserMoreMenu />
    //       // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

    //     },

    //     filter: true,

    //     sort: true,



    //   },
    // }



  ];



  const options = {
    filterType: 'checkbox',
    download: 'false',
    print: "false",
    viewColumns: "false"
  };

  return (
    <div>
      <MUIDataTable
        title={"Payment History"}
        data={paymentsList}
        columns={columns}
        options={options}
      />
     
    </div>
  )
}

export default CustomerPaymentInfo