import React, { useState } from 'react';
import './paymentinformation.css';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { green } from '@mui/material/colors';
import axios from 'axios'
import Key from '../clientVariables.json'


function Paymentinformation(props) {
  console.log(props.paymentSuccess, props.userDetails, props.packageDetails, props.salesPersonDetails);
  const [customerId, setCustomerId] = useState("")
  const [invoiceDoc, setInvoiceDoc] = useState("")

  const handleInvoice = () => {
    console.log('invoice /payment/get_PO_details')

    axios.post(`${Key.domain}/payment/get_PO_details`, {
      agreement_no: props.salesPersonDetails.agreement_no

    }).then(res => {
      console.log(res)
      if (res.data.data.POdetails.customer_id !== null && res.data.data.POdetails.customer_id !== undefined) {
        const customerIdd = res.data.data.POdetails.customer_id;
        const invoiceDocc = res.data.data.POdetails.invoice_doc;
        setCustomerId(customerIdd)
        setInvoiceDoc(invoiceDocc)
        fetch(
          `${Key.domain}/payment/attachments/${customerIdd}/${invoiceDocc}`,
          {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + window.localStorage['Access_Token'],
            },
          }
        ).then((resdata) => {
          console.log(resdata);
          console.log(resdata.url);
          const fileURL = resdata.url;

          console.log(fileURL);


          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        })
          .catch((e) => {
            console.log(e);
          });
      }
      else {
        console.log("failed")
      }
    })
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto mt-5">
            <div className="payment">
              <div>
                {props.packageDetails.price !== '0' && props.packageDetails.Amount !== '0' ?
                  <h3 style={{ color: 'green', textAlign: 'center', paddingTop: '18px' }}>Payment Successfull!</h3>
                  : <h3 style={{ color: 'green', textAlign: 'center', paddingTop: '18px' }}>Package Applied!</h3>
                }
                <br />

                <center>
                  <IoIosCheckmarkCircle size={75} color="green" />
                </center>
                <div>
                  <div className="row" id="labless">
                    <div className="col-md-6">
                      <p>Name</p>
                    </div>
                    <div className="col-md-6">
                      <p>{props.userDetails.full_name ? props.userDetails.full_name : 'N/A'}</p>
                    </div>

                    <div className="col-md-6">
                      <p>Email</p>
                    </div>
                    <div className="col-md-6">
                      <p>{props.userDetails.username ? props.userDetails.username : 'N/A'}</p>
                    </div>

                    <div className="col-md-6">
                      <p>Description</p>
                    </div>
                    <div className="col-md-6">
                      <p>{props.paymentSuccess.description ? props.paymentSuccess.description : 'N/A'}</p>
                    </div>

                    <div className="col-md-6">
                      <p>Amount Paid</p>
                    </div>
                    <div className="col-md-6">
                      <p>${props.packageDetails.Amount ? props.packageDetails.Amount : props.packageDetails.price}</p>
                    </div>

                    <div className="col-md-6">
                      <p>Transaction Id</p>
                    </div>
                    <div className="col-md-6">
                      <p>{props.paymentSuccess.id ? props.paymentSuccess.id : 'N/A'}</p>
                    </div>
                    {/* <div className="col-md-6">
                      <p>Recipt URL</p>
                    </div>
                    <div className="col-md-6">
                      <p>{props.paymentSuccess.receipt_url ? props.paymentSuccess.receipt_url : 'N/A'}</p>
                    </div> */}
                  </div>
                </div>
                <div className="buttons">
                  <a rel="noopener noreferrer" target="_blank" href="https://jobsnprofiles.com/EmpLogin">
                    <button type="button" className="btn btn-outline-primary">
                      Login your account{' '}
                    </button>
                  </a>
                  &nbsp;
                  <button type="button" className="btn btn-outline-secondary" onClick={handleInvoice} style={{ cursor: "pointer" }}>
                    {' '}
                    View Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Paymentinformation;
