import React from 'react';
import { useNavigate } from "react-router-dom";
import { BiFoodTag, BiArrowBack } from "react-icons/bi";
import UserDetails from './UserDetails';

const CustomerPersonalInfo = (props) => {
    const { userDetails } = props;
    const navigate = useNavigate();
    const redirectTo = () => {
        if (props !== undefined) {
            if (props.packageDetails.package_id === "1") {
                navigate("/dashboard/Subscriptions")
            } else {
                navigate("/dashboard/user")
            }
        }

    };


    return (
        <div>
            <div className="container">
                <div className="row">

                    <div className="col-6">

                        <div className="card p-2 mb-4">
                            {/* <div className="row mb-1">
                <div className="col-5">
                  {' '}
                  <b> Name</b>
                </div>
                <div className="col-1">:</div>
                <div className="col-6">{userDetails.full_name ? userDetails.full_name : 'N/A'}</div>
              </div> */}
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><div className="row mb-1">
                                    <div className="col-5">
                                        {' '}
                                        <b> Name</b>
                                    </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{userDetails.full_name ? userDetails.full_name : 'N/A'}</div>
                                </div></li>
                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> Email</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.username ? userDetails.username : 'N/A'}</div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> Phone</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.mobile ? userDetails.mobile : 'N/A'}</div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> Role</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.role ? userDetails.role : 'N/A'}</div>
                                    </div>
                                </li>

                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> City</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.cityname ? userDetails.cityname : 'N/A'}</div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> State</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.statename ? userDetails.statename : 'N/A'}</div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row mb-1">
                                        <div className="col-5">
                                            {' '}
                                            <b> Zipcode</b>
                                        </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{userDetails.zipcode ? userDetails.zipcode : 'N/A'}</div>
                                    </div>
                                </li>

                            </ul>







                        </div>
                    </div>
                    <div className="col-6">
                        <button onClick={redirectTo} style={{ float: "right", background: "#2065D1" }} className="btn tbn-secondary">
                            <BiArrowBack size={18} color="white" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerPersonalInfo;
