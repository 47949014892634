import { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { connect } from "react-redux";
import Key from '../clientVariables.json';

ChartJS.register(ArcElement, Tooltip, Legend);



function Jobsbreakdown(props) {
  const [jobsStats, setJobsStats] = useState([])

   const data = {
    labels: ['PerDay', 'PerWeek', 'PerMonth', 'PerDay M', 'perDay S'],
    datasets: [
      {
        label: '# of Jobs',
        data: [jobsStats.PerdayJobsCount, jobsStats.PerweekJobsCount, jobsStats.PermonthJobsCount, jobsStats.PerdayManualJobCount, jobsStats.PerdayScrapeJobsCount
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(255, 168, 86, 0.6)',
          'rgba(255, 250, 205, 0.4)'
        
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
           'rgba(160, 87, 0, 1)',
           'rgba(102, 87, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    axios
      .post(`${Key.domain}/dashboard/get_jobsPosted_dashboard_stats`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((resdata) => {
        console.log(resdata.data.data)
        setJobsStats(resdata.data.data)
      }).catch((err) => {
         
        if (err.response.data.data === "Access denied.. Please Login") {
          console.log(err.response.data.data )
        }
      });;

  }, [])

    
  return(
    <div>
  <h4  style={{ paddingLeft: "28px" }}> Jobs Posted Count</h4>
  <Pie data={data} />
  </div>
  ) 
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

export default connect(mapStateToProps, null)(Jobsbreakdown);
