/* eslint-disable */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Resumeviewer from "./ResumeViewer"
import MUIDataTable from "mui-datatables";
import "./Jobseeker.css"
import { connect } from "react-redux";
import user from "../../src/components/images/User.png"
import { Viewer } from '@react-pdf-viewer/core';
import { themePlugin } from '@react-pdf-viewer/theme';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core';


import FormControl from '@mui/material/FormControl';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { BsFillCalendarEventFill } from "react-icons/bs";
import {

  AiOutlineMail,
  AiOutlineEye,

  AiOutlineDownload,
  AiOutlineShareAlt,
  AiFillEye,


} from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { FaBuilding } from "react-icons/fa";

import { GoLocation } from "react-icons/go";
import { IoPersonSharp } from "react-icons/io5";
import {

  FaCommentDollar,

} from "react-icons/fa";
import { BsBagCheckFill, BsFillTelephoneFill } from "react-icons/bs"
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Key from '../clientVariables.json'
import { FcReading, FcViewDetails, FcGraduationCap, FcTodoList, FcInspection, FcDocument } from "react-icons/fc";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import SessionAlert from 'src/SessionAlert';
import moment from 'moment'

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';

import { Modal, Form } from "react-bootstrap";
import FormControlLabel from '@mui/material/FormControlLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import request from 'src/utils/request';
import httpConfig, { postOptions } from 'src/utils/httpConfig';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { ENDPOINT } from 'src/utils/endpoint';
import CustomToolBar from './customToolBar';
import * as XLSX from "xlsx";
import TextareaAutosize from '@mui/base/TextareaAutosize';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_id', label: 'Customer ID', alignRight: false },
  { id: 'company_name', label: 'Company Name', alignRight: false },
  { id: 'title', label: 'Plan Opted', alignRight: false },
  { id: 'package_end_date', label: 'Expiry Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: 'status', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });


  if (query) {

    return filter(array, (_user) => _user.company_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function User(props) {
  const history = useNavigate();



  const [users, setUsers] = useState([]);
  const [sendMail, setSendMail] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [profileResumes, setProfileResumes] = useState([]);
  const [openresume, setOpenResume] = useState(false)


  const [resutlData, setResultData] = useState([])
  const [showfile, setShowfile] = useState(false);
  const [selectedResIds, setSelectedResids] = useState([]);
  const [xlData, setXlData] = useState([]);
  const [xlFile, setXlFile] = useState();
  const [resumeList, setResumeList] = useState([]);
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [person, setPerson] = useState("");
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
  const [defaultPdfFile, setDefaultFile] = useState(selectresumeFileDetails !== undefined ?
    `${Key.domain1}/MyFiles/resume_attachments/${selectresumeFileDetails.id}/${selectresumeFileDetails.resumeid}/${selectresumeFileDetails.filename}` : "");
  const [isFileFound, setFileFound] = useState(true)
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const themePluginInstance = themePlugin();
  const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

  const [data, setData] = useState({
    fromEmail: "hot-jobs@jobsnprofiles.com",
    fullname: '',
    toEMail: '',
    ccEMail: 'divya.m@jobsnprofiles.com',
    subj: 'Recommended JOBS based on application',
    body: mailbody,
    jobtitle: '',
    skillname: '',
    location: 'Remote',
    toUserId: '',
    jobId: '',
  })
  const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location, jobId, toUserId } = { ...data }


  const changeHandler = e => {
    e.preventDefault()
    setData({ ...data, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    props.showLoader()
    axios.get(`${Key.domain}/admin/get_jobseekers_crm`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then(response => {
        var data = response.data.data
        console.log(data)
        setUsers(data)
        setSelectresumeFileDetails(data[0])
        var filename = data[0].filename ? data[0].filename : ""
        var finalfilename = filename.split(".")

        setDefaultFile(`${Key.domain1}/MyFiles/resume_attachments/${data[0].id}/${data[0].resumeid}/${finalfilename[0] + ".pdf"}`)

        props.hideLoader()
      })


  }, [])

  console.log(defaultPdfFile)
  const sendMailFunction = () => {
    setSendMail(true)
  }

  const handleCloseMail = () => setSendMail(false);
  const handleCloseresume = () => setOpenResume(false)


  // const getResumes = (data) => {
  //     // if (parseInt(data.mail_sent) === 0) {
  //     sendMailFunction();

  //     const jobtitle = data.application_title


  //     const searchResumesUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=${jobtitle !== '' && jobtitle !== undefined ? 'title:"' + jobtitle + '"' : "*%3A*"}&fl=title,id,cityName,created,shortRegion,stateName,prefferdskillsName,companyName&rows=10&start=0&sort=created%20desc&wt=json`;

  //     console.log(searchResumesUrl);

  //     fetch(`${Key.domain}/solr/solr_resumes`, {
  //         method: "POST",

  //         headers: {
  //             Accept: "application/json",

  //             "Content-Type": "application/json",
  //         },

  //         body: JSON.stringify({ url: searchResumesUrl }),
  //     }).then((response) => {
  //         response.json().then((resdata) => {
  //             console.log(resdata.data.response.docs);
  //             setProfileResumes(resdata.data.response.docs);
  //         });
  //     });
  //     // }
  // }


  const handleSendMail = (e) => {
    e.preventDefault();
    console.log(data)
    // var jobtitle = '"' + data.jobtitle + '"'
    console.log(profileResumes)
    console.log(selectedResumes)
    if (toEMail && subj && fromEmail && fullname) {

      if (selectedResumes.length > 10) {


        const msg = "Maximum limit is to share jobs is 10";
        toast(<ToastMessage message={msg} />);

      } else if (selectedResumes.length > 0) {
        fetch(`${Key.domain}/common/send_contactSales_jobs_Mail`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formdata: data, jobdata: selectedResumes.length > 0 ? selectedResumes : profileResumes }),
        })
          .then((response) => {
            response.json().then((resdata) => {
              console.log(resdata)
              if (resdata.data === "Mail sent To JS") {
                const msg = "Mail Sent Successfully";
                toast(<ToastMessage message={msg} />);
                window.location.reload()
              }
            })
          })

        axios
          .post(`${Key.domain}/admin/update_jobseekerMail_status`, {
            "admin_id": sessionStorage.getItem("id"),
            "session_token": sessionStorage.getItem("userToken"),
            "mail_status": 1,
            "email": toEMail,

          })
          .then((resdata) => {
            console.log(resdata.data.data)

          }).catch((err) => {
            console.log(err);
          });

      }
      else {
        const msg = "Select atleast 1 Job";
        toast(<ToastMessage message={msg} />);

      }

    } else {

      const msg = "Enter required fields";
      toast(<ToastMessage message={msg} />);

    }
  }

  const customColumns = columns => {
    const modifiedColumns = columns.map(column => {
      if (column.name === 'application_title' && users.every(item =>  item.application_title === undefined)) {
        return { ...column, name: 'js_user_role' };
      }
      return column;
    });
    return modifiedColumns;
  };


  const columns = [
    {
      label: <span>Name</span>,
      name: "full_name",
      options: {
        customBodyRender: (value) => {
          return value ? <a style={{ cursor: "pointer" }}>{value}</a> : "N/A"
        }
      },
    },
    /*  {
       label: <span>Resume ID</span>,
       name: "resumeid",
       options: {
         customBodyRender: (value) => {
           return value ? <a style={{ cursor: "pointer" }}>{value}</a> : "N/A"
         }
       },
     }, */
    {
      label: <span>Resume ID</span>,
      name: "resumeid",

      options: {

        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={
              <FormControl sx={{ m: 1, minWidth: 50 }}>
                <Button value={value}>{value ? value : "N/A"}</Button>
              </FormControl>
            } />
        )
      },
    },
    {
      label: <span>Role</span>,
      name: "application_title",
      options: {
        customBodyRender: (value) => {
          return value ? <a style={{ cursor: "pointer" }}>{value}</a> : "N/A"
        }
      },
    },
    {
      label: <span>Email</span>,
      name: "username",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <a style={{ cursor: "pointer" }}>{value}</a> : "N/A"
        }
      },
    },
    {
        label: <span>Phone</span>,
        name: "user_contact",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },
    },


    {
      label: <span>Registered Date</span>,
      name: "registerDate",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("YYYY-MM-DD HH:MM:SS")

        }
      },
    },

    {
      name: "sendEmail",
      label: "Send Mail",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel sx={{ m: 1, minWidth: 120 }}
            control={(parseInt(value) === 1 ? <Button onClick={(e) => sendMailFunction()}>Resend</Button> : <Button onClick={(e) => sendMailFunction()}>Send </Button>)} />
          // control={(parseInt(value) === 1 ? <Button disabled >Sent</Button> : <Button onClick={(e) => sendMailFunction()}>Send </Button>)} />
        )
      }
    }
  ];



  console.log(defaultPdfFile.includes('undefined'))


  const options = {
    // filterType: 'checkbox',
    // responsive: 'standard',
    size: 'small',
    tableBodyHeight: '100vh',
    fixedHeader: true,
    density: false,
    selectableRows: false,
    search: true,
    filter: true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,

    onCellClick: (colData, cellMeta, cellIndex) => {

      var row = users[cellMeta.dataIndex];
      // console.log(row)
      setEditedData(row)

      var filename = row.filename ? row.filename : ""
      var finalfilename = filename.split(".")
      // window.open(`https://api.jobsnprofiles.com/MyFiles/resume_attachments/${row.id}/${row.resumeid}/${finalfilename[0]+ ".pdf"}`, "_blank");
      setDefaultFile(`${Key.domain1}/MyFiles/resume_attachments/${row.id}/${row.resumeid}/${finalfilename[0] + ".pdf"}`)
      setSelectresumeFileDetails(row)
      setData({ ...data, toEMail: row.username, fullname: row.full_name })
      if (filename === "") {
        setFileFound(false)
      } else {
        setFileFound(true)
      }

      console.log(finalfilename, filename)

      // alert(`${Key.domain1}/MyFiles/resume_attachments/${row.id}/${row.resumeid}/${row.filename}`)

      console.log(cellMeta.colIndex)
      setEditedData(row)

      if (cellMeta.colIndex === 1 || cellMeta.colIndex === 2 || cellMeta.colIndex === 3 || cellMeta.colIndex === 4) {
        setOpenResume(true)
      }
      // else {

      //     const msg = "No resume found for this user";
      //     toast(<ToastMessage message={msg} />);

      //   }
      // else {

      //   if (row.resumeid !== undefined && row.resumeid !== null && row.resumeid !== "") {
      //     const url = `${Key.JNP_URL}/resume/view/${sessionStorage.getItem("id")}/${sessionStorage.getItem("userToken")}/resume_${row.resumeid}`

      //     const pdfWindow = window.open();
      //     pdfWindow.location.href = url;
      //   } 

      // if (cellMeta.colIndex === 5) {
      //   console.log(selectedTitle)
      // } else {

      //     const msg = "No resume found for this user";
      //     toast(<ToastMessage message={msg} />);

      //   }

      // console.log(cellIndex.props);
      // console.log(rowIndex.dataIndex);
      // console.log(rowIndex);
    },
  };


  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
    let filteredobjs = []
    if (name === String(resumeId)) {
      console.log("comes here", resumeId)
      const newResumes = profileResumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setProfileResumes(newResumes);
      console.log(newResumes)
      const obj = newResumes.filter((x) => x.isChecked === true);
      filteredobjs = obj.filter((x) => x.application_title !== '');
      const ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      // setProfileResumes(filteredobjs);
    }

  };
  const handleSearchJobs = (e) => {
    e.preventDefault();
    console.log(data)
    const jobtitle = JSON.stringify(data.jobtitle);
    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:${jobtitle}&fq=status:(1)&rows=20&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        // console.log(resdata.data.response.numFound)
        setProfileResumes(resdata.data.response.docs)

      });
    });
  }
  const modifiedColumns = customColumns(columns);
  console.log(modifiedColumns)
  return (
    <div>
      <Modal
        show={openresume}
        onHide={handleCloseresume}
        backdrop="static"
        centered
        size={isFileFound === true ? "lg" : "md"}
        keyboard={false}
        style={{ marginTop: "25px", marginLeft: "9%" }}
      >
        <Modal.Body>
          <div className='p-1'>

            <Modal.Header closeButton>
              <Modal.Title>Resume file view</Modal.Title>
            </Modal.Header>
            <div className="card rightScroll mt-2" style={{ padding: '1%', boxShadow: 'aquamarine', overflow: "auto" }}>

              {
                isFileFound === true ?
                  <div className='pdf-container' style={{ height: "70vh", width: "100%" }}>
                    {/* show pdf conditionally (if we have one)  */}

                    {defaultPdfFile.includes('undefined') === false ? <>{defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer fileUrl={defaultPdfFile}
                        plugins={[defaultLayoutPluginInstance, themePluginInstance]} theme="white" />
                    </Worker></>}</> : <div className="text-center"> <h1>No Resumes Found</h1></div>}

                  </div> : <div className='pdf-container' style={{ height: "30vh", width: "100%" }}>
                    <h1 className="text-center">No Resumes Found</h1>

                    {/* show pdf conditionally (if we have one) 
            {defaultPdfFile.includes('undefined') === false ? <> {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer fileUrl={defaultPdfFile}
                plugins={[defaultLayoutPluginInstance, themePluginInstance]} theme="white" />
            </Worker></>}</> : <div  className="text-center"> <h1>No Resumes Found</h1></div>} */}

                  </div>}
            </div>

          </div>
        </Modal.Body>
        {/*  <Modal.Footer>
    <Button style={{ background: "grey", color: "#ffff" }} onClick={handleCloseMail}>
      Close
    </Button>
    <Button style={{ background: "blue", color: "#ffff" }} variant="primary" onClick={(e) => handleSendMail(e)}>send</Button>
  </Modal.Footer> */}
      </Modal>





      <Modal
        show={sendMail}
        onHide={handleCloseMail}
        backdrop="static"
        centered
        size="xl"
        keyboard={false}
        style={{ marginTop: "25px", marginLeft: "9%" }}
      >


        <Modal.Body>
          <div className='p-1'>

            <Modal.Header closeButton>
              <Modal.Title>Send Jobs</Modal.Title>
            </Modal.Header>

            <div className='container'>
              <div className='row'>
                <div className='col-md-6 sales-styles'>

                  <div className="sales-style">
                    <Form>
                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>
                          From * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Name * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2} >
                          To * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="eg : example@domain.com" name="toEMail" value={toEMail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2} >
                          Cc:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="eg : something@domain.com, something2@domain.com" name="ccEMail" value={ccEMail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Sub * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Subject" name="subj" value={subj} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Body:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control as="textarea" rows={3} name="body" value={body} onChange={changeHandler} />
                  </Col>
                </Form.Group> */}

                    </Form>

                    <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

                    <Form>
                      <Row className="align-items-center">
                        <Col xs="3" lg="12">
                          <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Job Title
                          </Form.Label>
                          <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Job Title"
                            name="jobtitle" value={jobtitle} onChange={changeHandler}
                          />
                        </Col>

                        <Col xs="3" lg="12">
                          <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Location
                          </Form.Label>
                          <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Location"
                            name="location" value={location} onChange={changeHandler}
                          />
                        </Col>
                      </Row>
                    </Form>

                    <Form onSubmit={(e) => handleSearchJobs(e)}>

                      <Button className="mt-5" style={{ background: "blue", color: "#ffff", float: "right" }} variant="primary" type="submit">
                        Search
                      </Button>
                    </Form>

                  </div>

                </div>


                <div className='col-md-6 sales-styles'>
                  <div className="positions-scroll">
                    {profileResumes.length > 0 ? <h4>These are the active JOBS for your requirement {data.jobtitle} </h4> : <h4>Search for JOBS</h4>}

                    <br />

                    {profileResumes.length > 0 ? profileResumes.map((resume) => {
                      console.log((resume.workmode === "true"))
                      return (
                        <>
                          <div className="job-titile" style={{ padding: "5px" }}>
                            <Row className="job-checkbox">
                              <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                id="check"
                                checked={resume?.isChecked}
                                onClick={(e) => handleChange(e, resume.id)} /></Col>
                              <Col sm="11">  <h3> <a href="#"> {resume.title} </a> </h3> </Col>
                            </Row>


                            <ul>
                              <li> <FaSuitcase /> {resume.prefferdskillsName
                                ? resume.prefferdskillsName
                                  .join(",")
                                  .split(",")
                                  .join(", ")
                                  .slice(0, 100)
                                : "N/A"}</li>

                              <li style={{ float: "left" }}> <MdLocationOn /> {resume.workmode === "true" ? "Remote" : <>{resume.cityName}, {resume.stateName}</>} </li>

                              <li style={{ float: "right" }}> <FaCalendarAlt />

                                {resume.created ? moment(resume.created).format("LL") : "N/A"
                                }
                              </li>


                            </ul>
                          </div>
                          <hr style={{ width: "100%" }} />
                        </>)

                    }) : <center style={{ marginTop: "50px" }}> <h4>No JOBS</h4> </center>
                    }



                  </div>
                  <br />
                  <Form onSubmit={(e) => handleSendMail(e)}>
                    <Button type="submit" variant="secondary" style={{ background: "blue", color: "#ffff", border: 'transparent', float: "right" }}>
                      Share Mails
                    </Button>
                  </Form>

                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        {/*  <Modal.Footer>
          <Button style={{ background: "grey", color: "#ffff" }} onClick={handleCloseMail}>
            Close
          </Button>
          <Button style={{ background: "blue", color: "#ffff" }} variant="primary" onClick={(e) => handleSendMail(e)}>send</Button>
        </Modal.Footer> */}
      </Modal>



      <Container >

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <Typography variant="h4" gutterBottom>
                        Jobseekers
                    </Typography> */}
        </Stack>
      </Container>

      <div className='jobseeker-conatiner' style={{ marginTop: "-100px", boxShadow: "0px 0px 5px black" }}>
        
        <MUIDataTable
          title={<h4>Jobseekers</h4>}
          data={users}
          columns={modifiedColumns}
          options={options}
        />
      </div>


    </div>
  );
}


const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);