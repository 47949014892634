/* eslint-disable */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios'
import MUIDataTable from "mui-datatables";
import CustomerDetailView2 from './CustomerDetailView2';
// import Slider from "@material-ui/core/Slider";
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
//import {createTheme, ThemeProvider } from "@material-ui/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { connect } from "react-redux";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Key from '../clientVariables.json'
import { Modal, Form } from "react-bootstrap";

import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import SessionAlert from 'src/SessionAlert';
import moment from 'moment'

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';


import { SlOptionsVertical } from "react-icons/sl"


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_id', label: 'Customer ID', alignRight: false },
  { id: 'company_name', label: 'Company Name', alignRight: false },
  { id: 'title', label: 'Plan Opted', alignRight: false },
  { id: 'package_end_date', label: 'Expiry Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: 'status', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });


  if (query) {

    return filter(array, (_user) => _user.company_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function User(props) {
  const history = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [editedData, setEditedData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newCostumerId, setNewCustomerId] = useState("");
  const [users, setUsers] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false)
  const [refreshh, setrefreshh] = useState(1);
  const [actionPop, setActionPop] = useState(true)
  const [clickCount, setClickCount] = useState(0);
  const [previousItem, setPreviousItem] = useState(null);

  const [showResetPassword, setShowResetPass] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([0]);

  const handleRowClick = (rowData, rowMeta) => {
    const selectedRowIndex = rowMeta.dataIndex;
    setSelectedRows([selectedRowIndex]);
    // Increment the click count when a row is clicked
    const currentItem = rowData[1]; // assuming you want to track the second column
    if (currentItem === previousItem) {
      setClickCount(clickCount + 1);
    }
    setPreviousItem(currentItem);

  };


  const handleResetPassClose = () => {
    setShowResetPass(false)
  }
  const handleDeactivateClose = () => {
    setShowDeactivate(false)
  }


  const handleActionClose = () => {
    setShowActionPopup(false)
    setShowDeactivate(false)
    setShowResetPass(false)

  }


  useEffect(() => {
    props.showLoader();

    axios
      .post(`${Key.domain}/dashboard/get_extendedpackage_users`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        console.log(response)
        setUsers(response.data.data)
        setNewCustomerId(response.data.data[0].userid)
        console.log(response.data.data[0].userid)
        props.hideLoader();

      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          props.hideLoader();

          setSessionExpired(true)

        }
      });;


  }, [refreshh])





  const actionButton = () => {
    setShowActionPopup(true)
  }

  const deactivateAccountSubmit = () => {
    props.showLoader();

    axios
      .post(`${Key.domain}/customer/deactivate_jnp_user`, {
        "email": editedData.email
      })
      .then((response) => {
        console.log(response)
        let reloadusers = refreshh + 1
        setrefreshh(reloadusers)

        props.hideLoader();
        const msg = "Account '" + editedData.company_name + "' deactivated Successfully !";
        toast(<ToastMessage message={msg} />);

        handleActionClose()
      }).catch((err) => {
        // console.log(err.response.data.data )
          props.hideLoader();

          const msg = "Access denied.. Please Login";
          toast(<ToastMessage message={msg} />);

          handleActionClose()

        
      });;
  }

  const resetPasswordSubmit = () => {
    props.showLoader();

    axios
      .post(`${Key.domain}/customer/reset_jnp_user_password`, {
        "email": editedData.email
      })
      .then((response) => {
        console.log(response)

        let reloadusers = refreshh + 1
        setrefreshh(reloadusers)
        props.hideLoader();
        const msg = "Account '" + editedData.company_name + "' password reset successfully !";
        toast(<ToastMessage message={msg} />);

        handleActionClose()
      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          props.hideLoader();

          const msg = "Access denied.. Please Login";
          toast(<ToastMessage message={msg} />);

          handleActionClose()

        }
      });;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.userid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  console.log(selected.length)



  const handleChangePage = (event, newPage) => {

    console.log(newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;


  const handleChangeCustomerStatus = (customerid, status) => {

    console.log(customerid)
    console.log(status)

    props.showLoader();


    axios
      .post(`${Key.domain}/customer/active_inactive`, {
        "userid": customerid,
        "status": status,
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        console.log(response)

        let reloadusers = refreshh + 1
        setrefreshh(reloadusers)
        setActionPop(false)
        // props.hideLoader();

        let msg = status === 1 ? "Status changed to active" : "Status changed to inactive"

        toast(
          <ToastMessage message={msg} />
        )


      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          // props.hideLoader();

          setSessionExpired(true)
        }
      });;




  };

  const addcustomer = () => {
    history("/dashboard/addcustomer", { state: { customerid: 0 } });


    const detailsView = () => {
      history("/dashboard/customerDetailsView", { state: { customerid: props.customerid } });

    }
  };
  // let muiTheme = createTheme({ overrides: { MuiTableRow: { root: {'&:hover': {cursor:"pointer"}} }, }, });
  const getMuiTheme = () => createTheme({ components: { MuiTableRow: { styleOverrides: { root: { cursor: "pointer" } } } } });


  const columns = [
    // {
    //   label: <span>User ID</span>,
    //   name: "user_id",
    //   options: {
    //     customBodyRender: (value) => {
    //       // return value ? <a style={{ cursor: "pointer",  textDecoration: "none" }}>{value}</a> : "N/A"
    //       return value ? value: "N/A"

    //     }
    //   },
    // },

    {

      label: <span >Customer ID</span>,

      name: "userid",
      options: {

        customBodyRender: (value) => {
          return value ? value : "N/A"
        }
      },

    },

    {

      label: <span >Customer Name</span>,

      name: "user_name",
      options: {

        customBodyRender: (value) => {
          return value ? value : "N/A"
        }
      },

    },

    {
      label: <span >Company Name</span>,
      name: "company_name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span  >Plan Opted</span>,
      name: "packagetitle",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "Custom Plan"
        }
      },
    },
    {
      label: <span >Expiry Date</span>,
      name: "package_end_date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("LL") : "N/A"
        }
      },
    },

    {
      label: <span >Status</span>,
      name: "user_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseInt(value) === 1 ? "Active" : "In Active"

        }
      },
    },

    // {
    //   name: "user_id",
    //   label: "Actions",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <FormControlLabel
    //         control={<Button onClick={() => actionButton()}><SlOptionsVertical /></Button>} />
    //     )
    //   }
    // },

  ];


  console.log(newCostumerId)
  const handleUserDetails = (user) => {

    console.log(user.user_id)

    history("/dashboard/customerDetailsView", { state: { customerid: user.userid, freepackage: false } })
    // history.push({
    //   pathname: "/dashboard/customerDetailsView",
    //   state: {
    //     customerid: user_id,
    //   },
    // });

  }


  const options = {
    // filterType: 'checkbox',
    // responsive: 'standard',
    tableBodyHeight: '30vh',
    fixedHeader: true,
    density: true,
    selectableRows: false,
    search: true,
    filter: true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
    onRowClick: handleRowClick,
   
    setRowProps: (row, dataIndex, rowIndex) => {
        if (selectedRows.includes(dataIndex)) {
            return {
                style: {
                    backgroundColor: '#d9e8fc', // set your desired background color
                },
            };
        }
        return {};
    },

    onCellClick: (cellIndex, rowIndex) => {
      console.log(users[rowIndex.dataIndex]);
      console.log(rowIndex.dataIndex);
      console.log(rowIndex);

      let userdata = users[rowIndex.dataIndex]
      setEditedData(userdata)

      setNewCustomerId(userdata.userid)
      console.log(userdata.userid)
      if (clickCount === 1) {
        console.log(cellIndex)
        handleUserDetails(userdata)
      }


    },

  };




  return (
    <div>


      {/*<Modal show={showActionPopup} centered onHide={handleActionClose}>
        <Modal.Header closeButton>
          <Modal.Title>Action for - {editedData.company_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl fullWidth>
              <>
                <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={() => { setShowDeactivate(true), setShowResetPass(false) }
                }>
                  Deactivate
                </Button>
                <Button variant="Primary" style={{ background: "blue", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(false), setShowResetPass(true) }
                }>
                  Reset Password
                </Button>
              </>

            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <> {showResetPassword ? <div    >

            <h3>Are you sure to reset password ? </h3>
            <div style={{ textAlign: "right", marginRight: "15px" }}>
              <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                No
              </Button>
              <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={resetPasswordSubmit}>
                Yes
              </Button>
            </div>
          </div>
            : ""}
            {showDeactivate ?
              <div style={{ textAlign: "right", marginRight: "15px" }}>

                <h3>Confirm to deactivate account ? </h3>

                <div>
                  <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                    No
                  </Button>
                  <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                    Yes
                  </Button>
                </div>
              </div>
              : ""}
          </>
        </Modal.Footer>
      </Modal>}

      <Modal show={showResetPassword} centered onHide={handleResetPassClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Are you sure to reset password ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={resetPasswordSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeactivate} centered onHide={handleDeactivateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Confirm to deactivate account ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal> */}



      {sessionExpired ? <SessionAlert /> :
        <Page title="Customers" style={{ boxShadow: "0px 0px 5px black" }}>
          <Container style={{ marginTop: "-40px" }}>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}  >
              {/*  <Typography variant="h4" gutterBottom>
                Customers
              </Typography> */}

            </Stack>

            {/*  <Button style={{}} variant="contained" onClick={addcustomer} startIcon={<Iconify icon="eva:plus-fill" />}>
                New Customer
              </Button> */}


          </Container>
          <ThemeProvider theme={getMuiTheme()} >
            <MUIDataTable

              title={<div className='d-flex flex-row align-items-center'>
                <h4>Customers</h4>

               
               
                <div className='' style={{marginLeft: "15px",fontSize: "22px",marginTop: "-10px", color: "blue", cursor:"pointer"}}>
                <Tooltip title="Add New Customer">
               <span onClick={addcustomer} > <Iconify icon="eva:plus-fill" /></span>
               </Tooltip>
                 
                </div>
             
              </div>
              }
              data={users}
              columns={columns}
              options={options}
            />
          </ThemeProvider>

          <div>
            <CustomerDetailView2 editedData={editedData} customerId={newCostumerId} />
          </div>
        </Page>}
    </div>
  );
}


const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);