/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ENDPOINT } from 'src/utils/endpoint';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Resumeviewer from "./ResumeViewer"
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { toast } from "react-toastify";
import { MdLocationOn } from "react-icons/md";
import request from 'src/utils/request';
import ToastMessage from "../components/ToastMessage";


const statusItems = [
    {
        value: "Not Answered"
    },
    {
        value: "Will Check"
    },
    {
        value: "No Resumes"
    },
    {
        value: "No Recent Resumes"
    },
    {
        value: "Call Back Later"
    },
    {
        value: "Registered"
    },
    {
        value: "Job Sent"
    }
]


const salesPersons = [

    {
        value: "Anusha"
    },
    {
        value: "Tanya"
    }
]


function User(props) {
    const history = useNavigate();
    const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

    const [data, setData] = useState({
        fromEmail: "hot-resumes@jobsnprofiles.com",
        fullname: '',
        toEMail: '',
        ccEMail: '',
        subj: '',
        body: mailbody,
        jobtitle: '',
        skillname: '',
        location: '',

    });
    const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
    const [profileResumes, setProfileResumes] = useState([]);
    const [date, setDate] = useState("");
    const [showResume, setShowResume] = useState(false)
    const [users, setUsers] = useState([]);
    const [sendMail, setSendMail] = useState(false);
    const [person, setPerson] = useState("");
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [editedData, setEditedData] = useState([]);
    const [selectedResIds, setSelectedResids] = useState([]);
    const [selectedResumes, setSelectedResumes] = useState([]);
    const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location } = { ...data }
    const handleClose = () => {

        setShowResume(false);
    }


    useEffect(() => {
        props.showLoader();
        setSendMail(true)
        getUsers();
        var url = window.location.pathname
        var splittedUrl = url.split("/")
        setData({ ...data, fullname: splittedUrl[5].replace("%20", " "), toEMail: splittedUrl[4], jobtitle: splittedUrl[3].replace("%20", " "), location: "", skillname: "" })
        searchcompanyResumes('"' + splittedUrl[3] + '"')
    }, [])

    const searchcompanyResumes = (jobtitle) => {


        var mappeddate = sessionStorage.getItem("resumeMappedDates")
        console.log(jobtitle)

        if (jobtitle.includes(" / ")) {
            jobtitle = jobtitle.replace(' / ', '/')
        }

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '""' ? jobtitle : "* TO *"}&fq=res_cityName:(${data.location ? data.location : "*:*"})&fq=${skillname}&fq=created:[${mappeddate !== null ? mappeddate : '* TO *'}]&rows=20&start=0&sort=created%20desc&wt=json`
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    console.log(resdata)
                    if (resdata.data.response.docs.length === 0) {
                        showAlternateResults(jobtitle)
                    } else {
                        setProfileResumes(resdata.data.response.docs)

                    }

                })

            })
    }
    const handleCloseMail = () => {
        setData({ ...data, fullname: "", toEMail: "", jobtitle: "", location: "", skillname: "" })
        setSelectedResids([])
        setSelectedResumes([])
        setProfileResumes([])
        setSendMail(false);
    }
    const getUsers = () => {

        axios
            .post(`${Key.domain}/customer/get_subscribers`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token
            })
            .then((response) => {
                console.log(response)
                setUsers(response.data.data)
                props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    setSessionExpired(true)

                }
            });;
    }

    const handleDateChage = (value) => {
        setDate(value)
        if (value === "Today") {
            const today = new Date();
            var startdate = moment(today).subtract(1, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            sessionStorage.setItem("resumeMappedDates", mappeddate)

        } else {

            const today = new Date();
            var startdate = moment(today).subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            sessionStorage.setItem("resumeMappedDates", mappeddate)

        }
    }
    const showAlternateResults = async (jobtitle) => {
        let title = jobtitle.replace(/['"]/g, '')
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title})&rows=${10}&start=${0}&wt=json`;
        const result = await request(`${ENDPOINT['SolrResumes']}`,
            postOptions({
                url: searchResumesUrl,
            }))
        // console.log(result.data.response.docs)
        setProfileResumes(result.data.response.docs);
    }
    const handleSearch = (e) => {
        e.preventDefault();
        console.log(data)
        let jobtitle = JSON.stringify(data.jobtitle);
        var mappeddate = sessionStorage.getItem("resumeMappedDates")
        console.log(jobtitle)

        if (jobtitle.includes(" / ")) {
            jobtitle = jobtitle.replace(' / ', '/')
        }


        if (data.location.includes(',')) {
            const location = data.location !== "" ? data.location.split(/[,]+/) : "";
      
            if (location[1].replace(' ', '').length === 2) {
              var cityName = location[0]
              var stateName = ""
              var shortRegion = location[1]
            } else {
              cityName = location[0]
              stateName = location[1]
            }
      
      
          } else if (data.location.length === 2) {
            shortRegion = data.location
          } else {
            cityName = data.location
      
          }
      

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '""' ? jobtitle : "* TO *"} OR id:(${jobtitle ? jobtitle : "*%3A*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=${skillname}&fq=created:[${mappeddate !== null ? mappeddate : '* TO *'}]&rows=20&start=0&sort=created%20desc&wt=json`
        // const searchResumesUrl1 = "http://65.21.122.252:8983/solr/jnp_resumes_dev/select?fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone&q.op=OR&q=*React%20developer*&rows=10&start=0"

        console.log(searchResumesUrl)
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    console.log(resdata)
                    if (resdata.data.response.docs.length === 0) {
                        showAlternateResults(jobtitle)
                    } else {
                        setProfileResumes(resdata.data.response.docs)

                    }
                    // if (subScribersCCMails.length > 0) {
                    //   const newResumes = resdata.data.response.docs.map((list) => (
                    //     { ...list, isChecked: true })
                    //   );

                    //   setProfileResumes(newResumes);

                    //   console.log(newResumes)

                    //   const obj = newResumes.filter((x) => x.isChecked === true);
                    //   let filteredobjs = obj.filter((x) => x.application_title !== '');
                    //   const ids = filteredobjs.map(obj => obj.id)
                    //   setSelectedResids(ids)
                    //   setSelectedResumes(filteredobjs);
                    // }
                })

            })
    }
    const handleChange = (e, resumeId) => {
        const { name, value, checked } = e.target;
        let filteredobjs = []
        if (name === String(resumeId)) {
            console.log("comes here", resumeId)
            const newResumes = profileResumes.map((list) =>
                list.id === resumeId ? { ...list, isChecked: checked } : list
            );
            setProfileResumes(newResumes);
            console.log(newResumes)
            const obj = newResumes.filter((x) => x.isChecked === true);
            filteredobjs = obj.filter((x) => x.application_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
            // setProfileResumes(filteredobjs);
        }

    };
    const setMessageFunction = (e, t) => {



        console.log(comment)
        console.log(person)
        console.log(status)


        if (t === "sales") {
            setPerson(e.target.value)
        } else if (t === "status") {
            setStatus(e.target.value)
        } else if (t === "comments") {
            setComment(e.target.value)
        }

        axios
            .post(`${Key.domain}/customer/insert_update_subscribers_followup`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": sessionStorage.getItem("userToken"),
                "comment": t === "comments" ? e.target.value : comment,
                "user_id": editedData.user_id,
                "sales_person": t === "sales" ? e.target.value : person,
                "sales_response_status": t === "status" ? e.target.value : status
            })
            .then((resdata) => {
                console.log(resdata.data.data)
                getUsers();
            }).catch((err) => {
                console.log(err);
            });
    }

    const changeHandler = e => {
        e.preventDefault()
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const columns = [
        {
            name: "usercreated",
            label: "Date",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).format("LL") : "N/A"
                }
            },

        },
        {
            label: <span>Name</span>,
            name: "full_name",
            options: {

                customBodyRender: (value) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Company Name</span>,
            name: "company_name" !== null && "company_name" !== undefined && "company_name" !== "" ? "company_name" : "userCompanyName",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Email</span>,
            name: "username",
            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Last Logged in</span>,
            name: "lastlogindate",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).format("LL") : "N/A"
                }
            },
        },
        {
            label: <span>Status</span>,
            name: "activation",
            options: {
                setCellProps: () => ({ style: { minWidth: "110px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "In Active"

                }
            },
        },

        {
            label: <span>Jobs Posted</span>,
            name: "jobspostedcount",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value

                }
            },
        },



        {
            name: "comments",
            label: "Comments",
            options: {
                setCellProps: () => ({ style: { minWidth: "220px", maxWidth: "250px" } }),
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (

                    <TextareaAutosize
                        maxRows={3}
                        aria-label="maximum height"
                        placeholder="Note"
                        defaultValue={value}
                        style={{ width: "200px", marginTop: "8px", padding: "10px", borderRadius: "10px", borderColor: "gainsboro" }}
                        onChange={(e) => {
                            updateValue(e.target.value),
                                setComment(e.target.value)
                            // setMessageFunction(e, "sales")
                        }}
                        onBlur={(e) => setMessageFunction(e, "comments")}
                    />

                    // <FormControlLabel
                    //   control={<TextField value={value || ''} type='text' />}
                    //   onChange={(event) => { updateValue(event.target.value); setComment(event.target.value) }}
                    //   onBlur={(e) => setMessageFunction(e, "comments")}
                    // />
                )
            }
        },

        {
            name: "sales_response_status",
            label: "Response Status",
            options: {
                setCellProps: () => ({ style: { minWidth: "250px", maxWidth: "250px" } }),

                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel style={{ width: "70px" }} htmlFor="grouped-select">Status</InputLabel>
                                <Select style={{ width: "100%" }}
                                    defaultValue={value}
                                    id="grouped-select"
                                    label="Grouping"
                                    onChange={(e) => {
                                        updateValue(e.target.value),
                                            setStatus(e.target.value)
                                        // setMessageFunction(e, "status")
                                    }}
                                    onBlur={(e) => setMessageFunction(e, "status")}>

                                    {statusItems.map((item, index) => (
                                        <MenuItem value={item.value} >{item.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        } />
                )
            }

        },

        {
            name: "sales_person",
            label: "Sales Person",
            options: {
                setCellProps: () => ({ style: { minWidth: "250px", maxWidth: "250px" } }),
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel style={{ width: "100px" }} htmlFor="grouped-select">Sales person</InputLabel>
                                <Select style={{ width: "100%" }}
                                    defaultValue={value}
                                    id="grouped-select"
                                    label="Grouping"
                                    onChange={(e) => {
                                        updateValue(e.target.value),
                                            setPerson(e.target.value)
                                        // setMessageFunction(e, "sales")
                                    }}
                                    onBlur={(e) => setMessageFunction(e, "sales")}>

                                    {salesPersons.map((item, index) => (
                                        <MenuItem value={item.value} >{item.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        } />
                )
            }
        },

        {
            name: "sendMail",
            label: "Send Mail",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel sx={{ m: 1, minWidth: 120 }}
                        control={<Button onClick={() => sendMailFunction()}>send</Button>} />
                )
            }
        }
    ];

    const sendMailFunction = () => {
        setSendMail(true)
    }

    const handleUserDetails = (user) => {

        console.log(user.user_id)

        history("/dashboard/customerDetailsView", { state: { customerid: user.user_id } })
        // history.push({
        //   pathname: "/dashboard/customerDetailsView",
        //   state: {
        //     customerid: user_id,
        //   },
        // });

    }
    const handleSendMail = (e) => {

        e.preventDefault();
        console.log(data)
        // var jobtitle = '"' + data.jobtitle + '"'
        console.log(profileResumes)
        console.log(selectedResumes)

        if (toEMail && fromEmail && fullname) {
            if (selectedResumes.length > 5) {


                const msg = "Maximum limit is to share resumes is 5";
                toast(<ToastMessage message={msg} />);

            } else if (selectedResumes.length > 0) {
                fetch(`${Key.domain}/common/send_contactSales_resumes_Mail`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes, toEMail: data.username, mailBodyCondition: "fromSubscribers" }),
                })
                    .then((response) => {
                        response.json().then((resdata) => {
                            console.log(resdata)
                            if (resdata.data === "Mail sent To Employer") {
                                const msg = "Mail Sent Successfully";
                                toast(<ToastMessage message={msg} />);
                                window.location.reload()
                                setSendMail(false)

                            }
                        })
                    })
            } else {
                const msg = "Select atleast 1 resume";
                toast(<ToastMessage message={msg} />);

            }
        } else {

            const msg = "Enter required fields";
            toast(<ToastMessage message={msg} />);

        }
    }

    const options = {

        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,



        onCellClick: (cellIndex, rowIndex) => {
            console.log(users[rowIndex.dataIndex]);
            console.log(rowIndex.dataIndex);
            console.log(rowIndex);

            let userdata = users[rowIndex.dataIndex]

            setEditedData(userdata)
            setData({ ...data, fullname: userdata.full_name, toEMail: userdata.username })
            setPerson(userdata.sales_person)
            setStatus(userdata.sales_response_status)
            setComment(userdata.comments)

            if (rowIndex.colIndex === 0) {
                console.log(cellIndex)
                handleUserDetails(userdata)
            } else if (rowIndex.colIndex === 6) {
                if (userdata.company_id !== null) {
                    const url = `${Key.JNP_URL}/crm/company/view/comp_${userdata.company_id}`
                    // console.log(url.split("Job_"))
                    const Window = window.open();
                    Window.location.href = url;
                }
            }


        },

    };


    return (
        <div>
            <Modal
                show={sendMail}
                onHide={handleCloseMail}
                backdrop="static"
                centered
                size="xl"
                keyboard={false}
                style={{ marginTop: "25px", marginLeft: "9%" }}
            >


                <Modal.Body>
                    <div className='p-1'>

                        <Modal.Header closeButton>
                            <Modal.Title>Send Resumes</Modal.Title>
                        </Modal.Header>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 sales-styles'>

                                    <div className="sales-style">
                                        <Form>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={2}>
                                                    From * :
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                                <Form.Label column sm={2}>
                                                    Name * :
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                                <Form.Label column sm={2} >
                                                    To * :
                                                </Form.Label>
                                                <Col sm={10}>

                                                    <Col sm={10}>
                                                        <Form.Control type="name" placeholder="Full Name" name="fullname" value={toEMail} />
                                                    </Col>


                                                </Col>
                                            </Form.Group>



                                            {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
            <Form.Label column sm={2}>
              Body:
            </Form.Label>
            <Col sm={10}>
              <Form.Control as="textarea" rows={3} name="body" value={body} onChange={changeHandler} />
            </Col>
          </Form.Group> */}

                                        </Form>

                                        <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

                                        <Form>
                                            <Row className="align-items-center">
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Job Title
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Job Title"
                                                        name="jobtitle" value={jobtitle.replace("%20", " ")} onChange={changeHandler}
                                                    />
                                                </Col>
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Skills
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Skills"
                                                        name="skillname" value={skillname} onChange={changeHandler}
                                                    />
                                                </Col>
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Location
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Location"
                                                        name="location" value={location} onChange={changeHandler}
                                                    />
                                                </Col>
                                                {/* <Col xs="auto">
              <Button type="submit" className="mb-2">
                Add More
              </Button>
            </Col> */}
                                            </Row>
                                        </Form>
                                        <div className="mt-4" style={{ display: "flex", float: "right", }}>
                                            <div>
                                                <DropdownButton id="dropdown-basic-button" title={date ? date : "Date"} style={{ float: "right", }} >
                                                    <Dropdown.Item onClick={(e) => handleDateChage('Today')}>Today</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => handleDateChage('Last Week')}>Last Week</Dropdown.Item>
                                                </DropdownButton>
                                            </div> &nbsp;&nbsp;
                                            <div>

                                                <Form onSubmit={(e) => handleSearch(e)}>
                                                    <Button type="submit" style={{ float: "right", background: "blue", color: "#ffff" }} variant="primary">
                                                        Search
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='col-md-6 sales-styles' >
                                    <Modal size="lg" show={showResume} onHide={handleClose} centered>
                                        <Modal.Body>
                                            <Container>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                                                    </Col>

                                                </Row>


                                            </Container>
                                        </Modal.Body>

                                    </Modal>
                                    <div className=" positions-scroll">
                                        {profileResumes.length > 0 ? <h4>These are the active RESUMES for your requirement {data.jobtitle} </h4> : <h4>Search for resumes</h4>}

                                        <br />
                                        {profileResumes.length > 0 ? profileResumes.map((resume) => {
                                            return (
                                                <>
                                                    <div className="job-titile" style={{ padding: "5px" }}>
                                                        <Row className="job-checkbox">
                                                            <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                                                id="check"
                                                                checked={resume?.isChecked}
                                                                onClick={(e) => handleChange(e, resume.id)} /></Col>
                                                            <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                                                        </Row>


                                                        <ul>
                                                            <li> <FaSuitcase /> {resume.skillName
                                                                ? resume.skillName
                                                                    .join(",")
                                                                    .split(",")
                                                                    .join(", ")
                                                                    .slice(0, 100)
                                                                : "N/A"}</li>

                                                            <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                                                            <li style={{ float: "right" }}> <FaCalendarAlt />

                                                                {resume.lastmodified ? moment(resume.lastmodified).format("LL") : "N/A"
                                                                }
                                                            </li>


                                                        </ul>
                                                    </div>
                                                    <hr style={{ width: "100%" }} />
                                                </>)

                                        }) : <center style={{ marginTop: "50px" }}> <h4>No Resumes</h4> </center>
                                        }



                                    </div>
                                    <br />
                                    <Form onSubmit={(e) => handleSendMail(e)}>


                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "-5px" }}>
                                            Share Mail
                                        </Button>
                                    </Form>

                                </div>
                            </div>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: "grey", color: "#ffff" }} onClick={handleCloseMail}>
                        Close
                    </Button>
                    <Button style={{ background: "blue", color: "#ffff" }} variant="primary" onClick={(e) => handleSendMail(e)}>send</Button>
                </Modal.Footer>
            </Modal>
            <MUIDataTable
                title={"Subscribers List"}
                data={users}
                columns={columns}
                options={options}
            />
        </div>
    )
}




const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);