/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import moment from 'moment'
import Key from '../clientVariables.json';
import MenuItem from '@mui/material/MenuItem';
import Container from 'react-bootstrap/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Modal, Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Resumeviewer from "./ResumeViewer"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToastMessage from "../components/ToastMessage";
import request from 'src/utils/request';
import httpConfig, { postOptions } from 'src/utils/httpConfig';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { BiRightArrowAlt } from "react-icons/bi"
import { RiArrowDropDownLine } from "react-icons/ri"
import { toast } from "react-toastify";
import { MdLocationOn } from "react-icons/md";
import { ENDPOINT } from 'src/utils/endpoint';
import CustomToolBar from './customToolBar';
import * as XLSX from "xlsx";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import parse from "html-react-parser";
import TJsign from '../components/images/TJSign.jpg'
import { MdRefresh } from "react-icons/md";


const statusItems = [
  {
    value: "Not Answered"
  },
  {
    value: "Not Interested"
  },
  {
    value: "Will Check"
  },
  {
    value: "No Resumes"
  },
  {
    value: "No Recent Resumes"
  },
  {
    value: "Call Back Later"
  },
  {
    value: "Registered"
  },
  {
    value: "Unavailable"
  },
  {
    value: "Wrong Number"
  }
]


// const salesPersons = [

//   {
//     value: "Anusha"
//   },
//   {
//     value: "TJ"
//   }
// ]


function Salesmanagerleads() {
  const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
  const [profileResumes, setProfileResumes] = useState([]);
  const [resutlData, setResultData] = useState([])
  const [showfile, setShowfile] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [date, setDate] = useState("");
  const [showResume, setShowResume] = useState(false)
  const [selectedResIds, setSelectedResids] = useState([]);
  const [xlData, setXlData] = useState([]);
  const [xlFile, setXlFile] = useState();
  const [editedData, setEditedData] = useState([]);
  const [resumeList, setResumeList] = useState([]);
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [person, setPerson] = useState("");
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [showSalesPerson, setShowSalesPerson] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [salesPersons, setSalesPersons] = useState([]);


  const [salesPerson, setSalesPerson] = useState("");
  const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

  const [data, setData] = useState({
    fromEmail: sessionStorage.getItem("email") === "tj@jobsnprofiles.com" ? "tj@jobsnprofiles.com" : "anusha@jobsnprofiles.com",
    fullname: '',
    toEMail: '',
    ccEMail: '',
    subj: sessionStorage.getItem("email") === "tj@jobsnprofiles.com" ? '🔍 Unlock Your Potential: AI-Powered Hiring for Job Seekers & Employers! 🤝👥' : '',
    body: mailbody,
    jobtitle: '',
    skillname: '',
    location: '',
    toUserId: '',
    jobId: '',
  })
  const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location, jobId, toUserId } = { ...data }
  useEffect(() => {
    getData();
  }, [])


  const getData = () => {

    getLeads()
    console.log("leads")
    fetch(`${Key.domain}/configuration/get_constraints/salespersonslist/1`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        response.json().then((resdata) => {

          console.log(resdata)
          if (resdata && resdata.data && resdata.data.length > 0) {

            var list = resdata.data
            setSalesPersons(list.map((item) => item.name))

          }
        })
      })
  }


  const setMessageFunction = (e, t) => {
    console.log(e)

    if (t === "sales") {
      setPerson(e.target.value)
    } else if (t === "status") {
      setStatus(e.target.value)
    } else if (t === "comments") {
      setComment(e)
    }

    axios
      .post(`${Key.domain}/dashboard/update_lead_data`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": sessionStorage.getItem("userToken"),
        "comment": comment,
        "email": editedData.email_id,
        "sales_person": t === "sales" ? e.target.value : person,
        "status": t === "status" ? e.target.value : status
      })
      .then((resdata) => {
        console.log(resdata.data.data)
        getLeads();
      }).catch((err) => {
        console.log(err);
      });
  }

  const sendMailFunction = () => {
    setSendMail(true)
  }

  const handleClose = () => {

    setShowResume(false);
  }

  const changeSalesPerson = e => {
    setSalesPerson(e.target.value);
    setShowSalesPerson(true);
  }

  const handleSalesClose = () => {
    setShowSalesPerson(false)
  }

  const changeStatus = e => {
    setStatus(e.target.value);
    setShowStatus(true);
  }

  const handleStateClose = () => {
    setShowStatus(false)
  }


  const changeComment = e => {
    setComment(e.target.value);
    setShowComment(true);
  }

  const handleCommentClose = () => {
    setShowComment(false)
  }
  const handleSaveComment = () => {
    setMessageFunction(comment, "comments")
    handleCommentClose();

  }
  const columns = [
    {
      name: "created",
      label: "Date",
      options: {
        setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("LL") : "N/A"
        }
      },

    },

    {
      name: "company_name",
      label: "Company Name",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }
      /*options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <a href="/dashboard/LeadsDetailView"
            style={{ color: "#000", textDecoration: "none", cursor: "pointer" }}>{value}</a>
        }
      }*/
    },

    {
      name: "executive_first_name",
      label: "Contact Name",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }
    },
    {
      name: "email_id",
      label: "Email ID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }

    },
    {
      name: "phone",
      label: "Phone",
      options: {
        setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "160px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }
    },
    {
      name: "requirements",
      label: "Requirements",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      }
    },

    {
      name: "comments",
      label: "Comments",
      options: {
        setCellProps: () => ({ style: { minWidth: "190px", maxWidth: "190px" } }),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={
              <FormControl sx={{ m: 1, minWidth: 50 }}>
                <Button onClick={(e) => changeComment(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined && value !== 'undefined') ? <>{value.length > 15 ? value.slice(0, 15) + "..." : value} <BiRightArrowAlt /></> : <>Add <BiRightArrowAlt /></>}</Button>
              </FormControl>
            } />
        )
      }

    },

    {
      name: "reassigned",
      label: "Reassigned",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel sx={{ m: 1, minWidth: 120 }}
            control={(parseInt(value) === 1 ? <Button  >Ressigned</Button> : <Button > </Button>)} />
        )
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", maxWidth: "190px" } }),

        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            control={
              <FormControl sx={{ m: 1, minWidth: 50 }}>
                <Button onClick={(e) => changeStatus(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined) ? <>{value} <RiArrowDropDownLine /></> : <>Select <RiArrowDropDownLine /></>}</Button>
                {/*<InputLabel style={{ width: "70px" }} htmlFor="grouped-select">Status</InputLabel>
                <Select style={{ width: "100%" }}
                  defaultValue={value}
                  id="grouped-select"
                  label="Grouping"
                  onChange={(e) => {
                    updateValue(e.target.value),
                      setStatus(e.target.value)
                    // setMessageFunction(e, "status")
                  }}
                  onBlur={(e) => setMessageFunction(e, "status")}>

                  {statusItems.map((item, index) => (
                    <MenuItem value={item.value} >{item.value}</MenuItem>
                  ))}
                  </Select>*/}
              </FormControl>
            } />
        )
      }

    },

    // {
    //   name: "sales_person",
    //   label: "Sales Person",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "120px" } }),
    //     filter: true,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <FormControlLabel
    //         control={
    //           <FormControl sx={{ m: 1, minWidth: 100 }}>
    //             <Button onClick={(e) => changeSalesPerson(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined) ? <>{value} <RiArrowDropDownLine /></> : <>Select <RiArrowDropDownLine /></>}</Button>
    //             {/* <InputLabel style={{ width: "100px" }} htmlFor="grouped-select">Sales person</InputLabel>
    //             <Select style={{ width: "100%" }}
    //               defaultValue={value}
    //               id="grouped-select"
    //               label="Grouping"
    //               onChange={(e) => {
    //                 updateValue(e.target.value),
    //                   setPerson(e.target.value)
    //                 // setMessageFunction(e, "sales")
    //               }}
    //               onBlur={(e) => setMessageFunction(e, "sales")}>

    //               {salesPersons.map((item, index) => (
    //                 <MenuItem value={item.value} >{item.value}</MenuItem>
    //               ))}
    //               </Select>*/}
    //           </FormControl>
    //         } />
    //     )
    //   }
    // },

    {
      name: "mail_sent",
      label: "Send Mail",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel sx={{ m: 1, minWidth: 120 }}
            control={(parseInt(value) === 1 ? <Button onClick={(e) => sendMailFunction()} >Resend</Button> : <Button onClick={(e) => sendMailFunction()}>Send </Button>)} />
        )
      }
    }
  ];
  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
    let filteredobjs = []
    if (name === String(resumeId)) {
      console.log("comes here", resumeId)
      const newResumes = profileResumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setProfileResumes(newResumes);
      console.log(newResumes)
      const obj = newResumes.filter((x) => x.isChecked === true);
      filteredobjs = obj.filter((x) => x.application_title !== '');
      const ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      // setProfileResumes(filteredobjs);
    }

  };
  const handleDateChage = (value) => {
    setDate(value)
    if (value === "Today") {
      const today = new Date();
      var startdate = moment(today).subtract(1, 'd').format('YYYY-MM-DD hh:mm:ss');
      var convertedStartDate = moment.utc(moment(startdate)).format()
      var mappeddate = convertedStartDate + ' TO ' + 'NOW'
      sessionStorage.setItem("resumeMappedDates", mappeddate)

    } else {

      const today = new Date();
      var startdate = moment(today).subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
      var convertedStartDate = moment.utc(moment(startdate)).format()
      var mappeddate = convertedStartDate + ' TO ' + 'NOW'
      sessionStorage.setItem("resumeMappedDates", mappeddate)

    }
  }

  const showAlternateResults = async (jobtitle) => {
    let title = jobtitle.replace(/['"]/g, '')
    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title !== undefined && title !== '""' ? title : "*%3A*"})&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=${10}&start=${0}&wt=json`;
    const result = await request(`${ENDPOINT['SolrResumes']}`,
      postOptions({
        url: searchResumesUrl,
      }))
    console.log(result.data.response.docs)
    setProfileResumes(result.data.response.docs);


  }

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(data)
    let jobtitle = JSON.stringify(data.jobtitle);
    if (jobtitle.includes(" / ")) {
      jobtitle = jobtitle.replace(' / ', '/')
    }
    var mappeddate = sessionStorage.getItem("resumeMappedDates")
    console.log(jobtitle)


    if (data.location.includes(',')) {
      const location = data.location !== "" ? data.location.split(/[,]+/) : "";

      if (location[1].replace(' ', '').length === 2) {
        var cityName = location[0]
        var stateName = ""
        var shortRegion = location[1]
      } else {
        cityName = location[0]
        stateName = location[1]
      }


    } else if (data.location.length === 2) {
      shortRegion = data.location
    } else {
      cityName = data.location

    }


    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${jobtitle !== undefined && jobtitle !== "" ? jobtitle : "*%3A*"}) OR id:(${jobtitle ? jobtitle : "*%3A*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=(${skillname ? skillname : "*:*"})&fq=created:[${mappeddate !== null ? mappeddate : '* TO *'}]&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=20&start=0&sort=lastmodified%20desc&wt=json`
    // const searchResumesUrl1 = "http://65.21.122.252:8983/solr/jnp_resumes_dev/select?fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone&q.op=OR&q=*React%20developer*&rows=10&start=0"

    console.log(searchResumesUrl)
    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    })
      .then((response) => {
        response.json().then((resdata) => {
          console.log(resdata)
          if (resdata.data.response.docs.length === 0) {
            showAlternateResults(jobtitle)
          } else {
            setProfileResumes(resdata.data.response.docs)

          }
          // if (subScribersCCMails.length > 0) {
          //   const newResumes = resdata.data.response.docs.map((list) => (
          //     { ...list, isChecked: true })
          //   );

          //   setProfileResumes(newResumes);

          //   console.log(newResumes)

          //   const obj = newResumes.filter((x) => x.isChecked === true);
          //   let filteredobjs = obj.filter((x) => x.application_title !== '');
          //   const ids = filteredobjs.map(obj => obj.id)
          //   setSelectedResids(ids)
          //   setSelectedResumes(filteredobjs);
          // }
        })

      })
  }

  const changeHandler = e => {
    e.preventDefault()
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSendMail = (e) => {
    e.preventDefault();
    // var jobtitle = '"' + data.jobtitle + '"'
    console.log(profileResumes)
    console.log(selectedResumes)
    console.log(toEMail, sendMail)

    console.log(fullname)
    setSendingMail(true)
    if (toEMail && fromEmail && fullname) {
      console.log(fullname)

      if (selectedResumes.length > 5) {
        console.log(fromEmail)

        setSendingMail(false)

        const msg = "Maximum limit is to share resumes is 5";
        toast(<ToastMessage message={msg} />);
        setSendMail(false)

      } else if (selectedResumes.length > 0) {
        fetch(`${Key.domain}/common/send_livejob_resumes_mail`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes, mailBodyCondition: "fromLeads" }),
        })
          .then((response) => {
            response.json().then((resdata) => {
              console.log(resdata)
              if (resdata.data === "Mail sent") {
                // fetch(`${Key.domain}/customer/save_suggested_resumes_mail`, {
                //   method: 'POST',
                //   headers: {
                //     'Accept': 'application/json',
                //     'Content-Type': 'application/json'
                //   },
                //   body: JSON.stringify({
                //     admin_id: sessionStorage.getItem("id"),
                //     session_token: sessionStorage.getItem('userToken'),
                //     jobid: data.jobId,
                //     fromUserMail: data.fromEmail,
                //     fromUserId: sessionStorage.getItem("id"),
                //     toUserMail: data.toEMail,
                //     toUserId: data.toUserId
                //   }),
                // }).then((response) => {
                //   console.log(response)
                //   const msg = "Mail Sent Successfully";
                //   toast(<ToastMessage message={msg} />);
                //   window.location.reload()
                // })
                setSendingMail(false)
                const msg = "Mail Sent Successfully";
                toast(<ToastMessage message={msg} />);
                setSendMail(false)
                getLeads();
                // setTimeout(() => {
                //   window.location.reload()
                // }, 500);
              }
            })
          })
      } else {
        if (sessionStorage.getItem("email") === "tj@jobsnprofiles.com") {
          fetch(`${Key.domain}/common/send_livejob_resumes_mail`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes, mailBodyCondition: "fromLeads" }),
          })
            .then((response) => {
              response.json().then((resdata) => {
                console.log(resdata)
                if (resdata.data === "Mail sent") {
                  setSendingMail(false)
                  const msg = "Mail Sent Successfully";
                  toast(<ToastMessage message={msg} />);

                  // setTimeout(() => {
                  //   window.location.reload()
                  // }, 500);
                  setSendMail(false)
                }
              })
            })
        } else {
          setSendingMail(false)
          const msg = "Select atleast 1 resume";
          toast(<ToastMessage message={msg} />);
          setSendMail(false)
        }
      }
    }
  }
  const getLeads = async () => {
    const result = await request(`${ENDPOINT['getLeads']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken") }))

    const fiteredData = result.data.filter((obj, index, self) => {
      return !self.slice(index + 1).some((otherObj) => otherObj.email_id === obj.email_id);
    });

    setResultData(result.data)
  }

  const postLeads = async (xl_Data) => {
    const result = await request(`${ENDPOINT['postLeads']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken"), xlData: xl_Data }))
    if (result.success === 1) {
      handleClosefile()



      setTimeout(() => {
        getLeads()
      }, 2000);
    }
  }

  const readExcel = (file) => {
    // alert("Excel called")
    setXlFile(file)

  }

  const openModel = (file) => {
    // alert("Excel called")
    setShowfile(true)
  }



  const handleCloseMail = () => {
    setSendMail(false);
    setProfileResumes([])

  }
  const handleClosefile = () => setShowfile(false);

  const handleXlUpload = async () => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(xlFile);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        console.log(data);
        resolve(data);

      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setXlData(d);
      const xl_Data = d
      postLeads(xl_Data)

    });
    // console.log(xl_Data)
  }
  // const getResumes = (data) => {
  //   if (parseInt(data.mail_sent) === 0) {
  //     sendMailFunction();

  //     const jobtitle = data.requirements

  //     console.log(editedData.requirements)

  //     // setData({ ...data, toEMail: postedJob.contactemail, fullname: postedJob.contactname, toUserId: postedJob.uid, jobId: postedJob.id })
  //     // Get matched Profiles for selected posted Job
  //     const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '' ? '"' + jobtitle + '"' : " TO "}&fl=full_name%2Capplication_title%2Cid%2CskillName%2Cres_cityName%2Cres_shortRegion%2Clastmodified&rows=10&start=0&sort=created%20desc&wt=json`;

  //     console.log(searchResumesUrl);

  //     fetch(`${Key.domain}/solr/solr_resumes`, {
  //       method: "POST",

  //       headers: {
  //         Accept: "application/json",

  //         "Content-Type": "application/json",
  //       },

  //       body: JSON.stringify({ url: searchResumesUrl }),
  //     }).then((response) => {
  //       response.json().then((resdata) => {
  //         console.log(resdata.data.response.docs);
  //         setProfileResumes(resdata.data.response.docs);
  //       });
  //     });
  //   }
  // }

  const options = {
    selectableRows: false,
    viewColumns: false,
    responsive: 'stacked',
    print: false,
    search: true,
    // onCellClick: (cellIndex, rowIndex) => {
    onCellClick: (colData, cellMeta, cellIndex) => {

      var row = resutlData[cellMeta.dataIndex];
      // console.log(row)
      setEditedData(row)

      setPerson(row.sales_person)
      setStatus(row.status)
      setComment(row.comments)

      setData({ ...data, toEMail: row.email_id, fullname: row.contact_name, jobtitle: row.requirements })

      if (cellMeta.colIndex === 9) {
        console.log(row)
        setEditedData(row)
        // getResumes(row)
      }
      // console.log(cellIndex.props);
      // console.log(rowIndex.dataIndex);
      // console.log(rowIndex);
    },
    onRowClick: (rowData) => {

      //   setEditedData(rowData)
      //   getResumes(rowData)
      console.log(rowData)
    },
    customToolbar: () => {
      return (
        <CustomToolBar openModel={openModel} />
      );

    }
  }
  return (
    <div>
      {/* Update Comments Model */}
      <Modal show={showComment} centered onHide={handleCommentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl fullWidth>
              <> <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Add Comment..."
                onChange={(e) => setComment(e.target.value)
                }
                value={(comment !== undefined && comment !== 'undefined') ? comment : ""} size="50" /></>

            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleCommentClose}>
            Close
          </Button>
          <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={handleSaveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Status Model */}
      <Modal show={showStatus} centered onHide={handleStateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={status}
                onChange={(e) => {
                  setMessageFunction(e, "status"),
                    setStatus(e.target.value)
                }}
                label="Status"

              >{statusItems.map((item, index) => (
                <MenuItem value={item.value} >{item.value}</MenuItem>
              ))}


              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleStateClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Sales Model */}
      <Modal show={showSalesPerson} centered onHide={handleSalesClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Sales Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sales Person</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={salesPerson}
                onChange={(e) => {
                  setMessageFunction(e, "sales"),
                    setPerson(e.target.value)
                }}
                label="Sales Person"

              >{salesPersons.map((item, index) => (
                <MenuItem value={item} >{item}</MenuItem>
              ))}


              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleSalesClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* sending Mail Model */}

      <Modal
        show={sendMail}
        onHide={handleCloseMail}
        backdrop="static"
        centered
        size="xl"
        keyboard={false}
        style={{ marginTop: "25px", marginLeft: "9%" }}
      >


        <Modal.Body>
          <div className='p-1'>

            <Modal.Header closeButton>
              <Modal.Title>Send Resumes</Modal.Title>
              <Form onSubmit={(e) => handleSendMail(e)}>


                <Button variant="primary" disabled={sendingMail === true ? true : false} style={{ background: "#0d6efd", color: "#ffff", float: "right", marginTop: "-5px", marginLeft: "793px" }} type="submit" >
                  {sendingMail === true ? "Sending.." : "Send"}
                </Button>
              </Form>
            </Modal.Header>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 sales-styles'>

                  <div className="sales-style">
                    <Form>
                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>
                          From * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Name * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Full Name" name="fullname" onChange={changeHandler} value={fullname} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2} >
                          To * :
                        </Form.Label>
                        <Col sm={10}>

                          <Col sm={10}>
                            <Form.Control type="name" placeholder="Full Name" name="fullname" value={toEMail} />
                          </Col>


                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Sub * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Subject" name="subj" value={subj} onChange={changeHandler} />
                        </Col>
                      </Form.Group>



                    </Form>
                    {sessionStorage.getItem("email") !== "tj@jobsnprofiles.com" ?
                      <div>

                        <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

                        <Form>
                          <Row className="align-items-center">
                            <Col xs="3" lg="12">
                              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                Job Title
                              </Form.Label>
                              <Form.Control
                                className="mb-2"
                                id="inlineFormInput"
                                value={jobtitle}
                                placeholder="Job Title"
                                name="jobtitle" onChange={changeHandler}
                              />
                            </Col>
                            <Col xs="3" lg="12">
                              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                Skills
                              </Form.Label>
                              <Form.Control
                                className="mb-2"
                                id="inlineFormInput"
                                placeholder="Skills"
                                name="skillname" onChange={changeHandler}
                              />
                            </Col>
                            <Col xs="3" lg="12">
                              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                Location
                              </Form.Label>
                              <Form.Control
                                className="mb-2"
                                id="inlineFormInput"
                                placeholder="Location"
                                name="location" onChange={changeHandler}
                              />
                            </Col>

                          </Row>
                        </Form>

                        <div className="mt-4" style={{ display: "flex", float: "right", }}>
                          <div>
                            <DropdownButton id="dropdown-basic-button" title={date ? date : "Date"} style={{ float: "right", }} >
                              <Dropdown.Item onClick={(e) => handleDateChage('Today')}>Today</Dropdown.Item>
                              <Dropdown.Item onClick={(e) => handleDateChage('Last Week')}>Last Week</Dropdown.Item>
                            </DropdownButton>
                          </div> &nbsp;&nbsp;
                          <div>

                            <Form onSubmit={(e) => handleSearch(e)}>
                              <Button type="submit" style={{ float: "right", background: "#0d6efd", color: "#ffff" }} variant="primary">
                                Search
                              </Button>
                            </Form>
                          </div>
                        </div>

                      </div>

                      : ""}
                  </div>

                </div>


                <div className='col-md-6 sales-styles' >
                  <Modal size="lg" show={showResume} onHide={handleClose} centered>
                    <Modal.Body>
                      <Container>
                        <Row>
                          <Col xs={12} md={12}>
                            <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                          </Col>

                        </Row>


                      </Container>
                    </Modal.Body>

                  </Modal>

                  {sessionStorage.getItem("email") !== 'tj@jobsnprofiles.com' ?

                    <div className=" positions-scroll">
                      {profileResumes.length > 0 ? <h4>These are the active RESUMES for your requirement {data.jobtitle} </h4> : <h4>Search for resumes</h4>}

                      <br />
                      {profileResumes.length > 0 ? profileResumes.map((resume) => {
                        return (
                          <>
                            <div className="job-titile" style={{ padding: "5px" }}>
                              <Row className="job-checkbox">
                                <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                  id="check"
                                  checked={resume?.isChecked}
                                  onClick={(e) => handleChange(e, resume.id)} /></Col>
                                <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                              </Row>


                              <ul>
                                <li> <FaSuitcase /> {resume.skillName
                                  ? resume.skillName
                                    .join(",")
                                    .split(",")
                                    .join(", ")
                                    .slice(0, 100)
                                  : "N/A"}</li>

                                <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                                <li style={{ float: "right" }}> <FaCalendarAlt />

                                  {resume.lastmodified ? moment(resume.lastmodified).format("LL") : "N/A"
                                  }
                                </li>


                              </ul>
                            </div>
                            <hr style={{ width: "100%" }} />
                          </>)

                      }) : <center style={{ marginTop: "50px" }}> <h4>No Resumes</h4> </center>
                      }



                    </div>
                    : parse(`<html><body><div class="container"><div><p> Hi ${fullname}, </p><p> 👋 What's up! 😀 How's it going today? 😊 I'm interested in connecting with you. 👉 Recently, you inquired about our sweet company.</p><p> 🤵 I am the Sales Director at <a href="https://jobsnprofiles.com/" target="_blank">JobsNProfiles.com</a>. 🌐 We help companies find candidates faster and more effectively with AI-based resume scrubbing software. 💼 Would you be interested in learning more about how it works or how much time it can save you? ⏱</p><p>Always Here To Help, Call me, Text me, or Email me anytime.</p><p>"A Marvelous Day Awaits!"</p><br/><img src=${TJsign} /></div><br /></div></body></html>`)}

                  <br />


                </div>
              </div>

            </div>

          </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={showfile}
        onHide={handleClosefile}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "50vw" }} method="post" enctype="multipart/form-data" action="/upload" >
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosefile}>
            Close
          </Button>
          <Button variant="primary" onClick={handleXlUpload}>Upload</Button>
        </Modal.Footer>
      </Modal>
      <div style={{ marginTop: "-60px", boxShadow: "0px 0px 5px black" }}>
        <MUIDataTable
          title={<div>
            <div className='d-flex align-items-center'>
              <MdRefresh style={{ cursor: "pointer" }} onClick={() => { getData() }} />

              <h4>Leads List</h4>

            </div>
          </div>}
          data={resutlData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  )
}

export default Salesmanagerleads