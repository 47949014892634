import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';
import Key from '../clientVariables.json'


const DisplayingErrorMessagesSchema = Yup.object().shape({
    email: Yup.string().required("Email is Required"),


});




export const Forgotpassword = (props) => {



    
const sendForgotMail = (values) => {
    console.log(values)
    axios
        .post(`${Key.domain}/admin/forgotpassword_mail`, {
            "email": values.email
        })
        .then((response) => {
            console.log(response.data)

            const msg = "Mail sent successfully" 
            toast(
                <ToastMessage message={msg} />
            )
            window.location.href = "/login"



        })
}


    return (
        <div>

            <Formik
                initialValues={{
                    email: '',

                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={values => {

                    sendForgotMail(values)
                    console.log(values);
                }}
            >
                {({ errors, touched }) => (
                    <div >
                        <Form>
                            <div className="form-group  mb-3">

                                <div className='col-md-12'>
                                    <Field
                                        type='email'
                                        name="email"
                                        placeholder="Email"
                                        autocomplete="off"
                                        className={`mt-2 form-control
                       ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                    />

                                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                </div>
                            </div>


                            <button type="submit" className="btn btn-primary btn-block">
                                Submit
                            </button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    )

};
export default Forgotpassword;