import Key from '../clientVariables.json'

export const ENDPOINT = {
    getLeads: `${Key.domain}/dashboard/get_admin_d3e_Leads`,
    postLeads: `${Key.domain}/dashboard/post_Excel_leadsData`,
    getLevel2Packages: `${Key.domain}/customer/get_all_emp_level2_packages`,
    addNewPackage: `${Key.domain}/customer/add_New_Package`,
    updatePackage: `${Key.domain}/customer/updatePackage`,
    packageResetToDefault: `${Key.domain}/customer/package_Reset_To_Default`,
    deletePackage: `${Key.domain}/customer/delete_package`,
    getSubscribers: `${Key.domain}/customer/get_subscribers`,
    getUsersOnlineStatus: `${Key.domain}/dashboard/get_user_login_status`,
    SolrResumes: `${Key.domain}/solr/solr_resumes`,
    SolrJobs: `${Key.domain}/solr/solr_jobs`,
    UpdateSkills: `${Key.domain}/configuration/insert_or_update_skills`,
    UpdateVisatypes: `${Key.domain}/configuration/insert_or_update_visa`,
    UpdateJobtypes: `${Key.domain}/configuration/insert_or_update_jobtype`,
    getSharedMailReports: `${Key.domain}/customer/get_reports_for_shared_mails`,
    addSalesPerson: `${Key.domain}/common/insert_salesperson`,
    updateSalesPerson: `${Key.domain}/configuration/insert_or_update_salesperson`,
    getJobFairLeads: `${Key.domain}/dashboard/get_JobFair_Leads`,


}