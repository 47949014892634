import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Productsmenu from './Dashboardproductsmenu';
// components
import Iconify from '../../components/Iconify';
//

import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  // boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: `calc(100% - ${100}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    // minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const location = useLocation();
  const history = useNavigate();
  console.log(location);
  const redirctFunction = () => {
    history.push('/dashboard/app');
  };
  return (
    <RootStyle style={{ height: '80px' }}>
      <ToolbarStyle style={{display:'flex', justifyContent:'space-between'}}>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* {location.pathname === '/dashboard/CompaniesList' ? '' : <Searchbar />} */}

        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} style={{ 
          // height: '30px'
           }}>
          {/*  <LanguagePopover /> */}

          {/*  <Badge badgeContent={4} color="secondary">
  <MailIcon color="action" />
</Badge>
*/}
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
