/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { Viewer } from '@react-pdf-viewer/core';
import { themePlugin } from '@react-pdf-viewer/theme';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Worker } from '@react-pdf-viewer/core';
//import pdfFile from '../pdf/document.pdf'
// import './document.css'
import Key from '../clientVariables.json'
import axios from 'axios'

const DocumentNew = (props) => {
  //var defaultfilename = props.resumeDetails.filename.split('.').slice(0, -1).join('.') + ".pdf"
  const [defaultPdfFile, setDefaultFile] = useState(`${Key.domain1}/MyFiles/resume_attachments/${props.selectresumeFileDetails.uid}/${props.selectresumeFileDetails.resume_id}/${props.selectresumeFileDetails.profile_path !== undefined ? props.selectresumeFileDetails.profile_path : props.selectresumeFileDetails.profile_path}`)
  const [isFileFound, setFileFound] = useState(true)
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const themePluginInstance = themePlugin();
  return (
    isFileFound === true ?
      <div className='pdf-container' style={{ height: "70vh", width: "100%" }}>
        {/* show pdf conditionally (if we have one)  */}
        {defaultPdfFile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={defaultPdfFile}
            plugins={[defaultLayoutPluginInstance, themePluginInstance]} theme="dark" />
        </Worker></>}

      </div> : <div></div>
  )
}

export default DocumentNew