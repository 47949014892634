/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment'
import { MdLocationOn, MdLocalPhone, MdEmail, MdDelete } from "react-icons/md";
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { MdRefresh } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import axios from 'axios'
import Key from "../clientVariables.json";
import ToastMessage from "../components/ToastMessage";
import Button from 'react-bootstrap/Button';
import request from 'src/utils/request';
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';
import Resumeviewer from "./ResumeViewer"
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { Checkbox } from '@mui/material';
import MUIDataTable from "mui-datatables";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./Notes.css"
import { FaLinkedin } from "react-icons/fa";

function LiveJobs() {
    const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

    const [data, setData] = useState({
        fromEmail: "hot-resumes@jobsnprofiles.com",
        fullname: '',
        toEMail: '',
        ccEMail: '',
        subj: '',
        body: mailbody,
        jobtitle: '',
        skillname: '',
        location: 'Remote',
        toUserId: '',
        jobId: '',
    })
    const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location, jobId, toUserId } = { ...data }
    const [jobsList, setJobsList] = useState([]);
    const [profileResumes, setProfileResumes] = useState([]);
    const [selectedResIds, setSelectedResids] = useState([]);
    const [resumeChecked, setResumeChecked] = useState();
    const [selectedResumes, setSelectedResumes] = useState([]);
    const [firstResume, setFirstResume] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [skills, setSkills] = useState("");
    const [reslocation, resSetLocation] = useState("");

    const [arrayIds, setArrayIds] = useState([]);
    const [sent, setSent] = useState([]);
    const [selectedJobID, setSelectedJobID] = useState("");
    const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
    const [resumeIds, setResumeIds] = useState([]);
    const [showResume, setShowResume] = useState(false)
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [shortRegion, setShortRegion] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [showReports, setShowReports] = useState(false)
    const [reports, setReports] = useState([])
    const [resumesShared, setResumesShared] = useState([])
    const [companyName, setCompanyName] = useState("")
    const [showLinkedInProfiles, setShowLinkedInProfiles] = useState(false)
    const [job_title, setJob_title] = useState("")
    const [linkedInProfiles, setLinkedInProfiles] = useState([])
    console.log(sent)




    const MINUTE_MS = (1000 * 60) * 15;

    useEffect(() => {
        const interval = setInterval(() => {
            getResumesDataList();
        }, MINUTE_MS);

        return () => clearInterval(interval);

    }, [])





    useEffect(() => {
        getResumesDataList();
    }, [])


    const getLinkedInProfiles = async () => {
        setShowLinkedInProfiles(!showLinkedInProfiles);
        if (showLinkedInProfiles === false) {
            await fetch(`${Key.domain}/common/get_rolebased_linkedinProfiles`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ jobTitle: job_title }),
            })
                .then((response) => {
                    response.json().then((resdata) => {
                        setLinkedInProfiles(resdata.data)
                    })
                })
        } else {
            getResumes()
        }

    }

    const getResumesDataList = () => {
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?fq=posted_flag:(1)&q=*%3A*&fq=status:(1)&sort=created%20desc`

        var jobidss = []
        console.log(searchResumesUrl)
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    setJobsList(resdata.data.response.docs)
                    setFirstResume(resdata.data.response.docs[0].title)
                    console.log(resdata.data.response.docs)
                    resdata.data.response.docs.map((job) => {
                        console.log(job.id)
                        arrayIds.push(job.id)
                    })
                    setFirstResume(resdata.data.response.docs[0].title)
                    setSelectedJobID(resdata.data.response.docs[0].id)

                    const jobtitle = resdata.data.response.docs[0].title
                    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${jobtitle !== '""' ? jobtitle : "* TO *"})&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=10&start=0&wt=json`;

                    console.log(searchResumesUrl);

                    fetch(`${Key.domain}/solr/solr_resumes`, {
                        method: "POST",

                        headers: {
                            Accept: "application/json",

                            "Content-Type": "application/json",
                        },

                        body: JSON.stringify({ url: searchResumesUrl }),
                    }).then((response) => {
                        response.json().then((resdata) => {
                            console.log(resdata)
                            setProfileResumes(resdata.data.response.docs);
                            setData({ ...data, toEMail: resdata.data.response.docs[0].email_address, fullname: resdata.data.response.docs[0].full_name, toUserId: resdata.data.response.docs[0].uid, jobId: resdata.data.response.docs[0].id })

                        });
                        fetch(`${Key.domain}/customer/get_suggested_res_mail_sent_status`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                admin_id: sessionStorage.getItem("id"),
                                session_token: sessionStorage.getItem("userToken"),
                                job_ids: arrayIds,

                            }),
                        })
                            .then((response) => {

                                response.json().then((resdata) => {

                                    var arr = [];
                                    arr = resdata.data

                                    const mapped = arr.map((obj, index) => obj.jobid);
                                    const filtered = mapped.filter((type, index) => mapped.indexOf(type) === index)

                                    setSent(filtered)


                                    // console.log(resdata.data)
                                })
                            })
                    });
                })
            })

    }

    const handleLinkedInPrChange =(e, resumeId)=>{
        setResumeIds(prevArray => {
            // Check if the ID already exists in the array
            if (!prevArray.includes(resumeId)) {
                // Create a new array by spreading the previous array and appending the new ID
                return [...prevArray, resumeId];
            }
            return prevArray; // If the ID already exists, return the previous array without any changes
        })
        const { name, value, checked } = e.target;
        let filteredobjs = []
        if (name === String(resumeId)) {
            console.log("comes here", resumeId)
            const newResumes = linkedInProfiles.map((list) =>
                list.id === resumeId ? { ...list, isChecked: checked } : list
            );
            setLinkedInProfiles(newResumes);
            console.log(newResumes)
            const obj = newResumes.filter((x) => x.isChecked === true);
            filteredobjs = obj.filter((x) => x.profile_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
        }
    }


    const handleChange = (e, resumeId) => {
        setResumeIds(prevArray => {
            // Check if the ID already exists in the array
            if (!prevArray.includes(resumeId)) {
                // Create a new array by spreading the previous array and appending the new ID
                return [...prevArray, resumeId];
            }
            return prevArray; // If the ID already exists, return the previous array without any changes
        })
        const { name, value, checked } = e.target;
        let filteredobjs = []
        if (name === String(resumeId)) {
            console.log("comes here", resumeId)
            const newResumes = profileResumes.map((list) =>
                list.id === resumeId ? { ...list, isChecked: checked } : list
            );
            setProfileResumes(newResumes);
            console.log(newResumes)
            const obj = newResumes.filter((x) => x.isChecked === true);
            filteredobjs = obj.filter((x) => x.application_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
            // setProfileResumes(filteredobjs);
        }

    };

    const handleClose = () => {
        setShowResume(false)
        setShowReports(false)
    }

    const handleSendMail = (e) => {
        e.preventDefault();

        // var jobtitle = '"' + data.jobtitle + '"'
        console.log(profileResumes)
        console.log(selectedResumes)

        if (toEMail && fromEmail && fullname) {
            if (selectedResumes.length > 8) {


                const msg = "Maximum limit is to share resumes is 5";
                toast(<ToastMessage message={msg} />);

            } else if (selectedResumes.length > 0) {
                fetch(`${Key.domain}/common/send_livejob_resumes_mail`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes }),
                })
                    .then((response) => {
                        response.json().then((resdata) => {
                            console.log(resdata)
                            if (resdata.data === "Mail sent") {
                                fetch(`${Key.domain}/customer/save_suggested_resumes_mail`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        admin_id: sessionStorage.getItem("id"),
                                        session_token: sessionStorage.getItem('userToken'),
                                        jobid: data.jobId,
                                        fromUserMail: data.fromEmail,
                                        fromUserId: sessionStorage.getItem("id"),
                                        toUserMail: data.toEMail,
                                        toUserId: data.toUserId,
                                        sharedResumeIds: resumeIds
                                    }),
                                }).then((response) => {
                                    console.log(response)
                                    const msg = "Mail Sent Successfully";
                                    toast(<ToastMessage message={msg} />);
                                    window.location.reload()
                                })

                            }
                        })
                    })
            } else {
                const msg = "Select atleast 1 resume";
                toast(<ToastMessage message={msg} />);

            }
        }
    }
    const redirectTOJNP = (postedJob) => {
        // alert("hello")
        const url = `${Key.JNP_URL}/job/view/${sessionStorage.getItem("id")}/${sessionStorage.getItem("userToken")}/Job_${postedJob.id}`
        console.log(url.split("Job_"))
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
    }
    const getResumes = (postedJob) => {
        setShowLinkedInProfiles(false)
        const jobtitle = postedJob.title
        setJob_title(jobtitle)
        // console.log(postedJob)



        setSelectedJobID(postedJob.id)
        setData({ ...data, toEMail: postedJob.contactemail, fullname: postedJob.contactname, toUserId: postedJob.uid, jobId: postedJob.id })
        // Get matched Profiles for selected posted Job
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '' ? '"' + jobtitle + '"' : "* TO *"}&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=10&start=0&sort=created%20desc&wt=json`;

        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({ url: searchResumesUrl }),
        }).then((response) => {
            response.json()
                .then((resdata) => {
                    console.log(resdata.data.response.docs);
                    if (resdata.data.response.docs.length === 0) {
                        showAlternateResults(jobtitle)
                    } else {
                        setProfileResumes(resdata.data.response.docs);

                    }
                })

        }).catch((error) => {
            console.log(error)
        })
    }



    const showAlternateResults = async (jobtitle) => {
        let title1 = jobtitle.replace(/['"]/g, '')
        let title = title1.replace(' / ', '/')

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title})&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=${10}&start=${0}&wt=json`;
        const result = await request(`${ENDPOINT['SolrResumes']}`,
            postOptions({
                url: searchResumesUrl,
            }))
        console.log(result.data.response.docs)
        setProfileResumes(result.data.response.docs);


    }


    const getReports = async () => {
        axios.get(`${Key.domain}/customer/get_reports_for_shared_mails`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        })
            .then(response => {
                var data = response.data.data
                console.log(data)
                setReports(data);

                fetch(`${Key.domain}/customer/get_resumes_details_by_jobid`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        admin_id: sessionStorage.getItem("id"),
                        session_token: sessionStorage.getItem('userToken'),
                        job_id: data[0].jobid
                    }),
                }).then((response) => {
                    response.json().then((resdata) => {
                        console.log(resdata)
                        var data = resdata.data
                        console.log(data)
                        setResumesShared(data);

                    })
                })
            })
    }


    const filterJobsByDate = async () => {
        const inputFromDate = fromDate;
        const inputToDate = toDate;
        const inputFormat = 'MMMM D, YYYY h:mm A';

        // Convert the input date to UTC
        const utcFromDate = moment.utc(inputFromDate, inputFormat);
        const utcToDate = moment.utc(inputToDate, inputFormat);

        // Output the UTC date string
        const fromdate = utcFromDate.format()
        const todate = utcToDate.format()
        console.log(fromdate, todate)
        var mappeddate = fromdate + ' TO ' + todate
        if (mappeddate === 'Invalid date TO Invalid date') {
            mappeddate = "* TO *"
        }
        const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?fq=companyName:(${companyName !== "" ? companyName : "*:*"})&fq=created:[${mappeddate ? mappeddate : "* TO *"}]&fq=posted_flag:(1)&sort=created%20desc&start=${0}&wt=json`;
        const result = await request(`${ENDPOINT['SolrJobs']}`,
            postOptions({
                url: searchJobsUrl,
            }))
        setJobsList(result.data.response.docs);
        result.data.response.docs.map((job) => {
            console.log(job.id)
            arrayIds.push(job.id)
        })

    }

    const getSearchResumes = () => {
        let title = searchValue
        if (title.includes(" / ")) {
            title = title.replace(' / ', '/')
        }

        if (reslocation.includes(',')) {
            const location = reslocation !== "" ? reslocation.split(/[,]+/) : "";

            if (location[1].replace(' ', '').length === 2) {
                var cityName = location[0]
                var stateName = ""
                var shortRegion = location[1]
            } else {
                cityName = location[0]
                stateName = location[1]
            }


        } else if (reslocation.length === 2) {
            shortRegion = reslocation
        } else {
            stateName = reslocation

        }

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title !== undefined && title !== '""' ? '"' + title + '"' : "*%3A*"}) OR id:(${title ? title : "*%3A*"})&fq=${skills}&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=10&start=0&sort=created%20desc&wt=json`;
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: "POST",

            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({ url: searchResumesUrl }),
        }).then((response) => {
            response.json().then((resdata) => {
                console.log(resdata.data.response.docs);
                setProfileResumes(resdata.data.response.docs);
            });
        });
    }


    const columns = [

        {
            name: "title",
            label: "Job Title",


        },

        {
            name: "company_name",
            label: "Company Name",


        },

        {
            name: "created",
            label: <span>Posted Date</span>,

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("LLL")
                    // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

                },
            }
        },
    ]

    const ResumeColumns = [

        {
            name: "id",
            label: "ID",


        },

        {
            name: "application_title",
            label: "Title",


        },
        {
            name: "full_name",
            label: "Name",


        },

        {
            name: "last_modified",

            label: <span>Last Modified</span>,

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("LLL")
                    // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

                },
            }
        }
    ]

    const resumeOptions = {
        tableBodyHeight: '40vh',
        selectableRows: false,
        viewColumns: false,
        responsive: 'stacked',
        print: false,
        search: true,

    }

    const reportsOptions = {
        tableBodyHeight: '40vh',
        selectableRows: false,
        viewColumns: false,
        responsive: 'stacked',
        print: false,
        search: true,
        onCellClick: (cellIndex, rowIndex) => {
            // console.log(cellIndex.value);
            // console.log(rowIndex.dataIndex);
            // console.log(rowIndex);

            var row = reports[rowIndex.dataIndex];



            if (rowIndex.colIndex === 0) {

                fetch(`${Key.domain}/customer/get_resumes_details_by_jobid`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        admin_id: sessionStorage.getItem("id"),
                        session_token: sessionStorage.getItem('userToken'),
                        job_id: row.jobid
                    }),
                }).then((response) => {
                    response.json().then((resdata) => {
                        console.log(resdata)
                        var data = resdata.data
                        console.log(data)
                        setResumesShared(data);

                    })
                })


            }
        },

    }
    return (
        <div className='card p-1' style={{ marginTop: "-60px", boxShadow: "0px 5px 10px black" }}>


            <div className='container containerOne' >
                <div className='row'>
                    <h4 className="mb-3">Posted Jobs</h4>
                    <div className='col-12 row'>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>From : hot-resumes@jobsnprofiles.com</p>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>Name : {fullname}</p>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>To : {toEMail}</p>
                        {/* <p className='col-7 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>Subject : </p>*/}
                        <Form className='col-2 m-0 align-self-center' onSubmit={(e) => handleSendMail(e)}>


                            <Button variant="primary" type="submit" style={{ float: "right", }}
                            //disabled={sent.includes(parseInt(selectedJobID))}
                            >
                                Share Mail
                            </Button>
                        </Form>


                    </div>
                    <div className='col-md-6 mt-5' style={{ marginTop: "10%" }}>

                        <Modal size="lg" show={showReports} onHide={handleClose} centered
                            // style={{ marginLeft: "8%", marginTop:"100px"}}
                            style={{ paddingTop: '80px' }}
                        >
                            <Modal.Header closeButton />
                            <Modal.Body className="mt-4">
                                <Container>
                                    <Row>
                                        <Col xs={12} md={12} style={{ cursor: "pointer" }}>
                                            <MUIDataTable
                                                title={"Reports"}
                                                data={reports}
                                                columns={columns}
                                                options={reportsOptions}
                                            />
                                        </Col>
                                        <Col xs={12} md={12} style={{ marginTop: "5px", cursor: "pointer" }}>
                                            <MUIDataTable
                                                title={"Resumes"}
                                                data={resumesShared}
                                                columns={ResumeColumns}
                                                options={resumeOptions}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal>

                        <div className="sales-styles positions-scroll" style={{ overflow: "auto" }}>
                            <div className="d-flex flex-row justify-content-between" >
                                <b>Latest jobs (Posted Manually)</b>
                                <TbReportSearch style={{ cursor: "pointer", marginLeft: "47%", boxShadow: "0px 0px 5px black" }} onClick={() => { setShowReports(true); getReports() }} />
                                <MdRefresh style={{ cursor: "pointer", float: "right", boxShadow: "0px 0px 5px black" }} onClick={() => { getResumesDataList() }} />
                            </div>
                            <div >
                                <br />
                                <InputGroup className="mb-2">
                                    <Form.Control
                                        placeholder="Company Name"
                                        aria-label="Company Name"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                    <Form.Control
                                        placeholder="Jan 25 2023"
                                        aria-label="Search Resumes"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                    <Form.Control
                                        placeholder="May 15 2023"
                                        aria-label="Search Resumes"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setToDate(e.target.value)}
                                    />

                                    <Button variant="outline-secondary" id="button-addon2"
                                        onClick={() => filterJobsByDate()}>
                                        Search
                                    </Button>
                                </InputGroup>
                                <br />
                                {jobsList.map((each) => (
                                    <div className="card mb-1 p-2 pb-0 pt-0" onClick={(e) => { getResumes(each) }} style={{ cursor: "pointer", border: `${selectedJobID === each.id ? "2px solid #0d6efd" : ""}` }}>
                                        <div style={{ height: each.title && each.title.length > 35 ? "43px" : "26px" }} className='pb-0 d-flex flex-row justify-content-between text-center' ><span ><button onClick={() => redirectTOJNP(each)} style={{ color: "#0d6efd", marginBottom: "0px", border: "none", background: "transparent", textAlign: each.title && each.title.length > 30 ? "justify" : "left" }}><b>{each.title}</b></button>

                                        </span>
                                            <span style={{ marginTop: "-6px" }}> {sent.map((jobs) => {

                                                return parseInt(jobs) === parseInt(each.id) ? <><span><Checkbox size="small" checked /> </span></> : ""
                                            })}</span>

                                        </div>
                                        <div style={{ height: "27px" }} className='d-flex flex-row justify-content-between m-0'>
                                            <p>  {(each.companyName) ? each.companyName : "N/A"},&nbsp;
                                                {((each.cityName) && (each.stateName)) ? `${each.cityName}, ${each.stateName}, USA` : "Remote"}</p>
                                            <p className="" style={{ fontSize: "12px" }}>{moment(each.created).format("LL")}</p>
                                        </div>


                                    </div>
                                ))}


                            </div>
                        </div>

                    </div>


                    <div className='col-md-6 mt-4'>
                        <Modal size="xl" show={showResume} onHide={handleClose} centered style={{ marginLeft: "8%", height: "110%" }}>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal>
                        <div className="sales-styles positions-scroll">
                            <div >
                                <h6 className='mb-2'>Suggested RESUMES</h6>
                                <Form.Check aria-label="option 1" 
                                style={{float:"right"}}
                                    id="check"
                                    checked={showLinkedInProfiles}
                                    label="LinkedIn Profiles"
                                    onChange={(e) => { getLinkedInProfiles() }} />
                            </div>
                            <InputGroup className="mb-2">
                                <Form.Control
                                    placeholder="Resume Title"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <Form.Control
                                    placeholder="Skills"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => setSkills(e.target.value)}
                                />
                                <Form.Control
                                    placeholder="Location"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => resSetLocation(e.target.value)}
                                />
                                <Button variant="outline-secondary" id="button-addon2"
                                    onClick={() => getSearchResumes()}>
                                    Search
                                </Button>
                            </InputGroup>


                            {
                                showLinkedInProfiles === true ?

                                    linkedInProfiles.length > 0 ? linkedInProfiles.map((resume) => {
                                        return (
                                            <>
                                                <div className="job-titile" style={{ padding: "5px" }}>
                                                    <Row className="job-checkbox">
                                                        <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                                            id="check"
                                                            checked={resume?.isChecked}
                                                            onClick={(e) => handleLinkedInPrChange(e, resume.id)} /></Col>
                                                        <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                                                    </Row>


                                                    <ul>
                                                        <li> <FaSuitcase /> {resume.skillName
                                                            ? resume.skillName
                                                                .split(",")
                                                                .join(", ")
                                                                .slice(0, 100)
                                                            : "N/A"}</li>

                                                        <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                                                        <li style={{ float: "right" }}> <FaCalendarAlt />

                                                            {resume.last_modified ? moment(resume.last_modified).format("LL") : "N/A"
                                                            }
                                                        </li>


                                                    </ul>
                                                </div>
                                                <hr style={{ width: "100%" }} />
                                            </>)

                                    }) : <center style={{ marginTop: "50px" }}> <h4>No LinkedIn Profiles</h4> </center>
                                    :

                                    profileResumes.length > 0 ? profileResumes.map((resume) => {
                                        return (
                                            <>
                                                <div className="job-titile" style={{ padding: "5px" }}>
                                                    <Row className="job-checkbox">
                                                        <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                                            id="check"
                                                            checked={resume?.isChecked}
                                                            onClick={(e) => handleChange(e, resume.id)} /></Col>
                                                        <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                                                    </Row>


                                                    <ul>
                                                        <li> <FaSuitcase /> {resume.skillName
                                                            ? resume.skillName
                                                                .join(",")
                                                                .split(",")
                                                                .join(", ")
                                                                .slice(0, 100)
                                                            : "N/A"}</li>

                                                        <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                                                        <li style={{ float: "right" }}> <FaCalendarAlt />

                                                            {resume.lastmodified ? moment(resume.lastmodified).format("LL") : "N/A"
                                                            }
                                                        </li>


                                                    </ul>
                                                </div>
                                                <hr style={{ width: "100%" }} />
                                            </>)

                                    }) : <center style={{ marginTop: "50px" }}> <h4>No Resumes</h4> </center>


                            }

                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default LiveJobs