/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './Notes.css';
import Key from '../clientVariables.json';
import { MdOutlineNoteAdd } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-tooltip-lite';
import Avatar from '../companyimg.png';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment'

const CustomerNotes = (props) => {
  const { currentUser, customerId } = props;
  const [show, setShow] = useState(false);
  const [customerNoteData, setCustomerNoteData] = useState([]);
  const [note, setNote] = useState("")
  const [customerProfileImg, setCustomerProfileImg] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //   console.log(currentUser,customerId)

  useEffect(() => {
    getCustomerNotes()
    getCustomerProfileImage()

  }, []);
  const getCustomerProfileImage = () => {
    axios
      .post(`${Key.domain}/customer/cust_profile_image`, {
        user_id: sessionStorage.getItem('id'),
        session_token: currentUser.session_token,
        customer_id: customerId,
      })
      .then((res) => {
        console.log(res.data.data);
        const imageName = res.data.data
        setCustomerProfileImg(res.data.data, imageName)

      });
  }
  const getCustomerNotes = () => {
    axios
      .post(`${Key.domain}/customer/get_emp_notes`, {
        user_id: sessionStorage.getItem('id'),
        session_token: currentUser.session_token,
        customer_id: customerId,
      })
      .then((res) => {
        console.log(res.data.data);
        setCustomerNoteData(res.data.data);
      });
  }

  const sendNotification = () => {
    var notificationType = "crm_notes"
    var notificationSubject = "You have a note from Admin"
    axios
      .post(`${Key.domain}/customer/create_notification`, {
        user_id: sessionStorage.getItem("id"),
        notifiedto: customerId,
        notificationDescription: "",
        notificationSubject: notificationSubject,
        job_id: 0,
        notificationType: notificationType
      }).then(res => {
        console.log(res)
      })
  }
  const handleSendNote = e => {
    e.preventDefault()
    console.log("send note", note)
    handleClose();
    if (note !== "") {
      axios
        .post(`${Key.domain}/customer/post_emp_notes`, {
          session_token: currentUser.session_token,
          user_id: sessionStorage.getItem("id"),
          customer_id: customerId,
          msg_type: "2",
          note: note,
        }).then(res => {
          console.log(res)
          setNote("")
          getCustomerNotes()
          sendNotification()
        })
    }


  }


  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSendNote}>
                <div className="chat-message-form">
                  <div className="form-grouping">
                    <textarea
                      className="form-controlling message-input"
                      name="message-boxing"
                      placeholder="Enter message text "
                      value={note}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setNote(e.target.value)

                      }}
                    >
                      &nbsp;
                    </textarea>
                    <Button variant="primary" onClick={handleClose} type="submit" style={{ float: "right", marginTop: "10px" }} disabled={note !== "" ? false : true}>
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}

        </Modal.Footer>
      </Modal>
      <div className="container info">
        <ul class="list-group notes-styles">
          <li class="  d-flex justify-content-start align-items-start">
            <h4 className="heading" style={{ paddingLeft: '12px', fontSize: '22px' }}>
              <b>Queries</b>
            </h4>
          </li>
          <div
            class="modal1"
            style={{ display: 'flex', justifyContent: 'end', marginTop: '-35px' }}
            onClick={handleShow}
          >
            <Tooltip content="Send Message">
              <button type="button" class="btn btn-outline-primary" style={{ border: '#406882' }}>
                <MdOutlineNoteAdd size={22} color="#406882" />
              </button>
            </Tooltip>
          </div>

          <div class="container">
            <div class="contentWrapper wrapper-content animated fadeInRight">
              <div class="row">
                <div class="col-lg-12">
                  <div class="ibox chat-view">
                    <div class="ibox-content">
                      <div class="row">
                        <div class="col-md-12 ">
                          <div class="chat-discussion">
                            {customerNoteData.length > 0
                              ? customerNoteData.map((note) => {
                                return (
                                  <>
                                    {note.msg_type === '2' ? (
                                      <div class="chatting-message right">
                                        <img class="message-avatar"
                                          src={Avatar} alt=""
                                        />
                                        <div class="message-boxing">
                                          <a class="message-author" href="#">
                                            {props.currentUser.full_name}
                                          </a>
                                          <span class="message-date"> {moment(note.created).format("lll")} </span>
                                          <span class="message-content">
                                            {note.note}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div class="chatting-message left">
                                        <img class="message-avatar"
                                          src={customerProfileImg ? (`${Key.domain}/customer/profile_image/` + note.customer_id + "/" + customerProfileImg) : Avatar}
                                          alt=""
                                          style={{ borderRadius: "24px" }}
                                        />
                                        <div class="message-boxing">
                                          <a class="message-author" href="#">
                                            {note.full_name}
                                          </a>
                                          <span class="message-date"> {moment(note.created).format("lll")} </span>
                                          <span class="message-content">
                                            {note.note}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })
                              : ''}
                            {/* <div class="chatting-message left">
                              <img class="message-avatar" src={Avatar} alt="" />
                              <div class="message-boxing">
                                <a class="message-author" href="#">
                                  {' '}
                                  Michael Smith{' '}
                                </a>
                                <span class="message-date"> Mon Jan 26 2015 - 18:39:23 </span>
                                <span class="message-content">
                                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                  euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                                </span>
                              </div>
                            </div>
                            <div class="chatting-message right">
                              <img class="message-avatar" src={Avatar} alt="" />
                              <div class="message-boxing">
                                <a class="message-author" href="#">
                                  {' '}
                                  Karl Jordan{' '}
                                </a>
                                <span class="message-date"> Fri Jan 25 2015 - 11:12:36 </span>
                                <span class="message-content">
                                  Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                                  default model text, and a search for 'lorem ipsum' will uncover.
                                </span>
                              </div>
                            </div>
                            <div class="chatting-message right">
                              <img class="message-avatar" src={Avatar} alt="" />
                              <div class="message-boxing">
                                <a class="message-author" href="#">
                                  {' '}
                                  Michael Smith{' '}
                                </a>
                                <span class="message-date"> Fri Jan 25 2015 - 11:12:36 </span>
                                <span class="message-content">
                                  There are many variations of passages of Lorem Ipsum available, but the majority have
                                  suffered alteration.
                                </span>
                              </div>
                            </div>
                            <div class="chatting-message left">
                              <img class="message-avatar" src={Avatar} alt="" />
                              <div class="message-boxing">
                                <a class="message-author" href="#">
                                  {' '}
                                  Alice Jordan{' '}
                                </a>
                                <span class="message-date"> Fri Jan 25 2015 - 11:12:36 </span>
                                <span class="message-content">
                                  All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as
                                  necessary, making this the first true generator on the Internet. It uses a dictionary
                                  of over 200 Latin words.
                                </span>
                              </div>
                            </div>
                            <div class="chatting-message right">
                              <img class="message-avatar" src={Avatar} alt="" />
                              <div class="message-boxing">
                                <a class="message-author" href="#">
                                  {' '}
                                  Mark Smith{' '}
                                </a>
                                <span class="message-date"> Fri Jan 25 2015 - 11:12:36 </span>
                                <span class="message-content">
                                  All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as
                                  necessary, making this the first true generator on the Internet. It uses a dictionary
                                  of over 200 Latin words.
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
      {/* <div className="container">
    <div className="contentWrapper wrapper-content animated fadeInRight">
     
        <div className="row">
            <div className="col-lg-12">
                <div className="ibox chat-view">
                  
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="chat-discussion">
    
                                    <div className="chatting-message left">
                                        <img className="message-avatar" src={Avatar} alt=""/>
                                        <div className="message-boxing">
                                            <a className="message-author" href="#"> Michael Smith </a>
                                            <span className="message-date"> Mon Jan 26 2015 - 18:39:23 </span>
                                            <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                                                </span>
                                        </div>
                                    </div>
                                    <div className="chatting-message right">
                                    <img className="message-avatar" src={Avatar} alt=""/>
                                        <div className="message-boxing">
                                            <a className="message-author" href="#"> Karl Jordan </a>
                                            <span className="message-date">  Fri Jan 25 2015 - 11:12:36 </span>
                                            <span className="message-content">
                          Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover.
                                                </span>
                                        </div>
                                    </div>
                                    <div className="chatting-message right">
                                    <img className="message-avatar" src={Avatar} alt=""/>
                                        <div className="message-boxing">
                                            <a className="message-author" href="#"> Michael Smith </a>
                                            <span className="message-date">  Fri Jan 25 2015 - 11:12:36 </span>
                                            <span className="message-content">
                          There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.
                                                </span>
                                        </div>
                                    </div>
                                    <div className="chatting-message left">
                                    <img className="message-avatar" src={Avatar} alt=""/>
                                        <div className="message-boxing">
                                            <a className="message-author" href="#"> Alice Jordan </a>
                                            <span className="message-date">  Fri Jan 25 2015 - 11:12:36 </span>
                                            <span className="message-content">
                          All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
                                                    It uses a dictionary of over 200 Latin words.
                                                </span>
                                        </div>
                                    </div>
                                    <div className="chatting-message right">
                                    <img className="message-avatar" src={Avatar} alt=""/>
                                        <div className="message-boxing">
                                            <a className="message-author" href="#"> Mark Smith </a>
                                            <span className="message-date">  Fri Jan 25 2015 - 11:12:36 </span>
                                            <span className="message-content">
                          All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
                                                    It uses a dictionary of over 200 Latin words.
                                                </span>
                                        </div>
                                    </div>
    
                                </div>
    
                            </div>
                           
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div> */}
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

export default connect(mapStateToProps, null)(CustomerNotes);
