/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, getElementsAtEvent } from 'react-chartjs-2';
import { connect } from "react-redux";
import Key from '../clientVariables.json';
import { useLocation, useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);



function Costbreakdown(props) {
    const [resumeStats, setResumesStats] = useState([])
    const chartRef = useRef();
    const location = useLocation();
    const history = useNavigate();
    const data = {
        labels: ['PerDay', 'PerWeek', 'PerMonth',],
        datasets: [
            {
                label: '# of Resumes',
                data: [resumeStats.PerdayresumesCount, resumeStats.PerweekresumesCount, resumeStats.PermonthresumesCount,],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',


                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',

                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        axios
            .post(`${Key.domain}/dashboard/get_resumesUpload_opt_stats`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser.session_token
            })
            .then((resdata) => {
                console.log(resdata.data.data)
                setResumesStats(resdata.data.data)
            }).catch((err) => {

                if (err.response.data.data === "Access denied.. Please Login") {
                    console.log(err.response.data.data)
                }
            });;

    }, [])

    const handleData = (event) => {
        history("/dashboard/resumes", { state: { id: getElementsAtEvent(chartRef.current, event)[0].index, OPT: "YES" } })

        console.log(getElementsAtEvent(chartRef.current, event)[0].index);

    }

    return (
        <div>
            <h4>OPT Resumes Uploaded Count</h4>
            <Pie data={data} ref={chartRef} onClick={handleData} options={{
                legend: { display: true, position: "right" },

                datalabels: {
                    display: true,
                    color: "white",
                },
                tooltips: {
                    backgroundColor: "#5a6e7f",
                },
            }} />
        </div>
    )
}
function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}

export default connect(mapStateToProps, null)(Costbreakdown);
