/* eslint-disable */


import { applyMiddleware, createStore,compose } from "redux";
import rootReducer from "./reducers/rootReducer";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { loadState } from "./localStorage";

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV !== "production") {
  middleware.push(logger);
}

let persistedData = loadState();
export const store = createStore(
  rootReducer,
  persistedData,
  composeEnhancers(applyMiddleware(...middleware))
);
