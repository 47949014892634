/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CreditCardInput from 'react-credit-card-input';
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { icons } from "react-icons/lib";
import { toast } from "react-toastify";
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Key from '../../src/clientVariables.json';
import { connect } from "react-redux";
import ToastMessage from '../components/ToastMessage';




const LoginSchema = Yup.object().shape({
  name: Yup.string()

    .required("Full name is required"),
  cardnumber: Yup.string()
    .min(15, "Card No must be 15 characters ")
    .max(15, "Card No must be 15 characters ")
    .required("Card No is required"),

  month: Yup.string()

    .matches(/(0[1-9]|1[0-2])\/[0-9]{2}$/, " Invalid Date ")

    .required("Expiry is required"),



  cvv: Yup.string()
    .min(3, "Invalid Cvv")
    .max(4, "Invalid Cvv")
    .required("CVV is required"),

});




const Paymentform = (props) => {
  const [cardNo, setCardNo] = useState('');
  const [expiry, setExpiry] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [paymentResponse, setPaymentResponse] = useState({})
   const [isSubmitting, setIsLoading] = useState(false)
  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value;
    setCardNo(cardNumber);
  };


  const handleExpiryChange = (e) => {

    let expdate = e.target.value;
    setExpiry(expdate);
    let expiry = [];
    expiry = expdate.split("/");
    let expmonth = expiry[0];
    setMonth(expmonth);
    let expyear = expiry[1];
    setYear(expyear);

  };

  const handleCVVChange = (e) => {
    const cvv = e.target.value;
    setCvv(cvv);

  };

  const handleSubmit = (event) => {
    setIsLoading(true)
    if (props.packageDetails.Amount !== '0' && props.packageDetails.price !== '0') {
      axios
        .post(`${Key.domain}/payment/make_stripePayment`, {
          "cardNumber": cardNo,
          "month": parseInt(month),
          "year": parseInt(year),
          "cvv": cvv,
          "amount": 1 * 100,
          "description": "Jobs n Profiles Package subscription for" + ' ' + `${props.userDetails.username}`,


        })
        .then((response) => {
          setIsLoading(false)
          console.log(response)
          const retriveData = response;
          setPaymentResponse(retriveData)
          if (response.data.data.msg === "Payment Successful") {
            const transactionresponse = JSON.stringify(response.data.data.transactionresponse.charge);
            const transactionid = response.data.data.transactionresponse.charge;

            // props.showLoader();

            fetch(`${Key.domain}/payment/save_transaction_details`, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              },
              body: JSON.stringify({
                "crm_userid": props.salesPersonDetails.id,
                // "session_token": props.currentUser.session_token,
                "customer_id": props.userDetails.id,
                "customer_email": props.userDetails.username,
                "transactionid": transactionid,
                "transatcionData": transactionresponse,
                "companyDetails": props.companyDetails,
                "salesPersonDetails": props.salesPersonDetails,
                "packageDetails": props.packageDetails,
                "userDetails": props.userDetails,
                "price": props.packageDetails.Amount ? props.packageDetails.Amount : props.packageDetails.price
              })
            })
              .then(response => response.json())
              .then(resdata => {
                console.log(resdata)
                trackStatusUpdateForAdmin()

                props.getPaymentResponse(resdata, retriveData.data.data)
              })
          }

        })
        .catch((error) => {
          setIsLoading(false)
          console.log("comes here")
          console.log(error.response.data.error.type)
          toast(

            <ToastMessage message={error.response.data.error.type} />

          )
        });
    } else {
      fetch(`${Key.domain}/payment/save_transaction_details`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          "crm_userid": props.salesPersonDetails.id,
          // "session_token": props.currentUser.session_token,
          "customer_id": props.userDetails.id,
          "customer_email": props.userDetails.username,
          "transactionid": "",
          "transatcionData": "",
          "companyDetails": props.companyDetails,
          "salesPersonDetails": props.salesPersonDetails,
          "packageDetails": props.packageDetails,
          "userDetails": props.userDetails,
          "price": props.packageDetails.Amount ? props.packageDetails.Amount : props.packageDetails.price
        })
      })
        .then(response => response.json())
        .then(resdata => {
          console.log(resdata)

          props.getPaymentResponse(resdata, null)
        })
    }
  }
  
  const trackStatusUpdateForAdmin = async (flowStatus) => {
    try {

        const response = await axios.post(`${Key.domain}/customer/insert_or_update_subscriptions_trackStatus`, {
            "customer_id": props.userDetails.id,
            "customer_name": props.userDetails.full_name,
            "customer_email": props.userDetails.username,
            "current_package_id": props.packageDetails.package_id,
            "targeted_package_id": props.packageDetails.package_id,
            "status": 1,
            "flow_status": 4,
            "action_status": 0
        })
        if (response.data.success === 1) {

        }
    } catch (error) {
        console.log(error)
    }
}
const isButtonDisabled = !(cardNo && expiry && cvv);
  return (
    <div>
      {props.packageDetails.Amount !== '0' && props.packageDetails.price !== '0' ?

        <form>
          <div className="">
            <div className="row">
              <div className="col-lg-12">
                <div className="credir card">
                  <div className="bg" style={{ backgroundColor: "#0d6efd", padding: "2%", textAlign: "center", color: "#FFF" }}>Credit Card</div>
                </div>

                <div className="card mt-4"><CreditCardInput
                  cardNumberInputProps={{ value: cardNo, onChange: handleCardNumberChange }}
                  cardExpiryInputProps={{ value: expiry, onChange: handleExpiryChange }}
                  cardCVCInputProps={{ value: cvv, onChange: handleCVVChange }}

                  fieldClassName="input"
                />

                </div>
                <Button type="submit" style={{ width: "100%" }} className="btn btn-primary btn-block mt-4" onClick={handleSubmit} disabled={isButtonDisabled}>
                  {isSubmitting ? "Paying..." : "Pay Now"}
                </Button>

                {/* <LoadingButton style={{ width: "100%" }}
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                  onClick={handleSubmit}
                  loading={isSubmitting ? "Paying...." : ""}

                  
                >
                  Pay Now
                </LoadingButton> */}
             


              </div>
            </div>
          </div>
        </form>
        :
        <button style={{ width: "100%" }}
          type="button"
          className="btn btn-primary btn-block mt-4"
          onClick={handleSubmit}
        >
          Confirm
        </button>
      }
    </div>
  )
}


const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Paymentform)
