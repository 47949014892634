/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import "./loading.css"
import ScaleLoader from "react-spinners/ScaleLoader";

class PageLoader extends Component {
    
    render() {
        const { loading } = this.props;

        console.log(loading)
        if (!loading) return null;

        return (
            <div className="loading-container">
                <div className="loading">
                    <ScaleLoader
                        height='45'
                        width='5'
                        color='#406882'
                        loading={loading} size={100} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({ loading: state.loaderReducer.loading });

export default connect(mapStateToProps)(PageLoader);