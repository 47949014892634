import React, { useEffect, useState } from 'react'
import { Grid, Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { connect } from "react-redux";
import axios from "axios"
import AppWidgetSummary from "../sections/@dashboard/app/Accountingwidgetcount"
import Salesrevenue from './Salesrevenue';
import Costbreakdown from "./Coastbreakdown"
import Excecutivemetrics from './Executivemetrics';
import Subscriptionplanmetrix from './Subscriptionplanmetrix';
import Key from "../clientVariables.json"

function AccountingDashboard(props) {
  const [dashboardStats, setDashboardStats] = useState([])

  useEffect(() => {
    axios
      .post(`${Key.domain}/dashboard/get_sales_dashboard_stats`, {
        "sales_userid": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        setDashboardStats(response.data.data)
      })

  }, [])

  return (
    <div>

      <h4 className='mb-4' style={{ paddingLeft: "28px" }}>Sales Dashboard</h4>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Companies" total={dashboardStats.total_Companies} icon={'ant-design:user-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Sales" color="info" total={120} icon={'ant-design:user-outlined'} />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Renuwals" color="warning" total={154} icon={'ant-design:user-outlined'} />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Subscribers" color="success" total={dashboardStats.no_of_subscribers} icon={'ant-design:user-outlined'} />
          </Grid>





          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card>
              <h4 className='mb-4' style={{ paddingLeft: "28px" }}>Excecutive Matrics</h4>
              <Excecutivemetrics />
            </Card>
          </Grid>



          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card>
              <h4 className='mb-4' style={{ paddingLeft: "28px" }}>Subscription Plan Metrix</h4>
              <Subscriptionplanmetrix dashboardStats={dashboardStats}/>
            </Card>
          </Grid>


        </Grid>
      </Container>




    </div>
  )
}


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(AccountingDashboard));
