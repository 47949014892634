import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {AccountTree, AccountTreeOutlined, AccountTreeRounded, Assignment, AssignmentOutlined, Business, Dashboard, DashboardOutlined, ExpandLess, ExpandMore, Flare, Group, GroupOutlined, Groups, GroupsOutlined, Inbox, Inventory2, Inventory2Outlined, ManageAccounts, ManageAccountsOutlined, MultilineChart, OnlinePrediction, OnlinePredictionOutlined, People, PeopleAlt, PeopleAltOutlined, PeopleOutlineOutlined, PictureAsPdf, PictureAsPdfOutlined, Receipt, ReceiptOutlined, ShoppingBag, ShoppingBagOutlined, ShowChart, Work, WorkOutline, WorkspacePremium, WorkspacePremiumOutlined} from '@mui/icons-material';
import { Collapse, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotificationsPopover from './NotificationsPopover';
import AccountPopover from './AccountPopover';

const stylesIcon = {
  color:'#a63755'
}

const nav = [
    // {
    //     title: 'Dashboard',
    //     path: '/dashboard/app',
    //     icon_o: <Dashboard style={stylesIcon}/>,
    //     icon_f: <DashboardOutlined/>,
    //   },
    //   {
    //     title: 'Manage Users',
    //     path: '/dashboard/manageUsers',
    //     icon_o: <ManageAccounts style={stylesIcon}/>,
    //     icon_f: <ManageAccountsOutlined/>,
    
    //   },
      // {
      //   title: 'Recruiters',
    
      //   path: '/dashboard/People',
      //   icon_o: <PeopleAlt style={stylesIcon}/>,
      //   icon_f: <PeopleAltOutlined/>,
    
      // },
      // {
      //   title: 'Company Info',
      //   path: '/dashboard/Companyinfo',
      //   icon_o: <Business style={stylesIcon}/>,
      //   icon_f: <Business/>,
    
      // },
      // {
      //   title: 'Customers',
      //   path: '/dashboard/user',
      //   icon_o: <People style={stylesIcon}/>,
      //   icon_f: <PeopleOutlineOutlined/>,
      // },
      // {
      //   title: 'Subscribers',
      //   path: '/dashboard/Subscriptions',
      //   icon_o: <Groups style={stylesIcon}/>,
      //   icon_f: <GroupsOutlined/>,
      // },
      // {
      //   title: 'Benchsales',
      //   path: '/dashboard/Benchsales',
      //   icon_o: <Group style={stylesIcon}/>,
      //   icon_f: <GroupOutlined/>,
      // },
      {
        title: 'Leads',
        path: '/dashboard/Leads',
        icon_o: <ShoppingBag style={stylesIcon}/>,
        icon_f: <ShoppingBagOutlined/>,
      },
      // {
      //   title: 'Live Jobs M',
      //   path: '/dashboard/Livejobs',
      //   icon_o: <Work style={stylesIcon}/>,
      //   icon_f: <WorkOutline/>,
      // },
      // {
      //   title: 'Live Jobs S',
      //   path: '/dashboard/LivejobsScrapped',
      //   icon_o: <Work style={stylesIcon}/>,
      //   icon_f: <WorkOutline/>,
      // },
      // {
      //   title: 'Ceipal Jobs',
      //   path: '/dashboard/CeipalJobs',
      //   icon_o: <Work style={stylesIcon}/>,
      //   icon_f: <WorkOutline/>,
      // },
      // {
      //   title: 'LinkedIn Profiles',
      //   path: '/dashboard/LinkedInProfiles',
      //   icon_o: <Work style={stylesIcon}/>,
      //   icon_f: <WorkOutline/>,
      // },
      // {
      //   title: 'Jobfair Leads',
      //   path: '/dashboard/JobFairLeads',
      //   icon_o: <Flare style={stylesIcon}/>,
      //   icon_f: <Flare/>,
      // },
      // {
      //   title: 'Sales',
      //   path: '/dashboard/Sales',
      //   icon_o: <ShowChart style={stylesIcon}/>,
      //   icon_f: <ShowChart/>,
      // },
      // {
      //   title:'More...',
      //   sub:[
            
              
      //         {
      //           title: 'Requests',
      //           path: '/dashboard/Requests',
      //           icon_o: <AccountTreeRounded style={stylesIcon}/>,
      //           icon_f: <AccountTreeOutlined/>,
      //         },
      //         {
      //           title: 'Packages',
      //           path: '/dashboard/Package',
      //           icon_o: <Inventory2 style={stylesIcon}/>,
      //           icon_f: <Inventory2Outlined/>,
      //         },
      //         {
      //           title: 'Invoices',
      //           path: '/dashboard/Invoices',
      //           icon_o: <Receipt style={stylesIcon}/>,
      //           icon_f: <ReceiptOutlined/>,
      //         },
      //         // {
      //         //   title: 'Jobseekers',
      //         //   path: '/dashboard/Jobseekers',
      //         //   icon_o: <PeopleAlt style={stylesIcon}/>,
      //         //   icon_f: <PeopleAltOutlined/>,
      //         // },
      //         // {
      //         //   title: 'Jobfair',
      //         //   path: '/dashboard/Jobfair',
      //         //   icon_o: <ShoppingBag style={stylesIcon}/>,
      //         //   icon_f: <ShoppingBagOutlined/>,
      //         // },
      //         // {
      //         //   title: 'Configurartions',
      //         //   path: '/dashboard/Configurations',
      //         //   icon_o: <WorkspacePremium style={stylesIcon}/>,
      //         //   icon_f: <WorkspacePremiumOutlined/>,
      //         // },
      //         // {
      //         //   title: 'Resumes Count',
      //         //   path: '/dashboard/applicationsTitlesList',
      //         //   icon_o: <Assignment style={stylesIcon}/>,
      //         //   icon_f: <AssignmentOutlined/>,
      //         // },
      //         // {
      //         //   title: 'Online Users',
      //         //   path: '/dashboard/onlineUsers',
      //         //   icon_o: <OnlinePrediction style={stylesIcon}/>,
      //         //   icon_f: <OnlinePredictionOutlined  />,
      //         // },
              

      //   ]
      // },

]

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  minHeight: '50px !important'
}));


export default function DashboardSidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openMore, setOpenMore] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [title, setTitle] = React.useState('Dashboard');
  const isMobile = useMediaQuery('(max-width:480px)');

  const i = nav.length;
  const isSelected = (index) => selectedItem === index;

  const moreHandleClick = () => {
    setOpenMore(!openMore);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  

  const handleItemClick = (index, item) => {
    setSelectedItem(index);
    setTitle(item);
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{padding:'6px', 
      background:'#fff', color:'rgba(0,0,0,0.6)'
    }}>
        <Toolbar style={{minHeight:'40px'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <a href='https://jobsnprofiles.com/' target='_blank' without rel="noreferrer">
          <img src='	https://jobsnprofiles.com/static/media/jobsnProfiles.4126fcd86cb7b77f9045.webp' width={isMobile ? 110 : 140} alt='logo jnp' />
          </a> */}
          <Typography variant="h6" noWrap component="div" style={{color:'rgba(0,0,0,0.7)'}}>
             {title}
          </Typography>
          <Box sx={{flexGrow:1}} />
          <NotificationsPopover />
          <AccountPopover />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        <a href='https://jobsnprofiles.com/' target='_blank' without rel="noreferrer">
          <img src='	https://jobsnprofiles.com/static/media/jobsnProfiles.4126fcd86cb7b77f9045.webp' width={140} alt='logo jnp' />
          </a>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {nav.map((item, index) => <>
          {
            item.title === 'More...' 
            ? <>
            {/* <Divider /> */}
            <ListItemButton onClick={moreHandleClick}>
            <ListItemIcon>
              <Inbox />
            </ListItemIcon>
            <ListItemText primary="More" />
            {openMore ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMore} timeout="auto" unmountOnExit>
          {
            item.sub?.map((item, index) => 
            <List component="div" disablePadding 
            selected={isSelected(index+i)}
          onClick={() => handleItemClick(index+i, item.title)}
          sx={{ 
            // backgroundColor: isSelected(index+i) ? '#406882 !important' : 'inherit', 
            color:isSelected(index+i) ? '#a63755 !important' : 'inherit' }}
            >
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate(item.path)}>
              <ListItemIcon>
                {isSelected(index+i) ? item.icon_o : item.icon_f}
              </ListItemIcon>
              <ListItemText primary={`${item.title}`} />
            </ListItemButton>
          </List>
            )
          }
        </Collapse>
            </>
            : <ListItem key={index} disablePadding 
            selected={isSelected(index)}
          onClick={() => handleItemClick(index, item.title)}
          sx={{ 
            // backgroundColor: isSelected(index) ? '#406882 !important' : 'inherit', 
            color:isSelected(index) ? '#a63755 !important' : 'inherit' }}
            >
            <ListItemButton onClick={() => navigate(item.path)} >
              <ListItemIcon>
              {isSelected(index) ? item.icon_o : item.icon_f}
              
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
          }
          </>)}
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      
      </Main>
    </Box>
  );
}