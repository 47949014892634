import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';
import Key from '../clientVariables.json'




const DisplayingErrorMessagesSchema = Yup.object().shape({
    password: Yup.string().required(),

    confirmpassword: Yup.string().required()
        .oneOf([Yup.ref('password')], 'Passwords must match'),
});



export const Forgotpassword = () => {
    const [email, setEmail] = useState("");


    useEffect(() => {


        const Url = window.location.pathname
        const currentUrl = Url.split("/")
        const key = currentUrl[2].split("_")
        const username = key[0]

        setEmail(username)


        axios.post(`${Key.domain}/admin/check_activekey`, { email: username, Active_key: key[1], action: 2 })
            .then(resdata => {
                // console.log(resdata)
                if (resdata.data.success === 1 && resdata.data.data === "Keys doesn't matched") {
                    const msg = "Link expired !"
                    toast(
                        <ToastMessage message={msg} />
                    )


                    setTimeout(() => {
                        window.location.href = "/login"
                    }, 1000);



                } else if (resdata.data.success === 1 && resdata.data.data.affectedRows > 0) {
                    console.log(resdata)


                }
            })


    }, []);



    const updatePassword = (values) => {
        // console.log(values)


        if (values.confirmpassword === values.password) {
            axios.put(`${Key.domain}/admin/update_password`, {
                username: email,
                password: values.confirmpassword
            })
                .then(resdata => {
                    // console.log(resdata)
                    if (resdata.data.success === 1 && resdata.data.data.affectedRows > 0) {
                        // alert("Successfully Changed Password!!")

                        const msg = "Successfully Changed Password!!"
                        toast(
                            <ToastMessage message={msg} />
                        )

                        window.location.href = "/"
                    }
                    else {

                        const msg = "Something went wrong!!"
                        toast(
                            <ToastMessage message={msg} />
                        )
                    }
                })
        } else {
            const msg = "Password's doesn't matched !"
            toast(
                <ToastMessage message={msg} />
            )
        }

    }




    return (
        <div>

            <Formik
                initialValues={{
                    password: '',
                    confirmpassword: '',
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={values => {

                    updatePassword(values)
                    // console.log(values);
                }}
            >
                {({ errors, touched }) => (
                    <div>
                        <Form>
                            <div className="form-group  mb-3">

                                <div className='col-md-12'>
                                    <Field
                                        type='password'
                                        name="password"
                                        placeholder="Password"
                                        autocomplete="off"
                                        className={`mt-2 form-control
                       ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                    />

                                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className="form-group  mb-3">

                                    <Field
                                        type='password'
                                        name="confirmpassword"
                                        placeholder="Confirm Password"
                                        autocomplete="off"
                                        className={`mt-2 form-control
                     ${touched.confirmpassword && errors.confirmpassword ? 'is-invalid' : ''}`}
                                    />

                                    <ErrorMessage component="div" name="confirmpassword" className="invalid-feedback" />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">
                                Submit
                            </button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    )

};
export default Forgotpassword;