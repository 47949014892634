import { useState, useEffect } from 'react';


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Loader from "./components/Loader/loading"

// ----------------------------------------------------------------------

export default function App() {


  const [hideBar, setHideBar] = useState(true)


  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <Loader />
      <ToastContainer
        className="toaster-container"
        position="top-right"
        autoClose={2500}

        hideProgressBar={hideBar}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </ThemeProvider>
  );
}
