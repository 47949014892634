/*eslint-disable*/

import React, { useRef, useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
//import ToastMessage from '../../Components/ToastMessage';
import Key from "../clientVariables.json";
import {Button} from '@mui/material';
import { connect } from 'react-redux';
import { array } from "prop-types";
import Companydetails from "./Companydetails";

const EditCompany = ({companyDetails,closeshowEdit,userDetails,getCustomerDetails}) => {
    const formikRef = useRef()
    const [companyname, setCompanyName] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false);
    const [contactname,setcontactname] = useState("")
    const [email, setemail] = useState("")
    const [description, setDescription] = useState("");
    const [companyUrl, setCompanyURL] = useState("");
    const [ein, setEIN] = useState("");
    const [company_Coordinates, setCompany_coordinates] = useState([]);
    const [incorporationStates, setStatesOfIncorporation] = useState("")
    const [since, setsince] = useState("");
    const [SizeOfCompany, setSizeOfCompany] = useState("")
    const [address1, setAddress] = useState("");
    const [phoneno, setPhoneNo] = useState("");
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [disable ,setdisable] = useState(true);
    const [details,setdetails] = useState([])
   
    

    console.log("compannies", companyDetails)

    useEffect(() => {
        axios
            .get(`${Key.domain1}/commonAPIs/get_all_countries`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
            .then((resdata) => {
                console.log("countries", resdata.data.data);
                setCountries(resdata.data.data);
                getStates(233);
            });






    }, [companyDetails])
    //get cities

    useEffect(() => {
        if (companyDetails.state !== undefined && companyDetails.state !== null && companyDetails.state !== '') {
            getCities(companyDetails.state)
        }
        /* if(companyDetails.length>0){
            details.push(companyDetails)
        }
        if(companyDetails!== undefined || details.length == 0){
            details.push(companyDetails)
        } */

        
    }, [companyDetails])

    const getCities = (stateid) => {


        axios
            .post(`${Key.domain1}/commonAPIs/get_cities_by_stateid`, {
                state_id: stateid,
            })
            .then((response) => {
                console.log(response);
                setCities(response.data.data);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("state", stateid);
                }
            });
        console.log(formikRef.current);
    };

    const getStates = (countryid, values) => {
        console.log(countryid);
        axios
            .post(`${Key.domain1}/commonAPIs/get_states_by_countryid`, {
                country_id: countryid,
            })
            .then((response) => {
                console.log(response);
                setStates(response.data.data);
                if (countryid && countryid === 233) {
                    setStatesOfIncorporation(response.data.data)
                }
                if (formikRef.current) {
                    formikRef.current.setFieldValue("country", countryid || 233);
                }
            });
    };
    

    useEffect(() => {
        if (formikRef.current) {
            formikRef.current.setFieldValue(
                "companyname",
               // userDetails.company_name ?  userDetails.company_name : companyDetails.name
                companyDetails.name ? companyDetails.name :userDetails.company_name
            );
            formikRef.current.setFieldValue(
                "contactname",
                //userDetails.full_name ? userDetails.full_name:  companyDetails.contactname
                companyDetails.contactname ? companyDetails.contactname : userDetails.full_name
            )

            formikRef.current.setFieldValue(
                "email",
                //userDetails.username ? userDetails.username :companyDetails.contactemail
                companyDetails.contactemail ? companyDetails.contactemail : userDetails.username
            );
            formikRef.current.setFieldValue(
                "phoneno",
               // userDetails.phone_number ? userDetails.phone_number :  companyDetails.contactphone
                companyDetails.contactphone ? companyDetails.contactphone : userDetails.phone_number
            );
            formikRef.current.setFieldValue(
                "description",
             companyDetails.description
                // companyDetails.companyfax !== null &&companyDetails.companyfax !== undefined && companyDetails.companyfax !== "null" && companyDetails.companyfax !== "" ? companyDetails.companyfax : ""
            );
            formikRef.current.setFieldValue(
                "companyurl",
                companyDetails.url

            );
            formikRef.current.setFieldValue(
                "size",
                // companyDetails.companysize
                companyDetails.companysize !== null && companyDetails.companysize !== undefined && companyDetails.companysize !== "null" && companyDetails.companysize !== "" ? companyDetails.companysize : ""

            );
            formikRef.current.setFieldValue(
                "since",
                // companyDetails.since
                companyDetails.since !== null && companyDetails.since !== undefined && companyDetails.since !== "null" && companyDetails.since !== "" ? companyDetails.since : ""

            );
            // formikRef.current.setFieldValue(
            //   "companyurl",
            //   companyDetails.url
            // );

            formikRef.current.setFieldValue(
                "zipcode",
                // companyDetails.zipcode
                companyDetails.zipcode !== null && companyDetails.zipcode !== undefined && companyDetails.zipcode !== "null" && companyDetails.zipcode !== "" ? companyDetails.zipcode : ""

            );
            formikRef.current.setFieldValue(
                "state",
                companyDetails.state ? companyDetails.state : userDetails.statename
            );
            formikRef.current.setFieldValue(
                "country",
                companyDetails.country 
            );
            formikRef.current.setFieldValue(
                "city",
                companyDetails.city ? companyDetails.city : userDetails.cityname
            );
            /* formikRef.current.setFieldValue(
                "about",
                companyDetails.aboutcompany

            ); */
            formikRef.current.setFieldValue(
                "Address1",
                companyDetails.address1? companyDetails.address1 :  ""
            );
            formikRef.current.setFieldValue(
                "stateOfIncorporation",
                companyDetails.State_of_Inc || ''
            );
            formikRef.current.setFieldValue(
                "einOrTax",
                companyDetails.ein_tax_id || ''
            );
        }
    }, [companyDetails])
    const handlePhoneNumberChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);

        setPhoneNo(formattedPhoneNumber);
        if (formikRef.current) {
            formikRef.current.setFieldValue("phoneno", formattedPhoneNumber);
            // console.log(formikRef.current);
        }
    };
    const formatPhoneNumber = (value) => {
        if (!value) return value;
    
        //// clean the input for any non-digit values.
    
        const phoneNumber = value.replace(/[^\d]/g, "");
    
        const phoneNumberLength = phoneNumber.length;
    
        setPhoneNo(String(phoneNumber));
    
        if (phoneNumberLength < 4) return phoneNumber;
    
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
    
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      };
      const onTextEditorchanged = (value) => {

        setDescription(value);
    
        if (formikRef.current) {
          formikRef.current.setFieldValue("description", value);
        }
      };
     

    const UpdateCompany = (values) => {
        //console.log(props)
        //console.log(props.currentUser)
        //  alert(companyId)
        console.log("com",companyDetails)
        console.log("user",userDetails)
        console.log("details",details)
        setButtonLoading(true);
        axios
          .post(`${Key.domain}/customer/add_or_update_customer_companydetails`, {
            user_id: companyDetails.uid == undefined ? userDetails.id : companyDetails.uid,
            admin_id: sessionStorage.getItem('id'),
            comp_name: values.companyname,
            category: companyDetails.category,
            contact_name: values.contactname,
            contact_phone: values.phoneno,
            company_fax: companyDetails.companyfax,
            comp_url: values.companyurl,
            contact_email: values.email,
            since: values.since,
            companysize: values.size,
            country: 233,
            description: values.description,
            state: values.state,
            city: values.city,
            zipcode: values.zipcode,
            address1: values.Address1,
            address2: values.Address2,
            status: 1,
            logo: '',
            session_token: sessionStorage.getItem("userToken"),
            altcontactname: companyDetails.altercontactname,
            altcontactphone: companyDetails.altercontactphone,
            altcontactemail: companyDetails.altercontactemail,
            action:  Array.isArray(companyDetails) ?  0 :1,
            id: companyDetails.id == undefined ? userDetails.company_id : companyDetails.id,
          })
          .then((response) => {
            console.log("res",response);
           
            if (response.status === 200 && parseInt(response.data.success) === 1) {
              
              let msg = response.data.data.insertId !== 0 ? 'Company added successfully' : 'Company updated successfully';
             
              alert(msg)
              closeshowEdit()
           getCustomerDetails()
           jnpUpdateCompany()
           } 
           

          else if (response.status === 200 && parseInt(response.data.success) === 0){
              let msg = response.data.data
              
              alert(msg)
            } else {
              let msg ="Something went wrong !"
             alert(msg)
            }
          });
      };
      const jnpUpdateCompany = (fields) => {
        console.log(fields)
        setButtonLoading(true);
    
        if (fields.Address1) {
          axios
            .post(`${Key.domain1}/company/get_company_coordinates`, {
              "user_id": props.currentUser.id ? props.currentUser.id : null,
              "address": null,
              "session_token": props.currentUser.session_token ? props.currentUser.session_token : null
            })
            .then((response) => {
              console.log(response.data.data.candidates[0]);
              if (response.data.data.candidates && response.data.data.candidates.length > 0) {
                setCompany_coordinates(response.data.data.candidates[0])
                axios
                  .post(`${Key.domain}/company/update_company`, {
                    id: props.companyId,
                    uid: sessionStorage.getItem("id"),
                    description: fields.about,
                    companyfax: fields.fax,
                    contactname: fields.contactname,
                    since: fields.since,
                    contactphone: fields.phoneno,
                    companysize: fields.size,
                    country: fields.country,
                    state: fields.state,
                    city: fields.city,
                    zipcode: fields.zipcode,
                    address1: fields.Address1,
                    address2: fields.Address2,
                    session_token: props.currentUser.session_token,
                    category: fields.category,
                    company_name: fields.companyname,
                    contactemail: fields.email,
                    url: fields.companyurl,
                    latitude: response.data.data.candidates[0].geometry.location.lat,
                    longitude: response.data.data.candidates[0].geometry.location.lng,
                    logo: previewImagefile ? previewImagefile.name : companyDetails.logofilename && companyDetails.logofilename !== undefined && companyDetails.logofilename !== null && companyDetails.logofilename !== '' ? companyDetails.logofilename : null,
                    ein_or_tax: fields.einOrTax,
                    state_of_incorporation: fields.stateOfIncorporation
    
                  })
                  .then((response) => {
                    console.log(response);
                    if (response.data.data.affectedRows) {
    
                     /*  if (previewImagefile !== null) {
                        const data = new FormData();
                        data.append('company_logo', previewImagefile);
                        data.append('user_id', sessionStorage.getItem("id"));
                        data.append('company_id', props.companyId);
                        data.append('session_token', props.currentUser.session_token);
    
                        fetch(`${Key.domain}/company/upload_company_logo`, {
                          method: "POST",
                          // mode: "no-cors",
                          body: data
                        }).then(response => response.json())
                          .then(resdata => {
                            console.log(resdata)
                            if (resdata.success == 1) {
    
                            } else {
    
                            }
                          })
                      } */
    
                      setButtonLoading(false);
                      // alert("Company Details Updated")
    
    
                      let msg = "Company Details Updated"
                      toast(
                        <ToastMessage message={msg} />
                      )
                      props.handleEditClose()
                      
                    } else {
    
                      let msg = "Something went wrong"
                      toast(
                        <ToastMessage message={msg} />
                      )
    
                    }
    
                  });
              } else {
                // alert("Please give a valid address!")
    
    
                let msg = "Please give a valid address!"
                toast(
                  <ToastMessage message={msg} />
                )
                setButtonLoading(false);
              }
            }).catch(err => {
              // alert("Please give a valid address!")
    
              let msg = "Please give a valid address!"
              toast(
                <ToastMessage message={msg} />
              )
              setButtonLoading(false);
            })
        }
    
    
      }
      
      const handledisable = () =>{
        if(companyDetails.name){
            setdisable(false)
        }
      }
      

    return (
        <div id="addNewCompany">
            <Formik
                innerRef={formikRef}
                initialValues={{
                    companyname: "",//companyDetails.name,// props.companyDetails.,
                    //contactmail: "",//companyDetails.contactname,
                    contactname:"",
                    email:"" ,
                    description: "",
                    phoneno: "",
                    companyurl: "",
                    since: "",
                    Address2: "",
                    zipcode: "",
                    state: "",
                    city: "",
                    country: "",
                    size: "",
                    ein: "",
                    stateOfIncorporation: ""
                }}
                validationSchema={Yup.object().shape({
                    companyname: Yup.string()
                        .required("Company Name is Required")
                        .matches(
                            /^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=\',._-]+$/g,
                            // /^[a-zA-Z0-9\s.+-]+$/,
                            "Company name invalid "
                        ),
                        contactname:Yup.string()
                        .required("Contact Name is Required"),

                    email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is Required")
                        .matches(
                            /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|outlook|myyahoo))/,
                            "Only Business Emails are allowed "
                        ),
                        description:Yup.string()
                        .required("About company is required"),




                    // phoneno: Yup.string().required("Contact Phone is Required").min(14, "Contact number should be 10 digits"),

                    companyurl: Yup.string().required("Company url Required"),
                    Address1: Yup.string().required(" Address is required "),

                    // category: Yup.string()

                    // .required(" Category Url is Required"),

                    state: Yup.string().required(" Select from Dropdownn "),

                    city: Yup.string().required(" Select from Dropdownn"),
                    country: Yup.string().required(" Select from Dropdownn"),

                    zipcode: Yup.string()
                        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Zipcode should be 5 digits")

                        .required(" Zipcode is Required"),

                })}
                onSubmit={(fields) => {
                    console.log(fields);
                    UpdateCompany(fields)
                }}
                render={({ errors, status, touched, values, handleChange }) => (
                    <div className="container">
                        <Form autoComplete="off">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Company Name <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                             //value={alues.name}
                                            //  onChange={this.handleChange}
                                            //onChange={() =>  handleChange()}
                                            name="companyname"
                                            type="text"
                                            placeholder="Company Name"
                                            disabled={ companyDetails.name  ? true :false}
                                            className={
                                                "form-control" +
                                                (errors.companyname && touched.companyname
                                                    ? " is-invalid"
                                                    : "")
                                            }

                                        />
                                        <ErrorMessage
                                            name="companyname"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                  <div className="col-md-6">
                  <div className="form-group" id="_form-group">
                    <label>
                      Contact Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      name="contactname"
                      type="text"
                      placeholder="Contact Name"
                      className={
                        "form-control" +
                        (errors.contactname && touched.contactname
                          ? " is-invalid"
                          : "")
                      }

                    />
                    <ErrorMessage
                      name="contactname"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div> 
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Contact Email <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            //value={values.name}
                                            // onChange={handleEmailChange}
                                            // onChange={() =>  handleChange()}
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            // autocomplete="off"
                                            className={
                                                "form-control" +
                                                (errors.email && touched.email ? " is-invalid" : "")
                                            }

                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Contact Phone
                                        </label>
                                        <Field
                                            // value={values.phoneno}

                                            //  onChange={this.handleChange}
                                            onChange={handlePhoneNumberChange}
                                            value={values.phoneno}
                                            name="phoneno"
                                            type="text"
                                            placeholder="(xxx) xxx-xxxx"
                                            className={
                                                "form-control"

                                            }
                                        />
                                        <ErrorMessage
                                            name="phoneno"
                                            component="div"
                                            className="invalid-feedback"
                                        />

                                    </div>
                                </div>
                                {/*  <div className="col-md-6">
                  <div className="form-group" id="_form-group">
                    <label>Company Fax </label>
                    <Field
                      // value={formData.name}
                      //  onChange={this.handleChange}
                      name="fax"
                      type="text"
                      placeholder="(xxx) xxx-xxxx"
                      className={"form-control"}
                     // onChange={handleChangeFax}
                      value={values.fax}
                    />
                  </div>
                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Company Url<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            // value={formData.name}

                                            name="companyurl"
                                            type="text"
                                            placeholder="https:/www.example.com"
                                            disabled = {companyDetails.url ? true : false}
                                            className={
                                                "form-control" +
                                                (errors.companyurl && touched.companyurl
                                                    ? " is-invalid"
                                                    : "")
                                            }

                                        //onChange={() => companyUrl && handleChange()}

                                        />
                                        <ErrorMessage
                                            name="companyurl"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                        <p></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>Company Size </label>
                                        <Field
                                            // value={formData.name}
                                            //  onChange={this.handleChange}
                                            name="size"
                                            type="text"
                                            placeholder="Size"
                                            className={"form-control"}

                                        />

                                    </div>
                                </div>
                              {/*   <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>Description </label>
                                        <Field
                                           // value={formData.name}
                                            //  onChange={this.handleChange}
                                            name="description"
                                            type="text"
                                            placeholder="description"
                                            className={"form-control"}

                                        />

                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>Since</label>
                                        <Field
                                            // value={formData.name}
                                            //onChange={this.handleChange}
                                            name="since"
                                            type="text"
                                            placeholder="2001"
                                            className={"form-control"}

                                        />

                                    </div>
                                </div>
                                {/*  <div className="col-md-6">
                  <div className="form-group" id="_form-group">
                    <label>Category</label>
                    <Field
                      name="category"
                      as="select"
                      className={
                        "form-select form-control "
                        // (errors.category && touched.category ? " is-invalid" : "")
                      }
                    >
                      <option selected>Select Category</option>
                      {/* {specialization.map((item, index) => (
                        <option value={item.id}>{item.category_title}</option>
                      ))} 
                    </Field>

                    {/* <ErrorMessage
                                            name="category"
                                            component="div"
                                            className="invalid-feedback"
                                        /> 
                  </div>
                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group1">
                                        <label>
                                            EINSSN
                                        </label>
                                        <Field
                                            name="ein"
                                            type="text"
                                            placeholder="EIN/SSN"
                                            className={
                                                "form-control" +
                                                (errors.einOrTax && touched.einOrTax
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group1">
                                        <label>State of incorporation</label>
                                        <Field
                                            name="stateOfIncorporation"
                                            as="select"
                                            className={
                                                "form-select form-control"
                                            }
                                        >
                                            <option>Select State</option>
                                            {states.length > 0 && states.map((item, index) => (
                                                <option value={item.name}>{item.name}</option>
                                            ))}
                                        </Field>

                                    </div>
                                </div>
                              

                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Country <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            name="country"
                                            as="select"
                                            className={
                                                "form-select form-control" +
                                                (errors.country && touched.country ? " is-invalid" : "")
                                            }
                                            onChange={(e) => getStates(e.target.value)}
                                        // defaultValue={values.country}

                                        >
                                            <option>Select Country</option>
                                            {countries.length > 0 && countries.map((item, index) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name="country"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            State <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            name="state"
                                            as="select"
                                            className={
                                                "form-select form-control" +
                                                (errors.state && touched.state ? " is-invalid" : "")
                                            }
                                            onChange={(e) => getCities(e.target.value)}
                                        >
                                            <option>Select State</option>
                                            {states.length > 0 && states.map((item, index) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name="state"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>






                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            City <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            name="city"
                                            as="select"
                                            className={
                                                "form-select form-control" + 
                                                (errors.city && touched.city ? " is-invalid" : "")
                                            }
                                            onChange={(e) => {
                                                console.log(values);
                                                formikRef.current.setFieldValue(
                                                    "city",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option selected>Select City</option>
                                            {cities.length > 0 && cities.map((item, index) => (
                                                <option value={item.id}>{item.cityName}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name="city"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Zipcode <span style={{ color: "red" }}>*</span>{" "}
                                        </label>
                                        <Field
                                            // value={formData.name}
                                            //onChange={this.handleChange}
                                            name="zipcode"
                                            type="text"
                                            placeholder="Zipcode"
                                            className={
                                                "form-control" +
                                                (errors.zipcode && touched.zipcode
                                                    ? " is-invalid"
                                                    : "")
                                            }

                                        />
                                        <ErrorMessage
                                            name="zipcode"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group" id="_form-group">
                                        <label>
                                            Address <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Field
                                            // value={formData.name}
                                            //  onChange={this.handleChange}
                                            name="Address1"
                                            //value={values.address1}

                                            type="text"
                                            as="textarea"
                                            placeholder="Address"
                                            className={
                                                "form-control" +
                                                (errors.Address1 && touched.Address1
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />

                                        {errors.Address1 && touched.Address1 ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: ".875em",
                                                    color: "#dc3545",
                                                }}
                                            >
                                                {" "}
                                                Address is required
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                      
                                    </div>
                                </div>
                                <div className="col-md-12">
                <div className="form-group" id="_form-group">
                  <label name="description">
                    About company <span style={{ color: "red" }}>*</span>
                  </label>
                  <Editor
                    onEditorChange={(value) => {
                      onTextEditorchanged(value);
                    }}
                    value={values.description}
                    init={{
                      height: 240,
                      menubar: false,

                      statusbar: false,
                      plugins: ["code", "lists"],

                      toolbar: 'undo redo | casechange blocks | bold italic  | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist | '
                    }}
                    className={
                      "form-select form-control" +
                      (errors.description && touched.description ? " is-invalid" : "")
                    }
                    name="description"
                  />

                  {errors.description && touched.description ? (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: ".875em",
                        color: "#dc3545",
                      }}
                    >
                      {" "}
                      About Company is required
                    </div>
                  ) : (
                    ""
                  )}
                  <ErrorMessage
                    name="about"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
                            </div>

                            <div className="col-md-6">
                                <br />

                                {/* <Button variant="Primary" type="submit"  style={{ background: "blue", color: "#ffff",marginRight:"30px" }} >
                                    Save
                                </Button> */}
                                {
                                    !buttonLoading ? <Button variant="Primary" type="submit"  style={{ background: "blue", color: "#ffff",marginRight:"30px" }} >
                                    Save
                                </Button> :
                                <Button variant="Primary" type="submit"   style={{ background: "blue", color: "#ffff",marginRight:"30px" }} disabled>
                                Saving....
                            </Button>
                                }
                                <Button variant="Primary" onClick={closeshowEdit}  style={{ background: "blue", color: "#ffff",marginRight:"30px"  }} >
                                    Close
                                </Button>



                            </div>



                        </Form>
                    </div>
                )}
            />
        </div>

    )
}
function mapStateToProps(state, ownProps) {
    return {
      currentUser: state.UserReducer.user,
    };
  }
export default connect(mapStateToProps, null)(EditCompany);


