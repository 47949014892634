import React, { Component } from "react";
import { toast } from "react-toastify";
import { FiChevronRight } from 'react-icons/fi';

const Toastmsg = (props) => {
    //  console.log("toast called")
    return (
        <div
            style={{
                height: "100%",
                borderLeft: "5px solid #406882",
                display: "flex",
                alignItems: "center",
                paddingLeft: 5,
                progress: 0.2,
                backgroundColor: "#fff",
                textAlign: "center"
            }}                            >
            <FiChevronRight color={"#406882"} height="25px" width="25px" />{"  "}
            <span style={{ marginLeft: 5, color: "#000", fontSize: "17px" }}>{props.message}</span>
        </div>
    );
}




export default Toastmsg