import { combineReducers } from 'redux';
import UserReducer from './user.reducer';
import loaderReducer  from './loader.reducer';

const reducers = combineReducers({
    UserReducer,
    loaderReducer

});

export default reducers;
