/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import axios from 'axios';
import { BsTelephone, BsFillBuildingsFill, BsPeopleFill } from 'react-icons/bs';

import { CiLocationOn } from 'react-icons/ci';
import { TbMapPinCode, TbBrandLinkedin, TbBriefcase } from 'react-icons/tb';
import { BiBuilding } from 'react-icons/bi';
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { HiOutlineDocumentText } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';

import Key from '../clientVariables.json';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Companyinfo() {
  const [search, setSearch] = useState('');
  const [recruiters, setRecruiters] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [recruiterId, setRecruiterId] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [recruiterName, setRecruiterName] = useState('');

  const [lcaDetails, setLcaDetails] = useState([]);
  const [h1bDetails, seth1bDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [recruiterDetails, setRecruiterDetails] = useState([]);

  const [searchCompanyName, setsearchCompanyName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [lcaStatus, setLcaStatus] = useState([]);
  const [greenCardDetails, setGreenCardDetails] = useState([]);
  const [greenCardStatus, setGreenCardStatus] = useState([]);
  const [h1bStatus, seth1bStatus] = useState([]);
  const [attorneyDetails, setAttorneyDetails] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [visaStrength, setVisaStrength] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showMessage, setShowMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [visaByyear, setvisaByYear] = useState([]);
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newValue1) => {
    setValue1(newValue1);
  };
  const limit = 10;
  useEffect(() => {
    fetchRecruiterData();
  }, []);

  const fetchMoreData = () => {
    fetch(`${Key.domain}/company/get_Recruiters_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: offset, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        if (resdata.data.length === 0) {
          setHasMore(false);
        } else {
          setRecruiters((prevData) => [...prevData, ...resdata.data]);
          setOffset(offset + resdata.data.length);
        }
      });
    });
  };

  const getRecruiterDetails = (recruiter_ID) => {
    console.log(recruiter_ID);
    fetch(`${Key.domain}/company/get_Recruiter_Details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recruiter_id: recruiter_ID }),
    }).then((response) => {
      response.json().then((resdata) => {
        setRecruiterId(resdata.data[0].ID);
        setRecruiterName(resdata.data[0].EMP_CONTACT_NAME);
        setRecruiterDetails(resdata.data[0]);
      });
    });
  };

  const fetchRecruiterData = () => {
    fetch(`${Key.domain}/company/get_Recruiters_List`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page, limit }),
    }).then((response) => {
      response.json().then((resdata) => {
        setRecruiters(resdata.data);

        // setCompanies(prevData => [...prevData, ...resdata.data]);
        // setPage(page + 1);

        setRecruiterId(resdata.data[0].ID);
        setRecruiterName(resdata.data[0].EMP_CONTACT_NAME);
        /* getLcaStatus(resdata.data.response.docs[0].id);
        getLcaDetails(resdata.data.response.docs[0].id);
        getGreenCardDetails(resdata.data.response.docs[0].id);
        getGreenCardStatus(resdata.data.response.docs[0].id);
        getH1BStatus(resdata.data.response.docs[0].id); */
        getAttorneys(resdata.data[0].id);
        getRecruiterDetails(resdata.data[0].ID);
        // getCOmpanyContacts(resdata.data[0].id);
        // getVisaStrength(resdata.data[0].id);
      });
    });
  };

  const getAttorneys = (compid) => {
    axios
      .post(`${Key.domain}/company/get_company_Attorneys`, {
        company_id: compid,
      })
      .then((resdata) => {
        setAttorneyDetails(resdata.data.data.companyAttorneyDetails);
      });
  };

  const getCOmpanyContacts = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_Contacts`, {
        company_id: compId,
      })
      .then((resdata) => {
        setCompanyContacts(resdata.data.data.companyContacts);
      });
  };

  const getVisaStrength = (compId) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_Count`, {
        company_id: compId,
      })
      .then((resdata) => {
        setVisaStrength(resdata.data.data);
      });
  };

  const getVisaByYear = (e) => {
    axios
      .post(`${Key.domain}/company/get_Company_VisaStatus_byYear`, {
        company_id: companyId,
        year: e.target.value,
      })
      .then((resdata) => {
        console.log(resdata);
        setvisaByYear(resdata.data.data);
      });
  };

  const searchRecruiter = (value) => {
    console.log(value);
    if (value.length > 0) {
      axios
        .post(`${Key.domain}/company/search_recruiter`, {
          recruiterName: value,
        })
        .then((resdata) => {
          console.log(resdata);
          setRecruiters(resdata.data.data);
        });
      // let newdata=recruiters.filter(r=>r.EMP_CONTACT_NAME.toLowerCase().includes(value))
      // console.log(newdata)
      // setRecruiters(newdata)
    } else {
      fetchRecruiterData();
    }
  };
  const getCompanyDetails = (company_ID) => {
    fetch(`${Key.domain}/company/get_Company_Details`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ company_id: company_ID }),
    }).then((response) => {
      response.json().then((resdata) => {
        console.log(resdata.data);
        setCompanyId(resdata.data[0].id);
        setCompanyName(resdata.data[0].name);
        setCompanyDetails(resdata.data);
      });
    });
  };
  console.log(recruiters, recruiterId);
  return (
    <div>
      <div className="row" style={{ marginTop: '-70px' }}>
        <div
          className="col-3"
          style={{
            borderRightColor: '#000',

            padding: '0px',
            width: '260px',
            position: 'fixed',
          }}
        >
          <h5 style={{ color: '#a63755' }}>My Recruiters</h5>
          <div className="mb-0">
            <input
              type="text"
              //value={search}
              placeholder="Search Recruiter"
              onChange={(e) => searchRecruiter(e.target.value)}
              style={{ width: '250px', borderRadius: '12px', border: '1px solid lightgrey', padding: '5px' }}
            />
          </div>{' '}
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <InfiniteScroll
              dataLength={offset * 10}
              next={fetchMoreData}
              hasMore={hasMore}
              //loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              height={550}
              style={{ overflowY: 'scroll', overflowX: 'hidden' }}
              scrollableTarget="scrollableDiv"
              // refreshFunction={refresh}
              // pullDownToRefresh
              pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              // }
            >
              {recruiters.map((value) => (
                <Card >
                  <div className="p-2">
                    <div className="row">
                      <div className="col-3" 
                      style={{fontSize:'14px'}}
                      >
                        {/* <ListItemAvatar>
                          <Avatar>
                            <AccountCircleRoundedIcon />
                          </Avatar>
                        </ListItemAvatar> */}
                        <img
                          src={
                            value.EMP_PHOTO_URL != null
                              ? value.EMP_PHOTO_URL
                              : 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                          }
                          style={{ borderRadius: '5px'}}
                          alt="img"
                        />
                      </div>
                      <div
                        className="col-9"
                        style={{
                          color: '#406882',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          // paddingLeft: '22px',
                          paddingTop: '9px',
                        }}
                        onClick={() => {
                          setRecruiterId(value.ID);
                          setRecruiterName(value.EMP_CONTACT_NAME);

                          getRecruiterDetails(value.ID);

                          // getAttorneys(value.id);
                          // getCOmpanyContacts(value.id);
                          // getVisaStrength(value.id);
                          getCompanyDetails(value.COMPANY_ID);
                        }}
                      >
                        {value.EMP_CONTACT_NAME.slice(0, 50)}
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </InfiniteScroll>
          </List>
        </div>
        <div className="col-9" style={{ marginTop: '5px', width: '1027px', marginLeft: '269px' }}>
          <div>
            {/* <img
                                src={
                                  recruiterDetails.EMP_PHOTO_URL != null
                                    ? recruiterDetails.EMP_PHOTO_URL
                                    : 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                                }
                                style={{ width: '60px' }}
                                alt="recruiter_Image"
                               
                              /> */}

            {/*<h2 style={{ marginTop: '15px' }}>{companyDetails.name}</h2>*/}
          </div>

          <div className="company name"></div>

          <div className="row">
            <div className="col-12">
              <br />
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    variant="fullwidth"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#a63755',
                      },
                    }}
                  >
                    <Tab label="Recruiter Details" {...a11yProps(0)} style={{ minWidth: '20%' }} />
                    <Tab label="Company Details" {...a11yProps(1)} style={{ minWidth: '20%' }} />
                    {/* <Tab label="Jobs By Recruiter" {...a11yProps(2)} style={{ minWidth: '20%' }} /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="mt-3">
                    <div className="row">
                      <div className="col-5">
                        <Card>
                          <Card body>
                            <Card.Title
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={
                                  recruiterDetails.EMP_PHOTO_URL != null
                                    ? recruiterDetails.EMP_PHOTO_URL
                                    : 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                                }
                                style={{ width: '100px' }}
                                alt="img"
                                className="rounded"
                              />
                              <h3>
                                {' '}
                                <BiBuilding style={{ color: 'rgb(166, 55, 85)' }} />
                                &nbsp;
                                {recruiterDetails.EMP_CONTACT_NAME}
                              </h3>
                              <p>{recruiterDetails.EMP_DECL_TITLE}</p>
                            </Card.Title>
                            <ListGroup className="list-group-flush">
                              <ListGroup.Item>
                                <p>
                                  <BsTelephone style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                                  {recruiterDetails.EMP_CONTACT_PHONE ? recruiterDetails.EMP_CONTACT_PHONE : 'N/A'}{' '}
                                </p>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <p>
                                  <AiOutlineMail style={{ color: 'rgb(166, 55, 85)' }} />
                                  &nbsp;
                                  {recruiterDetails.EMP_CONTACT_EMAIL ? recruiterDetails.EMP_CONTACT_EMAIL : 'N/A'}{' '}
                                </p>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <p>
                                  <TbMapPinCode style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                                  {recruiterDetails.EMP_CONTACT_CITY}
                                  {' ,'}
                                  {recruiterDetails.EMP_CONTACT_STATE_PROVINCE}
                                </p>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <p>
                                  <TbBrandLinkedin style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                                  <a
                                    href={
                                      recruiterDetails.EMP_LINKEDIN_URL != null
                                        ? recruiterDetails.EMP_LINKEDIN_URL
                                        : 'N/A'
                                    }
                                    target="_blank"
                                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                  >
                                    <span>LinkedIn Profile</span>{' '}
                                  </a>
                                </p>
                              </ListGroup.Item>
                            </ListGroup>
                          </Card>
                        </Card>
                      </div>
                      <div className="col-7">
                        <Card>
                          <Card body>
                            <Card.Header style={{ fontWeight: '600', fontSize: '20px' }}>
                              {companyName ? companyName : ''}
                            </Card.Header>
                            <Card.Text>
                              <span style={{ fontWeight: '600' }}>Needs : </span>
                              <span> N/A</span>
                            </Card.Text>
                            <Card.Text>
                              <span style={{ fontWeight: '600' }}>Pains : </span>
                              <span> N/A</span>
                            </Card.Text>
                            <Card.Text>
                              <span style={{ fontWeight: '600' }}>Motivations : </span>
                              <span> N/A</span>
                            </Card.Text>
                            <Card.Text>
                              <span style={{ fontWeight: '600' }}>Day In The Life : </span>
                              <span> N/A</span>
                            </Card.Text>
                          </Card>
                        </Card>
                      </div>
                    </div>

                    {/* <p>
                      <TbWorld style={{ color: 'rgb(166, 55, 85)' }} /> &nbsp;
                      {companyDetails.url}{' '}
                    </p> */}
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <br />
                  <div className="row">
                    {companyDetails.map((company) => (
                      <div className="col-12">
                        <Card>
                          <Card body>
                            <div className="row" style={{ fontSize: '15px', fontWeight: '600' }}>
                              <div className="col-12" style={{ fontSize: '12px' }}>
                                {/* <img
                                  src={
                                    contact.EMP_PHOTO_URL != null
                                      ? contact.EMP_PHOTO_URL
                                      : 'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
                                  }
                                  className="rounded"
                                  style={{ width: '70', height: '60px', marginBottom: '5px' }}
                                /> */}
                                <h4
                                  style={{
                                    borderBottom: '1px solid black',
                                    marginBottom: '30px',
                                    paddingBottom: '10px',
                                  }}
                                >
                                  Company Details
                                </h4>
                                <div className="row">
                                  <div className="col">
                                    <p style={{ lineHeight: '13px' }}>
                                      <AiOutlineUser size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}>Company Name : </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.name != null ? company.name : 'N/A'}
                                      </span>
                                    </p>
                                    <p style={{ lineHeight: '13px' }}>
                                      {' '}
                                      <AiOutlineMail size={15} style={{ marginRight: '12px' }} />{' '}
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}>Company Email : </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.contactemail != null ? company.contactemail : 'N/A'}
                                      </span>
                                    </p>
                                    <p style={{ lineHeight: '13px' }}>
                                      {' '}
                                      <AiOutlinePhone size={15} style={{ marginRight: '12px' }} />{' '}
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}>Company Phone : </span>
                                      <span style={{ color: 'gray',fontSize: '16px'}}>
                                        {company.contactphone != null ? company.contactphone : 'N/A'}
                                      </span>
                                    </p>

                                    {/* <p style={{ lineHeight: '13px' }}>
                                      <HiOutlineDocumentText size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '16px' }}>Invoice Terms : </span>
                                      <span style={{ color: 'gray' }}>{company.packageid}</span>
                                    </p> */}
                                     <p style={{ lineHeight: '13px' }}>
                                      <BsPeopleFill size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}>Company Size : </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.companysize ? company.companysize : 'N/A'}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p style={{ lineHeight: '13px' }}>
                                      <CiLocationOn size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}> City: </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.city != null ? company.city : 'N/A'}
                                      </span>
                                    </p>
                                    <p style={{ lineHeight: '13px' }}>
                                      <CiLocationOn size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}> State : </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.state != null ? company.state : 'N/A'}
                                      </span>
                                    </p>
                                    <p style={{ lineHeight: '13px' }}>
                                      <CiLocationOn size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}> Company Address : </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>
                                        {company.address1 != null ? company.address1 : 'N/A'}
                                      </span>
                                    </p>
                                    <p style={{ lineHeight: '13px' }}>
                                      <BsFillBuildingsFill size={15} style={{ marginRight: '12px' }} />
                                      <span style={{ fontWeight: '500', fontSize: '14px' }}>Year Est.: </span>
                                      <span style={{ color: 'gray',fontSize: '16px' }}>{company.since ? company.since.slice(0,4) : 'N/A'}</span>
                                    </p>
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Card>
                        <br />
                      </div>
                    ))}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <br />
                  {/* <div className="row">
                    {attorneyDetails.map((attorney) => (
                      <div className="col-6">
                        <Card>
                          <Card body>
                            <>
                              <div className="col-12" style={{ fontSize: '12px' }}>
                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineUser size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_NAME}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineUser size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_FIRM_NAME}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlineMail size={15} style={{ marginRight: '12px' }} />{' '}
                                  {attorney.AGENT_ATTORNEY_EMAIL}
                                </p>
                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <AiOutlinePhone size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_PHONE}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <BiBuilding size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_CITY} |{' '}
                                  {attorney.AGENT_ATTORNEY_COUNTRY === 'UNITED STATES OF AMERICA'
                                    ? 'USA'
                                    : attorney.AGENT_ATTORNEY_COUNTRY}
                                </p>

                                <p style={{ lineHeight: '13px' }}>
                                  {' '}
                                  <SlLocationPin size={15} style={{ marginRight: '12px' }} />
                                  {attorney.AGENT_ATTORNEY_ADDRESS_1}, {attorney.AGENT_ATTORNEY_ADDRESS_2},{' '}
                                  {attorney.AGENT_ATTORNEY_POSTAL_CODE}
                                </p>
                              </div>
                            </>
                          </Card>
                        </Card>
                        <br />
                      </div>
                    ))}{' '}
                    &nbsp;
                  </div> */}
                </CustomTabPanel>
                {/* 
                <CustomTabPanel value={value} index={3}>
                  <div>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => getVisaByYear(e)}
                    >
                      <option selected>Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                    </select>
                    &nbsp;
                    {visaByyear.map((visa) => (
                      <Card>
                        <Card body>
                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <p style={{ lineHeight: '20px', fontSize: '15px' }}>Green Card</p>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Certified</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.certified}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Certified-Exipred</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.certified_expired}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Withdrawn</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.withdrawn}</div>
                          </div>

                          <div className="row" style={{ fontSize: '12px', lineHeight: '13px', fontWeight: '600' }}>
                            <div className="col-6">
                              <p style={{ lineHeight: '13px' }}>Denied</p>
                            </div>

                            <div className="col-1">:</div>

                            <div className="col-4">{visa.denied}</div>
                          </div>
                        </Card>
                      </Card>
                    ))}
                  </div>
                </CustomTabPanel> */}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companyinfo;
