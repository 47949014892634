/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Key from '../clientVariables.json'
import { connect } from "react-redux";
import CustomerDetailView2 from './CustomerDetailView2';
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ENDPOINT } from 'src/utils/endpoint';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Resumeviewer from "./ResumeViewer"
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { toast } from "react-toastify";
import { MdLocationOn } from "react-icons/md";
import request from 'src/utils/request';
import { RiArrowDropDownLine } from "react-icons/ri";
import ToastMessage from "../components/ToastMessage";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SlOptionsVertical } from "react-icons/sl"
import { MdDeleteOutline } from "react-icons/md";


function User(props) {
    const history = useNavigate();
    const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

    const [data, setData] = useState({
        fromEmail: "hot-resumes@jobsnprofiles.com",
        fullname: '',
        toEMail: '',
        ccEMail: '',
        subj: '',
        body: mailbody,
        jobtitle: '',
        skillname: '',
        location: '',

    });


    const [salesPersons, setSalespersonsList] = useState([]);


    const [users, setUsers] = useState([]);
    const [sendMail, setSendMail] = useState(false);
    const [person, setPerson] = useState("");
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [editedData, setEditedData] = useState([]);
    const [showComment, setShowComment] = useState(false);

    const [showResetPassword, setShowResetPass] = useState(false);
    const [showActionPopup, setShowActionPopup] = useState(false);
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [newCostumerId, setNewCustomerId] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [showSalesPerson, setShowSalesPerson] = useState(false);
    const [selectedRows, setSelectedRows] = useState([0]);

    useEffect(() => {

        getUsers();
        getSalespersons();

    }, [])


    const refreshData = () => {
        getUsers();
        getSalespersons();

    }
    const handleRowClick = (rowData, rowMeta) => {
        const selectedRowIndex = rowMeta.dataIndex;
        setSelectedRows([selectedRowIndex]);
    };


    const getSalespersons = () => {
        // props.showLoader();

        axios
            .get(`${Key.domain}/common/salespersons_list`, {

            })
            .then((response) => {
                console.log(response)
                setSalespersonsList(response.data.data)
                // props.hideLoader();

            })
    }


    const handleResetPassClose = () => {
        setShowResetPass(false)
    }
    const handleDeactivateClose = () => {
        setShowDeactivate(false)
    }


    const handleActionClose = () => {
        setShowActionPopup(false)
        setShowDeactivate(false)
        setShowResetPass(false)

    }


    const getUsers = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/get_nonverified_users`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token
            })
            .then((response) => {
                console.log(response)
                setUsers(response.data.data)
                setNewCustomerId(response.data.data[0].user_id)
                props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    setSessionExpired(true)

                }
            });;
    }

    const handleSalesClose = () => {
        setShowSalesPerson(false)
    }
    const changeSalesPerson = e => {
        setSalesPerson(e.target.value);
        setShowSalesPerson(true);
    }

    const actionButton = () => {
        setShowActionPopup(true)
    }

    const deactivateAccountSubmit = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/deactivate_jnp_user`, {
                "email": editedData.username
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();
                const msg = "Account '" + editedData.full_name + "' deactivated Successfully !";
                toast(<ToastMessage message={msg} />);

                handleActionClose()
            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    const msg = "Access denied.. Please Login";
                    toast(<ToastMessage message={msg} />);

                    handleActionClose()

                }
            });;
    }

    const resetPasswordSubmit = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/reset_jnp_user_password`, {
                "email": editedData.username
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();
                const msg = "Account '" + editedData.full_name + "' password reset successfully !";
                toast(<ToastMessage message={msg} />);

                handleActionClose()
            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    const msg = "Access denied.. Please Login";
                    toast(<ToastMessage message={msg} />);

                    handleActionClose()

                }
            });;
    }




    const columns = [
        {
            name: "usercreated",
            label: "Date",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).format("LL") : "N/A"
                }
            },

        },
        {
            label: <span>Name</span>,
            name: "full_name",
            options: {
                // setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (value) => {
                    return value ? <span style={{ background: "#edede8", borderRadius: "15px", padding: "7px" }}>{value}</span> : "N/A"
                }
            },
        },
        {
            label: <span>Email</span>,
            name: "username",
            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Company Name</span>,
            name: ("userCompanyName" !== null && "userCompanyName" !== undefined && "userCompanyName" !== "") ? "userCompanyName" : "company_name",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
    
        {
            label: <span>Last Logged in</span>,
            name: "lastlogindate",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).format("LL") : "N/A"
                }
            },
        },
        {
            name: "sales_person",
            label: "Sales Person",
            options: {
                setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "120px" } }),
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Button onClick={(e) => changeSalesPerson(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined) ? <>{value} <RiArrowDropDownLine /></> : <>Select <RiArrowDropDownLine /></>}</Button>
                            </FormControl>
                        } />
                )
            }
        },

        {
            name: "sendMail",
            label: "Activation Mail",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Button >Resend</Button>} />
                )
            }
        },
        {
            name: "sendMail",
            label: "Make Active",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Button style={{ color: "#ffffff", backgroundColor: 'rgb(13, 110, 253)' }}>Active</Button>} />
                )
            }
        },

        {
            name: "Delete",
            label: "Delete",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Tooltip title="delete"><Button ><MdDeleteOutline size='23' /></Button></Tooltip>} />
                )
            }
        }
    ];

    const deleteSubscriber = (userId) => {


        if (window.confirm("Are you sure you want to delete subscriber?")) {
            axios
                .post(`${Key.domain}/customer/delete_User`, {
                    "admin_id": sessionStorage.getItem("id"),
                    "user_id": userId,
                })
                .then((response) => {
                    getUsers()
                    const msg = "Subscriber Deleted Successfully!";
                    toast(<ToastMessage message={msg} />);
                }).catch((err) => {

                    toast(<ToastMessage message={err} />);

                })
        }

    }
    const makeActive = () => {
        handleCommentClose()

        props.showLoader();

        axios
            .post(`${Key.domain}/customer/active_useraccount`, {
                "email": editedData.username,
                "activated_by": sessionStorage.getItem("email")
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();
                handleCommentClose()
            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    handleCommentClose()

                }
            });;
    }


    const sendMailFunction = (user) => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/resend_users_confirmmail`, {
                "email": user.username
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    setSessionExpired(true)

                }
            });;
    }

    const handleUserDetails = (user) => {

        console.log(user.user_id)

        history("/dashboard/customerDetailsView", { state: { customerid: user.user_id } })
        // history.push({
        //   pathname: "/dashboard/customerDetailsView",
        //   state: {
        //     customerid: user_id,
        //   },
        // });

    }


    const setMessageFunction = (e, t) => {



        console.log(comment)
        console.log(person)
        console.log(status)


        if (t === "sales") {
            setPerson(e.target.value)
        } else if (t === "status") {
            setStatus(e.target.value)
        } else if (t === "comments") {
            setComment(e)
        }
        props.showLoader();


        axios
            .post(`${Key.domain}/customer/insert_update_subscribers_followup`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": sessionStorage.getItem("userToken"),
                "comment": t === "comments" ? e : comment,
                "user_id": editedData.user_id,
                "sales_person": t === "sales" ? e.target.value : person,
                // "sales_response_status": t === "status" ? e.target.value : status
                // "sales_person": "",
                "sales_response_status": ""
            })
            .then((resdata) => {
                console.log(resdata.data.data)
                handleSalesClose()

                getUsers();
            }).catch((err) => {
                console.log(err);
            });
    }

    const options = {
        tableBodyHeight: '40vh',
        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        onRowClick: handleRowClick,
        setRowProps: (row, dataIndex, rowIndex) => {
            if (selectedRows.includes(dataIndex)) {
                return {
                    style: {
                        backgroundColor: '#d9e8fc', // set your desired background color
                    },
                };
            }
            return {};
        },

        onCellClick: (cellIndex, rowIndex) => {
            console.log(users[rowIndex.dataIndex]);
            console.log(rowIndex.dataIndex);
            console.log(rowIndex);

            let userdata = users[rowIndex.dataIndex]

            setEditedData(userdata)
            setData({ ...data, fullname: userdata.full_name, toEMail: userdata.username })
            setPerson(userdata.sales_person)

            setNewCustomerId(userdata.user_id)
            // if (rowIndex.colIndex === 0) {
            //     console.log(cellIndex)
            //     handleUserDetails(userdata)
            // }


            if (rowIndex.colIndex === 6) {
                console.log(cellIndex)
                sendMailFunction(userdata)
            }


            if (rowIndex.colIndex === 7) {
                console.log(cellIndex)
                handleCommentOpen()
            }

            if (rowIndex.colIndex === 8) {
                deleteSubscriber(userdata.user_id)
            }
        }

    };


    const handleCommentOpen = () => {
        setShowComment(true);
    }

    const handleCommentClose = () => {
        setShowComment(false)
    }

    const getMuiTheme = () => createTheme({ components: { MuiTableRow: { styleOverrides: { root: { cursor: "pointer" } } } } });


    return (
        <div>

            <div style={{ marginTop: "0px", boxShadow: "0px 0px 5px black" }}>

                <Modal show={showActionPopup} centered onHide={handleActionClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Action for user - {editedData.full_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <FormControl fullWidth>
                                <>
                                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={() => { setShowDeactivate(true), setShowResetPass(false) }
                                    }>
                                        Deactivate
                                    </Button>
                                    <Button variant="Primary" style={{ background: "blue", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(false), setShowResetPass(true) }
                                    }>
                                        Reset Password
                                    </Button>
                                </>

                            </FormControl>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <> {showResetPassword ? <div    >

                            <h3>Are you sure to reset password ? </h3>
                            <div style={{ textAlign: "right", marginRight: "15px" }}>
                                <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                                    No
                                </Button>
                                <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={resetPasswordSubmit}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                            : ""}
                            {showDeactivate ?
                                <div style={{ textAlign: "right", marginRight: "15px" }}>

                                    <h3>Confirm to deactivate account ? </h3>

                                    <div>
                                        <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                                            No
                                        </Button>
                                        <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                                            Yes
                                        </Button>
                                    </div>
                                </div>
                                : ""}
                        </>
                    </Modal.Footer>
                </Modal>

                {/* <Modal show={showResetPassword} centered onHide={handleResetPassClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Are you sure to reset password ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={resetPasswordSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeactivate} centered onHide={handleDeactivateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Confirm to deactivate account ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal> */}

                {/* Update Sales Model */}
                <Modal show={showSalesPerson} centered onHide={handleSalesClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select Sales Person</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sales Person</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={salesPerson}
                                    onChange={(e) => {
                                        setMessageFunction(e, "sales"),
                                            setPerson(e.target.value)
                                    }}
                                    label="Sales Person"

                                >{salesPersons.map((item, index) => (
                                    <MenuItem value={item.name} >{item.name}</MenuItem>
                                ))}


                                </Select>
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleSalesClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showComment} centered onHide={handleCommentClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>

                            <h3>Confirm account activation ? </h3>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleCommentClose}>
                            No
                        </Button>
                        <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={makeActive}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>



                <ThemeProvider theme={getMuiTheme()}   >
                    <MUIDataTable


                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
            <div>
                <CustomerDetailView2 editedData={editedData} customerId={newCostumerId} refreshData={() => refreshData()} />
            </div>
        </div>
    )
}




const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);