/* eslint-disable*/
import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import { connect } from "react-redux";

import Companiestabs from './Companiestabs';
import Button from 'react-bootstrap/Button';
import { BiArrowBack } from 'react-icons/bi';
import Companylogo from '../components/images/company.png'
import Key from "../clientVariables.json";
import axios from "axios"
import MUIDataTable from "mui-datatables";
import moment from 'moment'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdSettings, IoMdTrash, IoMdShare } from 'react-icons/io'
import { MdUpdate, MdCreateNewFolder, MdMailOutline, MdEdit, MdExitToApp, MdDelete } from "react-icons/md"
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import request from 'src/utils/request';
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import "./dbconstraints.css"
import Iconify from '../components/Iconify';
import { showLoader, hideLoader } from "../constant/loader.action";


const ConstraintsList = (props) => {
    const [list, setList] = useState([]);
    const [tableTitle, setTableTitle] = useState("Skills List")
    const [selectedListID, setSelectedListID] = useState(1)
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedVisatype, setSelectedVisatype] = useState([]);
    const [selectedJobtype, setSelectedJobtype] = useState([]);
    const [showJobtype, setShowJobtype] = useState(false);
    const [selectedSalesperson, setSelectedSalesperson] = useState([]);
    const [showSalesperson, setShowSalesperson] = useState(false);
    const [showVisatype, setShowVisatype] = useState(false);
    const [showSkills, setShowSkills] = useState(false);
    const [salesPersonName, setSalesPersonName] = useState("")
    const [createSkill, setCreateSkill] = useState("")
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [showAddsalesperson, setShowAddsalespersons] = useState(false);
    const [showAddSkills, setShowAddSkills] = useState(false);


    useEffect(() => {
        getList()
    }, [])


    const getList = () => {
        props.showLoader();

        fetch(`${Key.domain}/configuration/get_constraints/1`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                response.json().then((resdata) => {

                    console.log(resdata)
                    setList(resdata.data)
                    props.hideLoader();

                })
            })

    }


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <p className="mb-0 d-inline"

            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                console.log(children)
            }}
        >
            {children}

        </p>
    ));

    var skillColumns = [
        {
            label: <span>ID</span>,
            name: "id",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },
        {
            label: <span>Skill Name</span>,
            name: "skills",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },

        {
            label: <span>Status</span>,
            name: "enabled",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "Inactive"
                }
            },
        },

        {
            label: <span>Actions</span>,
            name: "enabled",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span className='drop-down-hover'>
                                <Dropdown style={{
                                    display: "inline", backgroundColor: "", borderColor: ""
                                }}>
                                    <Dropdown.Toggle id="dropdown-custom-components">

                                        <IconButton aria-label="Example">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1" onClick={() => setShowSkills(true)}> <MdEdit /> Edit</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onClick={(e) => activeInactiveStatus(e, parseInt(value))}> <AirplanemodeInactiveIcon />{parseInt(value) === 1 ? "Inactive" : "Active"}</Dropdown.Item>
                                        <Dropdown.Item eventKey="1" onClick={() => setShowDeleteConfirmation(true)}> <MdDelete /> Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>&nbsp;

                        </div>


                    );
                },
            },
        },
    ];

    var visaColumns = [
        {
            label: <span>ID</span>,
            name: "id",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },
        {
            label: <span>Visa Type Name</span>,
            name: "type_name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },

        {
            label: <span>Status</span>,
            name: "isactive",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "Inactive"
                }
            },
        },

        {
            label: <span>Actions</span>,
            name: "isactive",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span className='drop-down-hover'>
                                <Dropdown style={{ display: "inline" }}>
                                    <Dropdown.Toggle id="dropdown-custom-components">

                                        <IconButton aria-label="Example">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1" onClick={() => setShowVisatype(true)}> <MdEdit /> Edit</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onClick={(e) => activeInactiveStatusVisatype(e, parseInt(value))}> <AirplanemodeInactiveIcon />{parseInt(value) === 1 ? "Inactive" : "Active"}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>&nbsp;

                        </div>


                    );
                },
            },
        },
    ];


    var jobColumns = [
        {
            label: <span>ID</span>,
            name: "id",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },
        {
            label: <span>Job Type Name</span>,
            name: "title",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },

        {
            label: <span>Status</span>,
            name: "isactive",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "Inactive"
                }
            },
        },
        {
            label: <span>Actions</span>,
            name: "isactive",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span className='drop-down-hover'>
                                <Dropdown style={{ display: "inline" }}>
                                    <Dropdown.Toggle id="dropdown-custom-components">

                                        <IconButton aria-label="Example">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1" onClick={() => setShowJobtype(true)} > <MdEdit /> Edit</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onClick={(e) => activeInactiveStatusJobtype(e, parseInt(value))}> <AirplanemodeInactiveIcon /> {parseInt(value) === 1 ? "Inactive" : "Active"}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>&nbsp;

                        </div>


                    );
                },
            },
        },
    ];


    var stateColumns = [
        {
            label: <span>ID</span>,
            name: "id",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },
        {
            label: <span>State Name</span>,
            name: "name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },

        {
            label: <span>Status</span>,
            name: "status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "Inactive"
                }
            },
        },
        {
            label: <span>Actions</span>,
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span>
                                <Dropdown style={{ display: "inline" }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Settings</Tooltip>}>
                                            <IconButton aria-label="Example">
                                                <MoreVertIcon />
                                            </IconButton>
                                        </OverlayTrigger>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1"> <MdEdit /> Edit</Dropdown.Item>
                                        <Dropdown.Item eventKey="2"> <AirplanemodeInactiveIcon /> Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>&nbsp;

                        </div>


                    );
                },
            },
        },
    ];


    var salespersonsColumns = [
        {
            label: <span>ID</span>,
            name: "id",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },
        {
            label: <span>Name</span>,
            name: "name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                }
            },
        },

        {
            label: <span>Status</span>,
            name: "status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? "Active" : "Inactive"
                }
            },
        },
        {
            label: <span>Actions</span>,
            name: "status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span className='drop-down-hover'>
                                <Dropdown style={{ display: "inline" }}>
                                    <Dropdown.Toggle id="dropdown-custom-components">

                                        <IconButton aria-label="Example">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="1" onClick={() => setShowSalesperson(true)} > <MdEdit /> Edit</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onClick={(e) => activeInactiveStatusSalesperson(e, parseInt(value))}> <AirplanemodeInactiveIcon /> {parseInt(value) === 1 ? "Inactive" : "Active"}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>&nbsp;

                        </div>


                    );
                },
            },
        },
    ];




    const onSelectListChange = (e) => {

        var v = e.target.value
        var n = e.target.name
        console.log(v)
        console.log(n)


        setSelectedListID(v)

        setTableTitle(
            parseInt(v) === 1 ? "Skills List" :
                parseInt(v) === 2 ? "Visa Types List" :
                    parseInt(v) === 3 ? "Job Types List" :
                        parseInt(v) === 4 ? "States List" :
                            parseInt(v) === 5 ? "Sales Persons List" :
                                "Sales Persons List")



        props.showLoader();


        fetch(`${Key.domain}/configuration/get_constraints/${v}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                response.json().then((resdata) => {

                    console.log(resdata)
                    setList(resdata.data)
                    props.hideLoader();

                })
            })



    }


    const updateSkill = async (e) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateSkills']}`,
            postOptions({
                id: selectedRow.id,
                skills: selectedRow.skills,
                status: 1,
                action: 0
            }))
        if (result.data.affectedRows === 1) {
            setShowSkills(false)
            getList()
        }
    }

    const deleteSkill = async (e) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateSkills']}`,
            postOptions({
                id: selectedRow.id,
                action: 2  //2 for delete
            }))
        if (result.data.affectedRows === 1) {
            setShowDeleteConfirmation(false)
            getList()
        }
    }



    const addSalesperson = async (e) => {

        e.preventDefault()
        const result = await request(`${ENDPOINT['addSalesPerson']}`,
            postOptions({
                name: salesPersonName
            }))
        if (result.data.affectedRows === 1) {
            setShowAddsalespersons(false)
            setSalesPersonName("")
            props.showLoader();


            fetch(`${Key.domain}/configuration/get_constraints/5`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })

        }
    }



    const addSkills = async (e) => {

        e.preventDefault()
        props.showLoader();

        const result = await request(`${ENDPOINT['UpdateSkills']}`,
            postOptions({
                skills: createSkill,
                status: 1,
                action: 1
            }))
        if (result.data.affectedRows === 1) {
            setShowAddSkills(false)
            setCreateSkill("")
            getList()
        }
    }



    const updateVisatype = async (e) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateVisatypes']}`,
            postOptions({
                id: selectedVisatype.id,
                visa_name: selectedVisatype.type_name,
                status: 1,
                action: 0
            }))
        if (result.data.affectedRows === 1) {
            setShowVisatype(false)
            props.showLoader();

            //getList()
            fetch(`${Key.domain}/configuration/get_constraints/2`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })


        }
    }



    const updateJobtype = async (e) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateJobtypes']}`,
            postOptions({
                id: selectedJobtype.id,
                job_type: selectedJobtype.title,
                status: 1,
                action: 0
            }))
        if (result.data.affectedRows === 1) {
            setShowJobtype(false)
            props.showLoader();

            //getList()
            fetch(`${Key.domain}/configuration/get_constraints/3`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })


        }
    }

    const updateSalesPerson = async (e) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['updateSalesPerson']}`,
            postOptions({
                id: selectedSalesperson.id,
                name: selectedSalesperson.name,
                status: 1,
                action: 0
            }))
        if (result.data.affectedRows === 1) {
            setShowSalesperson(false)
            props.showLoader();

            //getList()
            fetch(`${Key.domain}/configuration/get_constraints/5`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })


        }
    }



    const activeInactiveStatus = async (e, status) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateSkills']}`,
            postOptions({
                id: selectedRow.id,
                skills: 0,
                status: status === 1 ? 0 : 1,
                action: 0
            }))

        if (result.data.affectedRows === 1) {
            getList()

        }
    }

    const activeInactiveStatusVisatype = async (e, status) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateVisatypes']}`,
            postOptions({
                id: selectedVisatype.id,
                visa_name: selectedVisatype.type_name,
                status: status === 1 ? 0 : 1,
                action: 0
            }))

        if (result.data.affectedRows === 1) {
            props.showLoader();
            fetch(`${Key.domain}/configuration/get_constraints/2`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })
        }
    }


    const activeInactiveStatusJobtype = async (e, status) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['UpdateJobtypes']}`,
            postOptions({
                id: selectedJobtype.id,
                job_type: selectedJobtype.title,
                status: status === 1 ? 0 : 1,
                action: 0
            }))

        if (result.data.affectedRows === 1) {
            props.showLoader();

            fetch(`${Key.domain}/configuration/get_constraints/3`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })
        }
    }


    const activeInactiveStatusSalesperson = async (e, status) => {
        e.preventDefault()
        const result = await request(`${ENDPOINT['updateSalesPerson']}`,
            postOptions({
                id: selectedJobtype.id,
                name: selectedJobtype.name,
                status: status === 1 ? 0 : 1,
                action: 0
            }))

        if (result.data.affectedRows === 1) {
            props.showLoader();

            fetch(`${Key.domain}/configuration/get_constraints/5`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    response.json().then((resdata) => {

                        console.log(resdata)
                        setList(resdata.data)
                        props.hideLoader();

                    })
                })
        }
    }






    const options = {
        onCellClick: (colData, cellMeta, cellIndex) => {


            var row = list[cellMeta.dataIndex];
            console.log(row)
            setSelectedRow(row)
            setSelectedVisatype(row)
            setSelectedJobtype(row)

            setSelectedSalesperson(row)
            console.log(cellMeta.colIndex)


        },


        selectableRows: false
    };



    return (
        <div>

            <Modal show={showAddsalesperson} onHide={() => setShowAddsalespersons(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Salesperson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => addSalesperson(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Person name"
                                                aria-label="Skills"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSalesPersonName(e.target.value)}
                                                value={salesPersonName}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showAddSkills} onHide={() => setShowAddSkills(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Skill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => addSkills(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Skill"
                                                aria-label="Skill"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setCreateSkill(e.target.value)}
                                                value={createSkill}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal show={showSkills} onHide={() => setShowSkills(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Skill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => updateSkill(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Skills"
                                                aria-label="Skills"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSelectedRow({ ...selectedRow, skills: e.target.value })}
                                                value={selectedRow && selectedRow.skills}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm to delete skill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => deleteSkill(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Skills"
                                                aria-label="Skills"
                                                aria-describedby="basic-addon2"
                                                // onChange={(e) => setSelectedRow({ ...selectedRow, skills: e.target.value })}
                                                value={selectedRow && selectedRow.skills}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showVisatype} onHide={() => setShowVisatype(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Visatype</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => updateVisatype(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Skills"
                                                aria-label="Skills"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSelectedVisatype({ ...selectedVisatype, type_name: e.target.value })}
                                                value={selectedVisatype && selectedVisatype.type_name}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showJobtype} onHide={() => setShowJobtype(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Jobtype</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => updateJobtype(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Jobtype"
                                                aria-label="Jobtype"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSelectedJobtype({ ...selectedJobtype, title: e.target.value })}
                                                value={selectedJobtype && selectedJobtype.title}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showSalesperson} onHide={() => setShowSalesperson(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Salesperson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <form onSubmit={(e) => updateSalesPerson(e)}>
                                <div className="chat-message-form">
                                    <div className="form-grouping">
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Salesperson"
                                                aria-label="Salesperson"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSelectedSalesperson({ ...selectedSalesperson, name: e.target.value })}
                                                value={selectedSalesperson && selectedSalesperson.name}
                                            />

                                        </InputGroup>
                                        &nbsp;
                                        <Button variant="primary" type="submit" style={{ float: "right", marginTop: "10px" }} >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className='container'>
                <span>
                    <div className='col-sm-3'>
                        <select class="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => onSelectListChange(e)}>
                            <option value="1" name="Skills List">Skills</option>
                            <option value="2" name="Visa Types List">Visa Types</option>
                            <option value="3" name="Job Types List">Job Types</option>
                            <option value="4" name="States List">States</option>
                            <option value="5" name="Sales Persons List">Sales Persons</option>
                        </select>
                    </div>
                </span>
            </div>
            <br />

            <MUIDataTable
                options={options}
                title={<h4>{tableTitle}

                    {parseInt(selectedListID) === 5 ?
                        <Button variant="primary" onClick={() => { setShowAddsalespersons(true) }} style={{ float: "none", marginLeft: "5%" }} >
                            Add
                        </Button> : parseInt(selectedListID) === 1 ?
                            <Button variant="primary" onClick={() => { setShowAddSkills(true) }} style={{ float: "none", marginLeft: "5%" }} >
                                Add
                            </Button> : ""}

                </h4>}
                data={list}
                columns={parseInt(selectedListID) === 1 ? skillColumns :
                    parseInt(selectedListID) === 2 ? visaColumns :
                        parseInt(selectedListID) === 3 ? jobColumns :
                            parseInt(selectedListID) === 4 ? stateColumns :
                                salespersonsColumns}
            />
        </div >
    )
}


const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsList);