import React, { useEffect, useState } from 'react';
import { Card, Container } from '@mui/material';
import axios from 'axios'
import { connect } from "react-redux";
import moment from 'moment'
import { useLocation,useNavigate } from "react-router-dom";
import Key from '../clientVariables.json'



const SubscriberDetailsView = (props) => {
  const location = useLocation();
  const history = useNavigate();

  const [subscriberDetails, setSubscriberDetails] = useState({})
  const [planDetails, setPlanDetails] = useState({})
  const [companyDetails, setCompanyDetails] = useState({})
  console.log(location.state)

  useEffect(() => {
    console.log(location.state)
    axios
      .post(`${Key.domain}/customer/get_Subscriberdetails`, {
        "subscriber_id": location.state.subid,
      })
      .then((response) => {
        // console.log(response)
        console.log(response.data.data)

        setSubscriberDetails(response.data.data.userDetails);
        setPlanDetails(response.data.data.packageDetails);
        setCompanyDetails(response.data.data.companyDetails)

        props.hideLoader();

      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {

          console.log(err)
        }
      });;


  }, [])
  const upgradePackage = () => {
    history("/dashboard/addcustomer", { state: { customerid: location.state.subid } });
  }


  return (
    <div>
      <Container>
        <Card style={{ padding: '32px', marginBottom: '15px' }}>
          <div className="row">
            <div className="col-6">
              <h5 className='mb-4'>Subscriber Details</h5>
              <div className="row">
                <div className="col-5">
                  <p>CustomerID</p>
                  <p>Company Name</p>
                  <p>Email</p>
                  <p>Phone</p>
                  <p>Website</p>
                  <p>Registered with JobsnProfiles</p>
                </div>
                <div className="col-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div className="col-6">
                  <p>{subscriberDetails.id ? subscriberDetails.id : "N/A"}</p>
                  <p>{companyDetails && companyDetails.name ? companyDetails.name : "N/A"}</p>
                  <p>{subscriberDetails.username ? subscriberDetails.username : "N/A"}</p>
                  <p>{companyDetails && companyDetails.contactphone ? companyDetails.contactphone : "N/A"}</p>
                  <p>{companyDetails && companyDetails.url ? companyDetails.url : "N/A"}</p>
                  <p>{subscriberDetails.registerDate ? moment(subscriberDetails.registerDate).format("LL") : "N/A"}</p>
                </div>
              </div>
              <div className="row">
                <h5>Source</h5>
                <div className="col-5 ms-5">
                  <p>Direct Subscriber </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5 className='mb-4'>Plan Details</h5>

              <div className="row">
                <div className="col-5">
                  <p>Plan Opted</p>
                  <p>Benefits Active From</p>
                  <p>Benefits Expires On </p>
                  <p>Posts Allowed </p>
                  <p>Resume views Allowed </p>




                </div>
                <div className="col-2">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>



                </div>
                <div className="col-5">

                  <p>free(default)</p>
                  <p>{planDetails && planDetails.package_start_date ? moment(planDetails.package_start_date).format("LL") : "N/A"}</p>
                  <p>{planDetails && planDetails.package_end_date ? moment(planDetails.package_end_date).format("LL") : "N/A"}</p>
                  <p>{planDetails && planDetails.jobs_allowed ? planDetails.jobs_allowed : "N/A"}</p>
                  <p>{planDetails && planDetails.resumeviews_allowed ? planDetails.resumeviews_allowed : "N/A"}</p>






                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-4 w-25" onClick={() => upgradePackage()}>Upgrade</button>

            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default SubscriberDetailsView;
