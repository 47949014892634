import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios'
import { connect } from "react-redux";
import moment from 'moment'
import { useLocation, useNavigate } from "react-router-dom";

import Key from '../clientVariables.json'
import BsPersonalInfo from './BsPersonalInfo';
import BsCompanyInfo from './BsCompanyInfo';
import BsResumesList from './BsResumesList';
import CustomerNotes from './CustomerNotes';
import Activity from "./Activity";
import Mailbox from './Mail';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomerDetailView2 = () => {
    const [value, setValue] = useState(0);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [packageDetails, setPackageDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [resumesList, setResumesList] = useState([]);

    const location = useLocation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (location.state && location.state.setIndex) {
            setValue(location.state.setIndex)
        }
    }, [])
    useEffect(() => {
        // props.showLoader();

        axios
            .post(`${Key.domain}/customer/get_benchsalesdetails`, {
                "user_id": location.state.bsuserid
            })
            .then((response) => {
                // console.log(response)
                console.log(response.data.data)

                // console.log(response.data.data.companyDetails)

                setCustomerDetails(response.data.data)
                setCompanyDetails(response.data.data.companyDetails ? response.data.data.companyDetails : [])
                setUserDetails(response.data.data.userDetails ? response.data.data.userDetails : [])
                // props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    //   props.hideLoader();


                }
            });;

    }, [])
    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Profile " {...a11yProps(0)} />
                    <Tab label="Company Info" {...a11yProps(1)} />
                    <Tab label="Resumes" {...a11yProps(2)} />
                    {/* <Tab label="Mail Conversation" {...a11yProps(2)} />
                    <Tab label="Queries" {...a11yProps(2)} />
                    <Tab label="Activity" {...a11yProps(2)} /> */}


                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <BsPersonalInfo packageDetails={packageDetails} userDetails={userDetails} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BsCompanyInfo companyDetails={companyDetails} userDetails={userDetails} />
            </TabPanel>

            <TabPanel value={value} index={2} >
                <BsResumesList userDetails={userDetails} resumes= {resumesList}/>
            </TabPanel>


            <TabPanel value={value} index={4} >
                <Mailbox />
            </TabPanel>
            <TabPanel value={value} index={5} >
                <CustomerNotes bsuserid={location.state.bsuserid} />
            </TabPanel>
            <TabPanel value={value} index={6} >
                <Activity bsuserid={location.state.bsuserid} />
            </TabPanel>
        </Box>
    )
}

export default CustomerDetailView2