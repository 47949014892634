/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'
import { BiFoodTag, BiArrowBack } from "react-icons/bi";
import UserDetails from './UserDetails';
import { Button } from '@mui/material';
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

import CustomerTrack from "./CustomerTrackStatus"
const CustomerPersonalInfo = (props) => {
  const { userDetails } = props;
  const navigate = useNavigate();
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [refreshh, setrefreshh] = useState(1);
  console.log(userDetails)
  const redirectTo = () => {
    if (props !== undefined) {
      if (props.packageDetails.package_id === "1") {
        navigate("/dashboard/Subscriptions")
      } else {
        navigate("/dashboard/user")
      }
    }

  };

  const handleDeactivateClose = () => {
    setShowDeactivate(false)
  }

  const deactivateAccountSubmit = () => {
    props.showLoader();

    axios
      .post(`${Key.domain}/customer/deactivate_jnp_user`, {
        "email": userDetails.username
      })
      .then((response) => {
        console.log(response)
        const reloadusers = refreshh + 1
        setrefreshh(reloadusers)

        props.refreshData()
        // props.hideLoader();
        const msg = `Account ${userDetails.company_name} deactivated Successfully !`;
        toast(<ToastMessage message={msg} />);

      })
  }


  return (
    <div>
      <div className="container">
        <div className="row">

          <div className="col-6">

            <div className="card p-2 mb-4">
              {/* <div className="row mb-1">
                <div className="col-5">
                  {' '}
                  <b> Name</b>
                </div>
                <div className="col-1">:</div>
                <div className="col-6">{userDetails.full_name ? userDetails.full_name : 'N/A'}</div>
              </div> */}
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><div className="row mb-1">
                  <div className="col-5">
                    {' '}
                    <b> Name</b>
                  </div>
                  <div className="col-1">:</div>
                  <div className="col-6">{userDetails.full_name ? userDetails.full_name : 'N/A'}</div>
                </div></li>
                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> Email</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.username ? userDetails.username : 'N/A'}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> Phone</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.phone_number ? userDetails.phone_number : 'N/A'} {userDetails.phone_number? <span style={{margin:"0px 10px"}}><b>ext. </b>{userDetails.extension !== "undefined" && userDetails.extension!==null ?userDetails.extension:"N/A" }</span> :<></>}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> Role</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.role ? userDetails.role : 'N/A'}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> City</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.cityname ? userDetails.cityname : 'N/A'}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> State</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.statename ? userDetails.statename : 'N/A'}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row mb-1">
                    <div className="col-5">
                      {' '}
                      <b> Zipcode</b>
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-6">{userDetails.zipcode ? userDetails.zipcode : 'N/A'}</div>
                  </div>
                </li>

              </ul>


              <Button variant="secondary" className=" m-2" style={{ background: "grey", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(true); }
            }>
              Deactivate
            </Button>

            {showDeactivate ?
              <div className='mt-2'  style={{ marginLeft: "15px" }}>

                <h5>Confirm to <span style={{ textDecoration: "underline", color: "grey" }}>deactivate</span> account ? </h5>

                <div>
                  <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                    No
                  </Button>
                  <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                    Yes
                  </Button>
                </div>
              </div>
              : ""}




            </div>
          </div>
          <div className="col-6">
            {/* <button onClick={redirectTo} style={{ float: "right", background: "#2065D1" }} className="btn tbn-secondary">
              <BiArrowBack size={18} color="white" />
            </button> */}
          

            <CustomerTrack userDetails={userDetails} />

          </div>
        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonalInfo);