import * as Yup from 'yup';
import { useState , useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { withRouter } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import { connect } from "react-redux";
import axios from 'axios'
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import UserActions from "../../../controllers/user.controller";
// ----------------------------------------------------------------------

function LoginForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  useEffect(() => {
    axios.get(`https://api.ipify.org/?format=text`)
      .then(async response => {
        const ipAdress = response.data
        setIpAddress(ipAdress)
      })

  }, [])

  useEffect(() => {
if(isSubmitting === true) {
  setTimeout(() => {
    setIsSubmitting(false)
  }, 3000)
}
  }, [isSubmitting])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      console.log(values)
      setIsSubmitting(true)
      props.loginUser(values.email, values.password, ipAddress)
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgotpasswordemail" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}


const mapDispatchToProps = function (dispatch) {
  return {
    loginUser: (email, password, ipaddress) => {
      UserActions.login(email, password, ipaddress)(dispatch);
    },
    empty: (msg) => {
      UserActions.formEmpty(msg)(dispatch);
    },
    Loading: (status) => {
      UserActions.LoginLoadingSuccess(status)(dispatch);
    }

  };
};

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
    isLoading: state.UserReducer.isLoginLoading,
    iserror: state.UserReducer.error,
  };
}
export default (connect(mapStateToProps, mapDispatchToProps)(LoginForm));

