/* eslint-disable */

import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useState, useRef } from 'react';
import Key from '../../src/clientVariables.json'
import axios from 'axios'
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Purchaseorderview from './Purchaseorderview';

const LoginSchema = Yup.object().shape({
    // profileviews: Yup.string().required('profile views is required'),

    //postCount: Yup.string().required('This Field is required'),
    //ViewsCount: Yup.string().required('This Field is required'),

    // companies: Yup.string().required('This Field is required'),

    // packageduration: Yup.string().required('This Field is required'),
});
const planDetails = [
    { id: 2, value: 'Monthly' },
    { id: 3, value: 'Quaterly' },
    { id: 4, value: 'Half-yearly' },
    { id: 5, value: 'Anually' },
];


const Packagelimitations = (props) => {
    const [packageData, setPackageData] = useState([]);
    const [postPackage, setPostPackage] = useState('');
    const [viewsPackage, setViewsPackage] = useState('');
    const [companiesPackage, setCompaniesPackage] = useState('');
    const [recruitersPackage, setRecruiterPackage] = useState('');


    const [postAddonPackage, setPostAddonPackage] = useState('');
    const [viewsAddonPackage, setViewsAddonPackage] = useState('');
    const [recruitersAddonPackage, setRecruiterAddonPackage] = useState('');

    const [packageId, setPackageId] = useState('');
    const [paidPackageId, setPaidPackageId] = useState('');

    const [price, setPrice] = useState('');
    const [extraPosts, setExtraPosts] = useState(0)
    const [extraViews, setExtraViews] = useState(0)
    const [extraCompanies, setExtraCompanies] = useState(0)
    const [extraRecuiters, setExtraRecuiters] = useState(0)
    const [packageDetails, setPackageDetails] = useState([]);
    const [newpackageDetails, setnewPackageDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [compid, setCompid] = useState(0);

    const [jobsAllowed, setJobsAllowed] = useState(0);
    const [resumesAllowed, setResumesAllowed] = useState(0);
    const [recruitersAllowed, setRecruitersAllowed] = useState(0);

    const [jobsAddon, setJobsAddon] = useState(0);
    const [resumesAddon, setResumesAddon] = useState(0);
    const [recruitersAddon, setRecruitersAddon] = useState(0);

    const [companiesAllowed, setCompaniesAllowed] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [packageChange, setPackageChange] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState([])
    const [packageTitle, setPackageTitle] = useState("");
    const [buttonStatus, setButtonStatus] = useState(false)
    const history = useNavigate();

    const [addPosts, setAddPosts] = useState(0)
    const [addViews, setAddViews] = useState(0)
    const [addRecruiters, setAddRecruiters] = useState(0)

    const formikRef = useRef();
    const [open, setOpen] = React.useState(false);

    const location = useLocation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {


        axios.get(`${Key.domain}/customer/get_all_emp_packages`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        })
            .then(response => {
                var data = response.data.data
                // console.log(data)
                setPackageData(data)
            })


        getGetCustomerDetails()



    }, [])


    const handleSendMail = () => {
        setButtonLoading(true)
        axios
            .post(`${Key.domain}/customer/get_upgrade_packagedetails`, {
                customer_id: props.companyuserid,
            })
            .then((response) => {
                setnewPackageDetails(response.data.data.packageDetails)
                sessionStorage.setItem("newpackage", JSON.stringify(response.data.data));
                sessionStorage.setItem("newAmount", response.data.data.packageDetails.Amount);

                axios
                    .post(`${Key.domain}/payment/send_payment_mail`, {

                        "crm_userid": sessionStorage.getItem("id"),
                        "session_token": props.currentUser.session_token,
                        "customer_id": props.companyuserid,
                        "customer_email": customerDetails.userDetails.username,
                        "agreementData": customerDetails,
                        "price": price

                    })
                    .then((response) => {
                        setButtonLoading(false)
                        if (response.data.data && response.data.data === "mail sent") {
                            trackStatusUpdateForAdmin(3)
                            let msg = "Mail sent to customer successfully!"
                            toast(
                                <ToastMessage message={msg} />
                            )
                            history("/dashboard/user")
                        } else {
                            let msg = "Sending mail failed!"
                            toast(
                                <ToastMessage message={msg} />
                            )
                        }

                    })
            });


    }



    const getGetCustomerDetails = () => {
        if (props.companyuserid !== "" && props.companyuserid !== null && props.companyuserid !== undefined && props.companyuserid !== 0) {
            axios
                .post(`${Key.domain}/customer/get_upgrade_packagedetails`, {
                    customer_id: props.companyuserid,
                })
                .then((response) => {

                    console.log(response.data.data.packageDetails);
                    setPaidPackageId(response.data.data.packageDetails.package_id)
                    setPackageTitle(response.data.data.packageDetails.title)
                    setPackageId(response.data.data.packageDetails.package_id)
                    setPackageDetails(response.data.data.packageDetails)
                    setCustomerDetails(response.data.data)
                    setCompid(response.data.data.companyDetails ? response.data.data.companyDetails.id : 0)

                    if (response.data.data.packageDetails !== undefined) {

                        setPostPackage(response.data.data.packageDetails.jobs_allowed && response.data.data.packageDetails.jobs_allowed !== null && response.data.data.packageDetails.jobs_allowed !== undefined && response.data.data.packageDetails.jobs_allowed !== "null" ? response.data.data.packageDetails.jobs_allowed : 0);
                        setViewsPackage(response.data.data.packageDetails.resumeviews_allowed && response.data.data.packageDetails.resumeviews_allowed !== null && response.data.data.packageDetails.resumeviews_allowed !== undefined && response.data.data.packageDetails.resumeviews_allowed !== "null" ? response.data.data.packageDetails.resumeviews_allowed : 0);
                        setCompaniesPackage(response.data.data.packageDetails.companies_allowed && response.data.data.packageDetails.companies_allowed !== null && response.data.data.packageDetails.companies_allowed !== undefined && response.data.data.packageDetails.companies_allowed !== "null" ? response.data.data.packageDetails.companies_allowed : 0);
                        setRecruiterPackage(response.data.data.packageDetails.users_allowed && response.data.data.packageDetails.users_allowed !== null && response.data.data.packageDetails.users_allowed !== undefined && response.data.data.packageDetails.users_allowed !== "null" ? response.data.data.packageDetails.users_allowed : 0);

                        setPostAddonPackage(response.data.data.packageDetails.jobs_addon && response.data.data.packageDetails.jobs_addon !== null && response.data.data.packageDetails.jobs_addon !== undefined && response.data.data.packageDetails.jobs_addon !== "null" ? response.data.data.packageDetails.jobs_addon : 0);
                        setViewsAddonPackage(response.data.data.packageDetails.resumeviews_addon && response.data.data.packageDetails.resumeviews_addon !== null && response.data.data.packageDetails.resumeviews_addon !== undefined && response.data.data.packageDetails.resumeviews_addon !== "null" ? response.data.data.packageDetails.resumeviews_addon : 0);
                        setRecruiterAddonPackage(response.data.data.packageDetails.users_addon && response.data.data.packageDetails.users_addon !== null && response.data.data.packageDetails.users_addon !== undefined && response.data.data.packageDetails.users_addon !== "null" ? response.data.data.packageDetails.users_addon : 0);

                        //  setPrice(packageData[4].price)
                        if (formikRef.current) {
                            formikRef.current.setFieldValue(
                                "plandetails",
                                response.data.data.packageDetails.package_id
                            );
                            formikRef.current.setFieldValue(
                                "postCount",
                                response.data.data.packageDetails.jobs_allowed && response.data.data.packageDetails.jobs_allowed !== null && response.data.data.packageDetails.jobs_allowed !== undefined && response.data.data.packageDetails.jobs_allowed !== "null" ? response.data.data.packageDetails.jobs_allowed : 0

                            );
                            formikRef.current.setFieldValue(
                                "ViewsCount",
                                response.data.data.packageDetails.resumeviews_allowed && response.data.data.packageDetails.resumeviews_allowed !== null && response.data.data.packageDetails.resumeviews_allowed !== undefined && response.data.data.packageDetails.resumeviews_allowed !== "null" ? response.data.data.packageDetails.resumeviews_allowed : 0
                            );

                            formikRef.current.setFieldValue(
                                "companiesCount",
                                response.data.data.packageDetails.companies_allowed && response.data.data.packageDetails.companies_allowed !== null && response.data.data.packageDetails.companies_allowed !== undefined && response.data.data.packageDetails.companies_allowed !== "null" ? response.data.data.packageDetails.companies_allowed : 0
                            );
                            formikRef.current.setFieldValue(
                                "recruitersCount",
                                response.data.data.packageDetails.users_allowed && response.data.data.packageDetails.users_allowed !== null && response.data.data.packageDetails.users_allowed !== undefined && response.data.data.packageDetails.users_allowed !== "null" ? response.data.data.packageDetails.users_allowed : 0
                            );
                            formikRef.current.setFieldValue(
                                "extraPosts",
                                response.data.data.packageDetails.jobs_addon && response.data.data.packageDetails.jobs_addon !== null && response.data.data.packageDetails.jobs_addon !== undefined && response.data.data.packageDetails.jobs_addon !== "null" ? response.data.data.packageDetails.jobs_addon : 0
                            );

                            formikRef.current.setFieldValue(
                                "extraViews",
                                response.data.data.packageDetails.resumeviews_addon && response.data.data.packageDetails.resumeviews_addon !== null && response.data.data.packageDetails.resumeviews_addon !== undefined && response.data.data.packageDetails.resumeviews_addon !== "null" ? response.data.data.packageDetails.resumeviews_addon : 0
                            );
                            formikRef.current.setFieldValue(
                                "extraRecruiters",
                                response.data.data.packageDetails.users_addon && response.data.data.packageDetails.users_addon !== null && response.data.data.packageDetails.users_addon !== undefined && response.data.data.packageDetails.users_addon !== "null" ? response.data.data.packageDetails.users_addon : 0
                            );


                            formikRef.current.setFieldValue("addposts", 0);
                            formikRef.current.setFieldValue("addviews", 0);
                            formikRef.current.setFieldValue("addrecruiters", 0);
                        }
                    }
                });
        }
    }

    const getPlanDetails = (value, event) => {
        setPackageTitle(event.target[event.target.selectedIndex].id)
        if (formikRef.current) {
            formikRef.current.setFieldValue("plandetails", value);
            setPackageChange(true)
        }

        if (paidPackageId === value) {
            setPackageChange(false)
            formikRef.current.setFieldValue("extraPosts", packageDetails.jobs_addon);
            formikRef.current.setFieldValue("extraViews", packageDetails.resumeviews_addon);
            formikRef.current.setFieldValue("extraRecruiters", packageDetails.users_addon);
        } else {
            formikRef.current.setFieldValue("extraPosts", 0);
            formikRef.current.setFieldValue("extraViews", 0);
            formikRef.current.setFieldValue("extraRecruiters", 0);
        }

        var selectedPackage = packageData.filter(selectedPackage => selectedPackage.id === parseInt(value))
        setSelectedPackage(selectedPackage)

        if (value === "0") {
            setPackageId(value)
            setPostPackage("");
            setViewsPackage("");
            setCompaniesPackage("");
            setRecruiterPackage("");
            setPrice("")
            // setPostViews(packageData.filter(p=>p.id==value?`5/${p.value}`:""))
            formikRef.current.setFieldValue('postCount', "");
            formikRef.current.setFieldValue('ViewsCount', "");
            formikRef.current.setFieldValue('companiesCount', "");
            formikRef.current.setFieldValue('recruitersCount', "");
        } else {
            setPackageId(value)
            let IndexValue = parseInt(value) - 1
            setPostPackage(selectedPackage[0].jobs_allowed);
            setViewsPackage(selectedPackage[0].resumeViews_allowed);
            setCompaniesPackage(selectedPackage[0].companies_allowed);
            setRecruiterPackage(selectedPackage[0].users_allowed);
            setPrice(selectedPackage[0].price)


            formikRef.current.setFieldValue('postCount', selectedPackage[0].jobs_allowed);
            formikRef.current.setFieldValue('ViewsCount', selectedPackage[0].resumeViews_allowed + "/monthly");
            formikRef.current.setFieldValue('companiesCount', selectedPackage[0].companies_allowed);
            formikRef.current.setFieldValue('recruitersCount', selectedPackage[0].users_allowed);
        }


    };

    const sendPaymentLink = (values) => {
        setButtonStatus(true)
        console.log(selectedPackage)
        // console.log(packageDetails.package_days)
        // console.log(props.currentUser)

        // console.log(values)

        // if (paidPackageId !== packageId) {

        if (postPackage === '') {
            alert('Posts Allowed is mandatory')
        } else if (viewsPackage === '') {
            alert('Views Allowed is mandatory')

        } else {
            setJobsAllowed(parseInt(postPackage) < 0 || postPackage === null || !postPackage || postPackage === "null" || postPackage === "" || postPackage === undefined ? 0 : parseInt(postPackage) + parseInt(extraPosts))
            setResumesAllowed(parseInt(viewsPackage) < 0 || viewsPackage === null || !viewsPackage || viewsPackage === "null" || viewsPackage === "" || viewsPackage === undefined ? 0 : parseInt(viewsPackage) + parseInt(extraViews))
            setCompaniesAllowed(parseInt(companiesPackage) < 0 || parseInt(companiesPackage) === 0 || companiesPackage === null || !companiesPackage || companiesPackage === "null" || companiesPackage === "" || companiesPackage === undefined ? 0 : parseInt(companiesPackage) + parseInt(extraCompanies))
            setRecruitersAllowed(parseInt(recruitersPackage) < 0 || recruitersPackage === null || !recruitersPackage || recruitersPackage === "" || recruitersPackage === "null" || recruitersPackage === undefined ? 0 : parseInt(recruitersPackage) + parseInt(extraRecuiters))

            if ((parseInt(postPackage) < 0 || parseInt(postPackage) === 0 || postPackage === null || !postPackage || postPackage === "null" || postPackage === "" || postPackage === undefined) &&
                (parseInt(viewsPackage) < 0 || parseInt(viewsPackage) === 0 || viewsPackage === null || !viewsPackage || viewsPackage === "null" || viewsPackage === "" || viewsPackage === undefined) &&
                (parseInt(companiesPackage) < 0 || parseInt(companiesPackage) === 0 || companiesPackage === null || !companiesPackage || companiesPackage === "null" || companiesPackage === "" || companiesPackage === undefined) &&
                (parseInt(recruitersPackage) < 0 || parseInt(recruitersPackage) === 0 || recruitersPackage === null || !recruitersPackage || recruitersPackage === "" || recruitersPackage === "null" || recruitersPackage === undefined)) {
                let msg = "Package details must be atleast 1"
                toast(
                    <ToastMessage message={msg} />
                )
                setButtonStatus(false)

            } else {
                axios
                    .post(`${Key.domain}/customer/insert_or_update_customer_subscription`, {
                        "userid": props.companyuserid !== undefined ? props.companyuserid : location.state.customerid,
                        "admin_id": sessionStorage.getItem("id"),
                        //"empidsub": 1,
                        "company_id": packageDetails !== undefined ? compid : props.companyId,
                        "package_id": packageId,
                        "jobs_allowed": parseInt(postPackage) < 0 || postPackage === null || !postPackage || postPackage === "null" || postPackage === "" || postPackage === undefined ? 0 : parseInt(postPackage) + parseInt(extraPosts),
                        "resumeviews_allowed": parseInt(viewsPackage) < 0 || viewsPackage === null || !viewsPackage || viewsPackage === "null" || viewsPackage === "" || viewsPackage === undefined ? 0 : parseInt(viewsPackage) + parseInt(extraViews),
                        "users_allowed": parseInt(recruitersPackage) < 0 || recruitersPackage === null || !recruitersPackage || recruitersPackage === "" || recruitersPackage === "null" || recruitersPackage === undefined ? 0 : parseInt(recruitersPackage) + parseInt(extraRecuiters),

                        "jobs_addon": parseInt(values.extraPosts) + parseInt(values.addposts ? values.addposts : 0),
                        "resumeviews_addon": parseInt(values.extraViews) + parseInt(values.addviews ? values.addviews : 0),
                        "users_addon": parseInt(values.extraRecruiters) + parseInt(values.addrecruiters ? values.addrecruiters : 0),

                        "companies_allowed": parseInt(companiesPackage) < 0 || parseInt(companiesPackage) === 0 || companiesPackage === null || !companiesPackage || companiesPackage === "null" || companiesPackage === "" || companiesPackage === undefined ? 0 : parseInt(companiesPackage) + parseInt(extraCompanies),
                        "status": 1,
                        "action": packageDetails !== undefined ? "1" : "0",
                        "session_token": props.currentUser.session_token,
                        "package_expires_in_days": packageId === "0" ? 15 : selectedPackage[0].package_expires_in_days,
                        "price": price

                    })
                    .then((response) => {
                        if (response.data.success === 1) {
                            if (parseInt(price) === 0) {
                                sendUpgradtionMailForFreePackageCustomers()
                                trackStatusUpdateForAdmin()
                            } else {
                                setOpen(true)
                                getGetCustomerDetails()
                                trackStatusUpdateForAdmin()
                            }


                        }
                    })
            }
        }

    }

    // console.log(customerDetails)
    const trackStatusUpdateForAdmin = async (flowStatus) => {
        try {

            const response = await axios.post(`${Key.domain}/customer/insert_or_update_subscriptions_trackStatus`, {
                "customer_id": props.companyuserid,
                "customer_name": customerDetails.userDetails.full_name,
                "customer_email": customerDetails.userDetails.username,
                "current_package_id": customerDetails.packageDetails.package_id,
                "targeted_package_id": packageId,
                "status": 1,
                "flow_status": flowStatus === 3 ? flowStatus : 2,
                "action_status": 0
            })
            if (response.data.success === 1) {
                setButtonStatus(false)

            }
        } catch (error) {
            setButtonStatus(false)

            console.log(error)
        }
    }

    const sendUpgradtionMailForFreePackageCustomers = () => {
        axios
            .post(`${Key.domain}/payment/send_upgradation_mail`, {
                "user_id": sessionStorage.getItem("id"),
                "customer_id": props.companyuserid,
                "to_email_id": customerDetails.userDetails.username,
                "customer_name": customerDetails.userDetails.full_name
            })
            .then((response) => {
                console.log(response)

                if (response.data.success === 1) {
                    alert(response.data.data)
                    setButtonStatus(false)
                    history('/dashboard/user')
                }
            })
    }
    // console.log(props,packageTitle, (props?.freepackage!=="" ?  true : false))
    // const buyMore = (values) => {

    //     if (packageDetails !== undefined) {
    //         if (values.addposts || values.addviews || values.addrecruiters) {
    //             console.log(props.currentUser)
    //             setJobsAllowed(parseInt(postPackage) + parseInt(extraPosts))
    //             setResumesAllowed(parseInt(viewsPackage) + parseInt(extraViews))
    //             setCompaniesAllowed(parseInt(companiesPackage) + parseInt(extraCompanies))
    //             setRecruitersAllowed(parseInt(recruitersPackage) + parseInt(extraRecuiters))
    //             axios
    //                 .post(`${Key.domain}/customer/insert_or_update_customer_subscription`, {
    //                     "userid": props.companyuserid,
    //                     "admin_id": sessionStorage.getItem("id"),
    //                     //"empidsub": 1,
    //                     "company_id": packageDetails !== undefined ? compid : props.companyId,
    //                     "package_id": packageId,
    //                     "jobs_allowed": parseInt(postPackage),
    //                     "resumeviews_allowed": parseInt(viewsPackage),
    //                     "users_allowed": parseInt(recruitersPackage),


    //                     "jobs_addon": parseInt(extraPosts) + parseInt(values.addposts ? values.addposts : 0),
    //                     "resumeviews_addon": parseInt(extraViews) + parseInt(values.addviews ? values.addviews : 0),
    //                     "users_addon": parseInt(extraRecuiters) + parseInt(values.addrecruiters ? values.addrecruiters : 0),

    //                     "companies_allowed": parseInt(companiesPackage) + parseInt(extraCompanies),
    //                     "status": 1,
    //                     "action": "1",
    //                     "session_token": props.currentUser.session_token

    //                 })
    //                 .then((response) => {
    //                     console.log(response)
    //                     if (response.data.success === 1) {
    //                         // alert("Successfully added customer")
    //                         setOpen(true)
    //                         getGetCustomerDetails()
    //                         // let msg = packageDetails !== undefined ? "Updated customer subscription" : "Successfully added customer !"
    //                         // toast(
    //                         //     <ToastMessage message={msg} />
    //                         // )
    //                     }
    //                 })
    //         }
    //     }
    // }


    return (
        <div className="container">
            <div className="row">
                <div>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            plandetails: '',
                            postCount: '',
                            ViewsCount: '',
                            // extraViews: '',
                            companiesCount: '',
                            //  extraCompanies: '',
                            recruitersCount: '',
                            //  extraRecruiter: ""
                            // profileviews: '', jobposts: '', companies: '', packageduration: '', branding: ''
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={(values) => {
                            // console.log(values);
                            sendPaymentLink(values)
                            // alert("Form is validated! Submitting the form...");
                        }}
                    >
                        {({ touched, errors, isSubmitting, values, handleChange }) => (
                            <div>
                                <Form>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p><b>Package Details</b></p>

                                        </div>
                                        <div className='col-md-1'> </div>
                                        <div className='col-md-5'>
                                            <div>

                                                <p><b>Add on</b></p>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                            <div className="form-group mb-3">
                                                <label htmlFor="State">Plan details</label>
                                                <Field
                                                    name="plandetails"
                                                    as="select"
                                                    className={
                                                        'mt-2 form-select form-control' +
                                                        (errors.plandetails && touched.plandetails ? ' is-invalid' : '')
                                                    }
                                                    onChange={(e) => getPlanDetails(e.target.value, e)}
                                                    defaultValue={values.plandetails}
                                                >
                                                    <option selected>Select Plan</option>

                                                    {packageData.map((item, index) => (
                                                        <option disabled={index === 0 && props.freepackage !=="" && props.freepackage ==="Free Package" ? true : false} value={item.id} id={item.title}>{item.title}</option>
                                                    ))}
                                                    <option value={"0"}>Custom Plan</option>
                                                </Field>
                                            </div>
                                            {packageId !== "0" ?
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="posts">Posts Allowed</label>
                                                                <Field
                                                                    type="text"
                                                                    name="postCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                                                    ${touched.postCount && errors.postCount ? 'is-invalid' : ''}`}
                                                                    //onChange={(e) => handleChange()}
                                                                    defaultValue={postPackage}
                                                                    disabled={packageChange ? false : true}
                                                                />
                                                                <ErrorMessage component="div" name="postCount" className="invalid-feedback" />
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="posts">Extra Posts </label>
                                                                <Field
                                                                    type='extraPosts'
                                                                    name="extraPosts"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                                    ${touched.extraPosts && errors.extraPosts ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Views Allowed</label>

                                                                <Field
                                                                    type="text"
                                                                    name="ViewsCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                                                   ${touched.ViewsCount && errors.ViewsCount ? 'is-invalid' : ''}`}
                                                                    onChange={(e) => handleChange()}
                                                                    defaultValue={viewsPackage}
                                                                    disabled={packageChange ? false : true}

                                                                />

                                                                <ErrorMessage component="div" name="ViewsCount" className="invalid-feedback" />

                                                            </div>
                                                        </div>


                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Extra Views</label>
                                                                <Field
                                                                    type='extraViews'
                                                                    name="extraViews"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                                                   ${touched.extraViews && errors.extraViews ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}
                                                                />


                                                            </div>


                                                        </div>


                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="recruiters">Recruiters Allowed</label>

                                                                <Field
                                                                    type="text"
                                                                    name="recruitersCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                                ${touched.recruitersCount && errors.recruitersCount ? 'is-invalid' : ''
                                                                        }`}
                                                                    onChange={(e) => handleChange()}
                                                                    defaultValue={recruitersPackage}
                                                                    disabled={packageChange ? false : true}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Extra Recuiters</label>

                                                                <Field
                                                                    type='extraRecruiters'
                                                                    name="extraRecruiters"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                                    ${touched.extraRecruiters && errors.extraRecruiters ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :

                                                //This div is for custom plan
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="posts">Posts Allowed <span style={{color:"red"}}>*</span></label>
                                                                <Field
                                                                    type="text"
                                                                    name="postCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                                                    ${touched.postCount && errors.postCount ? 'is-invalid' : ''}`}
                                                                    onChange={(e) => setPostPackage(e.target.value)}
                                                                    value={postPackage}
                                                                    disabled={packageChange ? false : true}
                                                                />
                                                                {postPackage === '' ?

                                                                    <ErrorMessage component="div" name="postCount" className="invalid-feedback" />
                                                                    : ''}
                                                            </div>
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="posts">Extra Posts </label>
                                                                <Field
                                                                    type='extraPosts'
                                                                    name="extraPosts"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                            ${touched.extraPosts && errors.extraPosts ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Views Allowed <span style={{color:"red"}}>*</span></label>

                                                                <Field
                                                                    type="text"
                                                                    name="ViewsCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                        ${touched.ViewsCount && errors.ViewsCount ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => handleChange()}
                                                                    // defaultValue={viewsPackage}
                                                                    disabled={packageChange ? false : true}
                                                                    onChange={(e) => setViewsPackage(e.target.value)}
                                                                    value={viewsPackage}
                                                                />
                                                                {viewsPackage === '' ?
                                                                    <ErrorMessage component="div" name="ViewsCount" className="invalid-feedback" /> : ''}

                                                            </div>
                                                        </div>


                                                        <div className='col-md-6'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Extra Views</label>
                                                                <Field
                                                                    type='extraViews'
                                                                    name="extraViews"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                            ${touched.extraViews && errors.extraViews ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}
                                                                />


                                                            </div>


                                                        </div>


                                                    </div>



                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="recruiters">Recruiters Allowed</label>

                                                                <Field
                                                                    type="text"
                                                                    name="recruitersCount"
                                                                    placeholder=""
                                                                    className={`mt-2 form-control
                                                                    ${touched.recruitersCount && errors.recruitersCount ? 'is-invalid' : ''
                                                                        }`}
                                                                    // onChange={(e) => handleChange()}
                                                                    value={recruitersPackage}
                                                                    disabled={packageChange ? false : true}
                                                                    onChange={(e) => setRecruiterPackage(e.target.value)}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="views">Extra Recuiters</label>

                                                                <Field
                                                                    type='extraRecruiters'
                                                                    name="extraRecruiters"
                                                                    autocomplete="off"
                                                                    className={`mt-2 form-control
                                                                    ${touched.extraRecruiters && errors.extraRecruiters ? 'is-invalid' : ''}`}
                                                                    // onChange={(e) => setExtraPosts(e.target.value)}
                                                                    disabled={packageChange ? false : true}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            }

                                            <Dialog
                                                fullScreen
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <AppBar sx={{ position: 'relative' }}>
                                                    <Toolbar>
                                                        <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={handleClose}
                                                            aria-label="close"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                            Close
                                                        </Typography>
                                                        {!buttonLoading ?
                                                            <Button autoFocus color="inherit" onClick={handleSendMail} >
                                                                Send Mail
                                                            </Button>
                                                            :
                                                            <Button autoFocus color="inherit" onClick={handleSendMail} disabled>
                                                                Sending...
                                                            </Button>
                                                        }
                                                    </Toolbar>
                                                </AppBar>
                                                <List>
                                                    <Purchaseorderview price={price} customerDetails={customerDetails} customerid={props.companyuserid} jobsAllowed={jobsAllowed} resumesAllowed={resumesAllowed} companiesAllowed={companiesAllowed} recruitersAllowed={recruitersAllowed} packageTitle={packageTitle} />

                                                </List>
                                            </Dialog>
                                            &nbsp;
                                            {packageId !== "0" ?
                                                <button className="btn btn-primary btn-block mt-2" disabled={packageChange ? false : true}
                                                >
                                                    {"$ " + price}
                                                </button> :
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="views">Price</label>

                                                        <Field
                                                            type='Price'
                                                            name="Price"
                                                            autocomplete="off"
                                                            className={`mt-2 form-control
                                                                    ${touched.extraRecruiters && errors.extraRecruiters ? 'is-invalid' : ''}`}
                                                            // onChange={(e) => setExtraPosts(e.target.value)}
                                                            disabled={packageChange ? false : true}
                                                            placeholder="$ Enter Price"
                                                            onChange={(e) => setPrice(e.target.value)}
                                                            style={{ width: "94%" }}
                                                        />

                                                    </div>
                                                </div>
                                            } &nbsp;
                                            <Button type="submit" className="btn btn-outline-primary btn-block mt-2" 
                                            disabled={packageTitle!=="" && packageTitle==="Free Package" ? true:false}
                                            >
                                                {parseInt(price) !== 0 ? "Create Agreement" : buttonStatus ? "Upgrading.." : "Upgrade"}
                                            </Button>


                                        </div>

                                        <div className='col-md-1'> </div>
                                        <div className='col-md-5'>

                                            <div className='col-md-6' style={{ float: " " }}>



                                                <div className="form-group mb-3">
                                                    <label htmlFor="posts">Add Posts</label>
                                                    <Field
                                                        type='addposts'
                                                        name="addposts"
                                                        placeholder="Add extra posts"
                                                        autocomplete="off"
                                                        className={`mt-2 form-control
                                                    ${touched.addposts && errors.addposts ? 'is-invalid' : ''}`}
                                                        disabled={packageChange ? true : false}
                                                    />
                                                </div>



                                                <div className="form-group mb-3">
                                                    <label htmlFor="views">Add Views</label>
                                                    <Field
                                                        type='addviews'
                                                        name="addviews"
                                                        placeholder="Add extra views"
                                                        autocomplete="off"
                                                        className={`mt-2 form-control
                                                   ${touched.addviews && errors.addviews ? 'is-invalid' : ''}`}
                                                        disabled={packageChange ? true : false}
                                                    />
                                                </div>


                                                <div className="form-group mb-3">
                                                    <label htmlFor="recruiters">Add Recruiters</label>
                                                    <Field
                                                        type='addrecruiters'
                                                        name="addrecruiters"
                                                        placeholder="Add extra recruiters"
                                                        autocomplete="off"
                                                        className={`mt-2 form-control
                                                   ${touched.addrecruiters && errors.addrecruiters ? 'is-invalid' : ''}`}
                                                        disabled={packageChange ? true : false}
                                                    />
                                                </div>

                                                <button className="btn btn-primary btn-block mt-2" disabled={(!packageChange && compid === 0) ? false : true}
                                                >
                                                    Buy more
                                                </button>

                                            </div>
                                        </div>
                                    </div>

                                    &nbsp;
                                    &nbsp;
                                </Form>
                            </div>
                        )}
                    </Formik>
                </div>




            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,

    };
}
export default connect(mapStateToProps, null)(Packagelimitations)

