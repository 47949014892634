
/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import './SalesMarketing.css';

import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import { MdLocationOn } from "react-icons/md";
import InputGroup from 'react-bootstrap/InputGroup';
import Chip from '@mui/material/Chip';
import CreatableSelect from 'react-select/creatable';
import Modal from 'react-bootstrap/Modal';

import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';

import Key from "../clientVariables.json";
import request from 'src/utils/request';
import ToastMessage from "../components/ToastMessage";
import Resumeviewer from "./ResumeViewer"


import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


export default function ResumeMail() {
  const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

  const [data, setData] = useState({
    fromEmail: "anusha@jobsnprofiles.com",
    fullname: '',
    toEMail: '',
    ccEMail: '',
    subj: '',
    body: mailbody,
    jobtitle: '',
    skillname: '',
    location: '',

  })
  const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location } = { ...data }
  const [profileResumes, setProfileResumes] = useState([]);
  const [selectedResIds, setSelectedResids] = useState([]);
  const [resumeChecked, setResumeChecked] = useState();
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [emails, setEmails] = useState([])
  const [date, setDate] = useState("")
  const [subScribersCCMails, setSubscribersCCMails] = useState([])
  const [subscribers, setSubscribersClicked] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState([])
  const [showResume, setShowResume] = useState(false)
  const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
  const changeHandler = e => {
    e.preventDefault()
    setData({ ...data, [e.target.name]: e.target.value })
  }


  const handleToEmailChange = (e) => {
    console.log(e)
    e.map(x => x.__isNew__ === true ? addNewSkill(x) : setSelectedEmails(Array.isArray(e) ? e.map((x) => x.value) : []))

    setData({ ...data, toEMail: Array.isArray(e) ? e.map((x) => x.value) : [] })


  };

  const addNewSkill = () => {

  }

  useEffect(() => {
    //  getSubscribers()
  }, [])


  const showAlternateResults = async (jobtitle) => {
    let title = jobtitle.replace(/['"]/g, '')
    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title !== undefined && title !== '""' ? '"' + title + '"' : "*%3A*"}) OR id:(${title ? title : "*%3A*"})&fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone%2Cfilename&rows=${10}&start=${0}&wt=json`;
    const result = await request(`${ENDPOINT['SolrResumes']}`,
      postOptions({
        url: searchResumesUrl,
      }))
    // console.log(result.data.response.docs)
    setProfileResumes(result.data.response.docs);
  }


  const handleSearch = (e) => {
    setSubscribersClicked(true)
    e.preventDefault();
    console.log(data)
    let jobtitle = JSON.stringify(data.jobtitle);
    if (jobtitle.includes(" / ")) {
      jobtitle = jobtitle.replace(' / ', '/')
    }

    if (data.location.includes(',')) {
      const location = data.location !== "" ? data.location.split(/[,]+/) : "";

      if (location[1].replace(' ', '').length === 2) {
        var cityName = location[0]
        var stateName = ""
        var shortRegion = location[1]
      } else {
        cityName = location[0]
        stateName = location[1]
      }


    } else if (data.location.length === 2) {
      shortRegion = data.location
    } else {
      cityName = data.location

    }

    var mappeddate = sessionStorage.getItem("resumeMappedDates")
    console.log(jobtitle)
    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '""' ? jobtitle : "* TO *"}&fq=${skillname}&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=created:[${mappeddate !== null ? mappeddate : '* TO *'}]&fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone%2Cfilename&rows=20&start=0&sort=created%20desc&wt=json`
    // const searchResumesUrl1 = "http://65.21.122.252:8983/solr/jnp_resumes_dev/select?fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone&q.op=OR&q=*React%20developer*&rows=10&start=0"

    console.log(searchResumesUrl)
    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    })
      .then((response) => {
        response.json().then((resdata) => {
          console.log(resdata)
          if (resdata.data.response.docs.length === 0) {
            showAlternateResults(jobtitle)
          } else {
            setProfileResumes(resdata.data.response.docs)

          }
          if (subScribersCCMails.length > 0) {
            const newResumes = resdata.data.response.docs.map((list) => (
              { ...list, isChecked: true })
            );

            setProfileResumes(newResumes);

            console.log(newResumes)

            const obj = newResumes.filter((x) => x.isChecked === true);
            let filteredobjs = obj.filter((x) => x.application_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
          }
        })

      })
  }

  const handleDateChage = (value) => {
    setDate(value)
    if (value === "Today") {
      const today = new Date();
      var startdate = moment(today).subtract(1, 'd').format('YYYY-MM-DD hh:mm:ss');
      var convertedStartDate = moment.utc(moment(startdate)).format()
      var mappeddate = convertedStartDate + ' TO ' + 'NOW'
      sessionStorage.setItem("resumeMappedDates", mappeddate)

    } else {

      const today = new Date();
      var startdate = moment(today).subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
      var convertedStartDate = moment.utc(moment(startdate)).format()
      var mappeddate = convertedStartDate + ' TO ' + 'NOW'
      sessionStorage.setItem("resumeMappedDates", mappeddate)

    }
  }


  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
    let filteredobjs = []
    if (name === String(resumeId)) {
      console.log("comes here", resumeId)
      const newResumes = profileResumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setProfileResumes(newResumes);
      console.log(newResumes)
      const obj = newResumes.filter((x) => x.isChecked === true);
      filteredobjs = obj.filter((x) => x.application_title !== '');
      const ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      // setProfileResumes(filteredobjs);
    }

  };
  const handleSendMail = (e) => {

    e.preventDefault();
    console.log(data)
    // var jobtitle = '"' + data.jobtitle + '"'
    console.log(profileResumes)
    console.log(selectedResumes)

    if (toEMail && subj && fromEmail && fullname) {
      if (selectedResumes.length > 5 && subscribers === false) {


        const msg = "Maximum limit is to share resumes is 5";
        toast(<ToastMessage message={msg} />);

      } else if (selectedResumes.length > 0) {
        fetch(`${Key.domain}/common/send_contactSales_resumes_Mail`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes, sendtoSubscribers: subScribersCCMails.length > 0 ? 1 : 0 }),
        })
          .then((response) => {
            response.json().then((resdata) => {
              console.log(resdata)
              if (resdata.data === "Mail sent To Employer") {
                const msg = "Mail Sent Successfully";
                toast(<ToastMessage message={msg} />);
                window.location.reload()
              }
            })
          })
      } else {
        const msg = "Select atleast 1 resume";
        toast(<ToastMessage message={msg} />);

      }
    } else {

      const msg = "Enter required fields";
      toast(<ToastMessage message={msg} />);

    }
  }

  const getSubscribers = async () => {
    const result = await request(`${ENDPOINT['getSubscribers']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken") }))
    setSubscribersCCMails(result.data.map(user => user.username))
    // setSubscribersCCMails(
    //   result.data.map((user) => ({
    //     value: user.username,
    //     label: user.username,
    //   }))
    //);
    setData({ ...data, toEMail: result.data.map(user => user.username) })

  }
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const handleClose = () => {
    setShowResume(false)
  }

  return (
    <div>

      <div className='container'>
        <div className='row'>
          <div className='col-md-6 sales-styles'>

            <div className="sales-style">
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                  <Form.Label column sm={2}>
                    From * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Name * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2} >
                    To * :
                  </Form.Label>
                  <Col sm={10}>

                    {/* <CreatableSelect
                      isMulti
                      name="To Email"
                      placeholder="To Email"
                      options={subScribersCCMails}
                      onChange={handleToEmailChange}
                      value={subScribersCCMails.filter((obj) =>
                        selectedEmails.includes(obj.value)
                      )}
                      />*/}
                    <InputGroup className="mb-3">
                      <Form.Control type="name" placeholder="eg : example@domain.com" name="toEMail" value={toEMail} onChange={changeHandler} />
                      <Button variant="outline-secondary" id="button-addon2" onClick={() => getSubscribers()}>
                        Users
                      </Button>
                    </InputGroup>

                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2} >
                    Cc:
                  </Form.Label>
                  <Col sm={10}>
                    {/* <InputGroup className="mb-3">*/}
                    <Form.Control type="name" placeholder="eg : something@domain.com, something2@domain.com" name="ccEMail" value={ccEMail} onChange={changeHandler} />
                    {/*<Button variant="outline-secondary" id="button-addon2" onClick={() => getSubscribers()}>
                        Users
                      </Button>
  </InputGroup></Form.Group>*/}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                  <Form.Label column sm={2}>
                    Sub * :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="name" placeholder="Subject" name="subj" value={subj} onChange={changeHandler} />
                  </Col>
                </Form.Group>

                {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                <Form.Label column sm={2}>
                  Body:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control as="textarea" rows={3} name="body" value={body} onChange={changeHandler} />
                </Col>
              </Form.Group> */}

              </Form>

              <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

              <Form>
                <Row className="align-items-center">
                  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Job Title
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Job Title"
                      name="jobtitle" value={jobtitle} onChange={changeHandler}
                    />
                  </Col>
                  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Skills
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Skills"
                      name="skillname" value={skillname} onChange={changeHandler}
                    />
                  </Col>
                  <Col xs="3" lg="12">
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                      Location
                    </Form.Label>
                    <Form.Control
                      className="mb-2"
                      id="inlineFormInput"
                      placeholder="Location"
                      name="location" value={location} onChange={changeHandler}
                    />
                  </Col>
                  {/* <Col xs="auto">
                  <Button type="submit" className="mb-2">
                    Add More
                  </Button>
                </Col> */}
                </Row>
              </Form>
              <div className="mt-4" style={{ display: "flex", float: "right", }}>
                <div>
                  <DropdownButton id="dropdown-basic-button" title={date ? date : "Date"} style={{ float: "right", }} >
                    <Dropdown.Item onClick={(e) => handleDateChage('Today')}>Today</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handleDateChage('Last Week')}>Last Week</Dropdown.Item>
                  </DropdownButton>
                </div> &nbsp;&nbsp;
                <div>

                  <Form onSubmit={(e) => handleSearch(e)}>
                    <Button variant="primary" type="submit" style={{ float: "right", }}>
                      Search
                    </Button>
                  </Form>
                </div>
              </div>
            </div>

          </div>


          <div className='col-md-6 sales-styles' >
            <Modal size="lg" show={showResume} onHide={handleClose} centered>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
            <div className=" positions-scroll">
              {profileResumes.length > 0 ? <h4>These are the active RESUMES for your requirement {data.jobtitle} </h4> : <h4>Search for resumes</h4>}
              <Form onSubmit={(e) => handleSendMail(e)}>


<Button variant="primary" type="submit" style={{ float: "right", marginTop: "-35px" }}>
  Share Mail
</Button>
</Form>

              <br />
              {profileResumes.length > 0 ? profileResumes.map((resume) => {
                return (
                  <>
                    <div className="job-titile" style={{ padding: "5px" }}>
                      <Row className="job-checkbox">
                        <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                          id="check"
                          checked={resume?.isChecked}
                          onClick={(e) => handleChange(e, resume.id)} /></Col>
                        <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                      </Row>


                      <ul>
                        <li> <FaSuitcase /> {resume.skillName
                          ? resume.skillName
                            .join(",")
                            .split(",")
                            .join(", ")
                            .slice(0, 100)
                          : "N/A"}</li>

                        <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                        <li style={{ float: "right" }}> <FaCalendarAlt />

                          {resume.lastmodified ? moment(resume.lastmodified).format("LL") : "N/A"
                          }
                        </li>


                      </ul>
                    </div>
                    <hr style={{ width: "100%" }} />
                  </>)

              }) : <center style={{ marginTop: "50px" }}> <h4>No Resumes</h4> </center>
              }



            </div>
            <br />


          </div>
        </div>
        <hr />
      </div>

    </div>
  )
}
