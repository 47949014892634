import React, { useEffect, useState } from 'react'
import { Grid, Container, Typography } from '@mui/material';
import axios from 'axios';
import { connect } from "react-redux";
import AppWidgetSummary from "../sections/@dashboard/app/Accountingwidgetcount"
import Salesrevenue from './Salesrevenue';
import Costbreakdown from "./Coastbreakdown"
import Key from '../clientVariables.json'

function AccountingDashboard(props) {
  const [totalCompanies, setTotalCompanies] = useState(0)
  const [totalSales, setTotalSales] = useState(0)


  const [janCount, setJanCount] = useState(0)
  const [febCount, setFebCount] = useState(0)
  const [marchCount, setMarchCount] = useState(0)
  const [aprCount, setAprCount] = useState(0)
  const [mayCount, setMayCount] = useState(0)
  const [junCount, setJunCount] = useState(0)
  const [julyCount, setJulyCount] = useState(0)
  const [augCount, setAugCount] = useState(0)
  const [sepCount, setSepCount] = useState(0)
  const [octCount, setOctCount] = useState(0)
  const [novCount, setNovCount] = useState(0)
  const [decCount, setDecCount] = useState(0)

  useEffect(() => {
    axios
      .post(`${Key.domain}/dashboard/get_accounting_dashboard_stats`, {
        "accounting_userid": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        setTotalSales(response.data.data.total_Sales)
        setTotalCompanies(response.data.data.total_Companies)

        const salesRevenue = response.data.data.no_of_monthly_sales
        const filteredSales = salesRevenue.filter(data => data.year === 2022)

        const janObj = filteredSales.find(sub => sub.month === 1)
        setJanCount(janObj !== undefined ? janObj.SalesCount : 0)

        const febObj = filteredSales.find(sub => sub.month === 2)
        setFebCount(febObj !== undefined ? febObj.SalesCount : 0)

        const marObj = filteredSales.find(sub => sub.month === 3)
        setMarchCount(marObj !== undefined ? marObj.SalesCount : 0)

        const aprObj = filteredSales.find(sub => sub.month === 4)
        setAprCount(aprObj !== undefined ? aprObj.SalesCount : 0)

        const mayObj = filteredSales.find(sub => sub.month === 5)
        setMayCount(mayObj !== undefined ? mayObj.SalesCount : 0)

        const junObj = filteredSales.find(sub => sub.month === 6)
        setJunCount(junObj !== undefined ? junObj.SalesCount : 0)

        const julyyCount = filteredSales.find(sub => sub.month === 7)
        setJulyCount(julyyCount !== undefined ? julyyCount.SalesCount : 0)

        const augCont = filteredSales.find(sub => sub.month === 8)
        setAugCount(augCont !== undefined ? augCont.SalesCount : 0)

        const sepObj = filteredSales.find(sub => sub.month === 9)
        setSepCount(sepObj !== undefined ? sepObj.SalesCount : 0)

        const octObj = filteredSales.find(sub => sub.month === 10)
        setOctCount(octObj !== undefined ? octObj.SalesCount : 0)

        const novObj = filteredSales.find(sub => sub.month === 11)
        setNovCount(novObj !== undefined ? novObj.SalesCount : 0)

        const decObj = filteredSales.find(sub => sub.month === 12)
        setDecCount(decObj !== undefined ? decObj.SalesCount : 0)

      });
  }, [])

  return (
    <div>

      <h4 className='mb-4' style={{ paddingLeft: "28px" }}>AccountingDashboard</h4>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Companies" total={totalCompanies} icon={'ant-design:user-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Sales" color="info" total={totalSales} icon={'ant-design:user-outlined'} />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Profit" color="warning" total={300} icon={'ant-design:user-outlined'} />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Cost" color="success" total={125} icon={'ant-design:user-outlined'} />
          </Grid>


          <Grid item xs={12} sm={6} md={8} lg={8}>

            <Salesrevenue janCount={janCount} febCount={janCount} marchCount={marchCount} aprCount={aprCount} mayCount={mayCount} junCount={junCount} julyCount={julyCount} augCont={augCount} sepCount={sepCount} octCount={octCount} novCount={novCount} decCount={decCount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Costbreakdown />
          </Grid>


        </Grid>
      </Container>




    </div>
  )
}


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(AccountingDashboard));