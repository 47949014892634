import React ,{useEffect,useState}from 'react'
import MUIDataTable from "mui-datatables";
import { BsThreeDotsVertical } from 'react-icons/bs';
import {FcDocument} from "react-icons/fc";
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import { UserMoreMenu } from '../sections/@dashboard/user';
import Key from '../clientVariables.json'

const columns = ["Invoice No", "Customer Name", "Date", "Plan",  {


  name: "actions",

  label: <span>Aggrement</span>,

  options: {

    customBodyRender: (value, tableMeta, updateValue) => {

      // return <UserMoreMenu />
      return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

    },

    filter: true,

    sort: true,

    // setCellProps: value => {

    //   return {

    //     style: {

    //       cursor: 'pointer'

    //     }

    //   };

    // }



  },
},];


const options = {
  filterType: 'checkbox',
};


const data = [
  ["A745754", "People SOft", "12/02/2022", "Monthly"],
  ["S745445", "Memo Tech", "14/05/2022", "Monthly"],
  ["S421244", "Balleno", "14/05/2022", "Yearly"],

];


function Invoices(props) {
  const [invoiceData,setInoviceData]=useState([])
  const [customerId,setCustomerId]=useState("")
  const [invoiceDoc,setInvoiceDoc]=useState("")
  const [poDoc,setPoDoc]=useState("")
  const [invoice,setInvoice]=useState([])

  useEffect(()=>{
axios.post(`${Key.domain}/payment/get_invoiceslist`,{
  "crm_userid":sessionStorage.getItem("id"),
  "session_token": props.currentUser.session_token
})
.then(resdata=>{
  console.log(resdata)
  setInoviceData(resdata.data.data.InvoicesList?resdata.data.data.InvoicesList:[])
})
  },[])

// console.log(invoiceData)

const handleInvoice=(invoiceID)=>{
   console.log(invoiceID)
  axios.post(`${Key.domain}/payment/get_invoice_details`,{
    invoice_no:invoiceID
  }).then(res=>{
     console.log(res)
    if(res.data.data.Invoicedetails.customer_id!==null  && res.data.data.Invoicedetails.customer_id!==undefined){
      const customeridd=res.data.data.Invoicedetails.customer_id;
      const invoicedocc=res.data.data.Invoicedetails.invoice_doc;
      setCustomerId(customeridd)
      setInvoiceDoc(invoicedocc)
      // console.log(customerId,invoiceDoc)
  
      fetch(
        `${Key.domain}/payment/attachments/${customeridd}/${invoicedocc}`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + window.localStorage['Access_Token'],
          },
        }
      ).then((resdata) => {
        console.log(resdata);
        console.log(resdata.url);
        const fileURL = resdata.url;

        console.log(fileURL);
       

        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
      .catch((e) => {
        console.log(e);
      });
    }
   
    else{
      console.log("failed")
    }
  })
 

}


const handlePaymentAgreement=(poId)=>{
  console.log(poId)
  axios.post(`${Key.domain}/payment/get_PO_details`,{
    agreement_no:poId

}).then(res=>{
    console.log(res,res.data.data.POdetails)
    if(res.data.data.POdetails.customer_id!==null  && res.data.data.POdetails.customer_id!==undefined){
      const customeriddd=res.data.data.POdetails.customer_id;
      const agreementdocc=res.data.data.POdetails.agreement_doc
        setCustomerId(customeriddd)
        // setInvoiceDoc(res.data.data.POdetails.invoice_doc)
        setPoDoc(agreementdocc)
        fetch(
            `${Key.domain}/payment/attachments/${customeriddd}/${agreementdocc}`,
            {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + window.localStorage['Access_Token'],
              },
            }
          ).then((resdata) => {
            console.log(resdata);
            console.log(resdata.url);
            const fileURL = resdata.url;
    
            console.log(fileURL);
           
    
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          })
          .catch((e) => {
            console.log(e);
          });
        }
        else{
            console.log("failed")
          }
})
}
  const columns = [
    {
        label: <span style={{cursor: "pointer"}}>Invoice No</span>,
        name: "invoice_no",
        options: {
            customBodyRender: (value) => {
                // return <a onClick={()=>handleInvoice(value)} className="span" role="button" onKeyPress={onKeyPressHandler} tabIndex="0">{value }</a>
             // return <span style={{cursor:"pointer"}}> {value}</span>
             return value 
            }
        },
    },
    {
        label: <span>Customer Name</span>,
        name: "customer_name",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return value 
            }
        },
    },
    {
        label: <span>Date</span>,
        name: "created",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return moment(value).format("LL")

            }
        },
    },
    {
        label: <span>Plan</span>,
        name: "PlanName",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {

                return  value 

            }
        },
    },
    {
      label: <span>Agreement</span>,
      name: "agreement_no",
      options: {
          customBodyRender: (value, tableMeta, updateValue) => {
        
            return value 
          }
      },
  },
    
];
const options = {
  fixedHeader: true,
    density:true,
    selectableRows: true,
    search:true,
    filter:true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
  onCellClick:(cellIndex,rowIndex)=>{
    console.log(cellIndex.value );
    console.log(rowIndex.dataIndex);
    console.log(rowIndex);
    if(rowIndex.colIndex===0){
      console.log(cellIndex)
      handleInvoice(cellIndex)
    }
    if(rowIndex.colIndex===4){
      console.log(cellIndex)
      handlePaymentAgreement(cellIndex)

    }
   
  },
  
};
  return (
    <div style={{marginTop:"-60px",boxShadow:"0px 0px 5px black"}}>

      <MUIDataTable
        title={<h4>Invoice List</h4>}
        data={invoiceData}
        columns={columns}
        options={options}
      />

    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(Invoices));
