import React from 'react'
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement,Legend,Title,Tooltip } from 'chart.js';
 
Chart.register(
    LinearScale,CategoryScale,BarElement,Legend,Title,Tooltip
  )

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const options ={
    plugins:{
        legend:{
            position:'top'
        },
        title:{
            display:true,
            text:"Affliation ",
            color: '#406882',
            align:"start",
            font: {
                size: 20
            }
        }
    }
  }
  const data = {
    labels,
    datasets: [
      {
        label: '2020 Expense',
        data:[10000,8000,7000,6000,5000,4000,3000],
        backgroundColor: '#FFF7CD',
      },
      {
        label: '2021 Expense',
        data:[100200,80300,70200,60300,50200,40200,30300],
        backgroundColor: '#E9FCD4',
      },
      {
        label: '2022 Expense',
        data:[100200,80300,70200,60300,50200,40200,30300],
        backgroundColor: '#406882',
      },
    ],
  };

function Affliation() {
  return (
    <div>
    
    <Bar options={options} data ={data}  />
   
    </div>
  )
}

export default Affliation