/*eslint-disable*/
import React from "react";
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {

  handleClick = () => {
    console.log("clicked on icon!");
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <UploadFileIcon onClick={this.props.openModel} style={{cursor:"pointer",color:"#6e6e6e"}}/>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
