/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Modal, Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  Stack,
  Button,
  Container,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

import ToastMessage from '../components/ToastMessage';
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'

function Jobseekerregistrations(props) {

  const [jobseekersData, setJobseekersData] = useState([])
  const [selectedJobfairData, setSelectedJobfairData] = useState({})
  const [sendMail, setSendMail] = useState(false);
  const [profileResumes, setProfileResumes] = useState([]);
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [selectedResIds, setSelectedResids] = useState([]);
  const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";
  const [editedData, setEditedData] = useState([]);

  const [data, setData] = useState({
    fromEmail: "hot-jobs@jobsnprofiles.com",
    fullname: '',
    toEMail: '',
    ccEMail: 'divya.m@jobsnprofiles.com',
    subj: 'Recommended JOBS based on application',
    body: mailbody,
    jobtitle: '',
    skillname: '',
    location: 'Remote',
    toUserId: '',
    jobId: '',
  })
  const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location, jobId, toUserId } = { ...data }
  const getJobFairData=()=>{
    var users = []
    var oldUsers = []
    props.showLoader()
    axios.get(`${Key.domain}/common/get_js_jobfair_registrations`)
      .then(resdata => {
        console.log(resdata)

        var jobfairUsers = resdata.data.data[1].newUsers
        setJobseekersData(jobfairUsers)
        console.log(jobfairUsers)
        props.hideLoader()

      })

  }
  useEffect(() => {

    getJobFairData()
    
  }, [])



  console.log(jobseekersData)

  const handleResumeDownload = (row, cellIndex) => {
    // console.log(row)
    // console.log(row.id)

    // let selectedJobfair = selectedJobfairData
    let selectedJobfair = row

    // console.log(selectedJobfair.id)
    if (selectedJobfair.profile !== null && selectedJobfair.profile !== undefined && selectedJobfair.profile !== "") {

      fetch(
        `${Key.domain}/common/get_jobfair_file/${selectedJobfair.id}/${selectedJobfair.profile}`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + window.localStorage['Access_Token'],
          },
        }
      ).then((resdata) => {
        console.log(resdata);
        console.log(resdata.url);
        const fileURL = resdata.url;

        console.log(fileURL);


        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
        .catch((e) => {
          console.log(e);
        });


    }
  }

  const sendMailFunction = () => {
    setData({...data, jobtitle:""})

    setSendMail(true)
    setProfileResumes([])
  }
  const handleCloseMail = () => {
    setData({...data, jobtitle:""})
  setSendMail(false)
};
  const changeHandler = e => {
    e.preventDefault()
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const handleChange = (e, resumeId) => {
    const { name, value, checked } = e.target;
    let filteredobjs = []
    if (name === String(resumeId)) {
      console.log("comes here", resumeId)
      const newResumes = profileResumes.map((list) =>
        list.id === resumeId ? { ...list, isChecked: checked } : list
      );
      setProfileResumes(newResumes);
      console.log(newResumes)
      const obj = newResumes.filter((x) => x.isChecked === true);
      filteredobjs = obj.filter((x) => x.application_title !== '');
      const ids = filteredobjs.map(obj => obj.id)
      setSelectedResids(ids)
      setSelectedResumes(filteredobjs);
      // setProfileResumes(filteredobjs);
    }

  };
  const handleSearchJobs = (e) => {
    e.preventDefault();
    const jobtitle = JSON.stringify(data.jobtitle);
    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=title:${jobtitle}&fq=status:(1)&rows=20&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        // console.log(resdata.data.response.numFound)
        setProfileResumes(resdata.data.response.docs)

      });
    });
  }
  const handleSendMail = (e) => {
    e.preventDefault();
    // console.log(data)
    // var jobtitle = '"' + data.jobtitle + '"'
    console.log(profileResumes)
    console.log(selectedResumes)
    if (toEMail && subj && fromEmail && fullname) {

      if (selectedResumes.length > 10) {


        const msg = "Maximum limit is to share jobs is 10";
        toast(<ToastMessage message={msg} />);
        setSendMail(false)


      } else if (selectedResumes.length > 0) {
        fetch(`${Key.domain}/common/send_contactSales_jobs_Mail`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formdata: data, jobdata: selectedResumes.length > 0 ? selectedResumes : profileResumes }),
        })
          .then((response) => {
            response.json().then((resdata) => {
              console.log(resdata)
              if (resdata.data === "Mail sent To JS") {
                const msg = "Mail Sent Successfully";
                toast(<ToastMessage message={msg} />);
                // window.location.reload()
                setSendMail(false)
                getJobFairData()
              }
            })
          })

        axios
          .post(`${Key.domain}/common/update_jobfairUserMail_status`, {
            "admin_id": sessionStorage.getItem("id"),
            "session_token": sessionStorage.getItem("userToken"),
            "mail_status": 1,
            "email": toEMail,

          })
          .then((resdata) => {
            console.log(resdata.data.data)

          }).catch((err) => {
            console.log(err);
          });

      }
      else {
        const msg = "Select atleast 1 Job";
        toast(<ToastMessage message={msg} />);

      }

    } else {

      const msg = "Enter required fields";
      toast(<ToastMessage message={msg} />);

    }
  }
  const columns = [
    {
      label: <span>Full Name</span>,
      name: "full_name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Email</span>,
      name: "email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Role</span>,
      name: "role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Mobile</span>,
      name: "phone",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Resume</span>,
      name: "profile",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <a style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>{value}</a> : "N/A"
        }
      },
    },
    {
      label: <span>Date</span>,
      name: "created",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("MM D YYYY - h:mm:ss a")

        }
      },
    },
    {
      name: "sendEmail",
      label: "Send Mail",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel sx={{ m: 1, minWidth: 120 }}
            control={(parseInt(value) === 1 ? <Button onClick={(e) => sendMailFunction()}>Resend</Button> : <Button onClick={(e) => sendMailFunction()}>Send </Button>)} />
          // control={(parseInt(value) === 1 ? <Button disabled >Sent</Button> : <Button onClick={(e) => sendMailFunction()}>Send </Button>)} />
        )
      }
    }

  ];



  const options = {
    fixedHeader: true,
    density: true,
    selectableRows: true,
    search: true,
    filter: true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
    onRowClick: (rowData, rowState) => {
      var row = jobseekersData[rowState.dataIndex];

      // console.log(rowData)
      // console.log(rowState)
      // console.log(jobseekersData[rowState.dataIndex])
      setSelectedJobfairData(row)
      handleResumeDownload(row)

    },
    onCellClick: (cellIndex,cellMeta, rowIndex) => {
      // console.log(cellIndex.value);
      // console.log(rowIndex.dataIndex);
      // console.log(rowIndex);
      var userRow = jobseekersData[cellMeta.dataIndex];
      setEditedData(userRow)
      console.log(userRow)
      setData({ ...data, toEMail: userRow.email, fullname: userRow.full_name })

      if (rowIndex.colIndex === 4) {
        // console.log(cellIndex)
        var row = jobseekersData[rowIndex.rowIndex];
        // console.log(row)
        // handleResumeDownload(row, cellIndex)
      }
    },
  };
  return (
    <div style={{ boxShadow: "0px 0px 5px black" }}>
      <MUIDataTable
        title={<h5>Jobseekers List</h5>}
        data={jobseekersData}
        columns={columns}
        options={options}
      />
       <Modal
        show={sendMail}
        onHide={handleCloseMail}
        backdrop="static"
        centered
        size="xl"
        keyboard={false}
        style={{ marginTop: "25px", marginLeft: "9%" }}
      >


        <Modal.Body>
          <div className='p-1'>

            <Modal.Header closeButton>
              <Modal.Title>Send Jobs</Modal.Title>
            </Modal.Header>

            <div className='container'>
              <div className='row'>
                <div className='col-md-6 sales-styles'>

                  <div className="sales-style">
                    <Form>
                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>
                          From * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Name * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2} >
                          To * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="eg : example@domain.com" name="toEMail" value={toEMail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2} >
                          Cc:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="eg : something@domain.com, something2@domain.com" name="ccEMail" value={ccEMail} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                          Sub * :
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="name" placeholder="Subject" name="subj" value={subj} onChange={changeHandler} />
                        </Col>
                      </Form.Group>

                    </Form>

                    <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

                    <Form>
                      <Row className="align-items-center">
                        <Col xs="3" lg="12">
                          <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Job Title
                          </Form.Label>
                          <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Job Title"
                            name="jobtitle" value={jobtitle} onChange={changeHandler}
                          />
                        </Col>

                        <Col xs="3" lg="12">
                          <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                            Location
                          </Form.Label>
                          <Form.Control
                            className="mb-2"
                            id="inlineFormInput"
                            placeholder="Location"
                            name="location" value={location} onChange={changeHandler}
                          />
                        </Col>
                      </Row>
                    </Form>

                    <Form onSubmit={(e) => handleSearchJobs(e)}>

                      <Button className="mt-5" style={{ background: "blue", color: "#ffff", float: "right" }} variant="primary" type="submit">
                        Search
                      </Button>
                    </Form>

                  </div>

                </div>


                <div className='col-md-6 sales-styles'>
                  <div className="positions-scroll">
                    {profileResumes.length > 0 ? <h4>These are the active JOBS for your requirement {data.jobtitle} </h4> : <h4>Search for JOBS</h4>}

                    <br />

                    {profileResumes.length > 0 ? profileResumes.map((resume) => {
                      console.log((resume.workmode === "true"))
                      return (
                        <>
                          <div className="job-titile" style={{ padding: "5px" }}>
                            <Row className="job-checkbox">
                              <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                id="check"
                                checked={resume?.isChecked}
                                onClick={(e) => handleChange(e, resume.id)} /></Col>
                              <Col sm="11">  <h3> <a href="#"> {resume.title} </a> </h3> </Col>
                            </Row>


                            <ul>
                              <li> <FaSuitcase /> {resume.prefferdskillsName
                                ? resume.prefferdskillsName
                                  .join(",")
                                  .split(",")
                                  .join(", ")
                                  .slice(0, 100)
                                : "N/A"}</li>

                              <li style={{ float: "left" }}> <MdLocationOn /> {resume.workmode === "true" ? "Remote" : <>{resume.cityName}, {resume.stateName}</>} </li>

                              <li style={{ float: "right" }}> <FaCalendarAlt />

                                {resume.created ? moment(resume.created).format("LL") : "N/A"
                                }
                              </li>


                            </ul>
                          </div>
                          <hr style={{ width: "100%" }} />
                        </>)

                    }) : <center style={{ marginTop: "50px" }}> <h4>No JOBS</h4> </center>
                    }



                  </div>
                  <br />
                  <Form onSubmit={(e) => handleSendMail(e)}>
                    <Button type="submit" variant="secondary" style={{ background: "blue", color: "#ffff", border: 'transparent', float: "right" }}>
                      Share Mails
                    </Button>
                  </Form>

                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        {/*  <Modal.Footer>
          <Button style={{ background: "grey", color: "#ffff" }} onClick={handleCloseMail}>
            Close
          </Button>
          <Button style={{ background: "blue", color: "#ffff" }} variant="primary" onClick={(e) => handleSendMail(e)}>send</Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  )
}
const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Jobseekerregistrations);