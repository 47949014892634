/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ENDPOINT } from 'src/utils/endpoint';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Resumeviewer from "./ResumeViewer";
import Tooltip from '@mui/material/Tooltip';
import { MdDeleteOutline } from "react-icons/md";
import CustomerDetailView2 from './CustomerDetailView2';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { MdFilterList } from "react-icons/md";
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { toast } from "react-toastify";
import { MdLocationOn } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import request from 'src/utils/request';
import ToastMessage from "../components/ToastMessage";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { GoLocation } from "react-icons/go";
import { BiRightArrowAlt } from "react-icons/bi";
import { FaBookReader } from "react-icons/fa"

import { SlOptionsVertical } from "react-icons/sl"

function User(props) {
    const history = useNavigate();
    const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

    const [data, setData] = useState({
        fromEmail: "hot-resumes@jobsnprofiles.com",
        fullname: '',
        toEMail: '',
        ccEMail: '',
        subj: '',
        body: mailbody,
        jobtitle: '',
        skillname: '',
        location: '',

    });



    const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
    const [salesPersons, setSalespersonsList] = useState([]);

    const [profileResumes, setProfileResumes] = useState([]);
    const [date, setDate] = useState("");
    const [showResume, setShowResume] = useState(false)
    const [users, setUsers] = useState([]);
    const [sendMail, setSendMail] = useState(false);
    const [person, setPerson] = useState("");
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [editedData, setEditedData] = useState([]);
    const [selectedResIds, setSelectedResids] = useState([]);
    const [selectedResumes, setSelectedResumes] = useState([]);
    const [showComment, setShowComment] = useState(false);
    const [showResetPassword, setShowResetPass] = useState(false);
    const [showActionPopup, setShowActionPopup] = useState(false);
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [newCostumerId, setNewCustomerId] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [showSalesPerson, setShowSalesPerson] = useState(false);
    const [selectedRows, setSelectedRows] = useState([0]);
    const [showStrings, setShowStrings] = useState(false);
    const [showResumeViews, setShowResumeViews] = useState(false);
    const [showResumeDownloads , setShowResumeDownloads]=useState(false)

    const [searchStringsList, setSearchStringsList] = useState([]);
    const [resumeViewsList, setResumeViewsList] = useState([]);
    const [resumeDownloadsList, setResumeDownloadsList]=useState([])

    const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location } = { ...data }


    //To highlight Row
    const handleRowClick = (rowData, rowMeta) => {
        const selectedRowIndex = rowMeta.dataIndex;
        setSelectedRows([selectedRowIndex]);
    };


    const handleClose = () => {

        setShowResume(false);
    }

    const changeComment = e => {
        setComment(e.target.value);
        setShowComment(true);
    }

    const viewUserSearchStrings = e => {
        setShowStrings(true);
    }
    const handleStingsClose = () => {
        setShowStrings(false)
    }
    const handleResumeViewClose = () => {
        setShowResumeViews(false)
    }
    const handleResumeDownloadsClose = () => {
        setShowResumeDownloads(false)
    }
    const handleCommentClose = () => {
        setShowComment(false)
    }
    const handleResetPassClose = () => {
        setShowResetPass(false)
    }
    const handleDeactivateClose = () => {
        setShowDeactivate(false)
    }

    const handleSalesClose = () => {
        setShowSalesPerson(false)
    }
    const changeSalesPerson = e => {
        setSalesPerson(e.target.value);
        setShowSalesPerson(true);
    }
    const handleActionClose = () => {
        setShowActionPopup(false)
        setShowDeactivate(false)
        setShowResetPass(false)

    }
    const handleSaveComment = () => {
        setMessageFunction(comment, "comments")
        handleCommentClose();

    }
    useEffect(() => {

        getUsers();
        getSalespersons();

    }, [])


    const refreshData = () => {
        getUsers();
        getSalespersons();


    }

    const getSalespersons = () => {
        // props.showLoader();

        axios
            .get(`${Key.domain}/common/salespersons_list`, {

            })
            .then((response) => {
                console.log(response)
                setSalespersonsList(response.data.data)
                // props.hideLoader();

            })
    }


    const handleCloseMail = () => {
        setData({ ...data, fullname: "", toEMail: "", jobtitle: "", location: "", skillname: "" })
        setSelectedResids([])
        setSelectedResumes([])
        setProfileResumes([])
        setSendMail(false);
    }



    const getSearchStrings = (userId) => {

        axios
            .post(`${Key.domain}/customer/get_search_strings`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token,
                "subscriber_id": userId,
            }).then((response) => {
                console.log(response.data.data);
                setSearchStringsList(response.data.data);

                setShowStrings(true);


            });




    }

    console.log(searchStringsList)

    const getResumeViews=(userId)=>{
        // /customer/get_resumeviews_details
        axios
        .post(`${Key.domain}/customer/get_resumeviews_details`, {
            "admin_id": sessionStorage.getItem("id"),
            "session_token": props.currentUser && props.currentUser.session_token,
            "subscriber_id": userId,
            "viewNDownloadStatus":1
        }).then((response) => {
            console.log(response.data.data);
            setResumeViewsList(response.data.data);
            setShowResumeViews(true)
        });
    }
const getResumeDownloads=(userId)=>{
    axios
    .post(`${Key.domain}/customer/get_resumeviews_details`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser && props.currentUser.session_token,
        "subscriber_id": userId,
        "viewNDownloadStatus":0
    }).then((response) => {
        console.log(response.data.data);
        setResumeDownloadsList(response.data.data);
        setShowResumeDownloads(true)
    });
}
    const getUsers = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/get_subscribers`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": props.currentUser && props.currentUser.session_token
            })
            .then((response) => {
                console.log(response)
                setUsers(response.data.data)
                setNewCustomerId(response.data.data[0].user_id);
                props.hideLoader();

            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    setSessionExpired(true)

                }
            });;
    }

    const handleDateChage = (value) => {
        setDate(value)
        if (value === "Today") {
            const today = new Date();
            var startdate = moment(today).subtract(1, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            sessionStorage.setItem("resumeMappedDates", mappeddate)

        } else {

            const today = new Date();
            var startdate = moment(today).subtract(7, 'd').format('YYYY-MM-DD hh:mm:ss');
            var convertedStartDate = moment.utc(moment(startdate)).format()
            var mappeddate = convertedStartDate + ' TO ' + 'NOW'
            sessionStorage.setItem("resumeMappedDates", mappeddate)

        }
    }
    const showAlternateResults = async (jobtitle) => {
        let title = jobtitle.replace(/['"]/g, '')
        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:(${title})&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=${10}&start=${0}&wt=json`;
        const result = await request(`${ENDPOINT['SolrResumes']}`,
            postOptions({
                url: searchResumesUrl,
            }))
        // console.log(result.data.response.docs)
        setProfileResumes(result.data.response.docs);
    }
    const handleSearch = (e) => {
        e.preventDefault();
        console.log(data)
        let jobtitle = JSON.stringify(data.jobtitle);
        var mappeddate = sessionStorage.getItem("resumeMappedDates")
        console.log(jobtitle)

        if (jobtitle.includes(" / ")) {
            jobtitle = jobtitle.replace(' / ', '/')
        }

        if (data.location.includes(',')) {
            const location = data.location !== "" ? data.location.split(/[,]+/) : "";

            if (location[1].replace(' ', '').length === 2) {
                var cityName = location[0]
                var stateName = ""
                var shortRegion = location[1]
            } else {
                cityName = location[0]
                stateName = location[1]
            }


        } else if (data.location.length === 2) {
            shortRegion = data.location
        } else {
            cityName = data.location

        }

        const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=application_title:${jobtitle !== '""' ? jobtitle : "* TO *"} OR id:(${jobtitle ? jobtitle : "*%3A*"})&fq=res_cityName:(${cityName !== undefined && cityName !== '' && cityName !== null ? cityName : "*:*"})&fq=res_stateName:(${stateName !== undefined && stateName !== '' && stateName !== null ? stateName : "*:*"})&fq=res_shortRegion:(${shortRegion !== undefined && shortRegion !== '' && shortRegion !== null ? shortRegion : "*:*"})&fq=${skillname}&fq=created:[${mappeddate !== null ? mappeddate : '* TO *'}]&fl=full_name,application_title,id,skillName,res_cityName,res_shortRegion,lastmodified,res_fileName,uid,filename&rows=20&start=0&sort=created%20desc&wt=json`
        // const searchResumesUrl1 = "http://65.21.122.252:8983/solr/jnp_resumes_dev/select?fl=application_title%2CskillName%2Cuid%2Cres_fileName%2Cres_visatypeName%2Cres_jobtypeName%2Cres_shortRegion%2Cres_cityName%2Cres_stateName%2Ctotal_experience%2Cemail_address%2Cfull_name%2Calias%2Cid%2Clastmodified%2Chome_phone&q.op=OR&q=*React%20developer*&rows=10&start=0"

        console.log(searchResumesUrl)
        fetch(`${Key.domain}/solr/solr_resumes`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: searchResumesUrl }),
        })
            .then((response) => {
                response.json().then((resdata) => {
                    console.log(resdata)
                    if (resdata.data.response.docs.length === 0) {
                        showAlternateResults(jobtitle)
                    } else {
                        setProfileResumes(resdata.data.response.docs)

                    }
                    // if (subScribersCCMails.length > 0) {
                    //   const newResumes = resdata.data.response.docs.map((list) => (
                    //     { ...list, isChecked: true })
                    //   );

                    //   setProfileResumes(newResumes);

                    //   console.log(newResumes)

                    //   const obj = newResumes.filter((x) => x.isChecked === true);
                    //   let filteredobjs = obj.filter((x) => x.application_title !== '');
                    //   const ids = filteredobjs.map(obj => obj.id)
                    //   setSelectedResids(ids)
                    //   setSelectedResumes(filteredobjs);
                    // }
                })

            })
    }
    const handleChange = (e, resumeId) => {
        const { name, value, checked } = e.target;
        let filteredobjs = []
        if (name === String(resumeId)) {
            console.log("comes here", resumeId)
            const newResumes = profileResumes.map((list) =>
                list.id === resumeId ? { ...list, isChecked: checked } : list
            );
            setProfileResumes(newResumes);
            console.log(newResumes)
            const obj = newResumes.filter((x) => x.isChecked === true);
            filteredobjs = obj.filter((x) => x.application_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
            // setProfileResumes(filteredobjs);
        }

    };
    const setMessageFunction = (e, t) => {



        console.log(comment)
        console.log(person)
        console.log(status)


        if (t === "sales") {
            setPerson(e.target.value)
        } else if (t === "status") {
            setStatus(e.target.value)
        } else if (t === "comments") {
            setComment(e)
        }
        props.showLoader();


        axios
            .post(`${Key.domain}/customer/insert_update_subscribers_followup`, {
                "admin_id": sessionStorage.getItem("id"),
                "session_token": sessionStorage.getItem("userToken"),
                "comment": t === "comments" ? e : comment,
                "user_id": editedData.user_id,
                "sales_person": t === "sales" ? e.target.value : person,
                // "sales_response_status": t === "status" ? e.target.value : status
                // "sales_person": "",
                "sales_response_status": ""
            })
            .then((resdata) => {
                console.log(resdata.data.data)
                handleSalesClose()
                getUsers();
            }).catch((err) => {
                console.log(err);
            });
    }

    const changeHandler = e => {
        e.preventDefault()
        setData({ ...data, [e.target.name]: e.target.value })
    }



    const actionButton = () => {
        setShowActionPopup(true)
    }

    const deactivateAccountSubmit = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/deactivate_jnp_user`, {
                "email": editedData.username
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();
                const msg = "Account '" + editedData.full_name + "' deactivated Successfully !";
                toast(<ToastMessage message={msg} />);

                handleActionClose()
            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    const msg = "Access denied.. Please Login";
                    toast(<ToastMessage message={msg} />);

                    handleActionClose()

                }
            });;
    }

    const onSelectListChange = (e) => {
        var minvalue = 0
        var maxvalue = 10000
        if (e.target.value === "1") {
            minvalue = 1
            maxvalue = 30
        } else if (e.target.value === "2") {
            minvalue = 31
            maxvalue = 50
        } else if (e.target.value === "3") {
            minvalue = 51
            maxvalue = 70
        } else if (e.target.value === "4") {
            minvalue = 71
            maxvalue = 100
        } else if (e.target.value === "5") {
            minvalue = 101
            maxvalue = 150
        } else if (e.target.value === "6") {
            minvalue = 151
            maxvalue = 200
        } else if (e.target.value === "7") {
            minvalue = 201
            maxvalue = 500
        } else if (e.target.value === "8") {
            minvalue = 501
            maxvalue = 1000
        } else if (e.target.value === "9") {
            minvalue = 1001
            maxvalue = 2000
        } else if (e.target.value === "10") {
            minvalue = 2001
            maxvalue = 5000
        }
        console.log(minvalue, maxvalue)
        if (e.target.value == "free") {
            getUsers();
        } else {
            props.showLoader();

            axios
                .post(`${Key.domain}/admin/get_empsbyviews`, {
                    minresumeviews: minvalue,
                    maxresumeviews: maxvalue
                })
                .then((response) => {
                    console.log(response)
                    props.hideLoader();
                    setUsers(response.data.data)
                }).catch((err) => {
                    // console.log(err.response.data.data )

                    if (err.response.data.data === "Access denied.. Please Login") {
                        props.hideLoader();

                        const msg = "Access denied.. Please Login";
                        toast(<ToastMessage message={msg} />);



                    }
                });;
        }


    }

    const resetPasswordSubmit = () => {
        props.showLoader();

        axios
            .post(`${Key.domain}/customer/reset_jnp_user_password`, {
                "email": editedData.username
            })
            .then((response) => {
                console.log(response)
                getUsers();
                props.hideLoader();
                const msg = "Account '" + editedData.full_name + "' password reset successfully !";
                toast(<ToastMessage message={msg} />);

                handleActionClose()
            }).catch((err) => {
                // console.log(err.response.data.data )
                if (err.response.data.data === "Access denied.. Please Login") {
                    props.hideLoader();

                    const msg = "Access denied.. Please Login";
                    toast(<ToastMessage message={msg} />);

                    handleActionClose()

                }
            });;
    }
    const columnsSearch = [
        {
            name: "search_title",
            label: "Title",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },

        },
        {
            name: "search_location",
            label: "Location",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value === 'null' ? 'N/A' : value : "N/A"
                }
            },

        },
        {
            name: "search_skills",
            label: "Skills",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },

        },
    ]

const columnResumeViews=[
    {
        name: "id",
        label: "Resume Id",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },

    },
    {
        name: "full_name",
        label: "Full Name",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },

    },
    {
        name: "application_title",
        label: "Resume Title",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? value : "N/A"
            }
        },

    },
]
    const columns = [
        {
            name: "usercreated",
            label: "Date",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? moment(value).format("LL") : "N/A"
                }
            },

        },
        {
            label: <span>Name</span>,
            name: "full_name",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),
                customBodyRender: (value) => {
                    return value ? <span style={{ background: "#edede8", borderRadius: "15px", padding: "7px" }}>{value}</span> : "N/A"
                }
            },
        },
   
        {
            label: <span>Company Name</span>,
            name: "userCompanyName",
            options: {
                setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "200px" } }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : "N/A"
                }
            },
        },
        {
            label: <span>Resume Views</span>,
            name: "resumesviewed",

            options: {

                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 50 }}>
                                <Button value={value}>{value}</Button>
                            </FormControl>
                        } />
                )
            },
        },
        {
            label: <span>Resume Downloads</span>,
            name: "downloadsCount",

            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? value : 0
                }
            },
        },
        {
            label: <span>Search Strings</span>,
            name: "searchStringsCount",

            options: {

                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 50 }}>
                                <Button value={value}>{value}</Button>
                            </FormControl>
                        } />
                )
            },
        },
        {
            name: "user_id",
            label: "Upgrade Package",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Button disabled={value === null ? true : false}>Upgrade</Button>} />
                )
            }
        },

        // {
        //     name: "comments",
        //     label: "Comments",
        //     options: {

        //         filter: true,
        //         customBodyRender: (value, tableMeta, updateValue) => (
        //             <FormControlLabel
        //                 control={
        //                     <FormControl sx={{ m: 1, minWidth: 50 }}>
        //                         <Button onClick={(e) => changeComment(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined && value !== 'undefined') ? <>{value.length > 15 ? value.slice(0, 15) + "..." : value} <BiRightArrowAlt /></> : <>Add <BiRightArrowAlt /></>}</Button>
        //                     </FormControl>
        //                 } />
        //         )
        //     }
        // },

        {
            name: "sales_person",
            label: "Sales Person",
            options: {
                setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "120px" } }),
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Button onClick={(e) => changeSalesPerson(e)} value={value}>{(value !== 'null' && value !== null && value !== "" && value !== undefined) ? <>{value} <RiArrowDropDownLine /></> : <>Select <RiArrowDropDownLine /></>}</Button>
                            </FormControl>
                        } />
                )
            }
        },

        {
            name: "sendMail",
            label: "Send Mail",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Button onClick={() => sendMailFunction()}>send</Button>} />
                )
            }
        },

        // {
        //     name: "user_id",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (
        //             <FormControlLabel
        //                 control={<Button onClick={() => actionButton()}><SlOptionsVertical /></Button>} />
        //         )
        //     }
        // }

        {
            name: "Delete",
            label: "Delete",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Tooltip title="delete"><Button ><MdDeleteOutline size='23' /></Button></Tooltip>} />
                )
            }
        },
        {
            name: "activated_by",
            label: "Activated By",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Tooltip title={value} ><span>{value ? value : "N/A"}</span></Tooltip>} />
                )
            }
        },
        {
            name: "username",
            label: "Email ID",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Tooltip title={value} ><span>{value ? value : "N/A"}</span></Tooltip>} />
                )
            }
        },
        {
            name: "phone_number",
            label: "Phone",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<Tooltip title={value} ><span>{value ? value : "N/A"}</span></Tooltip>} />
                )
            }
        }
    ];



    const sendMailFunction = () => {
        setSendMail(true)
    }



    const upgradePackage = (userdata, userid) => {
        if (userdata.company_name) {
            history("/dashboard/addcustomer", {
                state: {
                    customerid: userid,
                    upgrade: true,
                    freepackage:userdata.packagetitle
                }
            });
        }

    }


    const handleUserDetails = (user) => {

        console.log(user.user_id)

        history("/dashboard/customerDetailsView", { state: { customerid: user.user_id } })

    }
    const handleSendMail = (e) => {

        e.preventDefault();
        console.log(data)
        // var jobtitle = '"' + data.jobtitle + '"'
        console.log(profileResumes)
        console.log(selectedResumes)

        if (toEMail && fromEmail && fullname) {
            if (selectedResumes.length > 5) {


                const msg = "Maximum limit is to share resumes is 5";
                toast(<ToastMessage message={msg} />);

            } else if (selectedResumes.length > 0) {
                fetch(`${Key.domain}/common/send_contactSales_resumes_Mail`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes, toEMail: data.username, mailBodyCondition: "fromSubscribers" }),
                })
                    .then((response) => {
                        response.json().then((resdata) => {
                            console.log(resdata)
                            if (resdata.data === "Mail sent To Employer") {
                                const msg = "Mail Sent Successfully";
                                toast(<ToastMessage message={msg} />);
                                window.location.reload()
                            }
                        })
                    })
            } else {
                const msg = "Select atleast 1 resume";
                toast(<ToastMessage message={msg} />);

            }
        } else {

            const msg = "Enter required fields";
            toast(<ToastMessage message={msg} />);

        }
    }


    const deleteSubscriber = (userId) => {


        if (window.confirm("Are you sure you want to delete subscriber?")) {
            axios
                .post(`${Key.domain}/customer/delete_User`, {
                    "admin_id": sessionStorage.getItem("id"),
                    "user_id": userId,
                })
                .then((response) => {
                    getUsers()
                    const msg = "Subscriber Deleted Successfully!";
                    toast(<ToastMessage message={msg} />);
                }).catch((err) => {

                    toast(<ToastMessage message={err} />);

                })
        }

    }

    const options = {
        tableBodyHeight: '40vh',
        fixedHeader: true,
        density: true,
        selectableRows: false,
        search: true,
        filter: true,
        sort: false,
        download: true,
        print: false,
        viewColumns: false,
        onRowClick: handleRowClick,
        setRowProps: (row, dataIndex, rowIndex) => {
            if (selectedRows.includes(dataIndex)) {
                return {
                    style: {
                        backgroundColor: '#d9e8fc', // set your desired background color
                    },
                };
            }
            return {};
        },



        onCellClick: (cellIndex, rowIndex) => {
            console.log(users[rowIndex.dataIndex]);
            console.log(rowIndex.dataIndex);
            console.log(rowIndex);

            let userdata = users[rowIndex.dataIndex]
            console.log(userdata)
            setEditedData(userdata)


            setNewCustomerId(userdata.user_id)

            setData({ ...data, fullname: userdata.full_name, toEMail: userdata.username })
            setPerson(userdata.sales_person)
            setStatus(userdata.sales_response_status)
            setComment(userdata.comments)
            if (rowIndex.colIndex === 3) {

                getResumeViews(userdata.user_id)
            }
            if (rowIndex.colIndex === 4) {

                getResumeDownloads(userdata.user_id)
            }
            if (rowIndex.colIndex === 5) {

                getSearchStrings(userdata.user_id)
            }
            if (rowIndex.colIndex === 1) {
                console.log(cellIndex)
                setEditedData(userdata)
            }

            if (rowIndex.colIndex === 6) {
                upgradePackage(userdata, userdata.user_id)

            }

            if (rowIndex.colIndex === 9) {
                deleteSubscriber(userdata.user_id)

            }


        },

    };
    const getMuiTheme = () => createTheme({ components: { MuiTableRow: { styleOverrides: { root: { cursor: "pointer" } } } } });


    return (
        <div>
            <Modal show={showComment} centered onHide={handleCommentClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FormControl fullWidth>
                            <> <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Add Comment..."
                                onChange={(e) => setComment(e.target.value)
                                }
                                value={(comment !== undefined && comment !== 'undefined') ? comment : ""} size="50" /></>

                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleCommentClose}>
                        Close
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={handleSaveComment}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showActionPopup} centered onHide={handleActionClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Action for user - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FormControl fullWidth>
                            <>
                                <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={() => { setShowDeactivate(true), setShowResetPass(false) }
                                }>
                                    Deactivate
                                </Button>
                                <Button variant="Primary" style={{ background: "blue", marginTop: "5px", color: "#ffff" }} onClick={() => { setShowDeactivate(false), setShowResetPass(true) }
                                }>
                                    Reset Password
                                </Button>
                            </>

                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <> {showResetPassword ? <div    >

                        <h3>Are you sure to reset password ? </h3>
                        <div style={{ textAlign: "right", marginRight: "15px" }}>
                            <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                                No
                            </Button>
                            <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={resetPasswordSubmit}>
                                Yes
                            </Button>
                        </div>
                    </div>
                        : ""}
                        {showDeactivate ?
                            <div style={{ textAlign: "right", marginRight: "15px" }}>

                                <h3>Confirm to deactivate account ? </h3>

                                <div>
                                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                                        No
                                    </Button>
                                    <Button variant="Primary" style={{ background: "blue", marginLeft: "10px", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                                        Yes
                                    </Button>
                                </div>
                            </div>
                            : ""}
                    </>
                </Modal.Footer>
            </Modal>

            {/* <Modal show={showResetPassword} centered onHide={handleResetPassClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Are you sure to reset password ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleResetPassClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={resetPasswordSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeactivate} centered onHide={handleDeactivateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Active User - {editedData.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h3>Confirm to deactivate account ? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleDeactivateClose}>
                        No
                    </Button>
                    <Button variant="Primary" style={{ background: "blue", color: "#ffff" }} onClick={deactivateAccountSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal> */}



            <Modal
                show={sendMail}
                onHide={handleCloseMail}
                backdrop="static"
                centered
                size="xl"
                keyboard={false}
                style={{ marginTop: "25px", marginLeft: "9%" }}
            >


                <Modal.Body>
                    <div className='p-1'>

                        <Modal.Header closeButton>
                            <Modal.Title>Send Resumes</Modal.Title>
                            <Form onSubmit={(e) => handleSendMail(e)}>


                                <Button variant="primary" type="submit" style={{ float: "right", marginTop: "-5px", marginLeft: "700px", background: "blue", color: "white" }}>
                                    Share Mail
                                </Button>
                            </Form>
                        </Modal.Header>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 sales-styles'>

                                    <div className="sales-style">
                                        <Form>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={2}>
                                                    From * :
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="email" placeholder="Email Id" name="fromEmail" value={fromEmail} onChange={changeHandler} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                                <Form.Label column sm={2}>
                                                    Name * :
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="name" placeholder="Full Name" name="fullname" value={fullname} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                                                <Form.Label column sm={2} >
                                                    To * :
                                                </Form.Label>
                                                <Col sm={10}>

                                                    <Col sm={10}>
                                                        <Form.Control type="name" placeholder="Full Name" name="fullname" value={toEMail} />
                                                    </Col>


                                                </Col>
                                            </Form.Group>



                                            {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
            <Form.Label column sm={2}>
              Body:
            </Form.Label>
            <Col sm={10}>
              <Form.Control as="textarea" rows={3} name="body" value={body} onChange={changeHandler} />
            </Col>
          </Form.Group> */}

                                        </Form>

                                        <h3 style={{ fontSize: "20px", }}>Search Strings</h3>

                                        <Form>
                                            <Row className="align-items-center">
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Job Title
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Job Title"
                                                        name="jobtitle" value={jobtitle} onChange={changeHandler}
                                                    />
                                                </Col>
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Skills
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Skills"
                                                        name="skillname" value={skillname} onChange={changeHandler}
                                                    />
                                                </Col>
                                                <Col xs="3" lg="12">
                                                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                                                        Location
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="mb-2"
                                                        id="inlineFormInput"
                                                        placeholder="Location"
                                                        name="location" value={location} onChange={changeHandler}
                                                    />
                                                </Col>
                                                {/* <Col xs="auto">
              <Button type="submit" className="mb-2">
                Add More
              </Button>
            </Col> */}
                                            </Row>
                                        </Form>
                                        <div className="mt-4" style={{ display: "flex", float: "right", }}>
                                            <div>
                                                <DropdownButton id="dropdown-basic-button" title={date ? date : "Date"} style={{ float: "right", }} >
                                                    <Dropdown.Item onClick={(e) => handleDateChage('Today')}>Today</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => handleDateChage('Last Week')}>Last Week</Dropdown.Item>
                                                </DropdownButton>
                                            </div> &nbsp;&nbsp;
                                            <div>

                                                <Form onSubmit={(e) => handleSearch(e)}>
                                                    <Button type="submit" style={{ float: "right", background: "blue", color: "#ffff" }} variant="primary">
                                                        Search
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='col-md-6 sales-styles' >
                                    <Modal size="lg" show={showResume} onHide={handleClose} centered>
                                        <Modal.Body>
                                            <Container>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                                                    </Col>

                                                </Row>


                                            </Container>
                                        </Modal.Body>

                                    </Modal>
                                    <div className=" positions-scroll">
                                        {profileResumes.length > 0 ? <h4>These are the active RESUMES for your requirement {data.jobtitle} </h4> : <h4>Search for resumes</h4>}

                                        <br />
                                        {profileResumes.length > 0 ? profileResumes.map((resume) => {
                                            return (
                                                <>
                                                    <div className="job-titile" style={{ padding: "5px" }}>
                                                        <Row className="job-checkbox">
                                                            <Col sm="1">  <Form.Check aria-label="option 1" name={resume.id}
                                                                id="check"
                                                                checked={resume?.isChecked}
                                                                onClick={(e) => handleChange(e, resume.id)} /></Col>
                                                            <Col sm="11">  <h3> <a style={{ cursor: "pointer", color: "#2065D1" }} onClick={() => { setShowResume(true); setSelectresumeFileDetails(resume) }}> {resume.application_title} </a> </h3> </Col>
                                                        </Row>


                                                        <ul>
                                                            <li> <FaSuitcase /> {resume.skillName
                                                                ? resume.skillName
                                                                    .join(",")
                                                                    .split(",")
                                                                    .join(", ")
                                                                    .slice(0, 100)
                                                                : "N/A"}</li>

                                                            <li style={{ float: "left" }}> <MdLocationOn /> {resume.res_cityName ? resume.res_cityName : ''}, {resume.res_shortRegion ? resume.res_shortRegion : ''} </li>

                                                            <li style={{ float: "right" }}> <FaCalendarAlt />

                                                                {resume.lastmodified ? moment(resume.lastmodified).format("LL") : "N/A"
                                                                }
                                                            </li>


                                                        </ul>
                                                    </div>
                                                    <hr style={{ width: "100%" }} />
                                                </>)

                                        }) : <center style={{ marginTop: "50px" }}> <h4>No Resumes</h4> </center>
                                        }



                                    </div>
                                    <br />


                                </div>
                            </div>

                        </div>

                    </div>
                </Modal.Body>

            </Modal>
            {/* show Resume views modal */}
            <Modal show={showResumeViews} size='lg' centered onHide={handleResumeViewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Resume Views</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 row'>

                        {resumeViewsList.length > 0 ?
                            <div>
                                <MUIDataTable
                                    className='col-12'

                                    data={resumeViewsList}
                                    columns={columnResumeViews}
                                    options={options}
                                />
                            </div>
                            : <div className='col-12 text-center'>
                                <h5>No Resume Views</h5>
                            </div>}

                    </div>
                </Modal.Body>

            </Modal>
            {/* show Resume Downloads modal */}
            <Modal show={showResumeDownloads} size='lg' centered onHide={handleResumeDownloadsClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Resume Downloads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 row'>

                        {resumeDownloadsList.length > 0 ?
                            <div>
                                <MUIDataTable
                                    className='col-12'

                                    data={resumeDownloadsList}
                                    columns={columnResumeViews}
                                    options={options}
                                />
                            </div>
                            : <div className='col-12 text-center'>
                                <h5>No Resume Downloads</h5>
                            </div>}

                    </div>
                </Modal.Body>

            </Modal>
            {/* show search strings */}
            <Modal show={showStrings} size='lg' centered onHide={handleStingsClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Strings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12 row'>

                        {searchStringsList.length > 0 ?
                            <div>
                                <MUIDataTable
                                    className='col-12'

                                    data={searchStringsList}
                                    columns={columnsSearch}
                                    options={options}
                                />
                            </div>
                            : <div className='col-12 text-center'>
                                <h5>No Search Strings</h5>
                            </div>}

                    </div>
                </Modal.Body>

            </Modal>

            {/* Update Sales Model */}
            <Modal show={showSalesPerson} centered onHide={handleSalesClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Sales Person</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sales Person</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={salesPerson}
                                onChange={(e) => {
                                    setMessageFunction(e, "sales"),
                                        setPerson(e.target.value)
                                }}
                                label="Sales Person"

                            >{salesPersons.map((item, index) => (
                                <MenuItem value={item.name} >{item.name}</MenuItem>
                            ))}


                            </Select>
                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ background: "grey", color: "#ffff" }} onClick={handleSalesClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ marginTop: "0px", boxShadow: "0px 0px 5px black" }}>
                <ThemeProvider theme={getMuiTheme()}   >
                    <MUIDataTable

                        title={<div>
                            <span>
                                <div className='col-sm-3'>
                                    <select name="plan" id="plan" class="form-select form-select-sm"
                                        aria-label=".form-select-sm example"
                                        onChange={(e) => onSelectListChange(e)}>
                                        <option value="free" >Sort by views</option>
                                        <option value="1" name="1 - 30">1 - 30</option>
                                        <option value="2" name="31 - 50">31 - 50</option>
                                        <option value="3" name="51 - 70">51 - 70</option>
                                        <option value="4" name="71 - 100">71 - 100</option>
                                        <option value="5" name="101 - 150">101 - 150</option>
                                        <option value="6" name="51 - 100">151 - 200</option>
                                        <option value="7" name="201 - 500">201 - 500</option>
                                        <option value="8" name="501 - 1000">501 - 1000</option>
                                        <option value="9" name="1001 - 2000">1001 - 2000</option>
                                        <option value="10" name="2001 - 5000">2001 - 5000</option>
                                    </select>
                                </div>
                            </span>
                        </div>}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
                <div>
                    <CustomerDetailView2 editedData={editedData} customerId={newCostumerId} refreshData={() => refreshData()} />
                </div>
            </div>

        </div>
    )
}




const mapDispatchToProps = dispatch => {

    return {

        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),

    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);