/* eslint-disable */


import axios from "axios";
import Key from '../clientVariables.json'
import moment from 'moment'

// import { toast } from "react-toastify";
import ToastMessage from '../components/Loader/loading';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const loginListeners = [];


class UserService {

    /**
    * Get logged user
    */

    notify = () => {toast("Default Notification !");}
  
    getUser() {
        try {
            const user = JSON.parse(
                localStorage.getItem("user")
            );
            sessionStorage.setItem("companyid", user.companyid);
            sessionStorage.setItem("id", user.id);
            sessionStorage.setItem("email", user.username);
            // sessionStorage.setItem("profileimg", user.image)

            sessionStorage.setItem("lastvisited", user.lastvisitDate);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        } catch (e) {
            return null;
        }
    }




    async login(username, password, ipaddress) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.domain}/admin/login`, {
                    username: username,
                    password: password,
                    lastvisitDate: moment.utc(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
                    ip_address: ipaddress
                })
                .then(async response => {
                    console.log(response)
                    var user = response
                    if (response.data.error){
                         alert(response.data.error)
                        // toast(
                        //     <ToastMessage message={response.data.error} />
                        // );

                        resolve(user)
                   } else{
                    var userdetails = response.data.data[0]
                    if (userdetails.id) {
                        if (user.package_id === 0) {
                            let userdetails = { username: username, password: password }
                            sessionStorage.setItem("userdetailsforpackage", JSON.stringify(userdetails))
                            window.location.href = "/packages"
                        }else {
                            loginListeners.forEach(fn => fn(userdetails));
                            sessionStorage.removeItem("userdetailsforpackage")
                            sessionStorage.setItem("companyid", userdetails.companyid);
                            sessionStorage.setItem("id", userdetails.id);
                            sessionStorage.setItem("profileimg", userdetails.image)
                            localStorage.setItem("profileimg", userdetails.image);

                            sessionStorage.setItem("ca_id", userdetails.ca_id);
                            sessionStorage.setItem("email", userdetails.username);
                            sessionStorage.setItem("lastvisited", userdetails.lastvisitDate);
                            sessionStorage.setItem("user", JSON.stringify(userdetails));
                            sessionStorage.setItem("userToken", userdetails.session_token);
                            localStorage.setItem("user", JSON.stringify(userdetails));
                            sessionStorage.setItem("package_id", userdetails.package_id)
                            sessionStorage.setItem("usertype", userdetails.user_type)
                            sessionStorage.removeItem("jobtitle")

                            resolve(user);

                        }

                    } else {
                        resolve(user)
                        var loading = false
                        sessionStorage.setItem("isLoading", loading)
                    }
                }
                })
                .catch(error => {
                    console.log (error)
                    // alert("Invalid credentials")
                    toast.error("Invalid Credentials !", {
                        position: toast.POSITION.TOP_RIGHT
                      });
                      if(error) {
                        return (
                            <>
                           
                              <ToastContainer />
                            </>
                          );
                      }
                      
                  
                     

                  

                    resolve(error)
                   
                    reject(error);
                    var loading = false
                    sessionStorage.setItem("isLoading", loading)
                });
        });
    }


    logout(uid,ipadress) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Key.domain}/admin/logout`, { adminid: sessionStorage.getItem("id") ,ip_address:ipadress})
                .then(response => {
                    ////////console.log.log(response)
                    loginListeners.forEach(fn => fn(null));
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href = '/'
                    resolve(true);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export default new UserService();
