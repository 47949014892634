/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'

function Employerregistrations() {

  const [empData, setEmpData] = useState([])


  useEffect(() => {
    axios.get(`${Key.domain}/common/get_emp_jobfair_registrations`)
      .then(resdata => {

        
        var jobfairUsers = resdata.data.data[1].newUsers
        setEmpData(jobfairUsers)

      })
  }, [])

  const columns = [
    {
      label: <span>Company Name</span>,
      name: "companyname",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Email</span>,
      name: "email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Contact Name</span>,
      name: "name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Openings</span>,
      name: "openpositions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Mobile</span>,
      name: "phonenumber",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Date</span>,
      name: "created",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("MM D YYYY - h:mm:ss a")

      }
      },
    },

  ];

  const options = {
    fixedHeader: true,
    density:true,
    selectableRows: true,
    search:true,
    filter:true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
  };
  return (
    <div style={{boxShadow:"0px 0px 5px black"}}>
      <MUIDataTable
        title={<h5>Employers List </h5>}
        data={empData}
        columns={columns}
        options={options}
      />
    </div>
  )
}

export default Employerregistrations