/* eslint-disable*/
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { BsFillTagsFill } from 'react-icons/bs';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Key from '../clientVariables.json';
import SessionAlert from '../SessionAlert';
import Costbreakdown from './Coastbreakdown';
import Jobsbreakdown from './Jobsbreakdown';
import OptBreakdown from './OPTBreakdown';
import request from '../utils/request';
import { ENDPOINT } from '../utils/endpoint';
import { postOptions } from '../utils/httpConfig';
import Salesexecutiveperformancee from './Salesexecutiveperformance';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import Subscribers from './Subscribers';
import Companies from './Companies';
import Latestsales from './Latestsales';
import UserStatus from './UserOnlineStatus';
import Affliation from './Afflications';
import Ads from './Ads';

// ----------------------------------------------------------------------

function DashboardApp(props) {
  const theme = useTheme();
  const history = useNavigate();
  const [dashboardStats, setDashboardStats] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [solrResumesCount, setSolrResumesCount] = useState(0);
  const [solrJobsCount, setSolrJobsCount] = useState(0);
  const [activeEmployers, setActiveEmployers] = useState([]);
  const [activeJobseekers, setActiveJobseekers] = useState([]);
  const [activeBenchsales, setActiveBenchsales] = useState([]);
  const [liveJobsCount, setLiveJobsCount] = useState(0);

  const redirectionFunction = () => {
    history('/dashboard/resumes', { state: { id: '', OPT: 'NO' } });
  };

  useEffect(() => {
    axios
      .post(`${Key.domain}/dashboard/get_dashboard_stats`, {
        admin_id: sessionStorage.getItem('id'),
        session_token: props.currentUser.session_token,
      })
      .then((resdata) => {
        console.log(resdata);
        setDashboardStats(resdata.data.data);
      })
      .catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === 'Access denied.. Please Login') {
          setSessionExpired(true);
        }
      });

    const searchResumesUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?&rows=20&start=0&sort=created%20desc&wt=json`;

    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: searchResumesUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        console.log(resdata);
        if (resdata.data.response.docs.length > 0) {
          console.log(resdata.data.response.numFound);
          setSolrResumesCount(resdata.data.response.numFound);
        }
      });
    });

    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?fq=status:(1)&rows=10&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        setSolrJobsCount(resdata.data.response.numFound);
      });
    });

    getUserStatus();

    //for livejobs count
    const searchResumesUrls = `${Key.Solr_url}/${Key.solrJobs_collection}/select?fq=posted_flag%3A1&q.op=OR&q=*%3A*&sort=created%20desc`;

    var jobidss = [];
    console.log(searchResumesUrl);
    fetch(`${Key.domain}/solr/solr_resumes`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: searchResumesUrls }),
    }).then((response) => {
      response.json().then((resdata) => {
        setLiveJobsCount(resdata.data.response.numFound);
      });
    });
  }, []);

  const getUserStatus = async () => {
    const result = await request(
      `${ENDPOINT['getUsersOnlineStatus']}`,
      postOptions({ user_id: sessionStorage.getItem('id'), session_token: sessionStorage.getItem('userToken') })
    );
    console.log(result);
    setActiveEmployers(result.data.filter((user) => user.user_type_id === '2' && user.action__type === 'loggedin'));
    setActiveJobseekers(result.data.filter((user) => user.user_type_id === '5' && user.action__type === 'loggedin'));
    setActiveBenchsales(result.data.filter((user) => user.user_type_id === '3' && user.action__type === 'loggedin'));
  };

  console.log(activeEmployers, activeEmployers, activeBenchsales);
  const redirecToJobs = () => {
    window.open('https://jobsnprofiles.com', '_blank');
  };
  const redirecTocustomers = () => {
    history('/dashboard/user');
  };
  const redirecTosubscribers = () => {
    history('/dashboard/Subscriptions');
  };
  const redirectToBenchsales = () => {
    history('/dashboard/Benchsales', { state: { id: '', OPT: 'NO' } });
  };
  const redirecToLiveJobs = () => {
    history('/dashboard/Livejobs');
  };

  return (
    <div style={{ marginTop: '-45px' }}>
      {sessionExpired ? (
        <SessionAlert />
      ) : (
        <Page title="Dashboard">
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary
                  title="Customers"
                  onClick={redirecTocustomers}
                  style={{ cursor: 'pointer' }}
                  total={dashboardStats.customersCount}
                  color="secondary"
                  icon={'ant-design:user-outlined'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ cursor: 'pointer' }}>
                <AppWidgetSummary
                  title="Active Jobseekers"
                  total={activeJobseekers.length}
                  color="warning"
                  icon={'ant-design:search-outlined'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} onClick={redirecToJobs} style={{ cursor: 'pointer' }}>
                <AppWidgetSummary
                  title="Total Jobs"
                  total={solrJobsCount}
                  color="primary"
                  icon={'ant-design:search-outlined'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} onClick={redirectToBenchsales} style={{ cursor: 'pointer' }}>
                <AppWidgetSummary
                  title="Active Bench Sales"
                  total={activeBenchsales.length}
                  color="success"
                  icon={'ant-design:user-outlined'}
                />
              </Grid>

              <Grid item md={12} />
              <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary
                  title=" Active Subscribers"
                  onClick={redirecTosubscribers}
                  color="info"
                  style={{ cursor: 'pointer' }}
                  total={dashboardStats.subscribersCount}
                  icon={'ant-design:user-outlined'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ cursor: 'pointer' }}>
                <AppWidgetSummary
                  title="Active Employers"
                  total={activeEmployers.length}
                  color="success"
                  icon={'ant-design:search-outlined'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} onClick={redirectionFunction} style={{ cursor: 'pointer' }}>
                <AppWidgetSummary
                  title="Total Resumes"
                  total={solrResumesCount}
                  color="info"
                  icon={'ant-design:file-outlined'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary
                  title="Live Jobs"
                  onClick={redirecToLiveJobs}
                  color="warning"
                  style={{ cursor: 'pointer' }}
                  total={liveJobsCount}
                  icon={'ant-design:user-outlined'}
                />
              </Grid>

              <div className="container">
                <div className="row">
                  <Grid item xs={12} md={12} lg={4}>
                    <br />
                    {/*  <h4 className='mb-4' style={{ paddingLeft: "28px" }}>Resumes Breakdown</h4> */}
                    <Costbreakdown />
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <br />
                    {/*   <h4 className='mb-4' style={{ paddingLeft: "28px" }}>OPT Resumes Breakdown</h4> */}
                    <OptBreakdown />
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <br />
                    {/*  <h4 className='mb-4' style={{ paddingLeft: "28px" }}>Jobs Breakdown</h4> */}
                    <Jobsbreakdown />
                  </Grid>
                </div>
              </div>
              <Grid item xs={12} md={12} lg={4}>
                <Companies />
              </Grid>
              <Grid item xs={12} md={12} lg={8}>
                <Subscribers />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Latestsales />
              </Grid>

              {/*  <Grid item xs={12} md={12} lg={6}>
              <h4 className='mb-4' style={{paddingLeft:"28px"}}>Sales Excecutive Performance</h4>
               <Salesexecutiveperformancee/>
            </Grid> */}

              {/* <Grid item xs={12} md={12} lg={12}>
                <UserStatus />
              </Grid> */}
            </Grid>
          </Container>
        </Page>
      )}
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(DashboardApp);
