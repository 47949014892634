/* eslint-disable */

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Companydetailsview from './Companydetailsview';
import Lcadetailsview from './LcaDetailView';
import H1bdetailsview from './H1bDetailview';
import GreenCardDetailView from './GreenCardDetailView';
import CompanyAttorneys from "./companyAttorneys"
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  
React.useEffect(() => {
   setValue(0)
}, [props])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(props.greenCardStatus)
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Company Details" {...a11yProps(0)} />
          <Tab label="LCA" {...a11yProps(1)} />
          <Tab label="Green Card" {...a11yProps(2)} />
          <Tab label="H1B" {...a11yProps(3)} />
          <Tab label="Attorneys" {...a11yProps(4)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Companydetailsview companyName={props.companyName} companyId={props.companyId} companyDetails={props.companyDetails} lcaStatus={props.lcaStatus}  greenCardStatus={props.greenCardStatus} h1bStatus={props.h1bStatus} greenCardDetails={props.greenCardDetails}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Lcadetailsview companyId={props.companyId} companyName={props.companyName} lcaDetails={props.lcaDetails} lcaStatus={props.lcaStatus}  />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GreenCardDetailView companyId={props.companyId} companyName={props.companyName} greenCardDetails={props.greenCardDetails} greenCardStatus={props.greenCardStatus} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <H1bdetailsview h1bDetails={props.h1bDetails} h1bStatus={props.h1bStatus}/>

      </TabPanel>
     <TabPanel value={value} index={4}>
      <CompanyAttorneys attorneyDetails={props.attorneyDetails}/>

  </TabPanel>

    </Box>
  );
}
