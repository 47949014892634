/* eslint-disable */


import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import moment from 'moment'
import Key from '../clientVariables.json'

function Jobseekerregistrations() {

  const [jobseekersData, setJobseekersData] = useState([])
  const [selectedJobfairData, setSelectedJobfairData] = useState({})


  useEffect(() => {


    var users = []
    var oldUsers = []

    axios.get(`${Key.domain}/common/get_js_jobfair_registrations`)
      .then(resdata => {
        console.log(resdata)

        var jobfairUsers = resdata.data.data[0].oldUsers
        setJobseekersData(jobfairUsers)
        console.log(jobfairUsers)

      })

  }, [])



  console.log(jobseekersData)

  const handleResumeDownload = (row, cellIndex) => {
    // console.log(row)
    // console.log(row.id)

    // let selectedJobfair = selectedJobfairData
    let selectedJobfair = row

    // console.log(selectedJobfair.id)
    if (selectedJobfair.profile !== null && selectedJobfair.profile !== undefined && selectedJobfair.profile !== "") {

      fetch(
        `${Key.domain}/common/get_jobfair_file/${selectedJobfair.id}/${selectedJobfair.profile}`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + window.localStorage['Access_Token'],
          },
        }
      ).then((resdata) => {
        console.log(resdata);
        console.log(resdata.url);
        const fileURL = resdata.url;

        console.log(fileURL);


        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
        .catch((e) => {
          console.log(e);
        });


    }
  }


  const columns = [
    {
      label: <span>Full Name</span>,
      name: "full_name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Email</span>,
      name: "email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Role</span>,
      name: "role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Mobile</span>,
      name: "phone",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "N/A"
        }
      },
    },
    {
      label: <span>Resume</span>,
      name: "profile",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <a style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>{value}</a> : "N/A"
        }
      },
    },
    {
      label: <span>Date</span>,
      name: "created",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("MM D YYYY - h:mm:ss a")

        }
      },
    },

  ];



  const options = {
    fixedHeader: true,
    density: true,
    selectableRows: true,
    search: true,
    filter: true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
    onRowClick: (rowData, rowState) => {
      var row = jobseekersData[rowState.dataIndex];

      // console.log(rowData)
      // console.log(rowState)
      // console.log(jobseekersData[rowState.dataIndex])
      setSelectedJobfairData(row)
      handleResumeDownload(row)

    },
    onCellClick: (cellIndex, rowIndex) => {
      // console.log(cellIndex.value);
      // console.log(rowIndex.dataIndex);
      // console.log(rowIndex);

      if (rowIndex.colIndex === 4) {
        // console.log(cellIndex)
        var row = jobseekersData[rowIndex.rowIndex];
        // console.log(row)
        // handleResumeDownload(row, cellIndex)
      }
    },
  };
  return (
    <div style={{ boxShadow: "0px 0px 5px black" }}>
      <MUIDataTable
        title={<h5>Previous Jobseekers List</h5>}
        data={jobseekersData}
        columns={columns}
        options={options}
      />
    </div>
  )
}

export default Jobseekerregistrations