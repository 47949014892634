import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Monthly', 'Quarterly', 'Half-Yearly', 'Yearly'],
  legend: {
    position: 'right'
  },
  datasets: [
    {
      label: '# of Votes',
      data: [150, 19, 3, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(144,238,144, 0.2)',


      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(144,238,144,1)',

      ],
      borderWidth: 1,
    },
  ],
};

function Subscriptionplanmetrix(props) {
  const data = {
    labels: ['Monthly', 'Quarterly', 'Half-Yearly', 'Yearly'],
    legend: {
      position: 'right'
    },
    datasets: [
      {
        label: '# of Votes',
        data: [props.dashboardStats.no_of_monthly_subscriptions, props.dashboardStats.no_of_quarterly_subscriptions, props.dashboardStats.no_of_half_year_subscriptions, props.dashboardStats.no_of_annual_subscriptions],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(144,238,144, 0.2)',


        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(144,238,144,1)',

        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} />;
}
export default Subscriptionplanmetrix
