/*eslint-disable*/
import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { appBarClasses, Container } from '@mui/material';
import { connect } from "react-redux";
import axios from 'axios';
import Key from '../../src/clientVariables.json'
import Subscribers from './Subscribers';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";


import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';



const roleData = [
  { id: 2, value: 'Admin' },
  { id: 3, value: 'Sales Manager' },
  { id: 4, value: 'Sales Executive' },
  { id: 5, value: 'Research Manager' },
  { id: 6, value: 'Research Associate' },
  { id: 7, value: 'Finance' },

];
// reporting person roles

const roleData2 = [
  { id: 2, value: 'Admin' },
  { id: 3, value: 'Sales Manager' },
  { id: 5, value: 'Research Manager' },
];

const UserDetails = (props) => {

  const [userRolesData, setUserRolesData] = useState(roleData);
  const [reportRolesData, setReportRolesData] = useState(roleData2);
  const formikRef = useRef();
  const [phoneNo, setPhoneNo] = useState('');
  const [reportingContactNo, setReportingContactNo] = useState("")
  const [reportingUserDetails, setreportingUserDetails] = useState([])
  const [personalUser, setPersonalUser] = useState([]);

  const [adminPermitted, setAdminPermitted] = useState(false);
  const [userPermitted, setUserPermitted] = useState(false);
  const [customerPermitted, setCustomerPermitted] = useState(false);
  const [invoicePermitted, setInvoicePermitted] = useState(false);
  const [subscribersPermitted, setSubscribersPermitted] = useState(false);
  const [researchPermitted, setResearchPermitted] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const history = useNavigate();

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNo(formattedPhoneNumber);
    if (formikRef.current) {
      formikRef.current.setFieldValue('contactNo', formattedPhoneNumber);
      // console.log(formikRef.current);
    }
  };


  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    setPhoneNo(String(phoneNumber));
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };


  // reporting person contact no format
  const handleReportingPhoneNumber = (e) => {
    const formattedPhoneNumberTwo = formatPhoneNumberTwo(e.target.value);
    setReportingContactNo(formattedPhoneNumberTwo);
    if (formikRef.current) {
      formikRef.current.setFieldValue('reportingContactNo', formattedPhoneNumberTwo);
      console.log(formikRef.current);
    }
  }
  const formatPhoneNumberTwo = (value) => {
    if (!value) return value;
    const phoneNumberTwo = value.replace(/[^\d]/g, '');
    const phoneNumberLengthTwo = phoneNumberTwo.length;
    setReportingContactNo(String(phoneNumberTwo));
    if (phoneNumberLengthTwo < 4) return phoneNumberTwo;
    if (phoneNumberLengthTwo < 7) {
      return `(${phoneNumberTwo.slice(0, 3)}) ${phoneNumberTwo.slice(3)}`;
    }
    return `(${phoneNumberTwo.slice(0, 3)}) ${phoneNumberTwo.slice(3, 6)}-${phoneNumberTwo.slice(6, 10)}`;
  };

  const getRoles = (e) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('userRole', e.target.value);

    }
  };
  const getReportingRoles = e => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reportingUserRole', e.target.value);
    }
  }

  const getReportingUsers = (usertypeid) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reportingUserRole', usertypeid);

      axios
        .post(`${Key.domain}/admin/get_crm_users_by_role`, {
          "admin_id": sessionStorage.getItem("id"),
          "session_token": props.currentUser.session_token,
          "usertypeid": usertypeid
        })
        .then((response) => {
          console.log(response.data.data)
          setreportingUserDetails(response.data.data)
        }).catch((err) => {
          // console.log(err.response.data.data )
        });

    }

  };

  useEffect(() => {
    if (props.userid) {
      axios
        .post(`${Key.domain}/admin/get_crm_userdetails`, {
          "crm_user_id": props.userid,

        })
        .then((response) => {
          console.log(response.data.data)

          var personalReportingUser = response.data.data.reportingDetails
          console.log(personalReportingUser)
          var arr = []
          arr.push(personalReportingUser)
          setPersonalUser(arr)
          var permissions = response.data.data.permissionDetails
          setAdminPermitted(permissions.admin_management === "0" ? false : true)
          setUserPermitted(permissions.user_management === "0" ? false : true)
          setCustomerPermitted(permissions.customer_management === "0" ? false : true)
          setInvoicePermitted(permissions.invoice_management === "0" ? false : true)
          setSubscribersPermitted(permissions.sales_management === "0" ? false : true)
          setResearchPermitted(permissions.research_management === "0" ? false : true)

          axios
            .post(`${Key.domain}/admin/get_crm_users_by_role`, {
              "admin_id": sessionStorage.getItem("id"),
              "session_token": props.currentUser.session_token,
              "usertypeid": response.data.data.reportingDetails.ReportPersonUserType
            })
            .then((response) => {
              console.log(response.data.data)
              setreportingUserDetails(response.data.data)

            }).catch((err) => {
              // console.log(err.response.data.data )
            });

          setPhoneNo(response.data.data.userDetails.mobile)

          if (formikRef.current) {
            formikRef.current.setFieldValue(
              "username",
              response.data.data.userDetails.full_name
            );
            formikRef.current.setFieldValue(
              "contactNo",
              response.data.data.userDetails.mobile
            );
            formikRef.current.setFieldValue(
              "email",
              response.data.data.userDetails.username
            );
            formikRef.current.setFieldValue(
              "userRole",
              response.data.data.userDetails.user_type
            );

            formikRef.current.setFieldValue(
              "reportingUserRole",
              response.data.data.reportingDetails.ReportPersonUserType
            );
            formikRef.current.setFieldValue(
              "reportingPersonName",
              response.data.data.reportingDetails.id
            );
            formikRef.current.setFieldValue(
              "reportingContactNo",
              response.data.data.reportingDetails.mobile
            );
            formikRef.current.setFieldValue(
              "reportingPersonEmail",
              response.data.data.reportingDetails.email
            );


          }
        }).catch((err) => {
          // console.log(err.response.data.data )
        });
    }
  }, [])



  const handleReportingPersonDetails = (reportingPersonId) => {
    // console.log(reportingPersonId)
    // console.log(reportingUserDetails)
    var personalReportingUser = reportingUserDetails.filter((userdetail) => userdetail.id === parseInt(reportingPersonId))
    console.log(personalReportingUser)
    setPersonalUser(personalReportingUser)
  }


  const addUser = (data) => {
    console.log(data)
    axios
      .post(`${Key.domain}/admin/add_or_update_crmuser`, {
        "full_name": data.username,
        "username": data.email,
        "status": 1,
        "reportingto": props.userid ? personalUser[0].id : personalUser[0].id,
        "usertypeid": data.userRole,
        //"otpKey": "",
        "mobile": data.contactNo,
        "user_management": userPermitted ? 1 : 0,
        "customer_management": customerPermitted ? 1 : 0,
        "sales_management": subscribersPermitted ? 1 : 0,
        "invoice_management": invoicePermitted ? 1 : 0,
        "admin_management": adminPermitted ? 1 : 0,
        "research_management": researchPermitted ? 1 : 0,
        "action": props.userid ? 1 : 0,
        "id": props.userid
      })
      .then((response) => {
        console.log(response.data.data)
        if (response.data.success === 1) {

          toast(
            <ToastMessage message={response.data.data} />
          )
          // alert(response.data.data)
          history("/dashboard/manageUsers");

        } else if (response.data.success === 0) {
          // alert(response.data.data)
          toast(
            <ToastMessage message={response.data.data} />
          )
        }
      }).catch((err) => {
        // alert(err)
        toast(
          <ToastMessage message={err} />
        )
      });


  };
  return (
    <Container>
      <div className="row">
        <div className="col-12">
          <Formik
            innerRef={formikRef}
            initialValues={{
              userName: '',
              contactNo: '',
              email: '',
              userRole: '',
              reportingUserRole: '',
              reportingPersonName: '',
              reportingPersonEmail: '',
              reportingContactNo: ""
            }}
            //   validationSchema={}
            onSubmit={(values) => {
              console.log(values);
              addUser(values)
              // alert("Form is validated! Submitting the form...");
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <div>
                <Form>
                  <div className="row">
                    {/* user details */}

                    <div className="col-6">
                      <h5>User Details</h5>
                      <div className="row">
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            {/* <label htmlFor="username">
                          User Name <span style={{ color: 'red' }}>*</span>
                        </label> */}
                            <Field
                              type="text"
                              name="username"
                              placeholder="User name"
                              autocomplete="off"
                              className={`mt-2 form-control
						                   ${touched.username && errors.username ? 'is-invalid' : ''}`}
                            />

                            <ErrorMessage component="div" name="username" className="invalid-feedback" />
                          </div>
                        </div>
                        {/* contact no */}
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            <Field
                              type="text"
                              name="contactNo"
                              placeholder="(xxx)-xxx-xxxx"
                              autocomplete="off"
                              className={`mt-2 form-control
                              ${touched.contactNo && errors.contactNo ? 'is-invalid' : ''}`}
                              onChange={handlePhoneNumberChange}
                              value={phoneNo}
                            />
                            <ErrorMessage component="div" name="contactNo" className="invalid-feedback" />
                          </div>
                        </div>
                        {/* email */}
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            <Field
                              type="email"
                              name="email"
                              placeholder="Enter email"
                              autocomplete="off"
                              className={`mt-2 form-control
						                  ${touched.email && errors.email ? 'is-invalid' : ''}`}
                            />

                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                          </div>
                        </div>
                        {/* role */}
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            <Field
                              name="userRole"
                              as="select"
                              className={`mt-2 form-select form-control ${errors.userRole && touched.userRole ? ' is-invalid' : ''
                                }`}
                              onChange={(e) => {
                                console.log(e.target.value);
                                getRoles(e);
                                // formikRef.current.setFieldValue("userRole",e.target.value)
                              }}
                            >
                              <option>Select role</option>
                              {userRolesData.map((r) => {
                                return <option value={r.id}>{r.value}</option>;
                              })}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* reporting person details */}

                    <div className="col-6">
                      <h5>Reporting Person Details</h5>
                      <div className="row">
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            {/* <label htmlFor="username">
                          User Name <span style={{ color: 'red' }}>*</span>
                        </label> */}
                            <Field
                              as="select"
                              name="reportingUserRole"
                              placeholder=""
                              autocomplete="off"
                              className={`mt-2 form-select form-control ${errors.reportingUserRole && touched.reportingUserRole ? ' is-invalid' : ''
                                }`}
                              onChange={e => getReportingUsers(e.target.value)}
                            >
                              <option selected>Select role</option>
                              {reportRolesData.map(r => <option value={r.id}>{r.value}</option>)}
                            </Field>

                            <ErrorMessage component="div" name="username" className="invalid-feedback" />
                          </div>
                        </div>
                        {/* filter reporting person names */}
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            <Field
                              as="select"
                              name="reportingPersonName"
                              placeholder=""
                              autocomplete="off"
                              className={`mt-2 form-select form-control ${errors.reportingPersonName && touched.reportingPersonName ? ' is-invalid' : ''
                                }`}
                              onClick={(e) => handleReportingPersonDetails(e.target.value)}

                            >
                              <option>Select User</option>
                              {reportingUserDetails.map((item, index) => (
                                <option value={item.id}>{item.full_name}</option>
                              ))}
                            </Field>
                          </div>
                        </div>
                        {/* ereporting person email */}
                        <div className="col-8">
                          <div className="form-group  mb-3">
                            <Field
                              type="email"
                              name="reportingPersonEmail"
                              placeholder="Enter email"
                              autocomplete="off"
                              className={`mt-2 form-control
						                  ${touched.reportingPersonEmail && errors.reportingPersonEmail ? 'is-invalid' : ''}`}
                              value={personalUser.length > 0 ? personalUser[0].username ? personalUser[0].username : personalUser[0].email : ""}
                            />
                          </div>
                        </div>
                        {/* reporting person contact no */}
                        <div className="col-8">
                          <Field
                            type="text"
                            name="reportingContactNo"
                            placeholder="(xxx)-xxx-xxxx"
                            autocomplete="off"
                            className={`mt-2 form-control
                             ${touched.reportingContactNo && errors.reportingContactNo
                                ? 'is-invalid'
                                : ''
                              }`}
                            onChange={handleReportingPhoneNumber}
                            value={personalUser.length > 0 ? personalUser[0].mobile : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3 mt-3">
                    <div className="col-4">
                      <h6>Access Control</h6>
                    </div>
                    <div className="col-8  justify-space-between">
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="addUser">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="addUser"
                            onChange={() => setAdminPermitted(!adminPermitted)}
                            checked={adminPermitted}
                          />
                          Admin
                        </label>
                      </div>

                      <div className="form-check ms-3 ">
                        <label className="form-check-label" htmlFor="suspendUser">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="suspendUser"
                            onChange={() => setUserPermitted(!userPermitted)}
                            checked={userPermitted}
                          />
                          User
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="removedUser">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="removedUser"
                            onChange={() => setCustomerPermitted(!customerPermitted)}
                            checked={customerPermitted}
                          />
                          Customer
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="viewReport">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="viewReport"
                            onChange={() => setInvoicePermitted(!invoicePermitted)}
                            checked={invoicePermitted}
                          />
                          Invoice
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="viewReport">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="viewReport"
                            onChange={() => setSubscribersPermitted(!subscribersPermitted)}
                            checked={subscribersPermitted}
                          />
                          Subscribers
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="viewReport">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="viewReport"
                            onChange={() => setResearchPermitted(!researchPermitted)}
                            checked={researchPermitted}
                          />
                          Research
                        </label>
                      </div>
                    </div>
                  </div>
                  { /*      <div className="row">
                    <div className="col-4">
                      <h6> Customer Related Permissions</h6>
                    </div>
                    <div className="col-8 d-flex justify-content-start">
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="addCustomer">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="addCustomer"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          Add customer
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="viewCustomer">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="viewCustomer"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          View customer details
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="viewcompany">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="viewcompany"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          View company report
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="susEmployer">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="susEmployer"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          Suspend employer
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="customerPlan">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="customerPlan"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          Activate customer plan
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <label className="form-check-label" htmlFor="deactivatePlan">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="deactivatePlan"
                          // onChange={(e) => handleChangeRemote(e)}
                          // checked={isRemote}
                          />
                          Deactivate customer plan
                        </label>
                      </div>
                    </div>
                            </div>*/}
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};


function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(UserDetails);
