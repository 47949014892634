import React from 'react'
import Logojnp from "./images/logo.png";

function Logo() {
  return (
    <div>
    <img src={Logojnp} alt="logo"/>
    </div>
  )
}

export default Logo