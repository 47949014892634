import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";

import UserDetails from './UserDetails';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

const AddUser = () => {
  const location = useLocation();
  const [userId,setUserId] = useState(0)
  console.log(location.state )
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          //  value={value} onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="User Details"
            //   {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <TabPanel>
        <UserDetails userid={location.state !== null ? location.state.userid : userId}/>
      </TabPanel>
    </Box>
  );
};

export default AddUser;
