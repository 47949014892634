/* eslint-disable */

import React from 'react';
import moment from 'moment';
import Logo from '../components/images/logo.png';
import './Purchase.css';

function Privacy(props) {
  const { customerDetails, companyDetails, packageDetails,currentUser ,price} = props;
  console.log(props)
  return (
    <div>
      <div className="container">
        <div className="row privacy">
          <a href="/home">
            {' '}
            <img
              src={Logo}
              style={{ width: '180px', height: '56px', marginTop: '-2px', paddingBottom: '10px', marginBottom: '25px' }}
            />
          </a>
          <div className="col-lg-6">
            <h3 style={{ fontWeight: 'bold', fontSize: '22px', textAlign: 'left' }}> Jobs 'n' Profiles, LLC </h3>
            <p style={{ fontSize: '16px', marginBottom: '0px' }}>392 E Winchester St, Suite 201,</p>
            <p style={{ fontSize: '16px' }}> Salt Lake City, Utah 84107, USA. </p>
          </div>

          <div className="col-lg-6">
            <h3 style={{ fontWeight: 'bold', fontSize: '22px', textAlign: 'right' }}>Date: {moment().format("MMM Do YYYY")}  </h3>
          </div>

          <h3 style={{ fontWeight: 'bold', fontSize: '22px', textAlign: 'center' }}>Purchase Order </h3>

          <div className="col-lg-12">
            <div className="row">
              <div className="border-bottom-2"></div>
              <div className="col-lg-6">
                <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Billed To</h3>
              </div>

              <div className="col-lg-6">
                <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Customer</h3>
              </div>
              <div className="border-bottom-2"></div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4">
                <ul style={{ listStyle: 'none' }}>
                  <li>Contact Name </li>
                  <li>Company Address</li>
                  <li>Phone </li>
                  <li>Email</li>
                </ul>
              </div>

              <div className="col-lg-1">
                <ul style={{ listStyle: 'none' }}>
                  <li> : </li>
                  <li> : </li>
                  <li> : </li>
                  <li> : </li>
                </ul>
              </div>

              <div className="col-lg-7">
                <ul style={{ listStyle: 'none' }}>
                  <li> {companyDetails !== undefined ? companyDetails.contactname : ""} </li>
                  <li> {companyDetails !== undefined ?companyDetails.address1 : ""} </li>
                  <li> {companyDetails !== undefined ?companyDetails.contactphone : ""} </li>
                  <li> {companyDetails !== undefined ?companyDetails.contactemail: ""} </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4">
                <ul style={{ listStyle: 'none' }}>
                  <li>Contact Name </li>
                  <li>Company Name</li>
                  <li>Company Address</li>
                  <li>EIN </li>
                </ul>
              </div>

              <div className="col-lg-1">
                <ul style={{ listStyle: 'none' }}>
                  <li> : </li>
                  <li> : </li>
                  <li> : </li>
                  <li> : </li>
                </ul>
              </div>

              <div className="col-lg-7">
                <ul style={{ listStyle: 'none' }}>
                  <li> {customerDetails.full_name}</li>
                  <li>{companyDetails !== undefined ? companyDetails.name : ""}</li>
                  <li> {companyDetails !== undefined ? companyDetails.address1 : ""}</li>
                  <li> {companyDetails !== undefined ? companyDetails.ein_tax_id: ""}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="row">
              <div className="border-bottom-2"></div>
              <div className="col-lg-12">
                <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Purchase Details</h3>
              </div>

              <div className="border-bottom-2"></div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row mb-4">
              <div className="col-lg-4">
                <ul style={{ listStyle: 'none' }}>
                  <li>Sales Executive : </li>
                  <li>Service Start Date : </li>
                  <li>Service End Date : </li>
                </ul>
              </div>

              <div className="col-lg-8">
                <ul style={{ listStyle: 'none' }}>
                  <li> {currentUser.full_name} </li>
                  <li> {moment(packageDetails.package_start_date).format("DD/MM/YYYY")} </li>
                  <li> {moment(packageDetails.package_end_date).format("DD/MM/YYYY")} </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4">
                <ul style={{ listStyle: 'none' }}>
                  <li> Payment Method : </li>
                  <li> Term (Months) : </li>
                </ul>
              </div>

              <div className="col-lg-8">
                <ul style={{ listStyle: 'none' }}>
                  <li>Card Payment  </li>
                  <li> {packageDetails.package_id==0 ?"15 days":packageDetails.term}</li>
                  <li> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <table class="table table-bordered purchase-order">
              <thead>
                <tr>
                  <th scope="col">S. No</th>
                  <th scope="col">Service Description</th>
                  <th scope="col">Amount in USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <p>Employer: {companyDetails !== undefined ? companyDetails.contactname : ""}</p>
                    <p>Resume Views: {packageDetails.resumeviews_allowed} {parseInt(packageDetails.resumeviews_addon) > 0 ? `+ ${packageDetails.resumeviews_addon}` : ""}</p>
                    <p>Job Posts: {packageDetails.jobs_allowed} {parseInt(packageDetails.jobs_addon)> 0 ? `+ ${packageDetails.jobs_addon}` : ""}</p>
                    <p>Recruiters: {packageDetails.users_allowed} {parseInt(packageDetails.users_addon) > 0 ? `+ ${packageDetails.users_addon}` : ""}</p>
                  </td>
                  <td> ${packageDetails.Amount !== null ? packageDetails.Amount : price}</td>
                </tr>
              </tbody>
            </table>
            <p style={{ fontWeight: 'bold' }}>
              Note: Updating the billing address with cause open and future invoices on this account to reflect and
              update
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-8"></div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-5">
                  <ul style={{ listStyle: 'none' }}>
                    <li>Purchase Value </li>
                    <li>Tax</li>
                    <li>Grand Total</li>
                  </ul>
                </div>

                <div className="col-lg-1">
                  <ul style={{ listStyle: 'none' }}>
                    <li> :</li>
                    <li> :</li>
                    <li> :</li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul style={{ listStyle: 'none' }}>
                    <li>USD {packageDetails.Amount !== null ? packageDetails.Amount : price}</li>
                    <li>USD 0</li>
                    <li>USD {packageDetails.Amount !== null ? packageDetails.Amount : price}</li>
                  </ul>{' '}
                </div>
              </div>
            </div>
          </div>
          <ul style={{ paddingLeft: '0px',listStyle:"none" }}>
            <li style={{ fontWeight: 'bold' }}>
              {' '}
              Please use this link to pay the full value of the agreement now via Credit Card{' '}
            </li>
            <li>
              {' '}
              <a 
              style={{color:"blue"}}
              // href="http://jobsnprofiles.com/cc?recordID=59Z008Q3  "
              //onClick={()=>window.open(`https://admin.jobsnprofiles.com/payment/${customerDetails.email_token? customerDetails.email_token : customerDetails.username}/${packageDetails.id}`)}
               >
                {' '}
                https://admin.jobsnprofiles.com/payment/{customerDetails.email_token? customerDetails.email_token : customerDetails.username}/{packageDetails.id}
              </a>{' '}
            </li>
          </ul>
        </div>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Product Information:</h3>
        <p>
          A JobsnProfiles license will permit Customer to find, view and message candidates in JobsnProfiles’s resume
          database (the “Database”) up to the maximum number of “Candidate Views” as set forth in this purchase order
          and until the Product End Date set forth in the Purchase order. Users (each, a “User”) under the direct
          control of Customer may access the Database and messaging tool through an individual account and password. A
          Candidate View occurs when a candidate is viewed following a Database search. Examples of a single Candidate
          View include without limitation when a User: a) opens a candidate profile or resume, b) opens and prints a
          candidate profile or resume, c) opens and emails the candidate profile or resume, d) opens and adds a profile
          or resume to a folder, or e) adds a candidate profile or resume to a folder without opening the candidate
          profile or resume. A Candidate View also occurs when the JobsnProfiles messaging tool messages each candidate
          by email or text message as part of a message campaign created by a User. The number of messages sent per day
          through the messaging tools will be limited by JobsnProfiles and may vary over time. Follow-up messages and
          replies to candidates by email or by text message for the first 30 days following the initial message are not
          counted as a new Candidate View. Customer agrees to respect candidate requests to be opted out from Customer
          contact. JobsnProfiles does not guarantee that messages will successfully reach each candidate’s personal
          inbox{' '}
        </p>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>Terms & Conditions:</h3>
        <p>
          Terms and Conditions This non-cancellable agreement is between your company or organization (You, Your) and
          Jobsnprofiles, Inc. Unless otherwise specified herein, each Term will automatically renew for additional terms
          of the same length until either party notifies the other in writing that the Purchase Agreement will not renew
          at least ninety (60) days prior to the expiration of the then-current term. Subsequent to the initial Term,
          the fees may be increased once per twelve (12) month period by no more than ten percent (10%). The discounts
          set forth herein shall apply to this order only and shall not apply to any renewal. If You breach any
          provisions of this agreement, Jobsnprofiles.com may: 1) discontinue Your service and/or 2) pursue all other
          available remedies to enforce this agreement and obtain payment hereunder. In such event, Jobsnprofiles.com
          shall be entitled to collect all of its costs and attorney fees incurred. Upon expiration of the service
          period and payment of applicable fees, You will automatically be cancelled from service and this agreement
          will terminate. Each named user is authorized to manually search the Jobsnprofiles.com site database of
          professionals and view the number of purchased resume profile views as noted on this agreement. Customer
          agrees not to use any robot, spider, site search/retrieval application, or other manual or automatic device or
          process to retrieve, index, data mine, or in any way reproduce or circumvent the navigational structure or
          presentation of the Site or its contents without Jobsnprofiles’s prior written authorization. This agreement
          is subject to Jobsnprofiles’s Terms and Conditions and Your execution of this agreement constitutes Your
          commitment that all named users covered by this agreement will comply with such terms of use, as they may be
          amended from time to time.
        </p>
        <p>
          This Purchase Order will become part of the Master Services Agreement ("MSA") between Jobsnprofiles and the
          Customer unless otherwise noted. Jobsnprofiles reserves the right to improve, modify, or substitute products
          from time to time for the products included in this Purchase Order. When the Sales Order is signed after the
          Product Start Date, it is automatically shifted to when Jobsnprofiles processes the order.
        </p>
        <p> I have read and agree to the Terms and Conditions. **Signature**</p>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>DocuSign by</h3>
        <ul style={{ paddingLeft: '0px',listStyle:"none" }}>
          <li>Signature</li>
          <li>Date: {moment().format('L')}</li>
        </ul>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}> Master Services Agreement </h3>
        <p>
          1. <strong>Agreement; Purchase Orders.</strong> {companyDetails !== undefined ? companyDetails.name:""} (referred as "Customer") and
          JobsnProfiles Inc. (referred as "JNP") have entered into this Master Services Agreement, including any
          exhibits, schedules, and attachments, and this Agreement applies each time Customer engages JNP for services.
          Each H Order describes the services to be provided (the "Services") as agreed by the parties from time to time
          (each a "Purchase order"). This Agreement shall automatically incorporate and be subject to each Purchase
          order and its schedules and attachments. Any inconsistency between the terms of a Purchase order and this
          Agreement will be resolved in favor of the terms of this Agreement.{' '}
        </p>
        <p>
          2. <strong>Term and Termination.</strong> Unless earlier terminated in accordance with this Agreement, this
          Agreement shall commence on the date JNP receives the executed version signed by Customer. The Initial Term of
          this Agreement shall be one year from the date this Agreement is received by JNP. There will be an automatic
          renewal of this agreement (excluding any Purchase orders) for an additional one-year period (each, a "Renewal
          Term") on the same terms and conditions herein (or as otherwise negotiated by the parties). A party may choose
          not to renew this Agreement if it notifies the other party in writing at least 60 days before the end of the
          Initial Term or the Renewal Term, as appropriate. Any outstanding Purchase order will not be terminated by the
          expiration or termination of this Agreement, and this Agreement shall survive any termination of such Purchase
          order for as long as the Purchase order remains in effect. JNP may suspend all Customer passwords and access
          codes, if applicable, immediately if Customer materially breaches any provision of this Agreement and remove
          any job postings that violate the terms of service of the Site, upon notice to Customer. In the event either
          party materially breaches this Agreement, then the contract will be automatically terminated, and all Purchase
          orders will be cancelled. The other party must cure the breach within ten (10) days after being notified of it
          in writing. The terms of Sections 2, 3, 4, 8 and 9 shall survive any expiration or termination of this
          Agreement.{' '}
        </p>
        <p>
          3. <strong>Payment.</strong> Each Purchase order will specify the customer's payment terms. All amounts
          payable by Customer for the Services (“Fees”) are due in full without deduction. On any Fees not paid when
          due, JNP may charge Customer interest of 5% per month and collection charges, or the maximum rate permitted by
          law, whichever is less. If any sales, use, excise, or other similar taxes are applicable to the Services, they
          are the customer's responsibility (excluding taxes based on JNP's net income). If JNP has an uncured material
          breach, it will refund Customer pre-paid amounts for Services not rendered at the time of termination. In
          addition, JNP may assign the right to collect and receive payments to a third party. In order to meet its
          obligations under this Agreement, JNP may set off any amount it receives from Customer against any amount it
          owes to Customer. When Customers request credit or JNP cannot authenticate their identity, JNP may obtain
          information about them from trade and bank references, external credit reporting agencies, consumer credit
          agencies, and other sources, as necessary.
        </p>
        <p>
          4. <strong>Confidentiality.</strong> This Agreement will remain confidential and neither party will disclose
          any terms of it to a third party (except to its professional advisors) unless the other party has previously
          given its consent in writing.
        </p>
        <p>
          5. <strong>Ownership.</strong> (a) In relation to JNP and Customer, any intellectual property that Customer
          provides for placement on any Site, including job postings, logos, advertisements, and/or any other content,
          including any proprietary rights contained therein, shall always remain Customer’s property. In connection
          with the Services provided during the term of this Agreement, the Customer grants JNP and its affiliates a
          royalty-free, fully paid up, non-exclusive and worldwide license to use, copy, reproduce, publish, perform,
          display, and distribute such Customer Content (in whole or in part).{' '}
        </p>
        <p>
          (b) During the duration of the Agreement, JNP and its licensors will retain all rights, title, and interests,
          including all intellectual property rights, concerning: (i) any proprietary technology or software contained
          in or incorporated into the Sites, (ii) the content on or contained within the Sites (excluding Customer
          Content), and all elements that are part of or incorporated into (or constitute a collection or compilation
          of) any of the foregoing.{' '}
        </p>
        <p>
          6. <strong>Data-Terms of Use.</strong> (a) All information and data received from JNP, including resume data,
          will be used in accordance with all applicable laws and in accordance with each Site's terms and conditions.
          Customer shall act appropriately to guard against loss, misuse, unauthorized access, disclosure, alteration,
          or destruction of Data. Data and Services provided hereunder shall only be used by the Customer for its
          internal business purposes and shall not be resold or transferred to any third party. Customer may, however,
          use the Services for employment purposes and transfer Data to third parties if Customer is an authorized
          recruiting or staffing company.
        </p>
        <p>
          (b) The customer is required to adhere to the terms and conditions described in a Purchase order or accessed
          by the customer. If there is any inconsistency between the terms of use of any accessed Site described in this
          paragraph and the terms of this Agreement, it will be the terms of this Agreement which will prevail. The
          terms of use of each Site are available on the applicable Site's homepage through the link "Terms of Use.
        </p>
        <p>
          7. <strong>Limited Warranty.</strong> JNP warrants that it will perform Services in a professional manner
          according to prevailing industry standards. Except for the foregoing, JNP services are provided as is without
          any warranties of any kind, exclusive or implied, including any warranties of merchantability, fitness for a
          particular purpose, or non-infringement with respect to the services or the sites, or the functionality,
          performance, or results of use.{' '}
        </p>
        <p>
          8. <strong>Indemnification.</strong> Each party (each, in such capacity, the "Indemnifying Party") shall
          indemnify the other party, its affiliates and their officers, directors, employees, and agents (each, in such
          capacity, an "Indemnified Party" and, collectively, the "Indemnified Parties") from and against any third
          party claims, actions or demands, including, but not limited to, reasonable legal fees, arising or resulting
          from (Any infringement or allegation of infringement of any patent, copyright, trade secret or other
          proprietary rights by a third party is a violation or alleged infringement, arising from or related to (i)
          JNP's software and technology used to deliver the Services, (ii) Customer Content submitted by or on behalf of
          the Customer to any Site and (b) JNP is liable for gross negligence or willful misconduct relating to the
          delivery of the Services and (c) Upon the Customer's use of the Services, gross negligence or willful
          misconduct may occur. This Agreement imposes indefinable obligations upon the Indemnifying Party if the
          Indemnified Party notifies the Indemnifying Party promptly in writing of any such claim, action, or demand and
          gives the Indemnifying Party the right to control and direct the investigation, preparation, defense, trial,
          and settlement of each claim, action or demand, provided, however, that the Indemnifying Party's indemnity
          obligations shall not cease unless the failure to so notify materially prejudices its ability to defend the
          claim. The Indemnified Party shall reasonably cooperate (at the Indemnifying Party’s expense) with the
          Indemnifying Party in the defense of a such claim.
        </p>
        <p>
          9. <strong>Limitation of Liability.</strong> Notwithstanding anything to the contrary contained in this
          agreement (including the Purchase order), except for obligations of an indemnifying party under section 8 or
          breaches of sections 4, or 6(a), but without in any way limiting customer’s payment obligations under this
          agreement, (a) no party will be liable to any other party (nor to any person claiming rights derived from the
          other party’s rights) for incidental, indirect, consequential, special, punitive or exemplary damages of any
          kind - including lost revenues or profits, loss of business or loss of data - arising out of or in connection
          with this agreement or the services provided hereunder (including without limitation as a result of any breach
          of any warranty or other term of this agreement), regardless of whether the party liable or allegedly liable
          was advised, had other reason to know, or in fact knew of the possibility thereof, and (b) each party’s
          maximum liability arising out of or in connection with this agreement, any product, the services provided
          hereunder or any site, regardless of the cause of action (whether in contract, tort, breach of warranty or
          otherwise), will not exceed the amount paid or payable by customer to JNP during the twelve months preceding
          the claim for damages.
        </p>
        <p>
          10. <strong>Miscellaneous.</strong> In addition to complying with all applicable local, national, and
          international laws, regulations and executive orders regarding labor and employment, and intellectual
          property, each party agrees to comply with all applicable local, national, and international laws. Customers
          acknowledge that U.S. job postings may not require citizenship or lawful permanent residency in the U.S. As a
          condition of employment, the customer must comply with any law, regulation, executive order, or contract with
          the federal, state, or local government. The parties to this Agreement are independent contractors, and
          nothing herein shall create a partnership, joint venture, or any type of agency relationship between JNP and
          Customer. This Agreement, which may be executed in counterparts and via facsimile or electronically
          transmitted signature, contains the complete understanding of the parties with respect to the transactions and
          matters contemplated hereby, and supersedes all prior communications, understandings, and agreements (whether
          oral or written), including any Purchase order s submitted by Customer or from time to time to come, and
          cannot be amended or waived except in writing signed by all parties. Neither party may assign this Agreement
          in whole or in part, by operation of law, merger, asset or stock sale or transfer, or otherwise, without the
          prior written consent of the non-assigning party. Unless (i) it is in connection with a merger, consolidation,
          reorganization, or sale of all or substantially all assets of the assigning party, or (ii) it is to a party
          that controls, is controlled by, or is under common control with the assigning party. There was no reliance on
          any representation or warranty made by any other party not set forth in this contract. A failure or delay in
          exercising any right or remedy provided in this Agreement shall not be construed as a waiver; nor shall any
          single or partial exercise of or failure to exercise any right or remedy be construed as a waiver.{' '}
        </p>
        <p>
          The exercise of any other right or remedy under this Agreement will not preclude its further exercise. Any
          provision of these conditions that is declared invalid, unlawful, or unenforceable to any extent shall be
          severed from the remainder, which shall remain valid to the fullest extent permitted by law in the event it is
          determined by a competent authority to be invalid, unlawful or unenforceable to any extent. Any disputes
          between Customer and JNP relating to this Agreement will be governed and construed by the laws of the State of
          Utah, the performance of each party under this Agreement is subject to Force Majeure. 'Force Majeure' refers
          to any unforeseeable event beyond the reasonable control of a party. In accordance with this Agreement, all
          notices shall be sent by first class mail, return receipt requested or overnight courier to the Customer at
          the address below and JNP at 392 E Winchester St, Suite 201,Salt Lake City, Utah 84107,USA. Attn: Legal Department or
          legal@jobsnprofiles.com, and shall be deemed received upon receipt. The terms of this Agreement cannot be
          enforced by anyone who is not a party to it.
        </p>
        <p>
          <strong>
            The person who executes this Agreement on behalf of Customer represents and warrants that he or she is
            authorized to do so.
          </strong>
        </p>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}>By:</h3>
        <p>(Docu sign) </p>
        <h3 style={{ fontWeight: 'bold', fontSize: '18px' }}> Company Name: '{companyDetails !== undefined ? companyDetails.name:""}'</h3>
        <ul style={{ paddingLeft: '0px', listStyle: 'none' }}>
          <li>Name : {customerDetails.full_name}</li>
          <li>Title : {customerDetails.role} </li>
          <li>Date : {moment().format('L')}  </li>
        </ul>
      </div>
    </div>
  );
}

export default Privacy;
