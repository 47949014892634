/* eslint-disable */

import React, { useRef, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Editor } from "@tinymce/tinymce-react";
import * as Yup from 'yup';
import axios from 'axios';
import Key from '../../src/clientVariables.json';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ToastMessage from '../components/ToastMessage';

function CompanyDetails(props) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoAlternate, setPhoneNoAlternate] = useState('')

  useEffect(() => {
    console.log(props.companyuserid);
    getStates(233);
    getCategories();

    if (
      props.companyuserid !== '' &&
      props.companyuserid !== null &&
      props.companyuserid !== undefined &&
      props.companyuserid !== 0
    ) {
      axios
        .post(`${Key.domain}/customer/get_customerdetails`, {
          customer_id: props.companyuserid,
        })
        .then((response) => {
          console.log(response.data.data.companyDetails);
          setCompanyId(response.data.data.companyDetails.id);
          getCities(response.data.data.companyDetails.state);

          if (formikRef.current) {
            formikRef.current.setFieldValue('companyname', response.data.data.companyDetails.name);
            formikRef.current.setFieldValue('contactname', response.data.data.companyDetails.contactname);
            formikRef.current.setFieldValue('contactphone', response.data.data.companyDetails.contactphone);
            formikRef.current.setFieldValue('state', response.data.data.companyDetails.state);
            setPhoneNo(response.data.data.companyDetails.contactphone)
            formikRef.current.setFieldValue('city', response.data.data.companyDetails.city);
            formikRef.current.setFieldValue('companyfax', response.data.data.companyDetails.companyfax);
            formikRef.current.setFieldValue('contactemail', response.data.data.companyDetails.contactemail);
            formikRef.current.setFieldValue('companyurl', response.data.data.companyDetails.domain_name);
            formikRef.current.setFieldValue('companysize', response.data.data.companyDetails.companysize);
            formikRef.current.setFieldValue('category', response.data.data.companyDetails.zipcode);
            formikRef.current.setFieldValue('since', response.data.data.companyDetails.since);
            formikRef.current.setFieldValue('zipcode', response.data.data.companyDetails.zipcode);
            formikRef.current.setFieldValue('address', response.data.data.companyDetails.address1);
            formikRef.current.setFieldValue('aboutcompany', response.data.data.companyDetails.description);
            formikRef.current.setFieldValue('altercontactemail', response.data.data.companyDetails.alter_contactemail !== "null" ?  response.data.data.companyDetails.alter_contactemail : "");
            formikRef.current.setFieldValue('altercontactphone', response.data.data.companyDetails.alter_contactphone);
            formikRef.current.setFieldValue('altercontactname', response.data.data.companyDetails.alter_contactname !== "null" ? response.data.data.companyDetails.alter_contactname : "");
          }
        });
    }
  }, []);

  const formikRef = useRef();
  const fileRef = useRef();

  const validationSchema = Yup.object().shape({
    contactemail: Yup.string().email('Invalid email address format').required('Email is required'),

    companyname: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=\',._-]+$/g,
        // /^[a-zA-Z0-9\s.+-]+$/,
        "Company name invalid "
      ).required('Company Name is required'),

    contactname: Yup.string()
      .required('Contact Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),

    contactphone: Yup.string().required('Phone is required').min(14, "Contact No should be 10 digits"),

    state: Yup.string().required('State is required'),

    // companyfax: Yup.string().required('Fax is required'),

    companyurl: Yup.string()
      .required('Url is required')
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      ),

    companysize: Yup.string().matches(/^[0-9\b]+$/, "Only Numbers allowed "),

    category: Yup.string().required('Category is required'),

    // since: Yup.string().required('Sicne is required'),

    city: Yup.string().required('City is required'),

    address: Yup.string().required('Address is required'),

    aboutcompany: Yup.string().required('About company  is required'),
    since: Yup.string().matches(/^[0-9\b]+$/, "Only Numbers allowed "),
    zipcode: Yup.string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "zipcode should be 5 digits").required(" Zipcode is required"),
  });

  const getCategories = () => {
    axios
      .get(`${Key.domain}/common/get_all_categories`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
      .then((resdata) => {
        console.log(resdata.data.data);
        setCategories(resdata.data.data);
      });
  };

  const getStates = (countryid, values) => {
    axios
      .post(`${Key.domain}/common/get_states_by_countryid`, {
        country_id: countryid,
      })
      .then((response) => {
        console.log(response);
        setStates(response.data.data);
      });
  };

  const getCities = (stateid) => {
    //  const selectedState = e.target.value;
    // console.log(selectedState)
    axios
      .post(`${Key.domain}/common/get_cities_by_stateid`, {
        state_id: stateid,
      })
      .then((response) => {
        console.log(response);
        setCities(response.data.data);
        if (formikRef.current) {
          formikRef.current.setFieldValue('state', stateid);
        }
      });
  };

  const CreateCompany = (values) => {
    //console.log(values)
    //  alert(companyId)
    axios
      .post(`${Key.domain}/customer/add_or_update_customer_companydetails`, {
        user_id: props.companyuserid,
        admin_id: sessionStorage.getItem('id'),
        comp_name: values.companyname,
        category: values.category,
        contact_name: values.contactname,
        contact_phone: values.contactphone,
        company_fax: values.companyfax,
        comp_url: values.companyurl,
        contact_email: values.contactemail,
        since: values.since,
        companysize: values.companysize,
        country: 233,
        description: values.aboutcompany,
        state: values.state,
        city: values.city,
        zipcode: values.zipcode,
        address1: values.address,
        address2: values.address,
        status: 1,
        logo: '',
        session_token: props.currentUser.session_token,
        altcontactname: values.altercontactname,
        altcontactphone: values.altercontactphone,
        altcontactemail: values.altercontactemail,
        action: companyId !== 0 ? 1 : 0,
        id: companyId,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200 && parseInt(response.data.success) === 1) {
          if (response.data.data.insertId !== 0) {
            props.handleTabChange(2, response.data.data.insertId);
          } else {
            props.handleTabChange(2, '');
          }
          let msg = companyId !== 0 ? 'Company updated successfully' : 'Company added successfully';
          toast(<ToastMessage message={msg} />);
        } else if (response.status === 200 && parseInt(response.data.success) === 0){
          let msg = response.data.data
          toast(<ToastMessage message={msg} />);
        } else {
          let msg ="Something went wrong !"
          toast(<ToastMessage message={msg} />);
        }
      });
  };
  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNo(formattedPhoneNumber);
    if (formikRef.current) {
      formikRef.current.setFieldValue('contactphone', formattedPhoneNumber);
      // console.log(formikRef.current);
    }
  };


  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    setPhoneNo(String(phoneNumber));
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  //   ////alternate conatct no
  const handleAlternateContactNo = (e) => {
    const formattedPhoneNumberAlternate = formatPhoneNumberAlternate(e.target.value);
    setPhoneNoAlternate(formattedPhoneNumberAlternate);
    if (formikRef.current) {
      formikRef.current.setFieldValue('altercontactphone', formattedPhoneNumberAlternate);
      // console.log(formikRef.current);
    }

  }
  const formatPhoneNumberAlternate = (value) => {

    if (!value) return value;
    const phoneNumberAlternate = value.replace(/[^\d]/g, '');
    const phoneNumberAlternateLength = phoneNumberAlternate.length;
    setPhoneNoAlternate(String(phoneNumberAlternate));
    if (phoneNumberAlternateLength < 4) return phoneNumberAlternate;
    if (phoneNumberAlternateLength < 7) {
      return `(${phoneNumberAlternate.slice(0, 3)}) ${phoneNumberAlternate.slice(3)}`;
    }
    return `(${phoneNumberAlternate.slice(0, 3)}) ${phoneNumberAlternate.slice(3, 6)}-${phoneNumberAlternate.slice(6, 10)}`;

  }

  const onTextEditorchanged = (value) => {
    console.log(value);
    if (formikRef.current) {

      formikRef.current.setFieldValue("aboutcompany", value);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Formik
            innerRef={formikRef}
            initialValues={{
              companyname: '',
              contactname: '',
              contactphone: '',
              companyfax: '',
              contactemail: '',
              companyurl: '',
              companysize: '',
              category: '',
              since: '',
              state: '',
              city: '',
              zipcode: '',
              address: '',
              aboutcompany: '',
              altercontactemail: '',
              altercontactphone: '',
              altercontactname: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              CreateCompany(values);
              // alert("Form is validated! Submitting the form...");
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <div>
                <Form>
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-lg-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="fullname">Company Name <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="text"
                          name="companyname"
                          placeholder="Company name"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                ${touched.companyname && errors.companyname ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="companyname" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="contactname">Contact Name <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="text"
                          name="contactname"
                          placeholder=" Contact Name"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                     ${touched.contactname && errors.contactname ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="contactname" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="Contact Phone">Phone <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="text"
                          name="contactphone"
                          placeholder="(xxx)-xxx-xxxx"
                          className={`mt-2 form-control
                                                ${touched.contactphone && errors.contactphone ? 'is-invalid' : ''}`}

                          onChange={handlePhoneNumberChange}
                          value={phoneNo}
                        />
                        <ErrorMessage component="div" name="contactphone" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="email"
                          name="contactemail"
                          placeholder="Enter email"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                ${touched.contactemail && errors.contactemail ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="contactemail" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="companyfax">Company Fax</label>
                        <Field
                          type="text"
                          name="companyfax"
                          placeholder="Fax"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                     ${touched.companyfax && errors.companyfax ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="companyfax" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="role">Company Url <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="text"
                          name="companyurl"
                          placeholder="Url"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                 ${touched.companyurl && errors.companyurl ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="companyurl" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="companysize">Company Size</label>
                        <Field
                          type="text"
                          name="companysize"
                          placeholder=" Size"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                 ${touched.companysize && errors.companysize ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="companysize" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  ">
                        <label htmlFor="Category">Category<span style={{ color: 'red' }}>*</span></label>
                        <Field
                          name="category"
                          as="select"
                          className={' mt-2 form-select form-control' + (errors.category && touched.category ? ' is-invalid' : '')}
                        >
                          <option selected>Select Category</option>
                          {categories.map((item, index) => (
                            <option value={item.id}>{item.category_title}</option>
                          ))}
                        </Field>
                        <ErrorMessage component="div" name="category" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="since">Since</label>
                        <Field
                          type="text"
                          name="since"
                          placeholder="Since"
                          className={`mt-2 form-control
                                        ${touched.since && errors.since ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage component="div" name="since" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group mt-2 mb-4">
                        <label htmlFor="state">State <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          name="state"
                          as="select"
                          className={' mt-2 form-select form-control' + (errors.state && touched.state ? ' is-invalid' : '')}
                          onChange={(e) => getCities(e.target.value)}
                        >
                          <option>Select state</option>
                          {states.map((item, index) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage component="div" name="state" className="invalid-feedback" />
                      </div>
                    </div>



                    <div className="col-4">
                      <div className="form-group mt-2">
                        <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          name="city"
                          as="select"
                          className={'mt-2 form-select form-control' + (errors.city && touched.city ? ' is-invalid' : '')}
                          onChange={(e) => {
                            console.log(values);
                            formikRef.current.setFieldValue('city', e.target.value);
                          }}
                        >
                          <option>Select city</option>
                          {cities.map((item, index) => (
                            <option value={item.id}>{item.cityName}</option>
                          ))}
                        </Field>
                        <ErrorMessage component="div" name="city" className="invalid-feedback" />
                      </div>
                    </div>




                    <div className="col-4">
                      <div className="form-group  mb-0 mt-2">
                        <label htmlFor="role">Zipcode <span style={{ color: 'red' }}>*</span></label>
                        <Field
                          type="text"
                          name="zipcode"
                          placeholder="Zipcode"
                          autocomplete="off"
                          className={`mt-2 form-control
                                         ${touched.zipcode && errors.zipcode ? 'is-invalid' : ''}`}
                        />

                        <ErrorMessage component="div" name="zipcode" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="row">


                      <div className="col-md-6 col-sm-6 col-xs-6">
                        <div className="form-group  mb-4">
                          <label htmlFor="role">Address <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            as="textarea"
                            name="address"
                            placeholder="Address"
                            autocomplete="off"
                            className={`mt-2 form-control
                                                 ${touched.address && errors.address ? 'is-invalid' : ''}`}
                          />

                          <ErrorMessage component="div" name="address" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div className="row">


                      <div className="col-md-6 col-sm-6 col-xs-6">
                        <div className="form-group  mb-4">
                          <label htmlFor="role">About Company <span style={{ color: 'red' }}>*</span></label>
                          {/* <Field
                          as="textarea"
                          name="aboutcompany"
                          placeholder="About Company"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                     ${
                                                       touched.aboutcompany && errors.aboutcompany ? 'is-invalid' : ''
                                                     }`}
                        />

                        <ErrorMessage component="div" name="aboutcompany" className="invalid-feedback" /> */}
                          <Editor
                            init={{
                              height: 250,
                              menubar: false,
                              statusbar: false,
                              plugins: ["code", "lists"],

                              toolbar:
                                "undo redo | styleselect | bold italic | link image | lists",
                            }}
                            onEditorChange={(e) => onTextEditorchanged(e)}
                            name="aboutcompany"
                            value={values.aboutcompany}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="contactname">Alternate Contact Name </label>
                        <Field
                          type="text"
                          name="altercontactname"
                          placeholder="  Contact Name"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                                               ${touched.altercontactname &&
                              errors.altercontactname
                              ? 'is-invalid'
                              : ''
                            }`}

                        />

                        <ErrorMessage component="div" name="altercontactname" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="Contact Phone">Alternate Phone </label>
                        <Field
                          type="text"
                          name="altercontactphone"
                          placeholder="Phone"
                          className={`mt-2 form-control
                                                                          ${touched.altercontactphone &&
                              errors.altercontactphone
                              ? 'is-invalid'
                              : ''
                            }`}
                          onChange={handleAlternateContactNo}
                          value={phoneNoAlternate}
                        />
                        <ErrorMessage component="div" name="altercontactphone" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group  mb-3">
                        <label htmlFor="email">Alternate Email </label>
                        <Field
                          type="email"
                          name="altercontactemail"
                          placeholder="Enter email"
                          autocomplete="off"
                          className={`mt-2 form-control
                                                                          ${touched.altercontactemail &&
                              errors.altercontactemail
                              ? 'is-invalid'
                              : ''
                            }`}
                        />

                        <ErrorMessage component="div" name="altercontactemail" className="invalid-feedback" />
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-block mt-4">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, null)(CompanyDetails);
