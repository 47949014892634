import React from 'react'
import { Bar } from "react-chartjs-2";
import Select from 'react-select'
import { Chart, CategoryScale, LinearScale, BarElement,Legend,Title,Tooltip } from 'chart.js';
 
Chart.register(
    LinearScale,CategoryScale,BarElement,Legend,Title,Tooltip
  )

  const labels = ['Jan', 'Feb', 'March', 'Apr', 'May','June','July','Aug','Sept','Oct','Nov','Dec'];
  const Years = ['Jan2000', 'Jan2001', 'Jan2002', 'Jan2003', 'Jan2004','Jan2005','Jan2006','Jan2000','Jan2000','Jan2000','Jan2000','Jan2000'];

  const options ={
    plugins:{
        legend:{
            position:'top'
        },
        title:{
            display:true,
            text:" Sales & Renewals ",
            color: '#406882',
            align:"start",
            font: {
                size: 20
            }

        }
    }
  }
  const options2 = [
    { value: 'Yearly', label: 'Yearly Package Plan' },
    { value: 'Half Yearly', label: 'Half Yearly Plan' },
    { value: 'Quaterly', label: 'Quaterly Plan' },
    { value: 'Monthly', label: 'Monthly Plan' },

  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Sales',
       
     
        data:[100,250,100,600,520,402,300,545,454,455,755],
        backgroundColor:'#84a59d'
      },
      {
        label: 'Renewals',
        data:[80,80,80,200,400,100,100,220,425,245,555],
        backgroundColor: '#f38c88',
      },
      


    ],
  };

function Subscribers() {
  return (
    <div>
    <div className='col-md-2 mt-1' style={{float:"right",paddingTop:"12px"}}>
  
    <Select options={options2} placeholder="Choose Plan"/>
    </div>
    <Bar options={options} data ={data}  />
   
    </div>
  )
}

export default Subscribers