/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Useravathar from '../components/images/User.png'
import Key from "../clientVariables.json";
import axios from "axios"
function AttorneyDetailView(props) {

    const [value, setValue] = React.useState(0);
    const [attorneyDetails, setAttorneyDetails] = React.useState([]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const filterYear = (year) => {
        const filterData = props.h1bDetails.filter(data => data.Fiscal_Year === parseInt(year))
        seth1bDetails(filterData)
    }

    useEffect(() => {
        setAttorneyDetails(props.attorneyDetails)
    }, [props.attorneyDetails])

    return (
        <div>
            {/* <div style={{ float: "right" }}>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e)=>filterYear(e.target.value)}>
                    <option selected>Year</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                </select>
    </div>*/}





            <br />
            <Row>

                {attorneyDetails.map(data =>
                    <Col sm={5}>
                        <div className='card p-3' >
                            <div style={{ textAlign: "center" }}>


                                <p><b>{data.AGENT_ATTORNEY_FIRM_NAME}</b></p>

                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Email : {data.AGENT_ATTORNEY_EMAIL}</li>
                                <li className="list-group-item">Phone : {data.AGENT_ATTORNEY_PHONE}</li>
                                <li className="list-group-item">Location : {data.AGENT_ATTORNEY_CITY}{data.AGENT_ATTORNEY_STATE_SHORTREGION_PROVINCE ? "," + data.AGENT_ATTORNEY_STATE_SHORTREGION_PROVINCE : ""}{",USA."}</li>
                                <li className="list-group-item">Address1 : {data.AGENT_ATTORNEY_ADDRESS_1}<br />
                                    Address2 : {data.AGENT_ATTORNEY_ADDRESS_2}
                                </li>
                                <li className="list-group-item">Zipcode : {data.AGENT_ATTORNEY_POSTAL_CODE}</li>

                            </ul>

                        </div>
                        <br />

                    </Col>

                )}


            </Row>
        </div>





    )
}

export default AttorneyDetailView