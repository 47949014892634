/* eslint-disable */

import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import ClickAwayListener from 'react-click-away-listener';
// component
import { VscVmActive } from "react-icons/vsc";
import { MdOutlineAirplanemodeInactive } from "react-icons/md";
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const history = useNavigate();

  const [isOpen, setIsOpen] = useState(false);


  console.log(props.customerid)

  const handlechange = (status) => {
    if (props.customerid) {

      props.handleChangeCustomerStatus(props.customerid, status)
    } else if (props.userid) {
      props.handleChangeUserStatus(props.userid, status)
    }
  }
  useEffect(() => {

    handlepopclose()
  }, [props.refreshh])

  const handlepopclose = () => {
    setIsOpen(false)
  }

  const handleEdit = () => {
    if (props.customerid) {
      history("/dashboard/addcustomer", { state: { customerid: props.customerid } });
    } else if (props.userid) {
      history("/dashboard/addUser", { state: { userid: props.userid } });
    }
  };


  const handleViewSubDetails = () => {
    history("/dashboard/SubscriberDetailsView", { state: { subid: props.customerid } })
  };

  console.log(props.fromsubscribers)

  const handleClickAway = () => {
    setIsOpen(false)
  };

  return (
    <>
     
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>
     
     
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => handlepopclose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
         <ClickAwayListener onClickAway={handleClickAway}>
        {props.status === "0" ? <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <VscVmActive size={22} />
          </ListItemIcon>
          <ListItemText onClick={() => handlechange(1)} primary="Active" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

          :
          <MenuItem sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <MdOutlineAirplanemodeInactive size={22} />
            </ListItemIcon>
            <ListItemText onClick={() => handlechange(0)} primary="Inactive" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
      </ClickAwayListener>
        {/* <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        {/* <MenuItem onClick={handleEdit} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      <ClickAwayListener onClickAway={handleClickAway}>
        {props.fromsubscribers === 1 ?
          <MenuItem onClick={handleViewSubDetails} to="#" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          :   <MenuItem onClick={handleEdit} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        </ClickAwayListener>
      </Menu>
   
    </>
  );
}
