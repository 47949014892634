/* eslint-disable */


import * as types from "../constant/actionTypes";
import UserService from "../services/user.service";

const initial_state = {
    user: UserService.getUser(),


};

export default (state = initial_state, action) => {
    switch (action.type) {

        case types.CREATE_USER_SUCCESS:
            return { ...state, user: action.payload };
        case types.LOGIN_SUCCESS:
            return { ...state, user: action.payload };
        case types.LOGIN_FAILURE:
            return { ...state, error: action.payload };
        case types.IS_LOGINLOADING:
            return { ...state, isLoginLoading: action.payload }
        default:
            return state;
    }
}
