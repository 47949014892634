/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Useravathar from '../components/images/User.png'
import Key from "../clientVariables.json";
import axios from "axios"
function GreenCardDetailView(props) {

    const [value, setValue] = React.useState(0);
    const [greenCardDetails, setGreenCardDetails] = React.useState([]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const filterYear = (year) => {
        const filterData = props.greenCardDetails.filter(data => data.FINANCIAL_YEAR === year)
        setGreenCardDetails(filterData)
    }

    useEffect(() => {
        setGreenCardDetails(props.greenCardDetails)
    }, [props.greenCardDetails])
    // console.log(props.greenCardStatus)
    return (
        <div style={{ height: "700px", overflow: "scroll" }}>
            <div style={{ float: "right" }}>
                <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => filterYear(e.target.value)}>
                    <option selected>Year</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                </select>
            </div>

            <table class="table table-bordered">

                <tbody>
                    <p>Status</p>

                    <tr>

                        <td>
                            <Row>
                                <Col sm={4}> No.of Filed </Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.greenCardStatus.noOfTotalFiles}</Col>
                            </Row>
                        </td>

                        <td>

                            <Row>
                                <Col sm={4}> No.of Certified </Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.greenCardStatus.noOfCertified}</Col>
                            </Row>
                        </td>

                        <td>

                            <Row>
                                <Col sm={4}> No.of Certified-Expired </Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.greenCardStatus.noOfCertifiedExp}</Col>
                            </Row>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Row>
                                <Col sm={4}> No.of Denied</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.greenCardStatus.noOfDenied}</Col>
                            </Row>
                        </td>

                        <td>
                            <Row>
                                <Col sm={4}> No.of Withdrawn</Col>
                                <Col sm={2} >:</Col>
                                <Col sm={4}>{props.greenCardStatus.noOfWithDrawn}</Col>
                            </Row>

                        </td>

                    </tr>

                </tbody>
            </table>











            {/*  <div className='card' style={{ width: "50%" }}>
                <div style={{ textAlign: "center", paddingTop: "15px" }}>


                    <p><b>Status</b></p>

                </div>
                <ul class="list-group list-group-flush">
                    <li className="list-group-item">

                    </li>
                    <li className="list-group-item">
                    </li>
                    <li className="list-group-item">

                    </li>

                    <li className="list-group-item">
                    </li>
                    <li className="list-group-item">
                        <Row>
                            <Col sm={4}>Visa class handle</Col>
                            <Col sm={2} >:</Col>
                            <Col sm={4}>  E3 Australian, H1B</Col>
                        </Row>
                    </li>
                </ul>

            </div>
    */}
            <br />
            <Row>


                {greenCardDetails.map(data =>
                    <Col sm={5}>
                        <div className='card p-3' >
                            <div style={{ textAlign: "center" }}>


                                <p><b>{data.JOB_TITLE}</b></p>

                            </div>
                            <ul className="list-group list-group-flush">
                                {/* <li className="list-group-item">Location : {data.WORKSITE_CITY}{data.WORKSITE_STATE ? "," + data.WORKSITE_STATE + "," : ""}{"USA."}</li>
                                <li className="list-group-item">Address1 : {data.WORKSITE_ADDRESS_1 ? data.WORKSITE_ADDRESS_1 :"N/A"}<br />
                                    Address2 : {data.WORKSITE_ADDRESS_2 ? data.WORKSITE_ADDRESS_2 :"N/A"}
                                </li>
                                <li className="list-group-item">Zipcode : {data.WORKSITE_POSTAL_CODE ? data.WORKSITE_POSTAL_CODE : "N/A"}</li>
                                <li className="list-group-item">Field Study : {data.MAJOR_FIELD_OF_STUDY}</li>
                                <li className="list-group-item">STATUS : {data.CASE_STATUS}</li>*/}

                                <li className="list-group-item">NAICS CODE : {data.NAICS_CODE}</li>
                                <li className="list-group-item">PW SOC TITLE : {data.PW_SOC_TITLE}</li>
                                <li className="list-group-item">PW SOC TITLE : {data.PW_SOC_CODE}</li>
                                <li className="list-group-item">PW SKILL LEVEL : {data.PW_SKILL_LEVEL}</li>
                                <li className="list-group-item">PW WAGE SOURCE : {data.PW_WAGE_SOURCE}</li>
                                <li className="list-group-item">MINIMUM EDUCATION : {data.MINIMUM_EDUCATION}</li>





                            </ul>

                        </div>
                        <br />

                    </Col>
                )}
            </Row>
        </div>





    )
}

export default GreenCardDetailView