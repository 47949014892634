/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import MUIDataTable from "mui-datatables";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FcDocument } from "react-icons/fc";
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';

// material
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { UserMoreMenu } from '../sections/@dashboard/user';
import "./packages.css"
import Key from '../clientVariables.json'
import Iconify from '../components/Iconify';
import request from 'src/utils/request';
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';



function Packages(props) {
  const history = useNavigate();
  const [showAddForm, setShowAddForm] = useState(false)
  const [showEditForm, setshowEditForm] = useState(false)
  const [packageData, setPackageData] = useState([]);
  const [packageId, setPackageId] = useState(0);

  const [level2Packages, setLevel2Packages] = useState([])
  const formikRef = useRef();
  const editableUsers = ['satya.bathula@nityainc.com', 'karteek.mudi@nityainc.com', 'james@nityainc.com']

  const AddValidationSchema = Yup.object().shape({
    packagName: Yup.string().required('Package Name is required'),
    jobsAllowed: Yup.string().required('Jobs Allowed is required').matches(/^[0-9\b]+$/, "Only Numbers allowed "),
    resumesAllowed: Yup.string().required('Resumes Allowed is required').matches(/^[0-9\b]+$/, "Only Numbers allowed "),
    usersAllowed: Yup.string().required('Users Allowed is required').matches(/^[0-9\b]+$/, "Only Numbers allowed "),
    terms: Yup.string().required('Terms is required'),
    packageExpiresInDays: Yup.string().required('PackageExpiresInDays is required').matches(/^[0-9\b]+$/, "Only Numbers allowed "),
    price: Yup.string().required('Price is required').matches(/^[0-9\b]+$/, "Only Numbers allowed "),

  });

  useEffect(() => {
    getLevel2Packages()
    getAllDefaultPackages()
  }, [])


  const getAllDefaultPackages = () => {
    axios.get(`${Key.domain}/customer/get_all_emp_packages`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then(response => {
        var data = response.data.data
        // console.log(data)
        setPackageData(data)
      })

  }

  const addPackage = () => {
    setShowAddForm(true)
  };


  const editPackage = () => {
    setshowEditForm(true)
  };


  const closePackage = () => {
    setShowAddForm(false)
    setshowEditForm(false)

  };

  const getLevel2Packages = () => {
    // const result =  request(`${ENDPOINT['getLevel2Packages']}`, getOptions({}))
    // console.log(result)
    // setLevel2Packages()
    fetch(`${ENDPOINT['getLevel2Packages']}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },

    }).then((response) => {
      response.json().then((resdata) => {
        setLevel2Packages(resdata.data)
      })

    })

  }


  const addNewPackage = async (packageDetails) => {
    var packageDetails = packageDetails
    const result = await request(`${ENDPOINT['addNewPackage']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken"), packageDetails: packageDetails }))
    if (result.data.msg) {
      alert(result.data.msg)
    } else {
      closePackage()
      getAllDefaultPackages()
      alert('Custom Package Added Successfully')
    }
  }

  const updatePackage = async (packageDetails) => {
    var packageDetails = packageDetails
    const result = await request(`${ENDPOINT['updatePackage']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken"), packageDetails: packageDetails, packageId: packageId }))
    if (result.data.affectedRows === 1) {
      closePackage()
      getAllDefaultPackages()
      alert('Package Updated Successfully')
    }
  }

  const resetToDefault = async (package_id) => {
    if (parseInt(props.currentUser.user_type) === 2 || parseInt(props.currentUser.user_type) === 1) {
      const result = await request(`${ENDPOINT['packageResetToDefault']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken"), package_id: package_id }))
      if (result.data.affectedRows === 1) {
        getAllDefaultPackages()
        alert('Package Reset Done')

      }
    } else {
      alert('You are not allowed to reset the package')
    }
  }


  const deletePackage = async (package_id) => {
    if (parseInt(props.currentUser.user_type) === 2 || parseInt(props.currentUser.user_type) === 1) {
      const result = await request(`${ENDPOINT['deletePackage']}`, postOptions({ admin_id: sessionStorage.getItem("id"), session_token: sessionStorage.getItem("userToken"), package_id: package_id }))
      if (result.data.affectedRows === 1) {
        getAllDefaultPackages()
        alert('Package Deleted Successfully')

      }
    } else {
      alert("You are not allowed to delete the package")
    }
  }

  const getPlanDetails = (value, event) => {
    setPackageId(value)
    var selectedPackage = packageData.filter(selectedPackage => selectedPackage.id === parseInt(value))
    if (value === "0") {
      formikRef.current.setFieldValue('postCount', "");
      formikRef.current.setFieldValue('ViewsCount', "");
      formikRef.current.setFieldValue('companiesCount', "");
      formikRef.current.setFieldValue('recruitersCount', "");
    } else {
      formikRef.current.setFieldValue('packagName', selectedPackage[0].title);
      formikRef.current.setFieldValue('jobsAllowed', selectedPackage[0].jobs_allowed);
      formikRef.current.setFieldValue('resumesAllowed', selectedPackage[0].resumeViews_allowed);
      formikRef.current.setFieldValue('usersAllowed', selectedPackage[0].users_allowed);
      formikRef.current.setFieldValue('companiesAllowed', selectedPackage[0].companies_allowed);
      formikRef.current.setFieldValue('terms', selectedPackage[0].term);
      formikRef.current.setFieldValue('packageExpiresInDays', selectedPackage[0].package_expires_in_days);
      formikRef.current.setFieldValue('price', selectedPackage[0].price);

    }
  }
  return (


    <section className="section" id="pricing">
      <div className="container">

        <div className="row">
          <div className="col-lg-12">
            <div className="title-box" style={{ float: "right", marginTop: "-30px", marginBottom: "10px" }}>
              <Button variant="contained" onClick={addPackage} startIcon={<Iconify icon="eva:plus-fill" />} disabled={editableUsers.includes(props.currentUser.username) ? false : true}>
                New Package
              </Button>&nbsp;
              <Button variant="contained" onClick={editPackage} startIcon={<Iconify icon="eva:edit-fill" />} disabled={editableUsers.includes(props.currentUser.username) ? false : true}>
                Edit Package
              </Button>
            </div>
          </div>
        </div>


        <div className="row">
          {packageData.map((pack, index) =>
            <div className="col-lg-4">
              <div className="pricing-box " style={{ boxShadow: "0px 0px 5px black", marginBottom: "10px" }}  >
                <i className="mdi mdi-account h1" />
                <h4 className="f-20 text-primary">{pack.title}
                  {editableUsers.includes(props.currentUser.username) &&

                    index > 4 ? <Tooltip title="Delete Package">
                    <DeleteOutlineIcon style={{ float: "right", cursor: "pointer" }} onClick={() => deletePackage(pack.id)} />
                  </Tooltip> : ""}
                  {editableUsers.includes(props.currentUser.username) && index < 5 ? <Tooltip title="Reset to default">
                    <RestartAltIcon style={{ float: "right", cursor: "pointer" }} onClick={() => resetToDefault(pack.id)} />
                  </Tooltip> : ""
                  }
                </h4>

                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>

                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>{"Unlimited"} </b>
                    Jobs Allowed</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>{pack.resumeViews_allowed} </b>
                    Resume Views Allowed</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>{pack.users_allowed} </b>
                    Users Allowed</p>

                  {index === 0 ?
                    <div>
                      <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b> </b> </p>
                      <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b> </b> </p>
                      <br />
                      
                    </div>
                    :
                    index === 1 ?
                      <div>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Dedicated Person/Support</b> </p>
                      </div>
                      :
                      index === 2 ?
                        <div>
                          <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Dedicated Person/Support</b> </p>
                        </div> :
                        index === 3 ?
                          <div>
                            <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Dedicated Person/Support</b> </p>
                          </div>
                          :
                          index === 4 ?
                            <div>
                              {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Company Branding</b> </p> */}
                              <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Dedicated Person/Support</b> </p>
                              {/* <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Messaging/Emails</b></p>
                              <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Bulk Emailing</b></p>
                              <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Personal Suggestions</b></p> */}

                            </div> : ""
                  }
                </div>


                <div className="pricing-plan pb-2 mt-4 pt-2">
                  <h4 className="text-muted"> <span className="plan pl-3 text-dark">{"$" + pack.price + ".00"}</span></h4>

                </div>



              </div>
            </div>
          )}


        </div>
      </div>
      <Modal show={showAddForm} onHide={closePackage} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Package</Modal.Title>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          initialValues={{ packagName: "", jobsAllowed: "", resumesAllowed: "", companiesAllowed: "", usersAllowed: "", terms: "", packageExpiresInDays: "", price: "" }}
          validationSchema={AddValidationSchema}
          onSubmit={(values) => {
            addNewPackage(values)
          }}
        >
          {({ touched, errors, isSubmitting, values, handleChange }) =>
            <div>
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-sm-4 col-lg-4" style={{ padding: "30px" }}>
                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Package Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="packagName"
                        placeholder="Package Name"
                        autocomplete="off"
                        className={`mt-2 form-control
                        ${touched.packagName && errors.packagName ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="packagName" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Jobs Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="jobsAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                       ${touched.jobsAllowed && errors.jobsAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="jobsAllowed" className="invalid-feedback" />
                    </div>


                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Resumes Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="resumesAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                       ${touched.resumesAllowed && errors.resumesAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="resumesAllowed" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Users Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="usersAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                      ${touched.usersAllowed && errors.usersAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="usersAllowed" className="invalid-feedback" />
                    </div>

                    <input type="checkbox" /> &nbsp;
                    <label htmlFor="fullname">
                      Company Branding
                    </label>

                  </div>


                  <div className="col-lg-6 col-sm-4 col-lg-4" style={{ padding: "30px" }}>
                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Companies Allowed<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="companiesAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        value={1}
                        className={`mt-2 form-control
                        ${touched.companiesAllowed && errors.companiesAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="companiesAllowed" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Terms<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="terms"
                        placeholder="Eg: 1 Month"
                        autocomplete="off"
                        className={`mt-2 form-control
                       ${touched.terms && errors.terms ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="terms" className="invalid-feedback" />
                    </div>


                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Package Expires in Days<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="packageExpiresInDays"
                        placeholder="Eg: 30"
                        autocomplete="off"
                        className={`mt-2 form-control
                       ${touched.packageExpiresInDays && errors.packageExpiresInDays ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="packageExpiresInDays" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Price<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="price"
                        placeholder="$"
                        autocomplete="off"
                        className={`mt-2 form-control
                      ${touched.price && errors.price ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="price" className="invalid-feedback" />
                    </div>
                    <Button style={{ float: 'right' }} type="submit">Submit</Button>

                  </div>

                </div>

              </Form>

            </div>
          }
        </Formik>

      </Modal>



      <Modal show={showEditForm} onHide={closePackage} centered style={{ top: "50px" }}>

        <Formik
          innerRef={formikRef}
          initialValues={{ packagName: "", jobsAllowed: "", resumesAllowed: "", companiesAllowed: "", usersAllowed: "", terms: "", packageExpiresInDays: "", price: "" }}
          validationSchema={AddValidationSchema}
          onSubmit={(values) => {
            console.log(values);
            updatePackage(values)
          }}
        >
          {({ touched, errors, isSubmitting, values, handleChange }) =>
            <div>
              <Form>
                <div className="row">
                  <div style={{ paddingTop: "12px", paddingLeft: "25px", paddingRight: "25px" }} >

                    <label htmlFor="State">Plan details</label>
                    <Field
                      name="plandetails"
                      as="select"
                      className={
                        'mt-2 form-select form-control' +
                        (errors.plandetails && touched.plandetails ? ' is-invalid' : '')
                      }
                      onChange={(e) => getPlanDetails(e.target.value, e)}
                      defaultValue={values.plandetails}
                    >
                      <option selected>Select Plan</option>

                      {packageData.map((item, index) => (
                        <option disabled={(index === 0 && props.freepackage !== undefined ? props.freepackage === false ? true : "" : "") ? true : false} value={item.id} id={item.title}>{item.title}</option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-sm-4 col-lg-4" style={{ padding: "30px" }}>





                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Package Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="packagName"
                        placeholder="Package Name"
                        autocomplete="off"
                        className={`mt-2 form-control
                      ${touched.packagName && errors.packagName ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="packagName" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Jobs Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="jobsAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                     ${touched.jobsAllowed && errors.jobsAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="jobsAllowed" className="invalid-feedback" />
                    </div>


                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Resumes Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="resumesAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                     ${touched.resumesAllowed && errors.resumesAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="resumesAllowed" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Users Allowed <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="usersAllowed"
                        placeholder="Eg: 10"
                        autocomplete="off"
                        className={`mt-2 form-control
                    ${touched.usersAllowed && errors.usersAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="usersAllowed" className="invalid-feedback" />
                    </div>




                 {/*    <div>
                      <input type="checkbox" checked={packageId === "2" ? true : packageId === "3" ? true : packageId === "4" ? true : packageId === "5" ? true : false} /> &nbsp;
                      <label htmlFor="fullname">
                        Company Branding
                      </label>
                    </div> */}

                  {/*   <div>
                      <input type="checkbox" checked={packageId === "3" ? true : packageId === "4" ? true : packageId === "5" ? true : false} /> &nbsp;
                      <label htmlFor="fullname">
                        Messaging/Emails
                      </label>
                    </div> */}

                    {/* <div>
                      <input type="checkbox" checked={packageId === "5" ? true : false} /> &nbsp;
                      <label htmlFor="fullname">
                        Personal Suggestions
                      </label>
                    </div> */}

                  </div>


                  <div className="col-lg-6 col-sm-4 col-lg-4" style={{ padding: "30px" }}>
                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Companies Allowed<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="companiesAllowed"
                        placeholder="Eg: 1"
                        value={1}
                        autocomplete="off"
                        className={`mt-2 form-control
                      ${touched.companiesAllowed && errors.companiesAllowed ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="companiesAllowed" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Terms<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="terms"
                        placeholder="In Terms of Months"
                        autocomplete="off"
                        className={`mt-2 form-control
                     ${touched.terms && errors.terms ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="terms" className="invalid-feedback" />
                    </div>


                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Package Expires in Days<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="packageExpiresInDays"
                        placeholder="1 Month"
                        autocomplete="off"
                        className={`mt-2 form-control
                     ${touched.packageExpiresInDays && errors.packageExpiresInDays ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="packageExpiresInDays" className="invalid-feedback" />
                    </div>

                    <div className="form-group  mb-3">
                      <label htmlFor="fullname">
                        Price<span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="price"
                        placeholder="$"
                        autocomplete="off"
                        className={`mt-2 form-control
                    ${touched.price && errors.price ? 'is-invalid' : ''}`}
                      />

                      <ErrorMessage component="div" name="price" className="invalid-feedback" />
                    </div>


                    <div>
                      <input type="checkbox" checked={packageId === "2" ? true : packageId === "3" ? true : packageId === "4" ? true : packageId === "5" ? true : false} /> 
                      <label htmlFor="fullname">
                        Dedicated Person/Support
                      </label>
                    </div>
                    {/* <div>
                      <input type="checkbox" checked={packageId === "5" ? true : packageId === "4" ? true : false} /> &nbsp;
                      <label htmlFor="fullname">
                        Bulk Emailing
                      </label>
                    </div> */}



                  </div>
                  <Button style={{ float: 'right' }} type="submit">Update</Button>
                </div>

              </Form>

            </div>
          }
        </Formik>

      </Modal>
    </section>


  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default (connect(mapStateToProps, null)(Packages));
