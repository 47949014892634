/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment'
import { MdLocationOn, MdLocalPhone, MdEmail, MdDelete } from "react-icons/md";
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaSuitcase, FaRegBuilding, FaCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { MdRefresh, MdAddBox, MdEdit } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import axios from 'axios'
import Key from "../clientVariables.json";
import ToastMessage from "../components/ToastMessage";
import Button from 'react-bootstrap/Button';
import request from 'src/utils/request';
import httpConfig, { getOptions, postOptions } from 'src/utils/httpConfig';
import { ENDPOINT } from 'src/utils/endpoint';
import Resumeviewer from "./LinkedinResumeViewer"
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { Checkbox } from '@mui/material';
import MUIDataTable from "mui-datatables";
import "./Notes.css"
import { FaLinkedin } from "react-icons/fa";

function LiveJobs() {
    const mailbody = "We have come across your job posting in LinkedIn on XYZ Technology. For that we have identified couple of Resumes and sending it across. Please find the attached resumes and let us know if that works for you. To connect employers and job seekers, we have created a job portal named as ‘Jobs n Profiles’ (jnp) that can simplify finding the right candidate for the recruiters and finding a right job for the job seekers in the current market.  This Jobs n Profiles job portal let recruiters to post their job requirement with custom application questions and efficiently search for candidates and resumes.  We strongly believe that our job portal can help recruiters pace up their job fulfillment process.  If you would like to go over our job portal functionality and its usability, please connect with us by clicking the link to know more about it. ";

    const [data, setData] = useState({
        fromEmail: "hot-resumes@jobsnprofiles.com",
        fullname: '',
        toEMail: '',
        ccEMail: '',
        subj: '',
        body: mailbody,
        jobtitle: '',
        skillname: '',
        location: 'Remote',
        toUserId: '',
        jobId: '',
    })
    const { fromEmail, fullname, toEMail, ccEMail, subj, body, jobtitle, skillname, location, jobId, toUserId } = { ...data }
    const [jobsList, setJobsList] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [selectedResIds, setSelectedResids] = useState([]);
    const [resumeChecked, setResumeChecked] = useState();
    const [selectedResumes, setSelectedResumes] = useState([]);
    const [firstResume, setFirstResume] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [skills, setSkills] = useState("");
    const [reslocation, resSetLocation] = useState("");
    const [formName, setFormName] = useState('')
    const [arrayIds, setArrayIds] = useState([]);
    const [sent, setSent] = useState([]);
    const [selectedJobID, setSelectedJobID] = useState("");
    const [selectresumeFileDetails, setSelectresumeFileDetails] = useState([])
    const [resumeIds, setResumeIds] = useState([]);
    const [showResume, setShowResume] = useState(false)
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [shortRegion, setShortRegion] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [showRoleForm, setShowRoleForm] = useState(false)
    const [reports, setReports] = useState([])
    const [resumesShared, setResumesShared] = useState([])
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState("")
    const [related_titles, setRelated_titles] = useState("")
    const [searchRole, setSearchRole] = useState("")
    const [search_Related_titles, setSearch_Related_titles] = useState("")
    const [roleId, setRoleId] = useState("")
    console.log(sent)




    const MINUTE_MS = (1000 * 60) * 15;

    useEffect(() => {
        const interval = setInterval(() => {
            getResumesDataList();
        }, MINUTE_MS);

        return () => clearInterval(interval);

    }, [])





    useEffect(() => {
        getRolesList();
    }, [])



    const getRolesList = () => {

        fetch(`${Key.domain}/common/get_c2c_roles`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                response.json().then((resdata) => {
                    setRoles(resdata.data)
                    getSuggestedLinkedInProfiles(resdata.data[0])

                })
            })

    }

    const addRole = (e) => {
        e.preventDefault()
        fetch(`${Key.domain}/common/add_c2c_roles`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ uid: sessionStorage.getItem("id"), role: role, related_titles: related_titles }),
        })

            .then((response) => {
                response.json().then((resdata) => {
                    handleClose()
                    getRolesList()

                })
            })

    }

    const updateRole = (e) => {
        e.preventDefault()
        fetch(`${Key.domain}/common/update_c2c_roles`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ uid: sessionStorage.getItem("id"), role: role, related_titles: related_titles, role_id: roleId }),
        })

            .then((response) => {
                response.json().then((resdata) => {
                    handleClose()
                    getRolesList()

                })
            })

    }

    const handleChange = (e, resumeId) => {
        setResumeIds(prevArray => {
            // Check if the ID already exists in the array
            if (!prevArray.includes(resumeId)) {
                // Create a new array by spreading the previous array and appending the new ID
                return [...prevArray, resumeId];
            }
            return prevArray; // If the ID already exists, return the previous array without any changes
        })
        const { name, value, checked } = e.target;
        let filteredobjs = []
        if (name === String(resumeId)) {
            console.log("comes here", resumeId)
            const newResumes = profileResumes.map((list) =>
                list.id === resumeId ? { ...list, isChecked: checked } : list
            );
            setProfileResumes(newResumes);
            console.log(newResumes)
            const obj = newResumes.filter((x) => x.isChecked === true);
            filteredobjs = obj.filter((x) => x.application_title !== '');
            const ids = filteredobjs.map(obj => obj.id)
            setSelectedResids(ids)
            setSelectedResumes(filteredobjs);
            // setProfileResumes(filteredobjs);
        }

    };

    const handleClose = () => {
        setShowRoleForm(false)
        setShowResume(false)
    }

    const handleSendMail = (e) => {
        e.preventDefault();

        // var jobtitle = '"' + data.jobtitle + '"'
        console.log(profileResumes)
        console.log(selectedResumes)

        if (toEMail && fromEmail && fullname) {
            if (selectedResumes.length > 8) {


                const msg = "Maximum limit is to share resumes is 5";
                toast(<ToastMessage message={msg} />);

            } else if (selectedResumes.length > 0) {
                fetch(`${Key.domain}/common/send_livejob_resumes_mail`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ formdata: data, resumedata: selectedResumes.length > 0 ? selectedResumes : selectedResumes }),
                })
                    .then((response) => {
                        response.json().then((resdata) => {
                            console.log(resdata)
                            if (resdata.data === "Mail sent") {
                                fetch(`${Key.domain}/customer/save_suggested_resumes_mail`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        admin_id: sessionStorage.getItem("id"),
                                        session_token: sessionStorage.getItem('userToken'),
                                        jobid: data.jobId,
                                        fromUserMail: data.fromEmail,
                                        fromUserId: sessionStorage.getItem("id"),
                                        toUserMail: data.toEMail,
                                        toUserId: data.toUserId,
                                        sharedResumeIds: resumeIds
                                    }),
                                }).then((response) => {
                                    console.log(response)
                                    const msg = "Mail Sent Successfully";
                                    toast(<ToastMessage message={msg} />);
                                    window.location.reload()
                                })

                            }
                        })
                    })
            } else {
                const msg = "Select atleast 1 resume";
                toast(<ToastMessage message={msg} />);

            }
        }
    }
    const redirectTOJNP = (postedJob) => {
        // alert("hello")
        const url = `${Key.JNP_URL}/job/view/${sessionStorage.getItem("id")}/${sessionStorage.getItem("userToken")}/Job_${postedJob.id}`
        console.log(url.split("Job_"))
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
    }

    const getUpdateRoleValues = (role) => {
        setFormName('Edit Role')
        setRole(role.role)
        setRelated_titles(role.related_titles)
        setRoleId(role.id)
    }



    const searchRoles = async () => {
        fetch(`${Key.domain}/common/search_roles`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ role: searchRole }),
        })

            .then((response) => {
                response.json().then((resdata) => {
                    setRoles(resdata.data)

                })
            })

    }




    const getSuggestedLinkedInProfiles = (role) => {
        setSelectedJobID(role.id)

        fetch(`${Key.domain}/common/get_LinkedIn_Profiles`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({ role_id: role.id }),
        }).then((response) => {
            response.json().then((resdata) => {
                setProfiles(resdata.data);
            });
        });
    }

    const DeleteRole = (role) => {
        if (window.confirm("Are you sure you want to delete Role?")) {

            fetch(`${Key.domain}/common/delete_role`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({ role_id: role.id }),
            }).then((response) => {
                response.json().then((resdata) => {
                    getRolesList()
                });
            });
        }
    }

    const columns = [

        {
            name: "title",
            label: "Job Title",


        },

        {
            name: "company_name",
            label: "Company Name",


        },

        {
            name: "created",
            label: <span>Posted Date</span>,

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("LLL")
                    // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

                },
            }
        },
    ]

    const ResumeColumns = [

        {
            name: "id",
            label: "ID",


        },

        {
            name: "application_title",
            label: "Title",


        },
        {
            name: "full_name",
            label: "Name",


        },

        {
            name: "last_modified",

            label: <span>Last Modified</span>,

            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("LLL")
                    // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>

                },
            }
        }
    ]

    const resumeOptions = {
        tableBodyHeight: '40vh',
        selectableRows: false,
        viewColumns: false,
        responsive: 'stacked',
        print: false,
        search: true,

    }

    const reportsOptions = {
        tableBodyHeight: '40vh',
        selectableRows: false,
        viewColumns: false,
        responsive: 'stacked',
        print: false,
        search: true,
        onCellClick: (cellIndex, rowIndex) => {
            // console.log(cellIndex.value);
            // console.log(rowIndex.dataIndex);
            // console.log(rowIndex);

            var row = reports[rowIndex.dataIndex];



            if (rowIndex.colIndex === 0) {

                fetch(`${Key.domain}/customer/get_resumes_details_by_jobid`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        admin_id: sessionStorage.getItem("id"),
                        session_token: sessionStorage.getItem('userToken'),
                        job_id: row.jobid
                    }),
                }).then((response) => {
                    response.json().then((resdata) => {
                        console.log(resdata)
                        var data = resdata.data
                        console.log(data)
                        setResumesShared(data);

                    })
                })


            }
        },

    }
    return (
        <div className='card p-1' style={{ marginTop: "-60px", boxShadow: "0px 5px 10px black" }}>


            <div className='container containerOne' >
                <div className='row'>
                    {/*  <h4 className="mb-3">Posted Jobs</h4>
                    <div className='col-12 row'>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>From : hot-resumes@jobsnprofiles.com</p>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>Name : {fullname}</p>
                        <p className='col-3 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>To : {toEMail}</p>
                        {/* <p className='col-7 m-1' style={{ border: "1px solid grey", borderRadius: "5px" }}>Subject : </p>
                        <Form className='col-2 m-0 align-self-center' onSubmit={(e) => handleSendMail(e)}>


                            <Button variant="primary" type="submit" style={{ float: "right", }}
                            //disabled={sent.includes(parseInt(selectedJobID))}
                            >
                                Share Mail
                            </Button>
                        </Form>


                    </div>*/}
                    <div className='col-md-6 mt-5' style={{ marginTop: "10%" }}>

                        <Modal size="md" show={showRoleForm} onHide={handleClose} centered
                            // style={{ marginLeft: "8%", marginTop:"100px"}}
                            style={{ paddingTop: '80px' }}
                        >
                            <Modal.Header closeButton>{formName}</Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Form >
                                            <Form.Group className="mb-3">
                                                <Form.Label>Role</Form.Label>
                                                <Form.Control type="role" placeholder="" onChange={(e) => setRole(e.target.value)} value={role} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Related Titles</Form.Label>
                                                <Form.Control as="textarea" onChange={(e) => setRelated_titles(e.target.value)} rows={3} value={related_titles} />
                                            </Form.Group>
                                            <Button type="submit" style={{ float: "right" }} onClick={(e) => { formName === 'Edit Role' ? updateRole(e) : addRole(e) }}>Submit</Button>
                                        </Form>

                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal>

                        <div className="sales-styles positions-scroll" style={{ overflow: "auto" }}>
                            <div className="d-flex flex-row justify-content-between" >
                                <b>Roles</b>
                                {/* <TbReportSearch style={{ cursor: "pointer", marginLeft: "47%", boxShadow: "0px 0px 5px black" }} onClick={() => { setShowReports(true); getReports() }} />
                                <MdRefresh style={{ cursor: "pointer", float: "right", boxShadow: "0px 0px 5px black" }} onClick={() => { getResumesDataList() }} /> */}
                                <MdAddBox onClick={() => { setShowRoleForm(true); setRole(""); setRelated_titles(""); setFormName('Add Role') }} style={{ cursor: "pointer", float: "right", boxShadow: "0px 0px 2px black" }} />
                            </div>
                            <div >
                                <br />
                                <InputGroup className="mb-2">
                                    <Form.Control
                                        placeholder="Role"
                                        aria-label="Company Name"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setSearchRole(e.target.value)}
                                    />
                                    {/* <Form.Control
                                        placeholder="Related titles"
                                        aria-label="Search Resumes"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setSearch_Related_titles(e.target.value)}
                                    /> */}
                                    <Button variant="outline-secondary" id="button-addon2"
                                        onClick={() => searchRoles()}>
                                        Search
                                    </Button>
                                </InputGroup>
                                <br />
                                {roles.map((each) => (
                                    <div className="card mb-1 p-2 pb-0 pt-0" /*onClick={(e) => { getResumes(each) }}*/ onClick={() => getSuggestedLinkedInProfiles(each)} style={{ cursor: "pointer", border: `${selectedJobID === each.id ? "2px solid #0d6efd" : ""}` }}>
                                        <div style={{ height: each.role && each.role.length > 35 ? "43px" : "26px" }} className='pb-0 ' ><span ><button style={{ color: "#0d6efd", marginBottom: "0px", border: "none", background: "transparent", textAlign: each.role && each.role.length > 30 ? "justify" : "left" }}><b>{each.role}</b></button>

                                        </span>
                                            <div style={{ float: "right" }}>
                                                <span style={{ color: "#0d6efd" }} onClick={() => { setShowRoleForm(true); getUpdateRoleValues(each) }}><MdEdit />
                                                </span>
                                                <span style={{ color: "#0d6efd" }} onClick={() => { DeleteRole(each) }}><MdDelete />
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ height: "27px" }} className='d-flex flex-row justify-content-between m-0'>
                                            {/* <p>  {(each.related_titles) ? each.related_titles.slice(0, 30) + '...' : "N/A"}&nbsp;
                                            </p> */}
                                            <p className="" style={{ fontSize: "12px" }}>{moment(each.created).format("LL")}</p>
                                        </div>


                                    </div>
                                ))}


                            </div>
                        </div>

                    </div>


                    <div className='col-md-6 mt-4'>
                        <Modal size="xl" show={showResume} onHide={handleClose} centered style={{ marginLeft: "8%", height: "110%" }}>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Resumeviewer selectresumeFileDetails={selectresumeFileDetails} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal>


                        <div className="sales-styles positions-scroll">
                            <h6 className='mb-2'>Suggested PROFILES</h6>

                            {/* <InputGroup className="mb-2">
                                <Form.Control
                                    placeholder="Resume Title"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <Form.Control
                                    placeholder="Skills"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => setSkills(e.target.value)}
                                />
                                <Form.Control
                                    placeholder="Location"
                                    aria-label="Search Resumes"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => resSetLocation(e.target.value)}
                                />
                                <Button variant="outline-secondary" id="button-addon2"
                                    onClick={() => getSearchResumes()}>
                                    Search
                                </Button>
                            </InputGroup> */}
                            <br />
                            {profiles.length > 0 ? profiles.map((profile) => {
                                return (
                                    <>
                                        <div className="job-titile" style={{ padding: "5px" }}>
                                            <Row className="job-checkbox">
                                                <Col sm="1">
                                                    {/* <Form.Check aria-label="option 1" name={profile.id}
                                                    id="check"
                                                    checked={profile?.isChecked}
                                                    onClick={(e) => handleChange(e, profile.id)} /> */}
                                                    <FaSuitcase />
                                                </Col>
                                                <Col sm="11"> <h3> <a style={{ cursor: "pointer", color: "#2065D1" }}
                                                    onClick={() => {
                                                        {
                                                            profile.profile_path !== null ? setShowResume(true) : null;  }
                                                            setSelectresumeFileDetails(profile)
                                                      
                                                    }}

                                                >  {profile.profile_title ? profile.profile_title : 'N/A'} </a>     <a target="_blank" href={'https://' + profile.linkedin_url}>
                                                        <FaLinkedin style={{ color: "#2065D1", float: "right", fontSize: "20px" }} />

                                                    </a>
                                                </h3> </Col>

                                            </Row>


                                            <ul>
                                                <li>
                                                    <MdLocalPhone /> {profile.profile_contact_no
                                                        ? profile.profile_contact_no
                                                        : "N/A"} &nbsp; &nbsp;

                                                    <MdEmail /> {profile.profile_email
                                                        ? profile.profile_email
                                                        : "N/A"}
                                                </li>
                                                <li>

                                                </li>

                                                <li style={{ float: "left" }}> <MdLocationOn /> {profile.profile_location ? profile.profile_location : 'N/A'} </li>

                                                <li style={{ float: "right" }}> <FaCalendarAlt />

                                                    {profile.created ? moment(profile.created).format("LL") : "N/A"
                                                    }
                                                </li>


                                            </ul>
                                        </div>
                                        <hr style={{ width: "100%" }} />
                                    </>)

                            }) : <center style={{ marginTop: "50px" }}> <h4>No Profiles</h4> </center>
                            }

                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default LiveJobs