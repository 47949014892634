/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from "axios"
import Key from "../clientVariables.json"
import Logo from "../components/images/logo.png"
import Purchase from './Purchase'

const Purchaseorderview = (props) => {
    const [customerDetails, setCustomerDetails] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [packageDetails, setPackageDetails] = useState([])
    const history = useNavigate()

    useEffect(() => {
        // console.log(props.customerDetails)
        // setCustomerDetails(props.customerDetails.userDetails)
        // setCompanyDetails(props.customerDetails.companyDetails)
        // setPackageDetails(props.customerDetails.packageDetails)

        getGetCustomerDetails()
    }, [])


    const getGetCustomerDetails = () => {
        if (props.customerid !== "" && props.customerid !== null && props.customerid !== undefined && props.customerid !== 0) {
            axios
                .post(`${Key.domain}/customer/get_upgrade_packagedetails`, {
                    customer_id: props.customerid,
                })
                .then((response) => {

                    console.log(response.data.data);
                    // setPackageDetails(response.data.data.packageDetails)
                    // setCustomerDetails(response.data.data)
                    // setCompid(response.data.data.companyDetails ? response.data.data.companyDetails.id : 0)



                    setCustomerDetails(response.data.data.userDetails)
                    setCompanyDetails(response.data.data.companyDetails)
                    setPackageDetails(response.data.data.packageDetails)

                });
        }
    }


    return (
        <div>

                <Purchase customerDetails={customerDetails} price={props.price} companyDetails={companyDetails} packageDetails={packageDetails} currentUser={props.currentUser} />
                
           
        </div>
    )
}


function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,

    };
}
export default connect(mapStateToProps, null)(Purchaseorderview)