/* eslint-disable */

import React, { useRef, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios'
import Key from '../../src/clientVariables.json'
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';


function Personaldetails(props) {

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [fullName, setFullName] = useState("");
    const [userName,setUserName]=useState("")

    const [phoneNo, setPhoneNo] = useState('');


    const handlePhoneNumberChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhoneNo(formattedPhoneNumber);
        if (formikRef.current) {
          formikRef.current.setFieldValue('mobileNo', formattedPhoneNumber);
          // console.log(formikRef.current);
        }
      };
    
    
      const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        setPhoneNo(String(phoneNumber));
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      };
        useEffect(() => {
            console.log(fullName)
            console.log(props.companyuserid)


            if (props.companyuserid !== "" && props.companyuserid !== null && props.companyuserid !== undefined && props.companyuserid !== 0) {
                axios
                    .post(`${Key.domain}/customer/get_customerdetails`, {
                        customer_id: props.companyuserid,
                    })
                    .then((response) => {

                        console.log(response.data.data);
                        setUserName( response.data.data.userDetails.username)
setPhoneNo(response.data.data.userDetails.phone_number)
                        getCities(response.data.data.userDetails.state);


                        if (formikRef.current) {
                            formikRef.current.setFieldValue(
                                "fullname",
                                response.data.data.userDetails.full_name
                            );
                            formikRef.current.setFieldValue(
                                "username",
                                response.data.data.userDetails.username
                            );
                            formikRef.current.setFieldValue(
                                "role",
                                response.data.data.userDetails.role
                            );
                            formikRef.current.setFieldValue('mobileNo', response.data.data.userDetails.phone_number);
                            formikRef.current.setFieldValue(
                                "state",
                                response.data.data.userDetails.state
                            );
                            formikRef.current.setFieldValue(
                                "country",
                                response.data.data.userDetails.country
                            );
                            formikRef.current.setFieldValue(
                                "city",
                                response.data.data.userDetails.city
                            );
                            formikRef.current.setFieldValue(
                                "zipcode",
                                response.data.data.userDetails.zipcode
                            );

                        }
                    });
            }
            axios
                .get(`${Key.domain}/common/get_all_countries`, {
                    method: "GET", // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                    },
                })
                .then((resdata) => {
                    console.log(resdata.data.data);
                    setCountries(resdata.data.data);
                    getStates(233);
                });



            axios
                .get(`${Key.domain}/common/get_all_countries`, {
                    method: "GET", // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                    },
                })
                .then((resdata) => {
                    console.log(resdata.data.data);
                    setCountries(resdata.data.data);
                    getStates(233);
                });


        }, [])



    const formikRef = useRef();
    const fileRef = useRef();


    const ValidationSchema = Yup.object().shape({

        // email: Yup.string()
        //     .email("Invalid email address format")
        //     .required("Email is required"),

        // password: Yup.string()
        //     .min(3, "Password must be 3 characters at minimum")
        //     .required("Password is required"),

        fullname: Yup.string()
        .required("Full name is required")
        .max(30, "Name must be  30 characters")
        .matches(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, "Only alphabets and  dot allowed for this field "),

        username: Yup.string()
        .email("Please enter valid email")
        .required("Please enter email")
        .matches(
            /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|outlook|myyahoo))/,
            "Only Business Emails are allowed "
        ),

        role:Yup.string().
        required("Role is required.").nullable(),
           
            
country:Yup.string()
.required("Country is required"),
        state: Yup.string()
            .required("State is required").nullable(),

        city: Yup.string()
            .required("City is required").nullable(),
          
        zipcode: Yup.string()
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "zipcode should be 5 digits").required(" Zipcode is required").nullable(),
        
        mobileNo:Yup.string().required("Contact No required").min(14,"Contact No should be 10 digits").nullable(),
    });


    const getStates = (countryid, values) => {
        axios
            .post(`${Key.domain}/common/get_states_by_countryid`, {
                country_id: countryid,
            })
            .then((response) => {
                console.log(response);
                setStates(response.data.data);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("country", countryid || 233);
                }
            });
    };

    const getCities = (stateid) => {
        //  const selectedState = e.target.value;
        // console.log(selectedState)
        axios
            .post(`${Key.domain}/common/get_cities_by_stateid`, {
                state_id: stateid,
            })
            .then((response) => {
                console.log(response);
                setCities(response.data.data);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("state", stateid);
                }
            });
    };

    const createEmployer = (values) => {
        console.log(values)
        if(formikRef.current){
            formikRef.current.setFieldValue(
                "username",
                values.username
            );
        }
        axios
            .post(`${Key.domain}/customer/add_or_update_customer_userdetails`, {
                "full_name": values.fullname,
                "username": values.username,
                // "password": values.password,
                "companyid": 0,
                "sendEmail": 1,
                "login_type_id": 2,
                "ca_id": sessionStorage.getItem("id"),
                "package_id": 1,
                "ats_enable": 1,
                "status": 1,
                "temp_password": "",
                "block": 0,
                "activation": 1,
                "regType": "Regular",
                "role": values.role,
                "countryid": values.country,
                "stateid": values.state,
                "cityid": values.city,
                "zipcode": values.zipcode,
                "session_token": props.currentUser.session_token,
                "admin_id": sessionStorage.getItem("id"),
                "user_id": props.companyuserid,
                "mobile":values.mobileNo,
                "action": (props.companyuserid !== "" && props.companyuserid !== null && props.companyuserid !== undefined && props.companyuserid !== 0) ? "1" : "0",

            })
            .then((response) => {
                console.log(response.data)



                if (response.data.userDetails === 0) {
                    console.log(response.data)

                    let msg = response.data.data
                    toast(
                        <ToastMessage message={msg} />
                    )

                } else {
                    let useridd = response.data.userDetails === 1 ? props.companyuserid : response.data.userDetails[0].userid
                    let actionn = (props.companyuserid !== "" && props.companyuserid !== null && props.companyuserid !== undefined && props.companyuserid !== 0) ? "1" : "0"
                    if (response.status === 200) {
                        // console.log(response.data)

                        props.handleTabChange(1, useridd)
                        let msg = actionn === 1 ? "Customer Details added" : "Customer Details updated"
                        toast(
                            <ToastMessage message={msg} />
                        )
                    }
                }

            })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <Formik
                        innerRef={formikRef}
                        initialValues={{ fullname: "", username: "", role: "", country: "", state: "", city: "", zipcode: "",mobileNo:""}}
                        validationSchema={ValidationSchema}
                        onSubmit={(values) => {
                            console.log(values);
                            createEmployer(values)

                            // props.handleTabChange(1)

                        }}
                    >
                        {({ touched, errors, isSubmitting, values,handleChange }) =>

                            <div>
                                <Form>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-lg-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="fullname">
                                                    Full Name <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="fullname"
                                                    placeholder="Full name"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                    ${touched.fullname && errors.fullname ? 'is-invalid' : ''}`}
                                                />

                                                <ErrorMessage component="div" name="fullname" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="username">
                                                Email <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="username"
                                                    placeholder="Enter Email"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                    ${touched.username && errors.username ? 'is-invalid' : ''
                                                        }`}
                                                        onChange={(e)=>{
                                                            (props.companyuserid !== "" && props.companyuserid !== null && props.companyuserid !== undefined && props.companyuserid !== 0)?
                                                            formikRef.current.setFieldValue("username", userName):
                                                            formikRef.current.setFieldValue("username", e.target.value)
                                                            ;
                                                        }}
                                                />

                                                <ErrorMessage component="div" name="username" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        {/* <div className="form-group  mb-3">
                                                                  <label htmlFor="email">Email</label>
                                                                  <Field
                                                                      type="email"
                                                                      name="email"
                                                                      placeholder="Enter email"
                                                                      autocomplete="off"
                                                                      className={`mt-2 form-control
                                                                  ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                                  />
          
                                                                  <ErrorMessage
                                                                      component="div"
                                                                      name="email"
                                                                      className="invalid-feedback"
                                                                  />
                                                              </div> */}
                                        <div className="col-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="role">
                                                    Role <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="role"
                                                    placeholder="Role"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                    ${touched.role && errors.role ? 'is-invalid' : ''}`}
                                                />

                                                <ErrorMessage component="div" name="role" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="role">
                                                    Contact No <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="mobileNo"
                                                    placeholder="(xxx)-xxx-xxxx"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                                   ${touched.mobileNo && errors.mobileNo ? 'is-invalid' : ''}`}
                                                 onChange={handlePhoneNumberChange}
                                                value={phoneNo}
                                                />

                                                <ErrorMessage component="div" name="mobileNo" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor="country">
                                                    Country <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    name="country"
                                                    as="select"
                                                    className={
                                                        'mt-2 form-select form-control' + (errors.country && touched.country ? ' is-invalid' : '')
                                                    }
                                                    onChange={(e) => getStates(e.target.value, values)}
                                                    defaultValue={values.country}
                                                >
                                                    <option selected>Select Country</option>
                                                    {countries.map((country) => (
                                                        <option value={country.id}>{country.name}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor="State">
                                                    State <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    name="state"
                                                    as="select"
                                                    className={
                                                        'mt-2 form-select form-control' + (errors.state && touched.state ? ' is-invalid' : '')
                                                    }
                                                    onChange={(e) => getCities(e.target.value)}
                                                    defaultValue={values.state}
                                                >
                                                    <option>Select state</option>
                                                    {states.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage component="div" name="state" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor="City">
                                                    City <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    name="city"
                                                    as="select"
                                                    className={
                                                        'mt-2 form-select form-control' + (errors.city && touched.city ? ' is-invalid' : '')
                                                    }
                                                    onChange={(e) => {
                                                        console.log(values);
                                                        formikRef.current.setFieldValue("city", e.target.value);
                                                    }}
                                                >
                                                    <option>Select city</option>
                                                    {cities.map((item, index) => (
                                                        <option value={item.id}>{item.cityName}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage component="div" name="city" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="mobile">
                                                    Zipcode <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="zipcode"
                                                    placeholder="Zipcode"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                                   ${touched.zipcode && errors.zipcode ? 'is-invalid' : ''}`}
                                                />

                                                <ErrorMessage component="div" name="zipcode" className="invalid-feedback" />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4 col-sm-4 col-lg-4"></div> */}

                                        <div className="col-md-4 col-sm-4 col-lg-4">
                                            {/* <div className="form-group  mb-3">
                                                                  <label htmlFor="password" >
                                                                      Password
                                                                  </label>
                                                                  <Field
                                                                      type="password"
                                                                      name="password"
                                                                      placeholder="Enter password"
                                                                      className={`mt-2 form-control
                                                                  ${touched.password && errors.password
                                                                              ? "is-invalid"
                                                                              : ""
                                                                          }`}
                                                                  />
                                                                  <ErrorMessage
                                                                      component="div"
                                                                      name="password"
                                                                      className="invalid-feedback"
                                                                  />
                                                              </div> */}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block mt-4">
                                        Submit
                                    </button>
                                </Form>
                                {/* <Form>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-lg-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="fullname">Full Name</label>
                                                <Field
                                                    type="text"
                                                    name="fullname"
                                                    placeholder="Full name"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                ${touched.fullname && errors.fullname ? "is-invalid" : ""}`}
                                                    onChange={(e) => {
                                                        formikRef.current.setFieldValue(
                                                            "fullname",
                                                            e.target.value);
                                                        setFullName(e.target.value)

                                                    }
                                                    }
                                                />

                                                <ErrorMessage
                                                    component="div"
                                                    name="fullname"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                            <div className="form-group  mb-3">
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                />

                                                <ErrorMessage
                                                    component="div"
                                                    name="email"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="State" >
                                                    State
                                                </label>
                                                <Field
                                                    name="state"
                                                    as="select"
                                                    className={
                                                        "form-select form-control" +
                                                        (errors.state && touched.state ? " is-invalid" : "")
                                                    }
                                                    onChange={(e) => getCities(e.target.value)}
                                                >
                                                    <option>Select state</option>
                                                    {states.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                            </div>

                                        </div>
                                        <div className="col-md-4 col-sm-4 col-lg-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="username">User Name</label>
                                                <Field
                                                    type="text"
                                                    name="username"
                                                    placeholder="User name"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                     ${touched.username && errors.username ? "is-invalid" : ""}`}
                                                />

                                                <ErrorMessage
                                                    component="div"
                                                    name="username"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                            <div className="form-group  mb-3">
                                                <label htmlFor="role">Role</label>
                                                <Field
                                                    type="text"
                                                    name="role"
                                                    placeholder="Role"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                     ${touched.role && errors.role ? "is-invalid" : ""}`}
                                                />

                                                <ErrorMessage
                                                    component="div"
                                                    name="role"
                                                    className="invalid-feedback"
                                                />
                                            </div>


                                            <div className="form-group mb-3">
                                                <label htmlFor="City" >
                                                    City
                                                </label>
                                                <Field
                                                    name="city"
                                                    as="select"
                                                    className={
                                                        "form-select form-control" +
                                                        (errors.city && touched.city ? " is-invalid" : "")
                                                    }
                                                    onChange={(e) => {
                                                        console.log(values);
                                                        formikRef.current.setFieldValue("city", e.target.value);
                                                    }}
                                                >
                                                    <option>Select city</option>
                                                    {cities.map((item, index) => (
                                                        <option value={item.id}>{item.cityName}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div>



                                        <div className="col-md-4 col-sm-4 col-lg-4">
                                            <div className="form-group  mb-3">
                                                <label htmlFor="password" >
                                                    Password
                                                </label>
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    className={`mt-2 form-control
                                                ${touched.password && errors.password
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="password"
                                                    className="invalid-feedback"
                                                />
                                            </div>


                                            <div className="form-group mb-3">
                                                <label htmlFor="country" >
                                                    Country
                                                </label>
                                                <Field
                                                    name="country"
                                                    as="select"
                                                    className={
                                                        "form-select form-control" +
                                                        (errors.country && touched.country ? " is-invalid" : "")
                                                    }
                                                    onChange={(e) => getStates(e.target.value, values)}
                                                    defaultValue={values.country}
                                                >
                                                    <option selected>Select Country</option>
                                                    {countries.map((country) => (
                                                        <option value={country.id}>{country.name}</option>
                                                    ))}
                                                </Field>
                                            </div>


                                            <div className="form-group  mb-3">
                                                <label htmlFor="role">Zipcode</label>
                                                <Field
                                                    type="text"
                                                    name="zipcode"
                                                    placeholder="zipcode"
                                                    autocomplete="off"
                                                    className={`mt-2 form-control
                                                 ${touched.zipcode && errors.zipcode ? "is-invalid" : ""}`}
                                                />

                                                <ErrorMessage
                                                    component="div"
                                                    name="zipcode"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                        </div>



                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block mt-4"
                                    // onClick={() => props.handleTabChange(1)}
                                    >
                                        Submit
                                    </button>
                                                    </Form>*/}
                            </div>

                        }
                    </Formik>
                </div>
            </div>
        </div>
    );
}



function mapStateToProps(state, ownProps) {
    return {
        currentUser: state.UserReducer.user,

    };
}
export default (connect(mapStateToProps, null)(Personaldetails));




