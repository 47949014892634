import React,{ useState, useEffect } from 'react'
import MUIDataTable from "mui-datatables";
import { BsThreeDotsVertical } from 'react-icons/bs';
import {FcDocument} from "react-icons/fc";
import { connect } from "react-redux";
import axios from "axios"
import moment from "moment";
import Button from 'react-bootstrap/Button';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { UserMoreMenu } from '../sections/@dashboard/user';
import { showLoader,hideLoader } from "../constant/loader.action";
import Key from '../clientVariables.json'




function Sales(props) {
  const history = useNavigate();

const [sales,setSales] = useState([])
  
  useEffect(() => {
    props.showLoader();

    axios
      .post(`${Key.domain}/dashboard/get_sales_list`, {
        "crm_userid": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        setSales(response.data.data.sales)
        props.hideLoader();

      }).catch((err) => {
   
      });;


  }, [])



  const columns = [
  
  {
    name: "customer_id",
  
    label: <span>Employee Code</span>,
  },
  
  {
    name: "Company",
  
    label: <span>Company Name</span>,
  },
  
  {
    name: "PlanName",
  
    label: <span>Plan</span>,
  },
  
  {
    name: "payment_response",
  
    label: <span>Transaction Status</span>,
  },

  {
    name: "amount",
  
    label: <span>Revenue</span>,
  },
  
  {
    name: "created",
  
    label: <span>Activated On</span>,

        options: {
        customBodyRender: (value, tableMeta, updateValue) => {
        return moment(value).format("LL")
        // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>
  
      },
    }
  },
  
  
  // {
  //   name: "actions",
  
  //   label: <span>Actions</span>,
  
  //   options: {
  
  //     customBodyRender: (value, tableMeta, updateValue) => {
  
  //       return <UserMoreMenu />
  //       // return <div>&nbsp;&nbsp;<FcDocument size={20}/></div>
  
  //     },
  
  //     filter: true,
  
  //     sort: true,
  
  //     setCellProps: value => {
  
  //       return {
  
  //         style: {
  
  //           cursor: 'pointer'
  
  //         }
  
  //       };
  
  //     }
  
  
  
  //   },
  // },


];
  
  
  const options = {
    fixedHeader: true,
    density:true,
    selectableRows: true,
    search:true,
    filter:true,
    sort: false,
    download: true,
    print: false,
    viewColumns: false,
  };
  
  
  // const data = [
  //   ["A745754", "People SOft", "Sales Excutive", "05","$1200",],
  //   ["D45245a", "Micro SOft", "Sales Excutive", "15","$1500",],
  
  // ];

  return (
    <div style={{marginTop:"-60px",boxShadow:"0px 0px 5px black"}}>
   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h4 style={{marginLeft:"30px"}}>Sales</h4>
        <Button variant="primary" style={{marginRight:"30px",marginTop:"5px"}} onClick={() => history('/dashboard/SalesMarketing')}>
          Share Mail
        </Button>
      </div>

      <MUIDataTable 
        title={""}
        data={sales}
        columns={columns}
        options={options}
      />

    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}

const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);

// import React from 'react'
// import { Grid, Container, Typography } from '@mui/material';
// import Card from '@mui/material/Card';
// import AppWidgetSummary from "../sections/@dashboard/app/Accountingwidgetcount"
// import Salesrevenue from './Salesrevenueadmin';
// import Costbreakdown from "./Coastbreakdown"
// import Excecutivemetrics from './Executivemetrics';
// import Subscriptionplanmetrix from './Subscriptionplanmetrix';
// import Salesexecutiveperformancee from './Salesexecutiveperformance';


// function Sales() {
//   return (
//     <div>
    
//     <h4 className='mb-4' style={{paddingLeft:"28px"}}>Sales </h4>
//     <Container>
//     <Grid container spacing={3}>
//       <Grid item xs={12} sm={6} md={3}>
//     <AppWidgetSummary title="Total Companies"  total={120} icon={'ant-design:user-outlined'} />
//     </Grid>

//     <Grid item xs={12} sm={6} md={3}>
//     <AppWidgetSummary title=" Revenue" color="info" total={120} icon={'ant-design:user-outlined'} />
//     </Grid>


//     <Grid item xs={12} sm={6} md={3}>
//     <AppWidgetSummary title=" Profit  " color="warning"  total={120} icon={'ant-design:user-outlined'} />
//     </Grid>

    
//     <Grid item xs={12} sm={6} md={3}>
//     <AppWidgetSummary title="Cost" color="success"  total={120} icon={'ant-design:user-outlined'} />
//     </Grid>

//     <Grid item xs={12} md={8} lg={8}>
//     <Salesrevenue/>
//   </Grid>

//   <Grid item xs={12} md={4} lg={4}>
//   <h4 className='mb-4' style={{paddingLeft:"28px"}}>Costbreakdown</h4>
//   <Costbreakdown/>
//   </Grid>



// <br/><br/>

//   <Grid item xs={12} md={8} lg={8}>
//   <h4 className='mb-4' style={{paddingLeft:"28px"}}>Sales Excecutive Performance</h4>
//   <Salesexecutiveperformancee/>
// </Grid>

// <Grid item xs={12} md={4} lg={4}>
// <h4 className='mb-4' style={{paddingLeft:"28px"}}>State Wise Sales</h4>
// <Costbreakdown/>
// </Grid>
  



//     </Grid>
//     </Container>




//     </div>
//   )
// }

// export default Sales
/* <div><h4>Sales<Button variant="primary" style={{float:"right"}}  onClick={
          () => history("/dashboard/SalesMarketing")
        }>
        Share Mail
       </Button></h4></div> */