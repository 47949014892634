/* eslint-disable */

import { filter } from 'lodash';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import axios from 'axios'
import { connect } from "react-redux";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Key from '../clientVariables.json'

import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import SessionAlert from 'src/SessionAlert';
import moment from 'moment'

import { showLoader } from "../constant/loader.action";
import { hideLoader } from "../constant/loader.action";

import { toast } from "react-toastify";
import ToastMessage from '../components/ToastMessage';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_id', label: 'Employee Code', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'reporting', label: 'Reporting Person', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: 'status', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.UserName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


function User(props) {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [users, setUsers] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false)
  const [refreshh, setrefreshh] = useState(1);
  const [actionPop, setActionPop] = useState(true)
  const history = useNavigate();


  useEffect(() => {
    props.showLoader();

    axios
      .post(`${Key.domain}/admin/get_crm_users`, {
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        console.log(response)
        setUsers(response.data.data)
        props.hideLoader();

      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          props.hideLoader();

          setSessionExpired(true)

        }
      });;


  }, [refreshh])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.empcode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {

    console.log(newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;


  const handleChangeUserStatus = (customerid, status) => {

    console.log(customerid)
    console.log(status)

    props.showLoader();


    axios
      .post(`${Key.domain}/admin/active_inactive_crm_user`, {
        "userid": customerid,
        "status": status,
        "admin_id": sessionStorage.getItem("id"),
        "session_token": props.currentUser.session_token
      })
      .then((response) => {
        console.log(response)


        let msg = status === 1 ? "Status changed to active" : "Status changed to inactive"
        toast(
          <ToastMessage message={msg} />
        )


        let reloadusers = refreshh + 1
        setrefreshh(reloadusers)
        setActionPop(false)
        // props.hideLoader();

      }).catch((err) => {
        // console.log(err.response.data.data )
        if (err.response.data.data === "Access denied.. Please Login") {
          // props.hideLoader();

          setSessionExpired(true)
        }
      });;




  };
  const handleCellClick = (e) => {

    history("/dashboard/userdetails");

    // setUserDetailView(true)
  }

  return (
    <div style={{marginTop:"-40px"}}>
      {sessionExpired ? <SessionAlert /> :
        <Page title="Users" >
          <Container >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Users
              </Typography>
              <Button variant="contained" component={RouterLink} to="/dashboard/addUser" startIcon={<Iconify icon="eva:plus-fill" />}>
                New User
              </Button>
            </Stack>

            <Card>
              <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> 

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }} style={{boxShadow:"0x 0px 5px black"}}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                        const isItemSelected = selected.indexOf(row.empcode) !== -1;
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row.empcode)} />
                            </TableCell>
                            <TableCell
                              onClick={() => history("/dashboard/userdetails", { state: { empid: row.empcode } })}
                              align="left"
                              style={{cursor:"pointer"}}
                              >{row.empcode}</TableCell>
                            <TableCell
                              onClick={() => history("/dashboard/userdetails", { state: { empid: row.empcode } })}
                              align="left">{row.UserName ? row.UserName : "N/A"}</TableCell>
                            <TableCell align="left">{row.role ? row.role : "N/A"}</TableCell>
                            <TableCell align="left">{row.ReportingPerson ? row.ReportingPerson : "N/A"}</TableCell>
                            <TableCell align="left">{parseInt(row.status) === 1 ? "Active" : "In Active"}</TableCell>

                            <TableCell align="left">
                              <UserMoreMenu
                                userid={row.empcode}
                                status={row.status}
                                handleChangeUserStatus={handleChangeUserStatus}
                                refreshh={refreshh} 
                                />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>}
    </div>
  );
}


const mapDispatchToProps = dispatch => {

  return {

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),

  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.UserReducer.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(User);